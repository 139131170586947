import { EditOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Layout, Row, message } from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';

import 'antd/dist/antd.css';
import { TFunction, withTranslation } from 'react-i18next';
import Formatter from '../../classes/Formatter';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ClientsController from '../../structures/controllers/Clients';
import UserController from '../../structures/controllers/User';
import { IGetCustomer } from '../../structures/interfaces/Clients';
import { IGetUserResponse } from '../../structures/interfaces/User';
import UpdateAccount from './updateAccount';
import UpdatePassword from './updatePassword';

const { Meta } = Card;

const UserAccount = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const [user, setUser] = useState<IGetUserResponse>();
  const [customer, setCustomer] = useState<IGetCustomer>();
  const [branchCompanyModalVisible, setBranchCompanyModalVisible] =
    useState(false);
  const [userRequesting, isLoadingU] = useRequest(UserController.getUser);
  const [customerRequesting, isLoadingC] = useRequest(
    ClientsController.getCustomer
  );
  const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false);
  const { userData, customerData } = useContext(UserContext);

  const errorToEnterOnAccount = () => {
    message.error(t('pages.account.error.errToAccess'));
  };

  useEffect(() => {
    if (userData) {
      userRequesting(userData.id)
        .then(usrData => setUser(usrData))
        .catch(() => errorToEnterOnAccount());
    } else if (customerData) {
      customerRequesting(customerData.id)
        .then(cstData => setCustomer(cstData))
        .catch(() => errorToEnterOnAccount());
    }
  }, [customerData, userData, branchCompanyModalVisible]);

  return userData ? (
    <>
      <Layout>
        <Card
          loading={isLoadingU}
          style={{
            width: 350,
            marginTop: 24,
            marginLeft: 24,
          }}
          actions={[
            <Button
              block
              style={{ border: 'none' }}
              onClick={() => {
                setBranchCompanyModalVisible(true);
              }}
            >
              <EditOutlined key="edit" /> {t('pages.account.editAccountBtn')}
            </Button>,
            <Button
              block
              style={{ border: 'none' }}
              onClick={() => {
                setUpdatePasswordVisible(true);
              }}
            >
              <LockOutlined key="changePassword" />{' '}
              {t('pages.account.changePasswordBtn')}
            </Button>,
          ]}
        >
          {user && (
            <>
              {' '}
              <Row style={{ marginBottom: 32, alignItems: 'center' }}>
                <Meta
                  style={{ maxWidth: '100%' }}
                  avatar={
                    <UserOutlined
                      style={{
                        padding: 5,
                        fontSize: 30,
                        border: '2px solid',
                        borderRadius: '50%',
                      }}
                    />
                  }
                  title={user && user.name}
                  description={user.userType.id}
                />
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item label={t('pages.account.tradeName')}>
                    {user.organization.tradeName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.account.cpf')}>
                    {Formatter.formatCPF(user.documentNumberCpf)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.account.phoneNumber')}>
                    {Formatter.formatCellphone(user.phoneNumber)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.account.email')}>
                    {user.email}
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            </>
          )}
        </Card>
      </Layout>

      {user && (
        <UpdateAccount
          visible={branchCompanyModalVisible}
          data={user}
          onClose={() => {
            setBranchCompanyModalVisible(false);
          }}
        />
      )}
      <UpdatePassword
        visible={updatePasswordVisible}
        onClose={() => {
          setUpdatePasswordVisible(false);
        }}
      />
    </>
  ) : (
    <>
      <Layout>
        <Card
          loading={isLoadingC}
          style={{
            width: 300,
            marginTop: 24,
            marginLeft: 24,
          }}
          actions={[
            <Button
              block
              style={{ border: 'none' }}
              onClick={() => {
                setBranchCompanyModalVisible(true);
              }}
            >
              <EditOutlined key="edit" /> {t('pages.account.editAccountBtn')}
            </Button>,
            <Button
              block
              style={{ border: 'none' }}
              onClick={() => {
                setUpdatePasswordVisible(true);
              }}
            >
              <LockOutlined key="changePassword" />{' '}
              {t('pages.account.changePasswordBtn')}
            </Button>,
          ]}
        >
          <Row style={{ marginBottom: 32, alignItems: 'center' }}>
            <Meta
              style={{ textAlign: 'center', alignItems: 'center' }}
              avatar={
                <UserOutlined
                  style={{
                    padding: 5,
                    fontSize: 30,
                    border: '2px solid',
                    borderRadius: '50%',
                  }}
                />
              }
              title={customer?.name ? customer.name : customer?.tradeName}
              description=""
            />
          </Row>
          <Row>
            <Descriptions column={1}>
              <Descriptions.Item label={t('pages.account.phoneNumber')}>
                {Formatter.formatCellphone(customer?.phoneNumber ?? '---')}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.account.email')}>
                {customer?.email ?? '---'}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Card>
      </Layout>

      {customer && (
        <UpdateAccount
          visible={branchCompanyModalVisible}
          data={customer}
          onClose={() => {
            setBranchCompanyModalVisible(false);
          }}
        />
      )}
      <UpdatePassword
        visible={updatePasswordVisible}
        onClose={() => {
          setUpdatePasswordVisible(false);
        }}
      />
    </>
  );
};

export default withTranslation()(UserAccount);
