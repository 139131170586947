import { gql, TypedDocumentNode } from 'urql';
import { ITutorial } from '../interfaces/Tutorials';

const TutorialQueries = {
  getTutorials: (): TypedDocumentNode<{
    tutorials: Array<ITutorial>;
  }> => gql`
    query getTutorials {
      tutorials: GetTutorials {
        id
        title
        description
        url
        sort
      }
    }
  `,
};

export default TutorialQueries;
