import axios from 'axios';

import User from '../classes/User';

const { REACT_APP_IMPORT_API_URL } = process.env;

const baseURL = REACT_APP_IMPORT_API_URL;

const instance = axios.create({ baseURL });

instance.interceptors.request.use(request => {
  const token = User.getToken();
  ((request ?? {}).headers ?? {})['request-source'] = 'panel';
  ((request ?? {}).headers ?? {})['Access-Control-Request-Header'] =
    'Authorization';
  ((request ?? {}).headers ?? {})['x-origin-request'] = 'Manager-panel';

  if (token)
    ((request ?? {}).headers ?? {})['Authorization'] = `Bearer ${token}`;

  return request;
});

export default instance;
