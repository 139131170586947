import { gql, TypedDocumentNode } from 'urql';
import {
  IntegrationMod,
  ISettings,
  ISettingsInput,
} from '../interfaces/Settings';

const SettingsQueries = {
  getSettings: (): TypedDocumentNode<
    { getSettings: ISettings },
    { where: { organization_id: string } }
  > => gql`
    query GetSettings($where: SettingsWhereInput!) {
      getSettings: GetSettings(where: $where) {
        id
        organizationId: organization_id
        tolerancePercentage: tolerance_percentage
        commonTsiSeedSchedulingTime: common_tsi_seed_scheduling_time
        commonWhiteSeedSchedulingTime: common_white_seed_scheduling_time
        hasSync: has_sync
        integrationUrl: integration_url
      }
    }
  `,

  updateSettings: (): TypedDocumentNode<
    { updateSettings: ISettings },
    {
      where: { organization_id: string };
      input: ISettingsInput;
    }
  > => gql`
    mutation UpdateSettings(
      $where: SettingsWhereInput!
      $input: SettingsUncheckedUpdateInput!
    ) {
      updateSettings: UpdateSettings(where: $where, input: $input) {
        id
        organizationId: organization_id
        tolerancePercentage: tolerance_percentage
        commonTsiSeedSchedulingTime: common_tsi_seed_scheduling_time
        commonWhiteSeedSchedulingTime: common_white_seed_scheduling_time
      }
    }
  `,

  //   Integration

  getIntegrationModules: (): TypedDocumentNode<
    { modules: IntegrationMod },
    { where: { id: string } }
  > => gql`
    query getIntegrationModule($where: OrganizationWhereUniqueInput!) {
      modules: GetIntegrationModule(where: $where) {
        modulesWithSync: modules_with_sync
      }
    }
  `,
};

export default SettingsQueries;
