import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Card,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  TimePicker,
  Tooltip,
} from 'antd';
import { Rule } from 'antd/lib/form';
import { isWeekend } from 'date-fns';
import { TFunction } from 'i18next';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { withTranslation } from 'react-i18next';

interface ICapacityCardProps {
  t: TFunction;
  colSize: any;
  validValueRule: any;
  unityType: string;
  disabledMaxCapacity: boolean;
  isWeekend: boolean;
  requiredRule: Rule;
  generalCapacity: number;
  saturdayCapacity: number;
  sundayCapacity: number;
}

const { Option } = Select;

const CapacityCard = ({
  t,
  colSize,
  validValueRule,
  unityType,
  disabledMaxCapacity,
  isWeekend,
  requiredRule,
  generalCapacity,
  saturdayCapacity,
  sundayCapacity,
}: ICapacityCardProps): ReactElement<unknown> => {
  const capacityPerCompanyValidator = (value: number) => {
    if (generalCapacity) {
      return value <= generalCapacity
        ? Promise.resolve()
        : Promise.reject(
            new Error(t('pages.scheduleRules.maxCapacityAlertError'))
          );
    }
    if (saturdayCapacity) {
      return value <= saturdayCapacity
        ? Promise.resolve()
        : Promise.reject(
            new Error(t('pages.scheduleRules.maxCapacityAlertError'))
          );
    }
    if (sundayCapacity) {
      return value <= sundayCapacity
        ? Promise.resolve()
        : Promise.reject(
            new Error(t('pages.scheduleRules.maxCapacityAlertError'))
          );
    }
  };

  return (
    <Card
      title={t('pages.scheduleRules.newRules.unityInformation.capacityTitle')}
      bordered={false}
    >
      <Row gutter={24}>
        <Col {...colSize}>
          <Form.Item
            dependencies={['capacityPerCompany']}
            name="generalCapacity"
            label={
              <>
                <Tooltip
                  title={t(
                    'pages.scheduleRules.newRules.unityInformation.tooltipGeneralCapacity'
                  )}
                >
                  {t(
                    'pages.scheduleRules.newRules.unityInformation.maximumCapacityForDayLabel'
                  )}{' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
            rules={[() => validValueRule]}
          >
            <InputNumber
              placeholder={t(
                'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
              )}
              addonAfter={
                unityType === 'BAG'
                  ? t(
                      'pages.scheduleRules.newRules.unityInformation.addonAfterBag'
                    )
                  : t(
                      'pages.scheduleRules.newRules.unityInformation.addonAfterSmallBag'
                    )
              }
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            name="generalHours"
            label={t(
              'pages.scheduleRules.newRules.unityInformation.generalHours'
            )}
          >
            <TimePicker.RangePicker
              format={'HH:mm'}
              style={{ width: '100%' }}
              allowClear={false}
              placeholder={[
                t('pages.scheduleRules.editRules.startTime'),
                t('pages.scheduleRules.editRules.endTime'),
              ]}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            dependencies={['generalCapacity']}
            validateFirst
            name="capacityPerCompany"
            label={
              <Tooltip
                title={t(
                  'pages.scheduleRules.newRules.capacityPerCompanyMessage'
                )}
              >
                {t(
                  'pages.scheduleRules.newRules.unityInformation.maximumCapacityForOrganizationLabel'
                )}{' '}
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[
              { ...requiredRule },
              () => validValueRule,
              {
                validator: (_, value) => capacityPerCompanyValidator(value),
              },
            ]}
          >
            <InputNumber
              disabled={disabledMaxCapacity}
              placeholder={t(
                'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
              )}
              addonAfter={
                unityType === 'BAG'
                  ? t(
                      'pages.scheduleRules.newRules.unityInformation.addonAfterBag'
                    )
                  : t(
                      'pages.scheduleRules.newRules.unityInformation.addonAfterSmallBag'
                    )
              }
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      {isWeekend && (
        <Alert
          message={t('pages.scheduleRules.newRules.workingDayAlert')}
          type="warning"
        />
      )}
    </Card>
  );
};

export default withTranslation()(CapacityCard);
