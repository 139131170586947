import {
  Descriptions,
  Divider,
  Form,
  FormInstance,
  Layout,
  message,
  Select,
  Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import {
  IFarmResponse,
  IGetClient,
} from '../../../structures/interfaces/Clients';

const handleFilterOptions = (input: string, option?: DefaultOptionType) => {
  const text = (option?.label || '') as string;
  return text.toLowerCase().includes(input.toLowerCase());
};

interface ISyncNewFarmProps {
  setSelectedFarm: Dispatch<SetStateAction<IFarmResponse | undefined>>;
  selectedFarm: IFarmResponse | undefined;
  selectedContact: IGetClient | undefined;
  form: FormInstance;
}
const SyncNewFarm = ({
  selectedFarm,
  setSelectedFarm,
  selectedContact,
  form,
}: ISyncNewFarmProps): ReactElement => {
  const { userData } = useContext(UserContext);
  const [fetchFarms] = useRequest(ClientsController.fetchOrgFarms);
  const [farms, setFarms] = useState<IFarmResponse[]>([]);

  const farmOptions: DefaultOptionType[] = farms.map(contact => ({
    value: contact.id,
    label: contact.name,
  }));

  const contactName =
    selectedContact?.name ||
    selectedContact?.tradeName ||
    selectedContact?.companyName;

  const contactDescriptions = (
    <Descriptions column={2} labelStyle={{ fontWeight: 'bold' }}>
      <Descriptions.Item label="Cliente">{contactName}</Descriptions.Item>

      <Descriptions.Item label="E-mail">
        {selectedContact?.email}
      </Descriptions.Item>

      <Descriptions.Item label="CPF">
        {Formatter.formatCPF(selectedContact?.documentNumberCpf)}
      </Descriptions.Item>
    </Descriptions>
  );

  const onSelect = (value: string) => {
    const selectedContact = farms.find(farm => farm.id === value);
    setSelectedFarm(selectedContact);
  };

  useEffect(() => {
    const orgId = userData?.organization.id || '';

    fetchFarms({ orgId })
      .then(setFarms)
      .catch(() => message.error('Clientes não encontrados'));
  }, []);

  return (
    <Layout style={{ background: '#fff' }}>
      {contactDescriptions}
      <Typography.Text style={{ marginBottom: 8 }}>Fazendas</Typography.Text>

      <Form form={form}>
        <Form.Item name={'farmId'}>
          <Select
            showSearch
            allowClear
            onSelect={onSelect}
            options={farmOptions}
            optionFilterProp="children"
            filterOption={handleFilterOptions}
            placeholder="Selecione uma fazenda"
            onClear={() => setSelectedFarm(undefined)}
          />
        </Form.Item>
      </Form>

      {selectedFarm && (
        <>
          <Divider />

          <Descriptions column={2} labelStyle={{ fontWeight: 'bold' }}>
            <Descriptions.Item label="Nome">
              {selectedFarm?.name}
            </Descriptions.Item>

            <Descriptions.Item label="CNPJ">
              {Formatter.formatCNPJ(selectedFarm.documentNumberCnpj)}
            </Descriptions.Item>

            <Descriptions.Item label="Insc. Estadual">
              {selectedFarm.stateRegistration}
            </Descriptions.Item>

            <Descriptions.Item label="Endereço">
              {selectedFarm.address}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </Layout>
  );
};

export default SyncNewFarm;
