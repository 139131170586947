import { ReactElement, useEffect, useMemo, useState } from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button, Card, Form, FormItemProps, Input, message, Row } from 'antd';

import AuthContainer from '../../../components/authentication/authContainer';
import UserController from '../../../structures/controllers/User';

import Authentication from '..';
import { useRequest } from '../../../hooks/useRequest';
import './index.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface formValues {
  password: string;
  email: string;
}

interface ILoginProps {
  t: TFunction;
  bordered?: boolean;
}

const ResetPassword = ({
  t,
  bordered = true,
}: ILoginProps): ReactElement<unknown> => {
  const [resetPasswordRequest, isResetPasswordRequesting] = useRequest(
    UserController.resetPassword
  );
  const [hasError, setHasError] = useState<FormItemProps>({});
  const location = useLocation<{ userId: string; token: string }>();
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!location.state?.userId || !location.state?.token) {
      history.push('/recuperar-senha');
    }
  }, []);

  const onFinish = (values: formValues) => {
    resetPasswordRequest({
      ...values,
      id: location.state.userId,
      token: location.state.token,
    })
      .then(() => {
        history.push('/auth');
        message.success('Senha atualizada com sucesso!');
      })
      .catch(() => {
        message.error('Não foi possível atualizar sua senha!');
      });
  };

  const RecoveryCodeForm = useMemo(
    () => (
      <Form
        className="form"
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        form={form}
      >
        <div>
          <Form.Item
            validateFirst
            name="password"
            label={t('pages.login.recoveryPassword.newPassword')}
            {...hasError}
            rules={[
              {
                required: true,
                message: t('pages.login.fields.requiredField'),
              },
              {
                min: 6,
                message: t('pages.login.fields.minimumDigits'),
                validateTrigger: 'onBlur',
              },
              {
                max: 128,
                message: t('pages.login.fields.maximumDigits'),
                validateTrigger: 'onBlur',
              },
            ]}
          >
            <Input.Password
              placeholder={t(
                'pages.login.recoveryPassword.newPasswordPlaceholder'
              )}
            />
          </Form.Item>

          <Form.Item
            validateFirst
            name="newPassword"
            label={t('pages.login.recoveryPassword.confirmPassword')}
            {...hasError}
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('pages.login.fields.requiredField'),
              },
              {
                validator: (_: unknown, value: string): Promise<void> => {
                  if (
                    `${value}` === form.getFieldsValue(['password'])['password']
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject();
                },
                message: t(
                  'pages.createPassword.fields.rules.differentPassword'
                ),
              },
            ]}
          >
            <Input.Password
              placeholder={t(
                'pages.login.recoveryPassword.newPasswordPlaceholder'
              )}
            />
          </Form.Item>
        </div>

        <Row justify="end">
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={isResetPasswordRequesting}
            style={{
              width: '100%',
            }}
          >
            {t('pages.login.recoveryPassword.confirmButton')}
          </Button>
        </Row>

        <Row justify="start">
          <Button
            onClick={() => history.push('/recuperar-senha/confirmar-codigo')}
            type="link"
            icon={<ArrowLeftOutlined />}
          >
            {t('pages.truckLoads.createLoad.viewLoad.backToListing')}
          </Button>
        </Row>
      </Form>
    ),
    [isResetPasswordRequesting, hasError]
  );

  return (
    <Authentication>
      <AuthContainer className="auth-container">
        <Card className="login-card" bordered={bordered}>
          <header>
            <div className="decoration"></div>
            <div className="header-container">
              <h1 className="title">
                {t('pages.login.recoveryPassword.title')}
              </h1>
              <span className="subtitle">
                {t('pages.login.recoveryPassword.subtitle')}
              </span>
            </div>
          </header>
          {RecoveryCodeForm}
        </Card>
      </AuthContainer>
    </Authentication>
  );
};

export default withTranslation()(ResetPassword);
