import { UF } from '../../interfaces/UF';
import { CompanyTypeEnum } from '../../interfaces/Company/CompanyType';
import { DocumentTypeEnum } from '../../interfaces/Company/DocumentType';
import { CompanyFunctionEnum } from '../../interfaces/Company/CompanyFunction';
import {
  ICompanyDto,
  ICreateCompanyDto,
  IUpdateCompanyDto,
  ICreateCompanyForm,
  IUpdateCompanyForm,
} from '../../interfaces/Company/CompanyDto';

abstract class CompanyModel implements ICompanyDto {
  user_id?: string;
  url_logo?: string;
  reference_company_id?: string;

  city_id?: number;
  complement?: string;
  country_id?: string;
  district_id?: number;
  district_name?: string;
  number?: string;
  state_id?: string;
  street?: string;
  zip_code_address?: string;

  contact_email?: string;
  contact_phone?: string;

  has_fleet?: boolean;
  fleet_quantity?: number;

  constructor(data: ICreateCompanyForm) {
    this.user_id = data.userId;
    this.url_logo = data.urlLogo;

    this.city_id = data.city;
    this.complement = data.complement;
    this.country_id = 'BR';
    this.number = data.addressNumber?.trim();
    this.state_id = data.state;
    this.street = data.address.trim();
    this.zip_code_address = data.zipCode?.replace(/\D/g, '');

    if (data.districtId) {
      this.district_id = data.districtId;
    } else {
      this.district_name = data.district;
    }

    this.contact_email = data.email;
    this.contact_phone = '+55' + data.phoneNumber.replace(/\D/g, '');

    this.has_fleet = data.hasFleet;
    this.fleet_quantity = Number(data.fleetQuantity) || undefined;
  }
}

export class CreateCompanyModel
  extends CompanyModel
  implements ICreateCompanyDto
{
  mother_company_id?: string;

  trade_name?: string;
  company_name: string;
  document_number: string;
  document_type: DocumentTypeEnum;
  company_type: CompanyTypeEnum;
  company_function: CompanyFunctionEnum;

  areas: string[];

  parent_trade_name?: string;
  parent_company_name?: string;
  parent_document_number?: string;
  parent_company_type?: CompanyTypeEnum;
  parent_document_type?: DocumentTypeEnum;

  constructor(data: ICreateCompanyForm) {
    super(data);

    const clearDocumentNumber = data.companyDocument.replace(/\D/g, '');
    const documentType =
      DocumentTypeEnum[clearDocumentNumber.length === 14 ? 'cnpj' : 'cpf'];

    const clearMotherDocumentNumber =
      data.motherCompany?.documentNumber?.replace(/\D/g, '');
    const motherCompanyType = data.motherCompany?.companyType
      ? CompanyTypeEnum[data.motherCompany.companyType]
      : undefined;
    const motherDocumentType = clearMotherDocumentNumber
      ? DocumentTypeEnum[
          clearMotherDocumentNumber.length === 14 ? 'cnpj' : 'cpf'
        ]
      : undefined;

    this.trade_name = data.companyTradeName.trim();
    this.company_name = data.companyName.trim();
    this.document_number = clearDocumentNumber;
    this.document_type = documentType;
    this.company_type = CompanyTypeEnum[data.companyType];
    this.company_function = CompanyFunctionEnum[data.companyFunction];

    this.areas = data.operationAreas || [];

    if (data.motherCompany?.id) {
      this.mother_company_id = data.motherCompany.id;
    } else {
      this.parent_trade_name = data.motherCompany?.tradeName?.trim();
      this.parent_company_name = data.motherCompany?.companyName?.trim();
      this.parent_document_number = clearMotherDocumentNumber;
      this.parent_company_type = motherCompanyType;
      this.parent_document_type = motherDocumentType;
    }
  }
}

export class UpdateCompanyModel
  extends CompanyModel
  implements IUpdateCompanyDto
{
  id: string;
  completing_data: boolean;
  areas_to_remove: UF[];
  areas_to_include: UF[];

  constructor(data: IUpdateCompanyForm) {
    super(data);

    this.id = data.id;
    this.completing_data = true;
    this.areas_to_remove = [];
    this.areas_to_include = data.operationAreas || [];
  }
}
