import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  message,
} from 'antd';
import { t } from 'i18next';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import Normalizer from '../../../../../classes/Normalizer';
import { useRequest } from '../../../../../hooks/useRequest';
import VehiclesController from '../../../../../structures/controllers/Vehicle';
import {
  ICreateVehicle,
  ISerializedUpdateVehicle,
  IUpdateVehicle,
} from '../../../../../structures/interfaces/Vehicle';
import { IErrorMessage } from '../../../../../utils/interfaces';
import UserContext from '../../../../../contexts/user';

const TRANSLATION_PATH = 'pages.vehicles.modal';
interface IModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
}

interface ICreateModalProps extends IModalProps {
  action: 'create';
}

interface IUpdateModalProps extends IModalProps {
  action: 'update';
  vehicleToUpdate: IUpdateVehicle;
  setVehicleToUpdate: React.Dispatch<
    React.SetStateAction<IUpdateVehicle | undefined>
  >;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CreateUpdateVehicleModal = (
  props: ICreateModalProps | IUpdateModalProps
): ReactElement => {
  const { visible, action, setVisible, onConfirm } = props;
  const { vehicleToUpdate, setVehicleToUpdate } = props as IUpdateModalProps;
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(true);
  const [initialValues, setInitialValues] = useState<IUpdateVehicle>();
  const [changedFields, setChangedFields] = useState<IUpdateVehicle>({});
  const { userData } = useContext(UserContext);
  const [form] = Form.useForm();

  const [createVehiclesRequest, isCreateVehiclesRequesting] = useRequest(
    VehiclesController.createVehicle
  );

  const [updateVehiclesRequest, isUpdateVehiclesRequesting] = useRequest(
    VehiclesController.updateVehicle
  );

  const handleValuesChange = (
    _: IUpdateVehicle,
    allFinalValues: IUpdateVehicle
  ) => {
    if (action === 'update') {
      const changedFields = Object.fromEntries(
        Object.entries(allFinalValues).reduce<[string, string | undefined][]>(
          (acc, curr) => {
            if (
              curr[1] !== (initialValues as Record<string, unknown>)[curr[0]]
            ) {
              return [...acc, curr];
            }
            return acc;
          },
          []
        )
      );

      setChangedFields(changedFields);
      if (Object.keys(changedFields).length > 0) setIsSubmitAvailable(true);
      else setIsSubmitAvailable(false);
    }
  };

  const handleSubmit = () => {
    if (userData) {
      form.validateFields().then(values => {
        if (action === 'create') {
          const serializedVehicle: ICreateVehicle = {
            organization_id: userData?.organization.id,
            vehicle_model: values.vehicleModel,
            weight_capacity: values.weightCapacity,
            bags_capacity: values.bagsCapacity,
            axles_amount: values.axlesAmount,
            bodywork: values.bodywork,
          };
          createVehiclesRequest({ input: serializedVehicle })
            .then(() => {
              setVisible(false);
              form.resetFields();
              showSuccess();
              onConfirm();
            })
            .catch(err => {
              showError(err);
            });
        } else if (action === 'update') {
          if (changedFields && vehicleToUpdate?.id) {
            const serializedChangedFields: ISerializedUpdateVehicle = {
              vehicle_model: changedFields.vehicleModel ?? undefined,
              weight_capacity: changedFields.weightCapacity ?? undefined,
              bags_capacity: changedFields.bagsCapacity ?? undefined,
              axles_amount: changedFields.axlesAmount ?? undefined,
              bodywork: changedFields.bodywork ?? undefined,
            };
            Object.keys(serializedChangedFields).forEach(key => {
              if (
                serializedChangedFields[
                  key as keyof ISerializedUpdateVehicle
                ] === undefined
              ) {
                delete serializedChangedFields[
                  key as keyof ISerializedUpdateVehicle
                ];
              }
            });

            updateVehiclesRequest({
              vehicleId: vehicleToUpdate.id,
              input: serializedChangedFields,
            })
              .then(() => {
                setVisible(false);
                form.resetFields();
                showSuccess();
                onConfirm();
              })
              .catch(err => {
                showError(err);
              });
          }
        }
      });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    setVehicleToUpdate && setVehicleToUpdate(undefined);
  };

  const showSuccess = () => {
    message.success({
      content: t(`pages.vehicles.success.${action}`),
      style: { marginTop: '4rem' },
    });
  };

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      style: { marginTop: '4rem' },
    });
  };

  const footer = (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <span
        style={{
          display: 'flex',
          gap: '12px',
          marginRight: '8px',
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
      >
        <Popconfirm
          okText={t('general.yes').toString()}
          cancelText={t('general.no').toString()}
          onConfirm={handleCancel}
          title={t(`${TRANSLATION_PATH}.confirmCancel`).toString()}
        >
          <Button
            key="back"
            danger
            disabled={isCreateVehiclesRequesting || isUpdateVehiclesRequesting}
            style={{ borderRadius: 2 }}
          >
            {t('general.cancel')}
          </Button>
        </Popconfirm>
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          htmlType="submit"
          loading={isCreateVehiclesRequesting || isUpdateVehiclesRequesting}
          style={{ borderRadius: 2 }}
          disabled={!isSubmitAvailable}
        >
          {action === 'create' ? t('general.create') : t('general.update')}
        </Button>
      </span>
    </div>
  );

  useEffect(() => {
    if (action === 'update') {
      form.setFieldsValue(vehicleToUpdate);
      setInitialValues(vehicleToUpdate);
      setIsSubmitAvailable(false);
    }
  }, [vehicleToUpdate]);

  return (
    <Modal
      open={visible}
      title={t(`${TRANSLATION_PATH}.${action}`)}
      okText="Confirmar"
      cancelButtonProps={{ danger: true }}
      cancelText="Cancelar"
      onCancel={handleCancel}
      onOk={handleSubmit}
      footer={footer}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        // initialValues={{ modifier: 'public' }}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="vehicleModel"
              label={t(`${TRANSLATION_PATH}.vehicleModel`)}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="axlesAmount"
              label={t(`${TRANSLATION_PATH}.axlesAmount`)}
              rules={[
                {
                  type: 'number',
                  min: 0,
                  message: t(`${TRANSLATION_PATH}.errors.axlesAmountMinValue`),
                },
                { required: true },
              ]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="weightCapacity"
              label={t(`${TRANSLATION_PATH}.weightCapacity`)}
              normalize={Normalizer.onlyNumbers}
              validateFirst
              rules={[
                {
                  type: 'number',
                  min: 0,
                  message: t(
                    `${TRANSLATION_PATH}.errors.weightCapacityMinValue`
                  ),
                },
                { required: true },
              ]}
            >
              <InputNumber addonAfter="kg" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="bagsCapacity"
              label={t(`${TRANSLATION_PATH}.bagsCapacity`)}
              rules={[
                {
                  type: 'number',
                  min: 0,
                  message: t(`${TRANSLATION_PATH}.errors.bagsCapacityMinValue`),
                },
                { required: true },
              ]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="bodywork"
              label={t(`${TRANSLATION_PATH}.bodywork`)}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateUpdateVehicleModal;
