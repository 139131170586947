import { IFindShippingCompanyResponse } from '../../../structures/interfaces/ShippingCompany';

export const BuildCarrierSlug = (
  carriers: IFindShippingCompanyResponse[]
): IFindShippingCompanyResponse[] => {
  return carriers?.map(carrier => ({
    ...carrier,
    slug: `${carrier.tradeName} ${carrier.companyName} ${carrier.documentNumberCnpj}`.toLowerCase(),
  }));
};
