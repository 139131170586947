import { IGetClient } from '../../../structures/interfaces/Clients';

export const BuildSlug = (contacts: IGetClient[]): IGetClient[] => {
  return contacts?.map(contact => ({
    ...contact,
    slug: `${contact.name} ${contact.companyName} ${contact.documentNumberCpf} ${contact.phoneNumber} ${contact.email} ${contact.status}`.toLowerCase(),
  }));
};
export const FilterContactsByStatus = (
  contacts: IGetClient[],
  status: string
): IGetClient[] => {
  return status !== undefined
    ? contacts.filter(contact => contact.status === status)
    : contacts;
};
