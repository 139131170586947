import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Moment } from 'moment';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { IContItemsResponse } from '../../../../../structures/interfaces/TruckLoad';
import {
  FilterContractItemsByCultivation,
  FilterContractsByDate,
} from '../LoadSteps.utils';

export interface ICultivationType extends DefaultOptionType {
  name?: string;
}
interface ITruckLoadsFilterProps {
  cultivations: ICultivationType[];
  contractItems: IContItemsResponse[];
  setFilteredContractItems: Dispatch<SetStateAction<IContItemsResponse[]>>;
  isCustomOrdering: boolean;
  setIsCustomOrdering: (value: boolean) => void;
}
interface IFilterFormFields {
  search: string;
  searchContracts: string;
  cultivation: string;
  contractDate: Moment[];
  customOrder: boolean;
}

const TruckLoadsFilter = ({
  cultivations,
  contractItems,
  setFilteredContractItems,
  isCustomOrdering,
  setIsCustomOrdering,
}: ITruckLoadsFilterProps): ReactElement => {
  const [form] = Form.useForm<IFilterFormFields>();

  const onFinish = ({
    search = '',
    searchContracts = '',
    cultivation,
    contractDate,
  }: IFilterFormFields) => {
    const filteredItems = contractItems.filter(item => {
      if (searchContracts) {
        return item?.contractSlug?.includes(
          searchContracts.toLowerCase().trim()
        );
      } else {
        return item?.slug?.includes(search.toLowerCase().trim());
      }
    });
    const aux = FilterContractItemsByCultivation(filteredItems, cultivation);
    const filterByDate = FilterContractsByDate(aux, contractDate);
    setFilteredContractItems(filterByDate);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item label="Pesquisa" name={'search'}>
            <Input.Search
              enterButton
              style={{ width: '100%' }}
              allowClear
              onSearch={() => form.submit()}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Contrato" name={'searchContracts'}>
            <Input.Search
              enterButton
              style={{ width: '100%' }}
              allowClear
              onSearch={() => form.submit()}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Cultivar" name={'cultivation'}>
            <Select
              showSearch
              optionFilterProp="name"
              filterOption={(input, option) =>
                (option?.name ?? '').toString().includes(input)
              }
              style={{ width: '100%' }}
              options={cultivations}
              allowClear
              key={'select'}
              onSelect={() => form.submit()}
              onClear={() => form.submit()}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Data do contrato" name={'contractDate'}>
            <DatePicker.RangePicker
              format={'DD/MM/YYYY'}
              onChange={() => form.submit()}
              style={{ width: '100%' }}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name={'customOrder'}>
            <Checkbox
              checked={isCustomOrdering}
              onChange={value => setIsCustomOrdering(value.target.checked)}
            >
              Quero ordenar a descarga
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Space>
            <Button
              icon={<ClearOutlined />}
              onClick={() => {
                form.resetFields();
                form.submit();
              }}
            >
              Limpar
            </Button>
            <Button
              icon={<FilterOutlined />}
              type="primary"
              onClick={() => form.submit()}
            >
              Filtrar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default TruckLoadsFilter;
