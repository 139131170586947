import { ReactElement, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Alert, Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import Formatter from '../../../classes/Formatter';
import AddressController from '../../../structures/controllers/Address';
import {
  ICityName,
  IPickUpLocation,
} from '../../../structures/interfaces/Address';
import { UF, UFNameOptions } from '../../../utils/UF';
import { useRequest } from '../../../hooks/useRequest';
import { localCountryName } from '../localCountryName';
import UserContext from '../../../contexts/user';
import { scrollOptions } from '../../../utils/formOptions';

const PickUpLocation = ({
  t,
  visible,
  onClose,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
}): ReactElement<unknown> => {
  const [ufOptions, setUfOptions] = useState<string>();
  const [stateName, setStateName] = useState<string>('');
  const [cityOptions, setCityOptions] = useState<ICityName[]>([]);
  const [findCitiesForStateRequest] = useRequest(
    AddressController.findCitiesByState
  );
  const [newPickUpLocationRequest] = useRequest(AddressController.newPickUpLoc);
  const [error, setError] = useState<{
    message: string;
    type: 'error';
    description?: string;
  } | null>(null);

  const { Option } = Select;
  const [form] = Form.useForm();
  const { userData } = useContext(UserContext);

  const onFinish = (values: IPickUpLocation) => {
    const zp = values.zipCode.replace('-', '');
    const req = {
      ...values,
      countryName: `${localCountryName}`,
      organizationId: `${userData?.organization.id}`,
      stateName: stateName,
      zipCode: zp,
    };

    newPickUpLocationRequest(req)
      .then(() => {
        form.resetFields();
        onClose();
      })
      .catch(({ message, description }) =>
        setError({
          message: t(message),
          description: t(description),
          type: 'error',
        })
      );
  };

  const handleOnChange = (selectedValue: string) => {
    const shortName = UFNameOptions.find(({ value }) => {
      return value === selectedValue;
    });
    setUfOptions(selectedValue);
    setStateName(shortName?.label as string);
  };

  useEffect(() => {
    if (ufOptions) {
      findCitiesForStateRequest(ufOptions)
        .then(city => {
          setCityOptions(city);
          if (
            cityOptions.find(
              city => form.getFieldValue('cityName') !== city.cityName
            )
          ) {
            form.setFieldsValue({
              cityName: null,
            });
          }
        })
        .catch(({ message, description }) =>
          setError({
            message: t(message),
            description: t(description),
            type: 'error',
          })
        );
    }
  }, [ufOptions]);

  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 6 };

  const formPickUp = (
    <Form
      scrollToFirstError={scrollOptions}
      name="basic"
      onFinish={onFinish}
      layout="vertical"
      form={form}
    >
      {error && (
        <Alert
          message={error.message}
          description={error.description}
          type={error.type}
          closable
          style={{ marginBottom: 24 }}
        />
      )}

      <Row gutter={[24, 0]}>
        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.pickUpLocations.localName')}
            name="title"
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.pickUpLocations.requiredRuleMessage'
                ),
              },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.pickUpLocations.localNamePlaceholder'
              )}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            normalize={Formatter.formatZipCode}
            label={t('pages.settings.pickUpLocations.zipcode')}
            name="zipCode"
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.pickUpLocations.requiredRuleMessage'
                ),
              },
              {
                min: 9,
                max: 9,
                message: t('pages.settings.pickUpLocations.zipcodeRuleMinSize'),
              },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.pickUpLocations.zipcodePlaceholder'
              )}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.pickUpLocations.state')}
            name="stateShortName"
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.pickUpLocations.requiredRuleMessage'
                ),
              },
            ]}
          >
            <Select
              placeholder={t('pages.settings.pickUpLocations.statePlaceholder')}
              onSelect={(value: UF) => handleOnChange(value)}
            >
              {UFNameOptions.map(uf => {
                return (
                  <Option key={uf.value} value={uf.value}>
                    {uf.value} - {uf.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.pickUpLocations.city')}
            name="cityName"
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.pickUpLocations.requiredRuleMessage'
                ),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t('pages.settings.pickUpLocations.cityPlaceholder')}
              optionFilterProp="children"
            >
              {cityOptions?.map(city => {
                return (
                  <Option
                    key={`${city.cityName}_${city.id}`}
                    value={city.cityName}
                  >
                    {city.cityName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.pickUpLocations.district')}
            name="district"
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.pickUpLocations.requiredRuleMessage'
                ),
              },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.pickUpLocations.districtPlaceholder'
              )}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.pickUpLocations.street')}
            name="street"
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.pickUpLocations.requiredRuleMessage'
                ),
              },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.pickUpLocations.streetPlaceholder'
              )}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.pickUpLocations.streetNumber')}
            name="streetNumber"
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.pickUpLocations.requiredRuleMessage'
                ),
              },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.pickUpLocations.streetNumberPlaceholder'
              )}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.pickUpLocations.complement')}
            name="complement"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.pickUpLocations.complementPlaceholder'
              )}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const footerModal = [
    <Button key="back" danger onClick={onClose}>
      {t('pages.settings.pickUpLocations.cancelButton')}
    </Button>,
    <Button
      key="confirm"
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
        setError(null);
      }}
    >
      {t('pages.settings.pickUpLocations.submitButton')}
    </Button>,
  ];

  return (
    <>
      <Modal
        onCancel={onClose}
        open={visible}
        title={t('pages.settings.pickUpLocations.title')}
        width={1000}
        centered
        onOk={() => {
          form.validateFields();
        }}
        footer={footerModal}
      >
        {formPickUp}
      </Modal>
    </>
  );
};

export default withTranslation()(PickUpLocation);
