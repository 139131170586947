import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Layout,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { ColumnsType } from 'antd/lib/table';
import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ReactChild } from 'react-router/node_modules/@types/react';
import Spreadsheet from 'react-spreadsheet';
import axios from '../../../api/requester';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import BreadCrumb from '../../../structures/controllers/Breadcrumb';
import CompanyController from '../../../structures/controllers/Company';
import {
  IColumnsOfImportedCustomersDocument,
  IErrorOnImportColumns,
  IImportColumns,
  IImportCustomersAPIResponse,
  IImportData,
  IUserDataOnImport,
} from '../../../structures/interfaces/Clients';
import { BaseSelectRef } from 'rc-select';
import { scrollOptions } from '../../../utils/formOptions';

const { Option } = Select;

const ConfigureCustomerImport = ({
  t,
}: {
  t: TFunction;
}): ReactElement<unknown> => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { state }: { state: IImportData } = useLocation();
  if (!state) history.push('/clientes');
  const { userData } = useContext(UserContext);

  const [getOrganizationApiKey] = useRequest(
    CompanyController.getOrganizationApiKey
  );

  const [pageSize, setPageSize] = useState(10);
  const [columnsOptions, setColumnsOptions] = useState<
    Array<{ title: string; value: string }>
  >([]);

  const customerNameRef = useRef<BaseSelectRef | null>(null);
  const companyNameRef = useRef<BaseSelectRef | null>(null);
  const tradeNameRef = useRef<BaseSelectRef | null>(null);
  const documentNumberCpfRef = useRef<BaseSelectRef | null>(null);
  const documentNumberCnpjRef = useRef<BaseSelectRef | null>(null);
  const stateRegistrationRef = useRef<BaseSelectRef | null>(null);
  const referenceNumberRef = useRef<BaseSelectRef | null>(null);
  const phoneNumberRef = useRef<BaseSelectRef | null>(null);
  const emailRef = useRef<BaseSelectRef | null>(null);

  const showSuccessMessage = () => {
    message.success({
      content: t('pages.clients.importConfig.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  const infoErrorSameValue = () => {
    message.error(t('pages.clients.importConfig.errors.sameValue'));
  };

  const infoAPIError = () => {
    message.error(t('pages.clients.importConfig.errors.api'));
  };

  const infoCnpjAndCpfNotInsertedError = () => {
    message.error({
      content: t('pages.clients.importConfig.errors.cnpjAndCpfNotInserted'),
      duration: 5,
    });
  };

  const infoWrongInputs = () => {
    Modal.warning({
      title: t('pages.clients.importConfig.errors.message'),
      content: t('pages.clients.importConfig.errors.verifyInputs'),
      centered: true,
      width: '50%',
    });
  };

  const errorColumns: ColumnsType<IErrorOnImportColumns> = [
    {
      title: t('pages.clients.importConfig.labelName'),
      dataIndex: ['owner_name'],
      key: 'owner_name',
    },
    {
      title: t('pages.clients.importConfig.labelTradeName'),
      dataIndex: ['trade_name'],
      key: 'trade_name',
    },
    {
      title: t('pages.clients.importConfig.labelStateRegistration'),
      dataIndex: ['state_registration'],
      key: 'state_registration',
    },
  ];

  const errorTable = (users: IUserDataOnImport[]) => (
    <>
      <p>{t('pages.clients.importConfig.errors.importFailedDescription')}</p>
      <Table
        rowKey={errOnImpCustomers => errOnImpCustomers.state_registration}
        columns={errorColumns}
        dataSource={users}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          onChange(_, size) {
            setPageSize(size);
          },
        }}
        size="small"
      />
    </>
  );

  const modalErrorWhileImporting = (users: IUserDataOnImport[]) => {
    Modal.warning({
      title: t('pages.clients.importConfig.errors.userAlreadyRegistered'),
      content: errorTable(users),
      centered: true,
      width: '50%',
      afterClose: () => {
        history.push('/clientes');
      },
    });
  };

  const modalErrorusuarios_repetidos = (users: IUserDataOnImport[]) => {
    Modal.warning({
      title: t('pages.clients.importConfig.errors.usuarios_repetidos'),
      content: errorTable(users),
      centered: true,
      width: '50%',
      afterClose: () => {
        history.push('/clientes');
      },
    });
  };

  const onFinish = (values: IColumnsOfImportedCustomersDocument) => {
    const formValues = form.getFieldsValue();
    let existsError = 0;
    Object.entries(formValues).map(formValue => {
      Object.entries(formValues).map(formValue2 => {
        if (formValue2[1] !== undefined && formValue[1] !== undefined)
          if (formValue2[0] !== formValue[0] && formValue2[0] !== 'firstLine') {
            if (formValue[1] === formValue2[1]) {
              existsError = 1;
            }
          }
      });
    });

    if (existsError === 0 && userData && values.firstLine !== undefined) {
      const firstContentLine = (values.firstLine + 1) as unknown;
      delete values.firstLine;

      Object.keys(values).forEach(
        key =>
          values[key as keyof IColumnsOfImportedCustomersDocument] ===
            undefined &&
          delete values[key as keyof IColumnsOfImportedCustomersDocument]
      );

      const columns = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [value, key])
      );

      const formData = new FormData();
      if (state.file.originFileObj)
        formData.append('file', state.file.originFileObj);

      formData.append('columns', JSON.stringify(columns));
      formData.append('start_row', firstContentLine as string | Blob);

      if (
        !('document_number_cnpj' in values) &&
        !('document_number_cpf' in values)
      ) {
        infoCnpjAndCpfNotInsertedError();
      } else {
        getOrganizationApiKey({
          organizationId: userData.organization.id,
        }).then(res => {
          axios
            .post('/importations/users', formData, {
              headers: {
                'x-access-key': res.apiKey,
              },
            })
            .then((apiResponse: unknown) => {
              const res = apiResponse as IImportCustomersAPIResponse;
              if (res.status) {
                infoWrongInputs();
              } else {
                if (res.ja_registrados && res.ja_registrados.length > 0) {
                  modalErrorWhileImporting(res.ja_registrados);
                } else if (
                  res.usuarios_repetidos &&
                  res.usuarios_repetidos.length > 0
                ) {
                  modalErrorusuarios_repetidos(res.usuarios_repetidos);
                } else {
                  showSuccessMessage();
                  history.push('/clientes');
                }
              }
            })
            .catch(() => infoAPIError());
        });
      }
    }
    if (existsError === 1) {
      infoErrorSameValue();
    }
  };

  let intervalCode: NodeJS.Timeout | string = '';
  // let intervalCode: NodeJS.Timer | string = '';

  const getTableNodesLines = () => {
    const el = document.getElementsByClassName('Spreadsheet__table')[0];

    if (!el) return [];

    const tBody = el.childNodes[1];
    return tBody.childNodes;
  };

  const getPreviewColumnNames = () => {
    const nodesLines = getTableNodesLines();

    if (!nodesLines.length) return [];

    const [tr1]: Array<ReactChild> | NodeListOf<ChildNode> = nodesLines;
    const columnsName: Array<string> = Array.prototype.map.call(
      tr1.childNodes,
      th => th.innerHTML
    ) as Array<string>;

    clearInterval(intervalCode);
    intervalCode = '';

    return columnsName.slice(1, columnsName.length);
  };

  const fillSelectColumnsOptions = () => {
    if (!intervalCode) {
      setColumnsOptions(
        getPreviewColumnNames().reduce((acc: IImportColumns[], col: string) => {
          acc.push({ title: col, value: col });
          return acc;
        }, [])
      );
    }
  };

  useEffect(() => {
    fillSelectColumnsOptions();
  }, [state?.file]);

  return (
    <>
      <Layout
        style={{
          flex: 1,
        }}
      >
        <PageHeader
          className="site-page-header"
          title={t('pages.clients.importConfig.title')}
          breadcrumb={<BreadCrumb />}
          subTitle={t('pages.clients.importConfig.subtitle')}
          ghost={false}
        />
        <Layout
          style={{
            height: '100%',
            padding: 24,
          }}
        >
          <Form
            scrollToFirstError={scrollOptions}
            onFinish={onFinish}
            layout="vertical"
            form={form}
          >
            <Card
              title={t('pages.clients.importConfig.formTitle')}
              extra={
                <Tooltip
                  title={t(
                    'pages.clients.importConfig.downloadTemplateWorksheet'
                  )}
                >
                  <a href="https://logmetrics-dev.s3.us-east-2.amazonaws.com/templates/template-clientes.xlsx">
                    <Button type="primary" icon={<DownloadOutlined />}>
                      {t('pages.clients.importConfig.templateWorksheet')}
                    </Button>
                  </a>
                </Tooltip>
              }
            >
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={t('pages.clients.importConfig.labelFirstLine')}
                    name="firstLine"
                    rules={[
                      {
                        required: true,
                        message: t('pages.clients.importConfig.rmFirstLine'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onSelect={() => customerNameRef.current?.focus()}
                      placeholder={t('pages.clients.importConfig.phFirstLine')}
                    >
                      {Array(15)
                        .fill(1)
                        .map((_, index) => (
                          <Option key={index} value={index}>
                            {t('pages.clients.importConfig.selectLine')}{' '}
                            {index + 1}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={t('pages.clients.importConfig.labelName')}
                    name="owner_name"
                    validateFirst
                    rules={[
                      {
                        message: t('pages.clients.importConfig.rmName'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onSelect={() => companyNameRef.current?.focus()}
                      ref={customerNameRef}
                      showAction={['focus']}
                      placeholder={t('pages.clients.importConfig.phSelect')}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={t('pages.clients.importConfig.labelCompanyName')}
                    name="company_name"
                  >
                    <Select
                      allowClear
                      onSelect={() => tradeNameRef.current?.focus()}
                      ref={companyNameRef}
                      showAction={['focus']}
                      placeholder={t('pages.clients.importConfig.phSelect')}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={t('pages.clients.importConfig.labelTradeName')}
                    name="trade_name"
                  >
                    <Select
                      allowClear
                      onSelect={() => documentNumberCpfRef.current?.focus()}
                      ref={tradeNameRef}
                      showAction={['focus']}
                      placeholder={t('pages.clients.importConfig.phSelect')}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.clients.importConfig.labelDocumentNumberCpf'
                    )}
                    name="document_number_cpf"
                  >
                    <Select
                      allowClear
                      onSelect={() => documentNumberCnpjRef.current?.focus()}
                      ref={documentNumberCpfRef}
                      showAction={['focus']}
                      placeholder={t('pages.clients.importConfig.phSelect')}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.clients.importConfig.labelDocumentNumberCnpj'
                    )}
                    name="document_number_cnpj"
                  >
                    <Select
                      allowClear
                      onSelect={() => stateRegistrationRef.current?.focus()}
                      ref={documentNumberCnpjRef}
                      showAction={['focus']}
                      placeholder={t('pages.clients.importConfig.phSelect')}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.clients.importConfig.labelStateRegistration'
                    )}
                    name="state_registration"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.clients.importConfig.rmStateRegistration'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onSelect={() => referenceNumberRef.current?.focus()}
                      ref={stateRegistrationRef}
                      showAction={['focus']}
                      placeholder={t('pages.clients.importConfig.phSelect')}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={
                      <Tooltip
                        title={t(
                          'pages.clients.importConfig.tooltipReferenceId'
                        )}
                      >
                        {t('pages.clients.importConfig.labelReferenceId')}{' '}
                        <QuestionCircleOutlined />
                      </Tooltip>
                    }
                    name="reference_id"
                  >
                    <Select
                      allowClear
                      onSelect={() => phoneNumberRef.current?.focus()}
                      ref={referenceNumberRef}
                      showAction={['focus']}
                      placeholder={t('pages.clients.importConfig.phSelect')}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={t('pages.clients.importConfig.labelPhoneNumber')}
                    name="phone_number"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.clients.importConfig.rmPhoneNumber'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onSelect={() => emailRef.current?.focus()}
                      ref={phoneNumberRef}
                      showAction={['focus']}
                      placeholder={t('pages.clients.importConfig.phSelect')}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    label={t('pages.clients.importConfig.labelEmail')}
                    name="email"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.clients.importConfig.rmEmail'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      ref={emailRef}
                      showAction={['focus']}
                      placeholder={t('pages.clients.importConfig.phSelect')}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card
              title={t('pages.clients.importConfig.tableTitle')}
              style={{ marginBottom: 84 }}
            >
              <Row
                style={{
                  width: '100% !important',
                  overflowX: 'auto',
                }}
              >
                <div
                  style={{
                    pointerEvents: 'none',
                  }}
                >
                  {state && <Spreadsheet data={state.table} />}
                </div>
              </Row>
            </Card>
          </Form>
        </Layout>
      </Layout>
      <Footer
        style={{
          position: 'fixed',
          left: 24,
          bottom: 0,
          width: '100%',
          backgroundColor: '#FFF',
          textAlign: 'center',
          boxShadow:
            '0 -6px 16px -8px rgba(0,0,0,.08),0 -9px 28px 0 rgba(0,0,0,.05),0 -12px 48px 16px rgba(0,0,0,.03)',
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <Button
            style={{ marginRight: 24 }}
            danger
            htmlType="reset"
            onClick={() => {
              form.resetFields();
              history.push('/clientes');
            }}
          >
            {t('pages.clients.importConfig.cancelButton')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form.submit();
            }}
          >
            {t('pages.clients.importConfig.confirmButton')}
          </Button>
        </div>
      </Footer>
    </>
  );
};

export default withTranslation()(ConfigureCustomerImport);
