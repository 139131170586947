import { ReactElement, useMemo, useState } from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { Button, Card, Form, FormItemProps, Input, Row } from 'antd';

import AuthContainer from '../../../components/authentication/authContainer';
import UserController from '../../../structures/controllers/User';

import Authentication from '..';
import { useRequest } from '../../../hooks/useRequest';
import './index.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface IRecoveryCode {
  email: string;
}

interface ILoginProps {
  t: TFunction;
  bordered?: boolean;
}

const SendRecoveryCode = ({
  t,
  bordered = true,
}: ILoginProps): ReactElement<unknown> => {
  const [codeRequest, isCodeRequesting] = useRequest(
    UserController.sendRecoveryCode
  );
  const [hasError, setHasError] = useState<FormItemProps>({});

  const history = useHistory();

  const onFinish = (values: IRecoveryCode) => {
    const lowerCaseValues: IRecoveryCode = {
      ...values,
      email: values.email.toLowerCase(),
    };

    codeRequest(lowerCaseValues.email)
      .then(() => {
        history.push('/recuperar-senha/confirmar-codigo', {
          email: lowerCaseValues.email,
        });
      })
      .catch(() => {
        setHasError({
          hasFeedback: true,
          validateStatus: 'error',
          help: t('pages.login.fields.invalidCredentials'),
        });
      });
  };

  const RecoveryCodeForm = useMemo(
    () => (
      <Form
        className="form"
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
      >
        <div>
          <Form.Item
            name="email"
            label={t('pages.login.fields.email.label')}
            {...hasError}
            rules={[
              {
                required: true,
                message: t('pages.login.fields.requiredField'),
              },
              {
                message: t('pages.login.fields.invalidEmail'),
                type: 'email',
                validateTrigger: 'click',
              },
            ]}
          >
            <Input
              type={'email'}
              placeholder={t('pages.login.recoveryPassword.placeholder')}
            />
          </Form.Item>
        </div>

        <Row justify="end">
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={isCodeRequesting}
            style={{
              width: '100%',
            }}
          >
            {t('pages.login.recoveryPassword.confirmButton')}
          </Button>
        </Row>

        <Row justify="start">
          <Button
            onClick={() => history.push('/auth')}
            type="link"
            icon={<ArrowLeftOutlined />}
          >
            {t('pages.truckLoads.createLoad.viewLoad.backToListing')}
          </Button>
        </Row>
      </Form>
    ),
    [isCodeRequesting, hasError]
  );

  return (
    <Authentication>
      <AuthContainer className="auth-container">
        <Card className="login-card" bordered={bordered}>
          <header>
            <div className="decoration"></div>
            <div className="header-container">
              <h1 className="title">
                {t('pages.login.recoveryPassword.title')}
              </h1>
              <span className="subtitle">
                {t('pages.login.recoveryPassword.subtitle')}
              </span>
            </div>
          </header>
          {RecoveryCodeForm}
        </Card>
      </AuthContainer>
    </Authentication>
  );
};

export default withTranslation()(SendRecoveryCode);
