import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import { TFunction } from 'i18next';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import AddressController from '../../../structures/controllers/Address';
import CompanyController from '../../../structures/controllers/Company';
import { ICityName } from '../../../structures/interfaces/Address';
import { IDefaultBranchCompanies } from '../../../structures/interfaces/Company/Company';
import { validateCnpj } from '../../../utils/inputRules';
import { UFNameOptions } from '../../../utils/UF';
import { scrollOptions } from '../../../utils/formOptions';

const { Title } = Typography;

const EditBranchCompany = ({
  t,
  visible,
  onClose,
  editData,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  editData: IDefaultBranchCompanies;
}): ReactElement<unknown> => {
  const [ufOptions, setUfOptions] = useState<string>();
  const [cityOptions, setCityOptions] = useState<ICityName[]>([]);
  const [error, setError] = useState<{
    message: string;
    type: 'error';
    description?: string;
  } | null>(null);

  const setState = (selectedValue: string) => {
    setUfOptions(selectedValue);
  };

  const [updateBranchOrganization, isUpdateBranchOrganizationRequesting] =
    useRequest(CompanyController.updateBranchOrganization);
  const [findCitiesForStateRequest] = useRequest(
    AddressController.findCitiesByState
  );

  const { userData, company } = useContext(UserContext);
  const [form] = Form.useForm();

  const { Option } = Select;
  const info = () => {
    message.success({
      content: t('pages.settings.editBranchCompany.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  useEffect(() => {
    if (company && editData && editData.defaultLocation) {
      form.setFieldsValue({
        tradeName: editData.tradeName,
        companyName: editData.companyName,
        documentNumberCnpj: Formatter.formatCNPJ(editData.documentNumberCnpj),
        zipCode: Formatter.formatZipCode(editData.defaultLocation.zipCode),
        stateShortName: editData.defaultLocation.stateShortName,
        cityName: editData.defaultLocation.cityName,
        district: editData.defaultLocation.district,
        street: editData.defaultLocation.street,
        streetNumber: editData.defaultLocation.streetNumber,
      });

      if (company.addresses) setState(company.addresses.stateShortName);
    }
  }, [company, editData]);

  useEffect(() => {
    if (ufOptions) {
      findCitiesForStateRequest(ufOptions)
        .then(city => {
          setCityOptions(city);
          if (
            cityOptions.find(
              city => form.getFieldValue('cityName') !== city.cityName
            )
          ) {
            form.setFieldsValue({
              cityName: null,
            });
          }
        })
        .catch(({ message, description }) =>
          setError({
            message: t(message),
            description: t(description),
            type: 'error',
          })
        );
    }
  }, [ufOptions]);

  const handleOnChange = (selectedValue: string) => {
    setUfOptions(selectedValue);
  };

  const onFinish = (values: any) => {
    if (userData && company && editData.defaultLocation) {
      values.zipCode = values.zipCode.replace(/[^0-9]/g, '');
      values.documentNumberCnpj = values.documentNumberCnpj.replace(
        /[^0-9]/g,
        ''
      );
      updateBranchOrganization({
        updateBranchOrganization: {
          ...values,
          addressId: editData.defaultLocation.id,
        },
        id: editData.id,
      })
        .then(() => {
          info();
          onClose();
        })
        .catch(({ message, description }) =>
          setError({
            message: t(message),
            description: t(description),
            type: 'error',
          })
        );
    }
  };

  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 6 };

  const formUpdateBranchCompany = (
    <Form
      scrollToFirstError={scrollOptions}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      {error && (
        <Alert
          message={error.message}
          description={error.description}
          type={error.type}
          closable
          style={{ marginBottom: 24 }}
        />
      )}

      <Row gutter={[24, 0]}>
        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editBranchCompany.tradeName')}
            name="tradeName"
            rules={[
              {
                required: true,
                message: t('pages.settings.editBranchCompany.rmTradeName'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editBranchCompany.phTradeName')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editBranchCompany.companyName')}
            name="companyName"
            rules={[
              {
                required: true,
                message: t('pages.settings.editBranchCompany.rmCompanyName'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editBranchCompany.phCompanyName')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editBranchCompany.documentNumberCnpj')}
            name="documentNumberCnpj"
            normalize={Formatter.formatCNPJ}
            validateFirst
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.editBranchCompany.rmDocumentNumberCnpj'
                ),
              },
              {
                min: 14,
                message: t(
                  'pages.settings.editBranchCompany.rmMinSizeDocumentNumberCnpj'
                ),
              },
              { ...validateCnpj, message: t(validateCnpj.message) },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.editBranchCompany.phDocumentNumberCnpj'
              )}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editBranchCompany.zipCode')}
            name="zipCode"
            normalize={Formatter.formatZipCode}
            rules={[
              {
                required: true,
                message: t('pages.settings.editBranchCompany.rmZipCode'),
              },
              {
                min: 9,
                max: 9,
                message: t('pages.settings.editBranchCompany.rmMinSizeZipCode'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editBranchCompany.phZipCode')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editBranchCompany.stateShortName')}
            name="stateShortName"
            rules={[
              {
                required: true,
                message: t('pages.settings.editBranchCompany.rmStateShortName'),
              },
            ]}
          >
            <Select
              placeholder={t(
                'pages.settings.editBranchCompany.phStateShortName'
              )}
              onSelect={(value: string) => handleOnChange(value)}
            >
              {UFNameOptions.map(uf => {
                return (
                  <Option value={uf.value}>
                    {uf.value} - {uf.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editBranchCompany.cityName')}
            name="cityName"
            rules={[
              {
                required: true,
                message: t('pages.settings.editBranchCompany.rmCityName'),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t('pages.settings.editBranchCompany.phCityName')}
              optionFilterProp="children"
            >
              {cityOptions?.map(city => {
                return (
                  <Option
                    key={`${city.cityName}_${city.id}`}
                    value={city.cityName}
                  >
                    {city.cityName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editBranchCompany.district')}
            name="district"
            rules={[
              {
                required: true,
                message: t('pages.settings.editBranchCompany.rmDistrict'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editBranchCompany.phDistrict')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editBranchCompany.street')}
            name="street"
            rules={[
              {
                required: true,
                message: t('pages.settings.editBranchCompany.rmStreet'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editBranchCompany.phStreet')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editBranchCompany.streetNumber')}
            name="streetNumber"
            rules={[
              {
                required: true,
                message: t('pages.settings.editBranchCompany.rmStreetNumber'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editBranchCompany.phStreetNumber')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const footerModal = [
    <Button key="back" danger onClick={onClose}>
      {t('pages.settings.editBranchCompany.cancelButton')}
    </Button>,
    <Button
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
        setError(null);
      }}
      loading={isUpdateBranchOrganizationRequesting}
    >
      {t('pages.settings.editBranchCompany.confirmButton')}
    </Button>,
  ];

  return (
    <Modal width={'70%'} open={visible} onCancel={onClose} footer={footerModal}>
      <Title level={5}>{t('pages.settings.editBranchCompany.title')}</Title>
      {formUpdateBranchCompany}
    </Modal>
  );
};

export default withTranslation()(EditBranchCompany);
