import { gql, TypedDocumentNode } from 'urql';
import {
  ICreateCadencesInput,
  ICreateCustomerRuleInput,
  ICreateCustomerRuleResponse,
  ICreateRule,
  ICreateRuleInput,
  IDeleteRuleResponse,
  IEditCustomerRule,
  IEditRules,
  IFindCadences,
  IFindRules,
  IGetPickUpLocations,
  IGetRulesIso,
  IGetSeedTypes,
} from '../interfaces/ScheduleRules';
import { ISettings, ISettingsInput } from '../interfaces/Settings';

const ScheduleRulesQueries = {
  getCultures: (): TypedDocumentNode<{ getSeedTypes: IGetSeedTypes[] }> => gql`
    query listCultures {
      getSeedTypes: ListSeedTypes {
        nameWithoutAccent: name_without_accent
        name
      }
    }
  `,

  getPickUpLocationsBySeedTypes: (): TypedDocumentNode<
    {
      getLocales: IGetPickUpLocations[];
    },
    {
      where: {
        seed_type_id?: string;
        organization_id?: string;
      };
    }
  > => gql`
    query listPickUpLocationsBySeedType($where: ListPickUpLocations!) {
      getLocales: ListPickUpLocationsBySeedType(where: $where) {
        id
        organizationId: organization_id
        title
      }
    }
  `,

  createRule: (): TypedDocumentNode<
    { createRule: ICreateRule[] },
    { input: ICreateRuleInput }
  > => gql`
    mutation createRule($input: RuleCreateInput!) {
      createRule: CreateRule(input: $input) {
        id
        unity
        endDate: end_date
        startDate: start_date
        seedTypeId: seed_type_id
        schedulingStart: scheduling_start
        pickUpLocationId: pick_up_location_id
        loadReportTimeout: load_report_timeout
        capacityPerCompany: capacity_per_company
        driverReportTimeout: driver_report_timeout
        releaseSubmissionDate: release_submission_date
        generalCapacityPerDay: general_capacity_per_day
        tsiSeedSchedulingTime: tsi_seed_scheduling_time
        whiteSeedSchedulingTime: white_seed_scheduling_time
      }
    }
  `,

  createCadences: (): TypedDocumentNode<
    {
      createCadences: { success: boolean };
    },
    {
      input: { cadences: ICreateCadencesInput[] };
    }
  > => gql`
    mutation createCadences($input: CadencesCreateInput!) {
      createCadences: CreateCadences(input: $input) {
        success
      }
    }
  `,

  createCustomerRules: (): TypedDocumentNode<
    {
      customerRule: ICreateCustomerRuleResponse;
    },
    {
      input: ICreateCustomerRuleInput;
    }
  > => gql`
    mutation createCustomerRule($input: CustomerRuleCreateInput!) {
      customerRule: CreateCustomerRule(input: $input) {
        id
        customerId: customer_id
        referenceRuleId: reference_rule_id
        startDate: start_date
        endDate: end_date
        capacity
      }
    }
  `,

  deleteCustomerRules: (): TypedDocumentNode<
    {
      deleteCustomerRule: { success: boolean };
    },
    {
      where: { id: string };
    }
  > => gql`
    mutation deleteCustomerRule($where: CustomerRuleWhereUniqueInput!) {
      deleteCustomerRule: DeleteCustomerRule(where: $where) {
        success
      }
    }
  `,

  getRules: (): TypedDocumentNode<{ getRules: IGetRulesIso[] }> => gql`
    query listRules($where: ListRulesWhere!) {
      getRules: ListRules(where: $where) {
        id
        seedTypeId: seed_type_id
        seedType: seed_type {
          name
        }
        pickUpLocation: pick_up_location {
          id
          title
        }
        createdAt: created_at
        releaseSubmissionDate: release_submission_date
        schedulingStart: scheduling_start
        capacityPerCompany: capacity_per_company
        whiteSeedSchedulingTime: white_seed_scheduling_time
        tsiSeedSchedulingTime: tsi_seed_scheduling_time
        loadReportTimeout: load_report_timeout
        driverReportTimeout: driver_report_timeout
        unity
        unityWeight: unity_weight
        capacityPerCompany: capacity_per_company
        startDate: start_date
        endDate: end_date
        cadences {
          id
        }
      }
    }
  `,

  editRules: (): TypedDocumentNode<{
    editRules: IEditRules[];
    where: { id: string };
  }> => gql`
    mutation editRule($where: RuleWhereUniqueInput!, $input: EditRuleInput!) {
      editRules: EditRule(where: $where, input: $input) {
        id
        seedTypeId: seed_type_id
        pickUpLocationId: pick_up_location_id
        releaseSubmissionDate: release_submission_date
        schedulingStart: scheduling_start
        loadReportTimeout: load_report_timeout
        driverReportTimeout: driver_report_timeout
        unity
        unityWeight: unity_weight
        whiteSeedSchedulingTime: white_seed_scheduling_time
        tsiSeedSchedulingTime: tsi_seed_scheduling_time
        capacityPerCompany: capacity_per_company
        startDate: start_date
        endDate: end_date
        generalSundayCapacity: general_sunday_capacity
        generalCapacityPerDay: general_capacity_per_day
        generalSaturdayCapacity: general_saturday_capacity
        seedType: seed_type {
          nameWithoutAccent: name_without_accent
          name
        }
        pickUpLocation: pick_up_location {
          id
          title
        }
        customerRules: customer_rules {
          id
          customerId: customer_id
          referenceRuleId: reference_rule_id
          startDate: start_date
          endDate: end_date
          capacity
        }
        cadences: cadences {
          id: id
          ruleId: rule_id
          capacityPerDay: capacity_per_day
          availableUnits: available_units
          startTime: start_time
          endTime: end_time
        }
      }
    }
  `,

  findCadencesInRules: (): TypedDocumentNode<{
    findCadences: IFindCadences;
    where: { id: string };
  }> => gql`
    query findRule($where: RuleWhereUniqueInput!) {
      findCadences: FindRule(where: $where) {
        id
        cadences {
          id
          ruleId: rule_id
          capacityPerDay: capacity_per_day
          availableUnits: available_units
          startTime: start_time
          endTime: end_time
        }
      }
    }
  `,

  findRule: (): TypedDocumentNode<{
    findRule: IFindRules;
    where: { id: string };
  }> => gql`
    query findRule($where: FindRuleWhereInput!) {
      findRule: FindRule(where: $where) {
        id
        seedTypeId: seed_type_id
        pickUpLocationId: pick_up_location_id
        releaseSubmissionDate: release_submission_date
        schedulingStart: scheduling_start
        loadReportTimeout: load_report_timeout
        driverReportTimeout: driver_report_timeout
        unity: unity
        whiteSeedSchedulingTime: white_seed_scheduling_time
        tsiSeedSchedulingTime: tsi_seed_scheduling_time
        capacityPerCompany: capacity_per_company
        startDate: start_date
        endDate: end_date
        generalCapacityPerDay: general_capacity_per_day
        generalSaturdayCapacity: general_saturday_capacity
        generalSundayCapacity: general_sunday_capacity
        cadences {
          id
          ruleId: rule_id
          capacityPerDay: capacity_per_day
          availableUnits: available_units
          startTime: start_time
          endTime: end_time
          preSchedules: pre_schedules {
            id
          }
        }
        customerRules: customer_rules {
          id
          customerId: customer_id
          startDate: start_date
          endDate: end_date
          capacity
        }
      }
    }
  `,

  deleteScheduleRule: (): TypedDocumentNode<{
    success: IDeleteRuleResponse;
  }> => gql`
    mutation deleteRule($where: RuleWhereUniqueInput!) {
      success: DeleteRule(where: $where) {
        success
      }
    }
  `,

  editCustomerRule: (): TypedDocumentNode<
    { editCustomerRule: IEditCustomerRule },
    { where: { id: string } }
  > => gql`
    mutation editCustomerRule(
      $where: CustomerRuleWhereUniqueInput!
      $input: EditCustomerRuleInput!
    ) {
      editCustomerRule: EditCustomerRule(where: $where, input: $input) {
        id
        startDate: start_date
        endDate: end_date
        capacity
        customerId: customer_id
      }
    }
  `,

  deleteCadence: (): TypedDocumentNode<
    { deleteCadence: { success: boolean } },
    { where: { cadence_ids: string[] } }
  > => gql`
    mutation deleteCadence($where: DeleteCadencesWhereInput!) {
      deleteCadence: DeleteCadences(where: $where) {
        success
      }
    }
  `,

  getSettings: (): TypedDocumentNode<
    { getSettings: ISettings },
    { where: { organization_id: string } }
  > => gql`
    query GetSettings($where: SettingsWhereInput!) {
      getSettings: GetSettings(where: $where) {
        id
        organizationId: organization_id
        tolerancePercentage: tolerance_percentage
        commonTsiSeedSchedulingTime: common_tsi_seed_scheduling_time
        commonWhiteSeedSchedulingTime: common_white_seed_scheduling_time
        hasSync: has_sync
        integrationUrl: integration_url
      }
    }
  `,

  updateTolerancePercentage: (): TypedDocumentNode<
    { updateTolerancePercentage: ISettings },
    {
      where: { organization_id: string };
      input: ISettingsInput;
    }
  > => gql`
    mutation UpdateSettings(
      $where: SettingsWhereInput!
      $input: SettingsUncheckedUpdateInput!
    ) {
      updateTolerancePercentage: UpdateSettings(where: $where, input: $input) {
        settingsId: id
        organizationId: organization_id
        tolerancePercentage: tolerance_percentage
        commonTsiSeedSchedulingTime: common_tsi_seed_scheduling_time
        commonWhiteSeedSchedulingTime: common_white_seed_scheduling_time
        hasSync: has_sync
        integrationUrl: integration_url
      }
    }
  `,

  updateTreatmentTime: (): TypedDocumentNode<
    { updateTreatmentTime: ISettings },
    {
      where: { organization_id: string };
      input: ISettingsInput;
    }
  > => gql`
    mutation UpdateSettings(
      $where: SettingsWhereInput!
      $input: SettingsUncheckedUpdateInput!
    ) {
      updateTreatmentTime: UpdateSettings(where: $where, input: $input) {
        settingsId: id
        organizationId: organization_id
        tolerancePercentage: tolerance_percentage
        commonTsiSeedSchedulingTime: common_tsi_seed_scheduling_time
        commonWhiteSeedSchedulingTime: common_white_seed_scheduling_time
        hasSync: has_sync
        integrationUrl: integration_url
      }
    }
  `,

  updateSettings: (): TypedDocumentNode<
    { updateSettings: ISettings },
    {
      where: { organization_id: string };
      input: ISettingsInput;
    }
  > => gql`
    mutation UpdateSettings(
      $where: SettingsWhereInput!
      $input: SettingsUncheckedUpdateInput!
    ) {
      updateSettings: UpdateSettings(where: $where, input: $input) {
        id
        organizationId: organization_id
        tolerancePercentage: tolerance_percentage
        commonTsiSeedSchedulingTime: common_tsi_seed_scheduling_time
        commonWhiteSeedSchedulingTime: common_white_seed_scheduling_time
      }
    }
  `,

  countRuleTruckLoads: (): TypedDocumentNode<
    { countRule: number },
    {
      where: { rule_id: string };
    }
  > => gql`
    query CountRuleTruckLoads($where: CountRuleTruckLoadsInput!) {
      countRule: CountRuleTruckLoads(where: $where)
    }
  `,
};

export default ScheduleRulesQueries;
