import {
  EllipsisOutlined,
  PlusOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Dropdown, MenuProps, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import Formatter from '../../../classes/Formatter';
import { IFarmResponse } from '../../../structures/interfaces/Clients';
import { IFarmOpenModal } from './FarmsPage';

const renderContent = (text?: string) => {
  if (text) return text;
  else return '---';
};
const renderCnpj = (record: IFarmResponse) => {
  if (!record.documentNumberCnpj) return '---';
  const documentSize = record.documentNumberCnpj.length;

  if (documentSize === 14) {
    return Formatter.formatCNPJ(record.documentNumberCnpj);
  }
  if (documentSize === 11) {
    return Formatter.formatCPF(record.documentNumberCnpj);
  } else {
    return record.documentNumberCnpj;
  }
};

interface IFarmsTableProps {
  filteredFarms: IFarmResponse[];
  setSelectedRowKeys: Dispatch<SetStateAction<React.Key[]>>;
  selectedRowKeys: React.Key[];
  setAddNewContact: Dispatch<SetStateAction<IFarmOpenModal>>;
  setFarm: Dispatch<SetStateAction<IFarmResponse | undefined>>;
}

const FarmsTable = ({
  filteredFarms,
  setSelectedRowKeys,
  selectedRowKeys,
  setAddNewContact,
  setFarm,
}: IFarmsTableProps): ReactElement => {
  const items = (record: IFarmResponse) =>
    [
      {
        key: 'add',
        label: 'Criar novo contato',
        icon: <PlusOutlined />,
        onClick: () => {
          setFarm(record);
          setAddNewContact({ action: 'add' });
        },
      },
      {
        key: 'sync',
        label: 'Vincular contato a fazenda',
        icon: <UserAddOutlined />,
        onClick: () => {
          setFarm(record);
          setAddNewContact({ action: 'sync' });
        },
      },
    ] as MenuProps['items'];

  const columns: ColumnsType<IFarmResponse> = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: 'Nome',
      key: 'name',
      dataIndex: ['name'],
      render: renderContent,
    },
    {
      title: 'Inscrição estadual',
      key: 'stateRegistration',
      dataIndex: ['stateRegistration'],
      render: renderContent,
    },
    {
      title: 'CNPJ',
      key: 'cnpj',
      dataIndex: ['cnpj'],
      render: (_, record) => renderCnpj(record),
    },
    {
      title: 'Endereço',
      key: 'address',
      dataIndex: ['address'],
      render: renderContent,
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => {
        return (
          <Dropdown
            menu={{ items: items(record) }}
            placement="top"
            trigger={['click']}
          >
            <EllipsisOutlined style={{ fontSize: 24 }} />
          </Dropdown>
        );
      },
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<IFarmResponse> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const expandableColumns = [
    { title: 'Contato', key: 'contact', dataIndex: ['customer', 'name'] },
    { title: 'E-mail', key: 'email', dataIndex: ['customer', 'email'] },
    {
      title: 'Telefone',
      key: 'phone',
      dataIndex: ['customer', 'phoneNumber'],
      render: (phone: string) => Formatter.formatPhoneWithoutDDI(phone),
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={filteredFarms}
      rowKey={farm => farm.id}
      rowSelection={rowSelection}
      expandable={{
        expandedRowRender: (_, index) => {
          return (
            <Table
              columns={expandableColumns}
              dataSource={filteredFarms[index].farmCustomers}
            />
          );
        },
      }}
    />
  );
};

export default FarmsTable;
