import {
  Button,
  Col,
  Descriptions,
  message,
  Popconfirm,
  Row,
  Space,
} from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../../classes/Formatter';
import UserContext from '../../../../../contexts/user';
import moment from 'moment';
import { useRequest } from '../../../../../hooks/useRequest';
import UserController from '../../../../../structures/controllers/User';
import { IGetUserResponse } from '../../../../../structures/interfaces/User';
import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';

interface ITruckLoadAuthorizationProps {
  loadAuthorized: boolean;
  handleAuthorizeLater?: () => void;
  handleAuthorization: () => void;
  isModal?: boolean;
}

const { Item } = Descriptions;
const { formatCPF, formatPhoneWithoutDDI, formatDate } = Formatter;
const TruckLoadAuthorization = ({
  loadAuthorized = false,
  handleAuthorization,
  handleAuthorizeLater,
  isModal,
}: ITruckLoadAuthorizationProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.truckLoads.authorization',
  });
  const { userData } = useContext(UserContext);
  const [fetchUser] = useRequest(UserController.getUser);
  const [user, setUser] = useState<IGetUserResponse>();

  const loadAuthTitle = t(
    `labels.${loadAuthorized ? 'authorizedName' : 'unauthorizedName'}`
  );

  const authorize = !loadAuthorized && (
    <Popconfirm title="Confirmar autorização?" onConfirm={handleAuthorization}>
      <Button type="primary" icon={<CheckOutlined />}>
        {t(`button.authorize`)}
      </Button>
    </Popconfirm>
  );
  const authorizeLater = handleAuthorizeLater && (
    <Popconfirm
      title="Tem certeza que deseja autorizar depois?"
      okText="Sim"
      onConfirm={handleAuthorizeLater}
    >
      <Button icon={<ClockCircleOutlined />}>
        {t(`button.authorizeLater`)}
      </Button>
    </Popconfirm>
  );

  useEffect(() => {
    if (userData) {
      fetchUser(userData.id)
        .then(setUser)
        .catch(() => message.error('Erro ao buscar dados do usuário'));
    }
  }, []);

  return (
    <>
      <Descriptions
        column={isModal ? 2 : 4}
        labelStyle={{ fontWeight: 'bold' }}
      >
        <Item label={loadAuthTitle}>{user?.name}</Item>

        <Item label={t('labels.document')}>
          {formatCPF(user?.documentNumberCpf)}
        </Item>

        <Item label={t('labels.phoneNumber')}>
          {formatPhoneWithoutDDI(user?.phoneNumber)}
        </Item>

        <Item label={t('labels.email')}>{user?.email}</Item>

        <Item label={t('labels.date')}>
          {formatDate(moment().toISOString())}
        </Item>
      </Descriptions>

      <Row style={{ textAlign: 'right' }}>
        <Col span={24}>
          <Space>
            {authorizeLater}
            {authorize}
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default TruckLoadAuthorization;
