import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EditFilled,
  EllipsisOutlined,
  EyeOutlined,
  FormOutlined,
  LockOutlined,
  MailOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { IGetClient } from '../../../structures/interfaces/Clients';
import { RegStatusEnum } from '../../../structures/interfaces/User';
import { farmMockData } from '../farms/Mockup';
import { IOpenModalType } from './ContactsPage';

interface IContactsTableProps {
  contacts: IGetClient[] | undefined;
  selectedRowKeys: React.Key[];
  isLoading: boolean;
  setSelectedRowKeys: (selectedRowKeys: React.Key[]) => void;
  setSelectedContact: Dispatch<SetStateAction<IGetClient | undefined>>;
  openModal: IOpenModalType;
  setOpenModal: Dispatch<SetStateAction<IOpenModalType>>;
}
const RenderStatus = (status: string) => {
  switch (status) {
    case 'NOT_ACTIVATED':
      return (
        <Tag icon={<CloseCircleOutlined />} color="volcano">
          <b>{RegStatusEnum.NOT_ACTIVATED}</b>
        </Tag>
      );
    case 'NOTIFIED':
      return (
        <Tag icon={<MailOutlined />} color="processing">
          <b>{RegStatusEnum.NOTIFIED}</b>
        </Tag>
      );
    case 'WAITING_ACTIVATION':
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          <b>{RegStatusEnum.WAITING_ACTIVATION}</b>
        </Tag>
      );
    case 'ACTIVATED':
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          <b>{RegStatusEnum.ACTIVATED}</b>
        </Tag>
      );
  }
};
const RenderActions = ({
  setOpenModal,
  setSelectedContact,
  record,
}: {
  record: IGetClient;
  setOpenModal: Dispatch<SetStateAction<IOpenModalType>>;
  setSelectedContact: Dispatch<SetStateAction<IGetClient | undefined>>;
}) => {
  return (
    <Dropdown
      menu={{
        items: [
          {
            label: 'Vincular a uma nova fazenda',
            key: 'syncToFarm',
            icon: <PlusOutlined />,
            onClick: () => {
              setSelectedContact(record);
              setOpenModal({ action: 'syncToFarm' });
            },
          },
          {
            label: 'Visualizar',
            key: 'view',
            icon: <EyeOutlined />,
            onClick: () => {
              setSelectedContact(record);
              setOpenModal({ action: 'view' });
            },
          },
          {
            label: 'Resetar Senha',
            key: 'reset',
            icon: <LockOutlined />,
            onClick: () => {
              setSelectedContact(record);
              setOpenModal({ action: 'reset' });
            },
          },
          {
            label: 'Editar',
            key: 'update',
            icon: <EditFilled />,
            onClick: () => {
              setSelectedContact(record);
              setOpenModal({ action: 'update' });
            },
          },
        ],
      }}
      trigger={['click']}
      placement="top"
    >
      <EllipsisOutlined style={{ cursor: 'pointer', fontSize: 30 }} />
    </Dropdown>
  );
};
const ContactsTable = ({
  contacts,
  selectedRowKeys,
  isLoading,
  setSelectedRowKeys,
  setOpenModal,
  setSelectedContact,
}: IContactsTableProps): ReactElement => {
  const { t } = useTranslation();

  const columns: ColumnsType<IGetClient> = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: 'Contato',
      dataIndex: ['name'],
      key: 'name',
      render: (_, record) => record.name,
    },
    {
      title: t('pages.clients.table.documentNumberCpf'),
      dataIndex: ['documentNumberCpfMask'],
      key: 'documentNumberCpf',
      render: data => (data ? data : '---'),
    },
    {
      title: t('pages.clients.table.phoneNumber'),
      dataIndex: ['phoneNumberMask'],
      key: 'phoneNumber',
      render: phone => (phone ? phone : '---'),
    },
    {
      title: t('pages.clients.table.email'),
      dataIndex: ['email'],
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: ['status'],
      key: 'status',
      render: status => RenderStatus(status),
    },
    {
      title: t('pages.settings.actions'),
      key: 'actions',
      render: (_, record) =>
        RenderActions({ setOpenModal, record, setSelectedContact }),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<IGetClient> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const expandableColumns = [
    { title: 'Nome', key: 'name', dataIndex: ['farm', 'name'] },
    { title: 'CNPJ', key: 'cnpj', dataIndex: ['farm', 'documentNumberCnpj'] },
    {
      title: 'Ins. Estadual',
      key: 'stateRegistration',
      dataIndex: ['farm', 'stateRegistration'],
    },
    { title: 'Endereço', key: 'address', dataIndex: ['farm', 'address'] },
  ];

  return (
    <Table
      rowSelection={rowSelection}
      scroll={{ x: 1300 }}
      rowKey={customer => customer.id}
      columns={columns}
      dataSource={contacts}
      size="small"
      loading={isLoading}
      pagination={{ hideOnSinglePage: true }}
      expandable={{
        expandedRowRender: (_, index) => (
          <Table
            columns={expandableColumns}
            dataSource={contacts?.[index].customerFarms}
          />
        ),
      }}
    />
  );
};

export default ContactsTable;
