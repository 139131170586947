interface ILanguage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const es: ILanguage = {
  translations: {
    tab: {
      authentication: 'Autenticación',
      signUp: 'Registro',
      home: 'Inicio',
      settings: 'Ajustes',
      icons: 'Iconos',
      validateEmail: 'Verificando correo electrónico...',
      registerCompany: 'Registrar Empresa',
      createPassword: 'Crear Contraseña',
      termsOfUse: 'Términos de Uso',
      companyCreated: 'Empresa Registrada',
    },
    languages: {
      ptBR: 'Português',
      enUS: 'English',
      es: 'Español',
    },
    headerMenu: {
      logOut: 'Salir',
    },
    general: {
      logoAlt: 'Logotipo de LogMetrics',
      yes: 'Sí',
      no: 'No',
      close: 'Cerrar',
      back: 'Devolver',
      save: 'Guardar',
    },
    sidebar: {
      options: {
        home: 'Inicio',
        settings: 'Ajustes',
        icons: 'Iconos',
      },
    },
    input: {
      rules: {
        required: 'Campo obligatorio',
        invalidEmail: 'Email inválido',
        minPassword: 'Mínimo 6 caracteres',
        invalidDocument: 'Número de documento inválido',
        invalidDocumentCPF: 'Número de documento inválido',
        invalidDocumentCNPJ: 'Número de documento inválido',
        invalidPhoneNumber: 'Número de teléfono inválido',
        invalidZipCode: 'Código postal inválido',
      },
    },
    pages: {
      loading: {
        text: 'Cargando',
      },
      login: {
        title: 'Acceso',
        subtitle: 'Ingrese sus datos para iniciar sesión en su cuenta.',
        fields: {
          email: {
            label: 'Correo electrónico',
            placeholder: 'Su correo electrónico',
          },
          password: {
            label: 'Contraseña',
            placeholder: 'Su contraseña',
          },
          keepConnected: 'Manténgame conectado',
          passwordRecovery: 'Recuperar contraseña',
          loginButton: 'Acceso',
        },
        footer: {
          text: '¿No tienes una cuenta? ',
          signup: '¡Registrarse!!',
        },
        success: {
          message: '¡Inicio de sesión realizado!',
          description: 'Redirigir al inicio del sistema',
        },
      },
      signUp: {
        title: 'Creando su cuenta',
        subtitle: 'Ingresa los siguientes datos para registrarte en el sistema',
        fields: {
          labels: {
            name: 'Nombre',
            email: 'Correo electrónico',
            phone: 'Teléfono',
            companyFunction: 'Su segmento de negocio',
          },
          placeholders: {
            name: 'Su nombre',
            email: 'Su correo electrónico',
            phone: 'Su número de teléfono',
            companyFunction: 'Su segmento de negocio',
          },
        },
        action: {
          cancel: 'Cancelar',
          submit: 'Crear cuenta',
          return: 'Devolver',
        },
        terms: {
          text: 'Al hacer clic en <strong>crear cuenta</strong> acepta los',
          link: 'términos de uso',
        },
        confirm: {
          cancel: '¿Realmente quieres cancelar el registro?',
        },
        messages: {
          accountCreated: {
            title: '¡Cuenta creada!',
            description:
              'Se envió al correo electrónico informando un enlace para validar la cuenta. Complete su registro utilizando el enlace en el correo electrónico.',
            buttonText: 'Volver a la pantalla de acceso',
          },
          shipperCreated: {
            title: '¡Embarcador registrado!',
            description:
              'Estamos validando sus datos. Espere nuestra respuesta a su correo electrónico.',
            buttonText: 'Volver a la pantalla de acceso',
          },
          carrierCreated: {
            title: '¡Transportista registrado!',
            description:
              'Estamos validando sus datos. Espere nuestra respuesta a su correo electrónico.',
            buttonText: 'Volver a la pantalla de acceso',
          },
          accountInApproval: {
            title: 'Cuenta en aprobación',
            description: [
              'Su cuenta está pendiente de aprobación.',
              'En breve recibirás un email con novedades.',
            ],
            buttonText: 'Volver a la pantalla de acceso',
          },
          accountDisapproved: {
            title: 'Cuenta reprobada',
            explanation: {
              reasons: 'Motivos',
              description: 'Descripción',
            },
            buttonText: 'Regularizar registro',
          },
          completeRegistration: {
            title: 'Completar el registro',
            description:
              'Necesitamos más información de su empresa para que se active.',
            buttonText: 'Completar el registro',
          },
        },
      },
      home: {
        title: '¡Bienvenido a la página de inicio!',
      },
      settings: {
        // title: 'Página de Ajustes',
      },
      icons: {
        title: 'Página de Iconos',
      },
      validateEmail: {
        notification: {
          success: {
            message: 'Correo electrónico verificado con éxito!',
            description:
              '¡Tu correo electrónico ha sido verificado con éxito! Por favor proceda con el registro',
          },
          error: {
            message: 'Error al verificar el correo electrónico...',
            description:
              'El enlace al que accedió está caducado o no es válido',
          },
        },
      },
      registerCompany: {
        shipperTitle: 'Registrar Embarcador',
        carrierTitle: 'Registrar Transportista',
        subtitle: 'Elige el seguimiento de tu empresa',
        sections: {
          companyData: {
            title: 'Datos de la empresa',
            fields: {
              labels: {
                companyType: 'Tipo de empresa',
                hasFleet: '¿Tienes tu propia flota?',
                fleetQuantity: 'Tamaño de la flota',
                logo: 'Haga clic o arrastre el archivo a esta área para cargarlo',
                logoDescription:
                  'El archivo debe tener un máximo de 5 MB y debe ser de tipo .png, .jpg o .jpeg',
              },
              placeholders: {
                companyType: 'Tipo de su empresa',
                fleetQuantity: 'Ingrese el número de camiones',
              },
              rules: {
                companyFunctionNotMatch:
                  'Empresa registrada para otro segmento',
                companyTypeNotMatch:
                  'Su tipo de empresa es incompatible con esta',
                companyStatusBlocked:
                  'Empresa no disponible para vincular nuevos dependientes',
                tradeNameLength:
                  'El nombre de fantasia debe tener menos de 100 caracteres',
                companyNameLength:
                  'La razón social debe tener menos de 100 caracteres',
                minimumFleetQuantity:
                  'El tamaño mínimo de la flota es de 1 camión',
                maximumFileSize: 'El tamaño máximo del archivo es de 5 MB',
                acceptedFileTypes:
                  'Solo se aceptan archivos .jpg, .jpeg y .png',
              },
            },
            parent: {
              fields: {
                labels: {
                  companyDocument: 'Número de documento',
                  companyTradeName: 'Nombre de fantasía',
                  companyName: 'Razón social',
                },
                placeholders: {
                  companyDocument: 'Su número de documento',
                  companyTradeName: 'Nombre de fantasía de su empresa',
                  companyName: 'Razón social de su empresa',
                },
                rules: {
                  invalidCompanyDocument: 'Número de documento en uso',
                },
              },
              notifications: {
                preRegisteredCompany: {
                  message: 'Empresa pre-registrada',
                  description:
                    'Algunos datos ya han sido rellenados por una sucursal. Por favor complete los datos restantes para completar el registro',
                },
              },
            },
            branch: {
              fields: {
                labels: {
                  motherCompanyDocument: 'Número de documento de la matriz',
                  motherCompanyName: 'Nombre de la matriz:',
                  companyDocument: 'Número de documento de la filial',
                  companyTradeName: 'Nombre de fantasía',
                  companyName: 'Razón social',
                },
                placeholders: {
                  motherCompanyDocument:
                    'Ingrese el número de documento de la matriz',
                  motherCompanyName: 'Ingrese el nombre de la matriz',
                  companyDocument: 'Su número de documento',
                  companyTradeName: 'Nombre de fantasía de su empresa',
                  companyName: 'Razón social de su empresa',
                },
                rules: {
                  invalidCompanyDocument: 'Número de documento en uso',
                  sameDocumentNumber:
                    'Su número de documento no puede ser el mismo que el de matriz',
                },
              },
              notifications: {
                preRegisteredCompany: {
                  message: 'Empresa pre-registrada',
                  description:
                    'Algunos datos ya han sido rellenados por alguna unidad. Por favor complete los datos restantes para completar el registro',
                },
              },
            },
            unit: {
              fields: {
                labels: {
                  motherCompanyDocument:
                    'Documento de la matriz o de la filial',
                  motherCompanyName: 'Nombre de la matriz o de la filial',
                  companyDocument: 'Documento de la unidad',
                  companyTradeName: 'Nombre de fantasía',
                  companyName: 'Razón social',
                },
                placeholders: {
                  motherCompanyDocument:
                    'Ingrese el número de documento de la matriz o de la filial',
                  companyDocument: 'Su número de documento',
                  companyTradeName: 'Nombre de fantasía de su empresa',
                  companyName: 'Razón social de su empresa',
                },
                rules: {
                  invalidCompanyDocument: 'Número de documento en uso',
                },
              },
            },
            modal: {
              title: {
                details: 'Datos de matriz',
                createParent: 'Registro de Matriz',
                createBranchOrUnit: 'Registro Matriz o Filial',
              },
              fields: {
                labels: {
                  motherCompanyType: 'Tipo de empresa',
                  motherCompanyDocument: 'Número de documento',
                  motherCompanyTradeName: 'Nombre de fantasía',
                  motherCompanyName: 'Razón social',
                },
                placeholders: {
                  motherCompanyTradeName: 'Nombre de fantasía de su empresa',
                  motherCompanyName: 'Razón social de su empresa',
                },
                rules: {
                  tradeNameLength:
                    'El nombre de fantasia debe tener menos de 100 caracteres',
                  companyNameLength:
                    'La razón social debe tener menos de 100 caracteres',
                },
              },
            },
          },
          companyAddress: {
            title: 'Dirección de la empresa',
            fields: {
              labels: {
                zipCode: 'Código postal',
                state: 'Estado',
                city: 'Ciudad',
                address: 'Dirección',
                district: 'Vecindario',
                addressNumber: 'Número',
                complement: 'Complementar/Observación',
              },
              placeholders: {
                zipCode: 'Su código postal',
                state: 'Estado',
                city: 'Ciudad donde se encuentra la empresa',
                address: 'Dirección donde se encuentra la empresa',
                district: 'Vecindario donde se encuentra la empresa',
              },
              rules: {
                addressMaxLength:
                  'La dirección debe tener menos de 100 caracteres',
                districtMaxLength:
                  'El vecindario debe tener menos de 100 caracteres',
                numberMaxLength: 'El número debe tener menos de 10 caracteres',
                complementMaxLength:
                  'El complemento debe tener menos de 100 caracteres',
              },
            },
          },
          companyContact: {
            title: 'Contacto de la empresa',
            fields: {
              labels: {
                email: 'Correo electrónico',
                phoneNumber: 'Teléfono',
              },
              placeholders: {
                email: 'Correo electrónico de la empresa',
                phoneNumber: 'Teléfono de la empresa',
              },
            },
          },
          carrierPolicies: {
            title: 'Políticas',
            fields: {
              labels: {
                expiresIn: 'Validez',
                name: 'Nombre',
                logo: 'Haga clic o arrastre el archivo a esta área para cargarlo',
                logoDescription:
                  'El archivo debe tener un máximo de 5 MB y debe ser de tipo .png, .jpg, .jpeg o .pdf',
              },
              placeholders: {
                name: 'Ej. póliza de seguro',
                expiresIn: 'dd/mm/aa',
              },
              rules: {
                maximumFileSize: 'El tamaño máximo del archivo es de 5 MB',
                acceptedFileTypes:
                  'Solo se aceptan archivos .jpg, .jpeg, .png y .pdf',
                nameLength:
                  'El nombre de fantasia debe tener menos de 100 caracteres',
                maximumFileAmount: {
                  message: 'Límite de archivos alcanzado',
                  description: 'El número máximo de archivos es 10',
                },
              },
            },
            fileList: {
              title: 'Archivos de políticas',
              tooltips: {
                remove: 'Eliminar',
                preview: 'Ver',
                retry: 'Reenviar',
              },
            },
            action: {
              addPolicy: 'Agregar política',
            },
          },
          carrierCompanyOperationAreas: {
            title: 'Área de actuación',
          },
        },
        action: {
          cancel: 'Cancelar',
          submit: 'Crear cuenta',
        },
        confirm: {
          cancel: '¿Realmente quieres cancelar el registro?',
        },
      },
      createPassword: {
        title: 'Seguridad',
        description:
          'Regístrate y confirme una contraseña segura para su cuenta',
        fields: {
          labels: {
            password: 'Contraseña',
            confirmPassword: 'Confirmar contraseña',
          },
          placeholders: {
            password: 'Introduce una contraseña válida',
            confirmPassword: 'Confirmar la contraseña',
          },
          rules: {
            passwordNotMatch: 'Las contraseñas no coinciden',
            passwordMinLength: 'La contraseña debe tener al menos 6 caracteres',
            passwordMaxLength:
              'La contraseña debe tener menos de 128 caracteres',
          },
        },
        action: {
          cancel: 'Cancelar',
          submit: 'Confirmar',
        },
        confirm: {
          cancel: '¿Realmente quieres cancelar el registro?',
        },
      },
      termsOfUse: {
        subtitle: 'Lea y revise los términos de uso',
        carrier: {
          title: 'Términos de Uso y Consentimiento del Transportista',
        },
        shipper: {
          title: 'Términos de Uso y Consentimiento del Embarcador',
        },
        fields: {
          labels: {
            acceptTerms: 'He leído y acepto las condiciones de uso',
          },
        },
        action: {
          cancel: 'No acepto',
          submit: 'Aceptar y continuar',
          return: 'Devolver',
        },
        confirm: {
          cancel: '¿Realmente desea rechazar los términos?',
        },
      },
    },
    enums: {
      reasonType: {
        COMPANY_DATA: 'Datos de la empresa',
        COMPANY_ADDRESS: 'Dirección de la empresa',
        COMPANY_CONTACT: 'Contacto de la empresa',
        COMPANY_POLICIES: 'Pólizas',
        COMPANY_OPERATION: 'Áreas de actuación',
        OTHER: 'Otro',
      },
      companyType: {
        parent: 'Matriz',
        branch: 'Filial',
        unit: 'Unidad',
      },
      companyFunction: {
        carrier: 'Transportista',
        shipper: 'Embarcador',
      },
    },
    errors: {
      default: {
        message: 'Ha ocurrido un error inesperado',
        description:
          'Vuelva a cargar la página e intente realizar la acción nuevamente, si el error ocurre nuevamente, comuníquese con nuestro soporte: suporte@suporte.com.br',
      },
      invalidToken: {
        message: 'Token invalido',
        description:
          'Realice un nuevo acesso en nuestra plataforma para continuar usando el sistema',
      },
      201: {
        message: 'Usuario no encontrado',
        description:
          'No pudimos encontrar a este usuario en nuestra base de datos.',
      },
      202: {
        message: 'Usuario ya registrado',
        description:
          'Ya existe un usuario registrado con este correo electrónico o número de documento.',
      },
      203: {
        message: 'Usuario no encontrado',
        description:
          'No encontramos un usuario con estas credenciales en nuestra base de datos.',
      },
      204: {
        message: 'Credenciales incompletas',
        description:
          'Debe ingresar un correo electrónico o número de documento para iniciar sesión',
      },
      404: {
        message: 'Ruta no encontrada',
        description:
          'No pudimos encontrar la ruta de destino para esta solicitud.',
      },
      500: {
        message: 'Empresa no encontrada',
        description:
          'No se puede encontrar una empresa con el número de documento dado',
      },
      501: {
        message: 'Datos incompletos',
        description:
          'Los campos de correo electrónico y teléfono son obligatorios, complétalos y vuelva a intentarlo',
      },
    },
  },
};
export default es;
