import { gql, TypedDocumentNode } from 'urql';
import {
  ICreateVehicle,
  ISerializedUpdateVehicle,
  IVehicle,
} from '../interfaces/Vehicle';

const VehicleQueries = {
  createVehicle: (): TypedDocumentNode<
    { createVehicle: { id: string } },
    { input: ICreateVehicle }
  > => gql`
    mutation createVehicle($input: VehicleUncheckedCreateInput!) {
      createVehicle: CreateVehicle(input: $input) {
        id
      }
    }
  `,

  getVehicles: (): TypedDocumentNode<
    { vehicles: IVehicle[] },
    { where: { organization_id: string } }
  > => gql`
    query ListVehicles($where: GetVehicleWhereInput!) {
      vehicles: ListVehicles(where: $where) {
        id
        vehicleModel: vehicle_model
        weightCapacity: weight_capacity
        bagsCapacity: bags_capacity
        axlesAmount: axles_amount
        bodywork
        updatedAt: updated_at
      }
    }
  `,

  updateVehicle: (): TypedDocumentNode<
    {
      updateVehicle: { id: string };
    },
    {
      input: ISerializedUpdateVehicle;
      where: { id: string };
    }
  > => gql`
    mutation updateVehicle(
      $input: VehicleUncheckedUpdateInputWithoutOrganizationId!
      $where: VehicleWhereUniqueInput!
    ) {
      updateVehicle: UpdateVehicle(input: $input, where: $where) {
        id
      }
    }
  `,

  deleteVehicle: (): TypedDocumentNode<
    {
      deleteVehicle: { success: boolean };
    },
    {
      where: { id: string };
    }
  > => gql`
    mutation deleteVehicle($where: VehicleWhereUniqueInput!) {
      deleteVehicle: DeleteVehicle(where: $where) {
        success
      }
    }
  `,
};

export default VehicleQueries;
