/**
 * @description Formats the values passed to the given format.
 */

class Converter {
  /**
   * @function convertMinutesInHours
   * @param {string} text - Text aim to be formatted in minutes.
   * @returns {string}
   * @description Return a formated hours with 'HH:MM' format
   */
  convertMinutesInHours = (text: number) => {
    if (!text) return '';
    const time = text / 60;
    let timeInt: string | number = parseInt(`${time}`);
    let timeDec: string | number = parseInt(
      `${Math.round((time - timeInt) * 60)}`
    );

    if (`${timeInt}`.length < 2) timeInt = `0${timeInt}`;
    if (`${timeDec}`.length < 2) timeDec = `0${timeDec}`;

    return `${timeInt}:${timeDec}`;
  };

  /**
   * @function convertMinutesInHours
   * @param {string} text - Text aim to be formatted in minutes.
   * @returns {string}
   * @description Return a formated hours with 'HH:MM' format
   */
  convertHoursInMinutes = (text: string | number) => {
    const value = `${text}`.replace(',', '.');
    const time = Number(value) * 60;
    const minutes = Math.ceil(time);
    return minutes;
  };

  convertMinInHours = (min: number) => {
    const hours = Math.floor(min / 60);
    const minutes = min % 60;
    const textoHoras = hours;
    const textoMinutos = `${minutes / 60}`.slice(-1);
    if (textoMinutos == '0') {
      return textoHoras;
    }
    return `${textoHoras}h ${textoMinutos}min`;
  };
}

export default new Converter();
