import {
  Button,
  Calendar,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Rule } from 'antd/lib/form';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import moment, { Moment } from 'moment';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { withTranslation } from 'react-i18next';
import { Farm } from '../../../../../../structures/interfaces/Clients';
import {
  ICreateCustomerRule,
  ICreateCustomerRuleFormValues,
  IEditCustomerRule,
} from '../../../../../../structures/interfaces/ScheduleRules';
import { scrollOptions } from '../../../../../../utils/formOptions';

interface ICustomerRuleProps {
  initialMonthAppear?: Moment;
  fillCalendarData: (value?: Moment) => JSX.Element;
  setCalendarId: (value: SetStateAction<moment.Moment | undefined>) => void;
  setShowEditModal: (value: SetStateAction<boolean>) => void;
  formEditCalendar: FormInstance;
  startDate: Moment;
  endDate: Moment;
  showEditModal: boolean;
  clientFormView: boolean;
  setClientFormView: Dispatch<SetStateAction<boolean>>;
  formCustomerRule: FormInstance;
  onFinishCustomerRule: (values: ICreateCustomerRuleFormValues) => void;
  colSize: any;
  requiredRule: Rule;
  clients: Farm[];
  setCustomerRuleDates: (value: any) => void;
  customerRuleDates: any;
  validValueRule: any;
  customerRules: ICreateCustomerRule[];
  setCustRuleVisible: Dispatch<SetStateAction<boolean>>;
  pageSize: number;
  generalCapacity: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  setDeleteCRModalVisible: Dispatch<SetStateAction<boolean>>;
  setSelectedCustomerRule: Dispatch<
    SetStateAction<IEditCustomerRule | undefined>
  >;
}

const { Option } = Select;
const { RangePicker } = DatePicker;
const CustomerForm = ({
  endDate,
  fillCalendarData,
  formEditCalendar,
  initialMonthAppear,
  setCalendarId,
  setShowEditModal,
  startDate,
  showEditModal,
  clientFormView,
  formCustomerRule,
  onFinishCustomerRule,
  setClientFormView,
  clients,
  colSize,
  requiredRule,
  customerRuleDates,
  customerRules,
  setCustRuleVisible,
  setCustomerRuleDates,
  validValueRule,
  pageSize,
  setPageSize,
  setSelectedCustomerRule,
  setDeleteCRModalVisible,
  generalCapacity,
}: ICustomerRuleProps): ReactElement<unknown> => {
  const tableColumns: ColumnsType<ICreateCustomerRule> = [
    {
      title: t('pages.scheduleRules.newRules.cadencesTable.organization'),
      render: customer => {
        const stateId = clients.find(id => customer.customerId === id.id);
        return stateId ? stateId.name || stateId.tradeName : '---';
      },
      key: 'customerId',
    },
    {
      title: () => t('pages.scheduleRules.rulePeriodDate'),
      render: (_, record) => {
        return (
          <Space size="middle">
            {moment(record.startDate).format('DD/MM/YYYY')}
            {'-'}
            {moment(record.endDate).format('DD/MM/YYYY')}
          </Space>
        );
      },
      key: 'rulePeriod',
    },
    {
      title: t('pages.scheduleRules.newRules.cadencesTable.capacity'),
      dataIndex: ['capacity'],
      key: 'capacity',
    },
    {
      title: t('pages.scheduleRules.newRules.cadencesTable.actions'),
      key: 'action',
      render: (index: IEditCustomerRule) => {
        return (
          <Space>
            <Typography.Link
              style={{ marginRight: 12 }}
              onClick={() => {
                if (index) {
                  setCustRuleVisible(true);
                  setSelectedCustomerRule(index);
                }
              }}
            >
              {t('pages.settings.actionEdit')}
            </Typography.Link>
            <Typography.Link
              style={{ color: 'red' }}
              onClick={() => {
                setSelectedCustomerRule(index);
                setDeleteCRModalVisible(true);
              }}
            >
              {t('pages.settings.actionDelete')}
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const disableCustomerRuleDates: RangePickerProps['disabledDate'] =
    current => {
      const isDateIntoRule = !current.isBetween(
        startDate,
        endDate,
        'days',
        '[]'
      );
      return isDateIntoRule;
    };

  return (
    <Card
      title={t('pages.scheduleRules.newRules.calendarTitle')}
      style={{ marginBottom: 84 }}
    >
      <Calendar
        key={initialMonthAppear?.toISOString()}
        defaultValue={initialMonthAppear}
        dateCellRender={fillCalendarData}
        onSelect={e => {
          setCalendarId(e);
          setShowEditModal(true);
          if (!showEditModal) {
            formEditCalendar.resetFields();
          }
        }}
        disabledDate={date =>
          date.isBefore(startDate, 'day') || date.isAfter(endDate, 'day')
        }
      />
      <Row>
        <Col span={8}>
          <Form.Item>
            <Checkbox
              name="userRuleView"
              checked={clientFormView}
              onChange={() => {
                setClientFormView(!clientFormView);
              }}
            >
              {t('pages.scheduleRules.newRules.checkboxLabel')}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {clientFormView && (
        <>
          <Form
            scrollToFirstError={scrollOptions}
            layout="vertical"
            onFinish={onFinishCustomerRule}
            form={formCustomerRule}
          >
            <Row gutter={24}>
              <Col {...colSize}>
                <Form.Item
                  rules={[{ ...requiredRule }]}
                  name="customerId"
                  label={t('pages.scheduleRules.newRules.clientLabel')}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t(
                      'pages.scheduleRules.newRules.defaultValueSelect'
                    )}
                  >
                    {clients &&
                      clients.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name || item.tradeName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col {...colSize}>
                <Form.Item
                  name="rulePeriod"
                  label={t('pages.scheduleRules.newRules.rulePeriodLabel')}
                  validateFirst
                  rules={[{ ...requiredRule }]}
                >
                  <RangePicker
                    placement="topLeft"
                    format={'DD/MM/YYYY'}
                    defaultValue={[startDate, endDate]}
                    onChange={(values: any) => {
                      setCustomerRuleDates([...customerRuleDates, values]);
                    }}
                    disabledDate={disableCustomerRuleDates}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>

              <Col {...colSize}>
                <Form.Item
                  rules={[
                    { ...requiredRule },
                    () => validValueRule,
                    {
                      validator: (_, value) => {
                        if (generalCapacity && value) {
                          return value < generalCapacity
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  t(
                                    'pages.scheduleRules.newRules.CRweightOverLimit'
                                  )
                                )
                              );
                        }
                      },
                    },
                  ]}
                  name="capacity"
                  label={t(
                    'pages.scheduleRules.newRules.maximumCapacityPerCompanyLabel'
                  )}
                >
                  <InputNumber
                    placeholder={t(
                      'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col {...colSize}>
                <Form.Item label=" ">
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    htmlType="submit"
                  >
                    {t('pages.scheduleRules.newRules.buttonContent')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            rowKey={({ id }) => id}
            size="small"
            bordered
            columns={tableColumns}
            dataSource={customerRules}
            pagination={{
              hideOnSinglePage: true,
              pageSize: pageSize,
              onChange(_, size) {
                setPageSize(size);
              },
            }}
          />
        </>
      )}
    </Card>
  );
};

export default withTranslation()(CustomerForm);
