import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Layout,
  PageHeader,
  Row,
  Space,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DefaultLogoCompany from '../../../assets/defaultLogoCompany.png';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import AddressController from '../../../structures/controllers/Address';
import CompanyController from '../../../structures/controllers/Company';
import {
  IDeletePickUpLocation,
  IEditPickUpLocation,
  PickUpLocation,
} from '../../../structures/interfaces/Address';
import {
  IDefaultBranchCompanies,
  IDeleteBranchCompany,
} from '../../../structures/interfaces/Company/Company';
import DeleteBranchCompany from './deleteBranchCompany';
import DeletePickUpAddress from './deletePickUpAddress';
import EditBranchCompany from './editBranchCompany';
import EditPickUpLocation from './editPickUpLocation';
import NewBranchCompany from './newBranchCompany';
import PickUpLocations from './pickUpLocations';

const css: React.CSSProperties = {
  marginTop: 24,
  alignItems: 'center',
};

const actionStyle: React.CSSProperties = {
  color: 'red',
};

const SettingsPage = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const [visible, setVisible] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [newBranchCompanyVisible, setNewBranchCompanyVisible] = useState(false);
  const [updateBranchCompanyModalVisible, setUpdateBranchCompanyModalVisible] =
    useState(false);
  const [branchCompanyData, setBranchCompanyData] =
    useState<IDefaultBranchCompanies>();
  const [modalVisible, setModalVisible] = useState(false);
  const [deletePickUpLocModalVisible, setDeletePickUpLocModalVisible] =
    useState(false);
  const [deletePickUpLocData, setDeletePickUpLocData] =
    useState<IDeletePickUpLocation>();
  const [deleteBranchCompModalVisible, setDeleteBranchCompModalVisible] =
    useState(false);
  const [deleteBranchCompData, setDeleteBranchCompData] =
    useState<IDeleteBranchCompany>();
  const [editData, setEditData] = useState<IEditPickUpLocation | any>();
  const [dataSource, setDataSource] = useState<PickUpLocation[]>([]);
  const [branchesDataSource, setBranchesDataSource] = useState<
    IDefaultBranchCompanies[]
  >([]);
  const [findOrganization] = useRequest(CompanyController.findOrganizationById);
  const [findPickUpLocationsRequest, isFindPickUpLocationsRequesting] =
    useRequest(AddressController.findPickUpLocations);

  const [findBranchesRequest, isFindBranchesRequesting] = useRequest(
    CompanyController.getBranchCompanies
  );

  const {
    userData,
    company,
    setCompany,
    filterByCreate,
    filterByUpdate,
    filterByDelete,
  } = useContext(UserContext);

  const createSettingsPermission = filterByCreate?.find(
    permission => permission?.userPermissions.module === 'REGRAS'
  );
  const updateSettingsPermission = filterByUpdate?.find(
    permission => permission?.userPermissions.module === 'REGRAS'
  );
  const deleteSettingsPermission = filterByDelete?.find(
    permission => permission?.userPermissions.module === 'REGRAS'
  );

  const EditCompany = (
    <>
      {updateSettingsPermission && (
        <Link
          to={`/configuracoes/empresas/${userData?.organization.id}/editar`}
        >
          <Button type="primary" size="small">
            {t('pages.settings.btnEditCompany')}
          </Button>
        </Link>
      )}
    </>
  );

  const btnNewPickUpLocation = (
    <>
      {createSettingsPermission && (
        <Button
          type="primary"
          htmlType="submit"
          size="small"
          onClick={() => {
            setVisible(true);
          }}
        >
          {t('pages.settings.btnNewPickUpLocation')}
        </Button>
      )}
    </>
  );

  const btnNewSubsidiary = (
    <>
      {createSettingsPermission && (
        <Button
          type="primary"
          htmlType="submit"
          size="small"
          onClick={() => setNewBranchCompanyVisible(true)}
        >
          {t('pages.settings.btnNewSubsidiary')}
        </Button>
      )}
    </>
  );

  const columnsPickUpLocation: ColumnsType<PickUpLocation> = [
    {
      title: t('pages.settings.title'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: t('pages.settings.zip_codes'),
      dataIndex: 'zipCode',
      key: 'zipCode',
    },
    {
      title: t('pages.settings.state_name'),
      dataIndex: 'stateShortName',
      key: 'stateShortName',
    },
    {
      title: t('pages.settings.city_name'),
      dataIndex: 'cityName',
      key: 'cityName',
    },
    {
      title: t('pages.settings.districts'),
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: t('pages.settings.street'),
      dataIndex: 'street',
      key: 'street',
    },
    {
      title: t('pages.settings.street_number'),
      dataIndex: 'streetNumber',
      key: 'streetNumber',
    },
    {
      title: t('pages.settings.complement'),
      dataIndex: 'complement',
      key: 'complement',
    },
    {
      title: t('pages.settings.action'),
      key: 'action',
      render: index => (
        <Space size="middle">
          {updateSettingsPermission && (
            <a
              onClick={() => {
                if (index) setEditData(index);
                setModalVisible(true);
              }}
            >
              {t('pages.settings.actionEdit')}
            </a>
          )}
          {deleteSettingsPermission && (
            <a
              style={actionStyle}
              onClick={() => {
                setDeletePickUpLocModalVisible(true);

                if (index) {
                  const data = {
                    id: index.id,
                    title: index.title,
                  };
                  setDeletePickUpLocData(data);
                }
              }}
            >
              {t('pages.settings.actionDelete')}
            </a>
          )}
        </Space>
      ),
    },
  ];

  const columnsMyBranches: ColumnsType<IDefaultBranchCompanies> = [
    {
      title: t('pages.settings.trade_name'),
      dataIndex: 'tradeName',
      key: 'tradeName',
      sorter: (a, b) => a.tradeName.localeCompare(b.tradeName),
    },
    {
      title: t('pages.settings.document_number'),
      dataIndex: 'documentNumberCnpj',
      key: 'documentNumberCnpj',
    },
    {
      title: t('pages.settings.company_name'),
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: t('pages.settings.zip_codes'),
      dataIndex: ['defaultLocation', 'zipCode'],
      key: 'zipCode',
    },
    {
      title: t('pages.settings.state_name'),
      dataIndex: ['defaultLocation', 'stateShortName'],
      key: 'stateShortName',
    },
    {
      title: t('pages.settings.city_name'),
      dataIndex: ['defaultLocation', 'cityName'],
      key: 'cityName',
    },
    {
      title: t('pages.settings.districts'),
      dataIndex: ['defaultLocation', 'district'],
      key: 'districts',
    },
    {
      title: t('pages.settings.street'),
      dataIndex: ['defaultLocation', 'street'],
      key: 'street',
    },
    {
      title: t('pages.settings.street_number'),
      dataIndex: ['defaultLocation', 'streetNumber'],
      key: 'streetNumber',
    },
    {
      title: t('pages.settings.action'),
      key: 'action',
      render: (index: IDefaultBranchCompanies) => (
        <Space size="middle">
          {updateSettingsPermission && (
            <a
              onClick={() => {
                setUpdateBranchCompanyModalVisible(true);
                if (index) {
                  setBranchCompanyData(index);
                }
              }}
            >
              {t('pages.settings.actionEdit')}
            </a>
          )}
          {deleteSettingsPermission && (
            <a
              style={actionStyle}
              onClick={() => {
                setDeleteBranchCompModalVisible(true);
                if (index) {
                  const data = {
                    id: index.id,
                    tradeName: index.tradeName,
                  };
                  setDeleteBranchCompData(data);
                }
              }}
            >
              {t('pages.settings.actionDelete')}
            </a>
          )}
        </Space>
      ),
    },
  ];

  const filteredColumnsPickUpLocation = columnsPickUpLocation.filter(column => {
    if (!updateSettingsPermission && !deleteSettingsPermission) {
      return column.key != 'action';
    } else {
      return column;
    }
  });

  const filteredColumnsMyBranches = columnsMyBranches.filter(column => {
    if (!updateSettingsPermission && !deleteSettingsPermission) {
      return column.key != 'action';
    } else {
      return column;
    }
  });

  useEffect(() => {
    if (userData) {
      findPickUpLocationsRequest({
        organizationId: userData?.organization.id,
      }).then(pickUpLocation => {
        setDataSource(pickUpLocation);
      });
    }
  }, [company]);

  useEffect(() => {
    if (userData) {
      findBranchesRequest({ id: userData.organization.id }).then(branch => {
        setBranchesDataSource(branch);
      });
    }
  }, [userData, company]);

  return (
    <>
      <Layout>
        <PageHeader
          className="site-page-header"
          title={t('pages.settings.pageTitle')}
          subTitle={t('pages.settings.subtitle')}
          ghost={false}
          style={{ margin: 0 }}
        />

        <Row>
          <div style={{ marginLeft: 24, marginRight: 24 }}>
            <Col span={24}>
              <Card
                title={t('pages.settings.cardTitle')}
                style={css}
                extra={EditCompany}
              >
                <Row gutter={[80, 0]}>
                  <Col>
                    {t('pages.settings.company_name')}: {company?.tradeName}
                  </Col>
                  <Col>
                    {t('pages.settings.companyName')}: {company?.companyName}
                  </Col>
                  <Col>
                    {t('pages.settings.document_number')}:{' '}
                    {Formatter.formatCNPJ(company?.documentNumberCnpj ?? '')}
                  </Col>
                </Row>
                <div style={{ marginTop: 24, marginBottom: 24 }}>
                  <img
                    src={
                      company?.logoUrl ? company?.logoUrl : DefaultLogoCompany
                    }
                    style={{
                      maxWidth: '30%',
                      borderRadius: '2rem',
                    }}
                  />
                </div>
              </Card>
            </Col>

            <Card title={t('pages.settings.contact')}>
              <Descriptions
                column={{ xxl: 6, xl: 3, lg: 5, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label={t('pages.settings.phoneNumber')}>
                  {company?.contacts.map(item =>
                    Formatter.formatCellphone(item.phoneNumber ?? '')
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.settings.email')}>
                  {company?.contacts.map(item => item.email ?? '')}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            <Col span={24}>
              <Card title={t('pages.settings.companyAdress')}>
                <Descriptions
                  column={{ xxl: 6, xl: 3, lg: 5, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label={t('pages.settings.zip_codes')}>
                    {company?.addresses &&
                      Formatter.formatZipCode(company?.addresses.zipCode)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.settings.state_name')}>
                    {company?.addresses && company?.addresses.stateShortName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.settings.city_name')}>
                    {company?.addresses && company?.addresses.cityName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.settings.districts')}>
                    {company?.addresses && company?.addresses.district}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.settings.street')}>
                    {company?.addresses && company?.addresses.street}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.settings.street_number')}>
                    {company?.addresses && company?.addresses.streetNumber}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>

            <Col span={24}>
              <Card
                title={t('pages.settings.pick_up_locations')}
                style={css}
                extra={btnNewPickUpLocation}
              >
                <Table
                  rowKey={pickUpLocation => pickUpLocation.id}
                  columns={filteredColumnsPickUpLocation}
                  dataSource={dataSource}
                  loading={isFindPickUpLocationsRequesting}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: pageSize,
                    onChange(_, size) {
                      setPageSize(size);
                    },
                  }}
                />
              </Card>
            </Col>

            <Col span={24}>
              <Card
                title={t('pages.settings.mySubsidiarys')}
                style={{
                  marginTop: 24,
                  alignItems: 'center',
                  marginBottom: 24,
                }}
                extra={btnNewSubsidiary}
              >
                {
                  <Table
                    rowKey={branche => branche.id}
                    loading={isFindBranchesRequesting}
                    pagination={{
                      hideOnSinglePage: true,
                      pageSize: pageSize,
                      onChange(_, size) {
                        setPageSize(size);
                      },
                    }}
                    columns={filteredColumnsMyBranches}
                    dataSource={branchesDataSource}
                  />
                }
              </Card>
            </Col>
          </div>
        </Row>
      </Layout>
      <PickUpLocations
        visible={visible}
        onClose={() => {
          setVisible(false);
          if (userData) {
            findOrganization({
              organizationId: userData?.organization.id,
            })
              .then(comp => {
                setCompany(comp);
              })
              .catch(() => {
                <Alert
                  message={t('error.100.message')}
                  description={t('error.100.description')}
                />;
              });
          }
        }}
      />
      <NewBranchCompany
        visible={newBranchCompanyVisible}
        onClose={() => {
          setNewBranchCompanyVisible(false);
          if (userData) {
            findOrganization({
              organizationId: userData?.organization.id,
            })
              .then(comp => {
                setCompany(comp);
              })
              .catch(() => {
                <Alert
                  message={t('error.100.message')}
                  description={t('error.100.description')}
                />;
              });
          }
        }}
      />
      {branchCompanyData && (
        <EditBranchCompany
          visible={updateBranchCompanyModalVisible}
          onClose={() => {
            setUpdateBranchCompanyModalVisible(false);
            if (userData) {
              findOrganization({
                organizationId: userData?.organization.id,
              })
                .then(comp => {
                  setCompany(comp);
                })
                .catch(() => {
                  <Alert
                    message={t('error.100.message')}
                    description={t('error.100.description')}
                  />;
                });
            }
          }}
          editData={branchCompanyData}
        />
      )}
      <EditPickUpLocation
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          if (userData) {
            findOrganization({
              organizationId: userData?.organization.id,
            })
              .then(comp => {
                setCompany(comp);
              })
              .catch(() => {
                <Alert
                  message={t('error.100.message')}
                  description={t('error.100.description')}
                />;
              });
          }
        }}
        editData={editData}
      />
      {deletePickUpLocData && (
        <DeletePickUpAddress
          visible={deletePickUpLocModalVisible}
          onConfirm={() => {
            const newData = [...dataSource];
            const index = dataSource.findIndex(
              item => deletePickUpLocData.id === item.id
            );
            newData.splice(index, 1);
            setDataSource(newData);
            setDeletePickUpLocModalVisible(false);
          }}
          deleteData={deletePickUpLocData}
          onClose={() => setDeletePickUpLocModalVisible(false)}
        />
      )}
      {deleteBranchCompData && (
        <DeleteBranchCompany
          visible={deleteBranchCompModalVisible}
          onConfirm={() => {
            if (branchesDataSource) {
              const newData = [...branchesDataSource];
              const index = newData.findIndex(item => {
                if (deleteBranchCompData.id === item.id) return item.id;
              });
              newData.splice(index, 1);
              setBranchesDataSource(newData);
            }

            setDeleteBranchCompModalVisible(false);
          }}
          deleteData={deleteBranchCompData}
          onClose={() => setDeleteBranchCompModalVisible(false)}
        />
      )}
    </>
  );
};

export default withTranslation()(SettingsPage);
