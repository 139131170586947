import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  PageHeader,
  Row,
} from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { ReactElement, useContext, useEffect } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Formatter from '../../../classes/Formatter';
import Validator from '../../../classes/Validator';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import UserController from '../../../structures/controllers/User';
import { scrollOptions } from '../../../utils/formOptions';
import { validateCpf } from '../../../utils/inputRules';
import {
  IAuthToken,
  IUpdateUserDataFormValues,
} from '../../../utils/interfaces';
import { getDataFromStorage } from '../../../utils';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const ConfigureUserAccess = ({
  t,
}: {
  t: TFunction;
}): ReactElement<unknown> => {
  const [form] = Form.useForm();
  const { firstLoginData, logout } = useContext(UserContext);
  const history = useHistory();

  const [updateUserData, loadingUpdateUserData] = useRequest(
    UserController.updateUserData
  );

  const requiredRule = {
    required: true,
    message: t('pages.login.fields.requiredField'),
  };

  const info = () => {
    message.success({
      content: t('pages.customerAccount.firstConfig.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  const onFinish = (values: IUpdateUserDataFormValues) => {
    const reqValues = { ...values };
    delete reqValues.repeatNewPassword;

    reqValues.phoneNumber = reqValues.phoneNumber.replace(/[^0-9]/g, '');
    reqValues.documentNumberCpf = reqValues.documentNumberCpf.replace(
      /[^0-9]/g,
      ''
    );

    if (firstLoginData) {
      updateUserData({
        userData: {
          ...firstLoginData,
          documentNumberCpf: reqValues.documentNumberCpf,
          name: reqValues.name,
          password: reqValues.password,
          phoneNumber: reqValues.phoneNumber,
        },
        id: firstLoginData.id,
      })
        .then(() => {
          info();
          logout();
          history.push('/auth');
        })
        .catch(() => {
          message.error({
            content: t('pages.customerAccount.firstConfig.error.message'),
            style: { marginTop: '4rem' },
          });
        });
    }
  };

  useEffect(() => {
    const token = getDataFromStorage('token');
    if (token) {
      const decodedToken = jwtDecode<IAuthToken & JwtPayload>(`${token}`);
      if (!decodedToken.first_login) {
        history.push('/auth');
      }
    } else {
      history.push('/auth');
    }

    if (firstLoginData) {
      const validDocument = Validator.validateCPF(
        firstLoginData.documentNumberCpf
      );
      const validPhone = Validator.validateCellphoneWithoutDDI(
        firstLoginData.phoneNumber
      );

      if (!validDocument) firstLoginData.documentNumberCpf = '';
      if (!validPhone) firstLoginData.phoneNumber = '';

      const formatedData = {
        ...firstLoginData,
        documentNumberCpf: Formatter.formatCPF(
          firstLoginData.documentNumberCpf
        ),
        phoneNumber: Formatter.formatCellphone(firstLoginData.phoneNumber),
      };

      form.setFieldsValue(formatedData);
    }
  }, [firstLoginData]);

  const basicData = () => {
    return (
      <>
        <Card title={t('pages.customerAccount.firstConfig.basicData')}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={7} xl={5} xxl={5}>
              <Form.Item
                label={t('pages.customerAccount.firstConfig.name')}
                name="name"
                rules={[requiredRule]}
              >
                <Input
                  placeholder={t('pages.customerAccount.firstConfig.phName')}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={4}>
              <Form.Item
                label={t('pages.customerAccount.firstConfig.documentNumberCpf')}
                name="documentNumberCpf"
                normalize={Formatter.formatCPF}
                validateFirst
                rules={[
                  {
                    min: 11,
                    message: t(
                      'pages.customerAccount.firstConfig.rmDocumentNumberCpfMinSize'
                    ),
                  },
                  { ...validateCpf, message: t(validateCpf.message) },
                  requiredRule,
                ]}
              >
                <Input
                  placeholder={t(
                    'pages.customerAccount.firstConfig.phDocumentNumberCpf'
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </>
    );
  };
  const contact = () => {
    return (
      <Card title={t('pages.customerAccount.firstConfig.contact')}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={7} xl={6} xxl={4}>
            <Form.Item
              label={t('pages.customerAccount.firstConfig.phoneNumber')}
              name="phoneNumber"
              normalize={Formatter.formatCellphone}
              rules={[
                {
                  required: true,
                  message: t('pages.customerAccount.firstConfig.rmPhoneNumber'),
                },
              ]}
            >
              <Input
                placeholder={t(
                  'pages.customerAccount.firstConfig.phPhoneNumber'
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    );
  };
  const security = () => {
    return (
      <Card
        title={t('pages.customerAccount.firstConfig.security')}
        style={{ marginBottom: 84 }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
            <Form.Item
              label={t('pages.customerAccount.firstConfig.password')}
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('pages.customerAccount.firstConfig.rmPassword'),
                },
                {
                  min: 6,
                  message: t(
                    'pages.customerAccount.firstConfig.rmMinLenPassword'
                  ),
                },
              ]}
            >
              <Input.Password
                placeholder={t('pages.customerAccount.firstConfig.phPassword')}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
            <Form.Item
              label={t('pages.customerAccount.firstConfig.repeatNewPassword')}
              name="repeatNewPassword"
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t(
                    'pages.customerAccount.firstConfig.rmRepeatNewPassword'
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t(
                          'pages.customerAccount.firstConfig.rmComparePasswords'
                        )
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={t(
                  'pages.customerAccount.firstConfig.phRepeatNewPassword'
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    );
  };
  const footer = () => {
    return (
      <Footer
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: '#FFF',
          textAlign: 'center',
          boxShadow:
            '0 -6px 16px -8px rgba(0,0,0,.08),0 -9px 28px 0 rgba(0,0,0,.05),0 -12px 48px 16px rgba(0,0,0,.03)',
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <Button
            style={{ marginRight: 24 }}
            danger
            htmlType="reset"
            onClick={() => {
              history.push('/area-usuario/primeiro-login');
            }}
          >
            {t('pages.editCompany.cancelBtn')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form.submit();
            }}
            loading={loadingUpdateUserData}
          >
            {t('pages.editCompany.confirmBtn')}
          </Button>
        </div>
      </Footer>
    );
  };

  return (
    <Layout style={{ minHeight: '100%', minWidth: '100%' }}>
      <PageHeader
        className="site-page-header"
        title={t('pages.customerAccount.firstConfig.title')}
        subTitle={t('pages.customerAccount.firstConfig.subtitle')}
        ghost={false}
      />
      <Layout
        style={{
          height: '100%',
          padding: 24,
        }}
      >
        <Form
          scrollToFirstError={scrollOptions}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          {basicData()}
          {contact()}
          {security()}
        </Form>
      </Layout>
      {footer()}
    </Layout>
  );
};

export default withTranslation()(ConfigureUserAccess);
