import { DeleteOutlined, HolderOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Button,
  Col,
  Form,
  FormInstance,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Table,
  Tag,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import React, {
  CSSProperties,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useRequest } from '../../../../../hooks/useRequest';
import TruckLoadsController from '../../../../../structures/controllers/TruckLoad';
import {
  IContItemsResponse,
  IGetTruckLoad,
  IStepTwoData,
} from '../../../../../structures/interfaces/TruckLoad';
import TruckImage, { ITruckImageItems } from '../../../components/TruckImage';
import {
  GetCultivationColor,
  handleRemoveLoad,
  isColorLight,
  removeDuplicatesContractItems,
} from '../LoadSteps.utils';
import { ItemsToAddType } from './StepTwo.contractItemsTable';

export type DefaultRecordType = Record<string, unknown>;

interface IOrderTableProps {
  data?: IContItemsResponse[];
  setData?: Dispatch<SetStateAction<IContItemsResponse[]>>;
  stepTwoData?: IStepTwoData;
  contractItemsForm?: FormInstance;
  setFilterItems?: Dispatch<SetStateAction<IContItemsResponse[]>>;
  itemsToAdd?: ItemsToAddType;
  isViewLoad?: boolean;
  isStepThree?: boolean;
  filterItems?: IContItemsResponse[];
  loadItems?: IContItemsResponse[];
  isEdition?: boolean;
  loadedAmountForm?: FormInstance;
  truckLoadData?: IGetTruckLoad;
  loadAll?: boolean;
  setLoadAll?: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
}
const OrderingTable = ({
  data = [],
  setData,
  isViewLoad,
  setFilterItems,
  contractItemsForm,
  itemsToAdd,
  filterItems,
  isEdition,
  loadedAmountForm,
  truckLoadData,
  loadAll,
  setLoadAll,
  isLoading,
  isStepThree,
}: IOrderTableProps): ReactElement => {
  const [form] = Form.useForm();
  const [hoveredBoxId, setHoveredBoxId] = useState<{ id: string }>();
  const [deleteItems] = useRequest(TruckLoadsController.deleteTruckLoadItems);

  const encodedCachedLoadedamount = localStorage.getItem('loadedAmount');

  const cachedLoadedAmount = encodedCachedLoadedamount
    ? JSON.parse(window.atob(encodedCachedLoadedamount))
    : null;

  const contractItemsColumns: ColumnsType<IContItemsResponse> = [
    {
      title: 'Org',
      key: 'sort',
      render: () => <DragHandle />,
    },
    {
      title: 'Ordem',
      key: 'order',
      dataIndex: 'order',
      render: (_, __, index) => {
        const value = removeDuplicatesContractItems(contractItems);
        return `${value.length - index}ª desc`;
      },
    },
    {
      title: 'Cliente',
      key: 'farmName',
      dataIndex: ['contract', 'farm', 'name'],
    },
    {
      title: 'Insc. Estadual',
      key: 'stateRegistration',
      dataIndex: ['contract', 'farm', 'stateRegistration'],
    },
    {
      title: 'Pedido',
      key: 'contractNumber',
      dataIndex: ['contract', 'referenceNumber'],
    },
    {
      title: 'Data do contrato',
      key: 'contractDate',
      dataIndex: ['contract', 'contractDate'],
      render: text => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Item ID',
      key: 'referenceNumber',
      dataIndex: 'referenceNumber',
    },
    {
      title: 'Cultivar',
      key: 'cultivation',
      dataIndex: ['cultivation'],
      render: cultivation => {
        const color = GetCultivationColor(cultivation);
        const textStyle = isColorLight(color) ? '#000000' : '#FFFF';
        const css: CSSProperties = {
          fontWeight: 'bold',
          color: textStyle,
          borderRadius: 8,
          width: '90%',
          textAlign: 'center',
        };
        return (
          <Tag color={color} style={css}>
            {cultivation}
          </Tag>
        );
      },
    },
    {
      title: 'Peneira',
      key: 'sieve',
      dataIndex: 'sieve',
    },
    {
      title: 'Categoria',
      key: 'category',
      dataIndex: 'category',
    },
    {
      title: 'Embalagem',
      key: 'packing',
      dataIndex: ['packing'],
    },
    {
      title: 'TSI',
      key: 'tsi',
      dataIndex: ['tsi'],
      render: tsi => {
        const color = tsi ? 'blue' : 'volcano';
        const content = tsi ? 'Sim' : 'Não';
        return <Tag color={color}>{content}</Tag>;
      },
    },
    {
      title: 'Peso est total',
      key: 'estimatedWeight',
      dataIndex: 'estimatedWeight',
    },
    {
      title: 'Quantidade alocada',
      key: 'allocatedAmount',
      dataIndex: 'allocatedAmount',
    },
    {
      title: 'Quantidade carregada',
      key: 'loadedAmount',
      dataIndex: 'loadedAmount',
      render: (_, record) => {
        if (loadAll) {
          loadedAmountForm?.setFieldsValue({
            [record.truckLoadItemId || '']: record.allocatedAmount,
          });
          const encryptedData = window.btoa(
            JSON.stringify(loadedAmountForm?.getFieldsValue())
          );
          localStorage.setItem('loadedAmount', encryptedData);
          setLoadAll?.(false);
        } else {
          if (truckLoadData?.status === 'LOADED')
            return truckLoadData.loadedAmount;

          const initialValue = cachedLoadedAmount
            ? cachedLoadedAmount[record.truckLoadItemId || '']
            : truckLoadData?.loadedAmount;

          return (
            <Form form={loadedAmountForm}>
              <Form.Item
                noStyle
                name={record.truckLoadItemId}
                initialValue={initialValue || 0}
              >
                <InputNumber
                  disabled
                  max={record.allocatedAmount}
                  min={0}
                  onChange={val => {
                    if (val !== null && !Number.isNaN(val)) {
                      const encryptedData = window.btoa(
                        JSON.stringify(loadedAmountForm?.getFieldsValue())
                      );
                      localStorage.setItem('loadedAmount', encryptedData);
                    }
                  }}
                />
              </Form.Item>
            </Form>
          );
        }
      },
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => {
        return (
          <Popconfirm
            zIndex={1050}
            title="Tem certeza que deseja remover este item?"
            onConfirm={() => {
              if (
                setData &&
                setFilterItems &&
                contractItemsForm &&
                itemsToAdd &&
                filterItems
              ) {
                handleRemoveLoad({
                  contractItems: filterItems,
                  setAddedItems: setData,
                  contractItemsForm,
                  selectedItemId: record.id,
                  allocatedAmount: record.allocatedAmount || 0,
                  setContractItems: setFilterItems,
                });
                if (record.truckLoadItemId) {
                  deleteItems({
                    input: { data: { id: record.truckLoadItemId } },
                  })
                    .then(() => message.success('Item removido com sucesso!'))
                    .catch(() => message.error('falha ao remover item'));
                }
              }
            }}
          >
            <Button danger icon={<DeleteOutlined />}>
              Remover
            </Button>
          </Popconfirm>
        );
      },
    },
  ];
  const filteredColumns = contractItemsColumns.filter(col => {
    if (isViewLoad) {
      return (
        col.key !== 'referenceNumber' &&
        col.key !== 'estimatedWeight' &&
        col.key !== 'stateRegistration' &&
        col.key !== 'contractDate' &&
        col.key !== 'sieve' &&
        col.key !== 'quantity' &&
        col.key !== 'category' &&
        col.key !== 'sort' &&
        col.key !== 'actions'
      );
    } else if (isEdition) {
      return (
        col.key !== 'referenceNumber' &&
        col.key !== 'estimatedWeight' &&
        col.key !== 'stateRegistration' &&
        col.key !== 'contractDate' &&
        col.key !== 'sieve' &&
        col.key !== 'quantity' &&
        col.key !== 'category' &&
        col.key !== 'sort'
      );
    } else if (isStepThree) {
      return (
        col.key !== 'referenceNumber' &&
        col.key !== 'estimatedWeight' &&
        col.key !== 'stateRegistration' &&
        col.key !== 'contractDate' &&
        col.key !== 'sieve' &&
        col.key !== 'quantity' &&
        col.key !== 'category' &&
        col.key !== 'loadedAmount'
      );
    } else {
      return (
        col.key !== 'referenceNumber' &&
        col.key !== 'estimatedWeight' &&
        col.key !== 'stateRegistration' &&
        col.key !== 'contractDate' &&
        col.key !== 'sieve' &&
        col.key !== 'quantity' &&
        col.key !== 'category'
      );
    }
  });

  const handleBoxHover = (id: string | undefined) => {
    if (!id) {
      setHoveredBoxId(undefined);
    } else {
      setHoveredBoxId({ id });
    }
  };
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setData?.(prevState => {
        const activeIndex = prevState.findIndex(record => {
          return record.id === active?.id;
        });
        const overIndex = prevState.findIndex(record => {
          return record.id === over?.id;
        });
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const uniqueContractItemsId = new Set<string>(data?.map(item => item.id));

  const contractItems = Array.from(uniqueContractItemsId).map(id =>
    data.find(item => item.id === id)
  ) as unknown as IContItemsResponse[];
  const truckImageItems: ITruckImageItems[] = contractItems.filter(
    item => item.id
  ) as unknown as ITruckImageItems[];

  return (
    <Form form={form}>
      <Row gutter={[24, 24]}>
        <Col span={2}>
          <TruckImage
            colorsAssigned={undefined}
            truckLoadItems={truckImageItems}
            onBoxHover={handleBoxHover}
            hoveredRow={hoveredBoxId?.id}
          />
        </Col>

        <Col span={22}>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={removeDuplicatesContractItems(contractItems).map(
                i => i.id
              )}
              strategy={verticalListSortingStrategy}
            >
              <Table
                loading={isLoading}
                size="small"
                rowKey={'id'}
                columns={filteredColumns}
                dataSource={removeDuplicatesContractItems(contractItems)}
                components={{ body: { row: TableRow } }}
                pagination={false}
                onRow={record => {
                  return {
                    onMouseEnter: () => handleBoxHover(record.id),
                    onMouseLeave: () => handleBoxHover(undefined),
                  };
                }}
              />
            </SortableContext>
          </DndContext>
        </Col>
      </Row>
    </Form>
  );
};

export default OrderingTable;

// COMPONENT TOOLS

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}
const RowContext = React.createContext<RowContextProps>({});
const DragHandle = (): ReactElement => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}
const TableRow: React.FC<RowProps> = props => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative' } : {}),
    // custom style
    background: '#fafafa',
    border: '1px solid #ccc',
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};
