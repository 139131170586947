import client from '../../api/urql/customClient';
import { IGetLoadingOrder, IReports } from '../interfaces/Reports';
import ReportQueries from '../queries/Reports';

class ReportsController {
  static getReports = (operatorId: string): Promise<IReports[]> =>
    new Promise((resolve, reject) =>
      client
        .query(ReportQueries.getReports(), {
          where: { operator_id: operatorId },
        })
        .then(({ reports }) => {
          resolve(reports);
        })
        .catch(err => reject(err))
    );

  static getLoadingOrder = (
    loadingOrderId: string
  ): Promise<IGetLoadingOrder> =>
    new Promise((resolve, reject) =>
      client
        .query(ReportQueries.getLoadingOrder(), {
          where: { loading_order_id: loadingOrderId },
        })
        .then(({ loadingOrder }) => {
          resolve(loadingOrder);
        })
        .catch(err => reject(err))
    );
}

export default ReportsController;
