import Formatter from '../../classes/Formatter';
import {
  ICityName,
  IDistrictName,
  IStateName,
  IZipCodeAddress,
  IZipCodeAddressAPI,
} from '../interfaces/Address';

class ZipCodeAddressModel implements IZipCodeAddress {
  zipCode: string;
  zipCodeFormatted: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  state: IStateName;
  city: ICityName;
  district?: IDistrictName;

  constructor(data: IZipCodeAddressAPI) {
    this.zipCode = data.zipCode;
    this.zipCodeFormatted = Formatter.formatZipCode(data.zipCode);
    this.address = [data.streetType, data.streetName].filter(Boolean).join(' ');
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.state = data.state;
    this.city = data.city;
    this.district = data.district;
  }
}

export default ZipCodeAddressModel;
