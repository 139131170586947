import { AxiosRequestConfig } from 'axios';

import axios from './axios';
import errorHandler from './errorHandler';

import { IAPIResponse } from '../utils/interfaces';

function requestHandler<T>(request: Promise<IAPIResponse>): Promise<T> {
  return new Promise((resolve, reject) =>
    request
      .then(({ data }) => {
        const { errors = [] } = data;
        if (errors.length) reject(errorHandler(errors));
        resolve(data);
      })
      .catch(err => reject(err))
  );
}

export const requester = {
  get: <T>(url: string, params?: unknown): Promise<T> =>
    requestHandler(axios.get(url, { params })),
  patch: <T>(url: string, body?: unknown): Promise<T> =>
    requestHandler(axios.patch(url, body)),
  post: <T>(
    url: string,
    body?: unknown,
    config?: AxiosRequestConfig
  ): Promise<T> => requestHandler(axios.post(url, body, config)),
  delete: <T>(url: string): Promise<T> => requestHandler(axios.delete(url)),
};

export default requester;
