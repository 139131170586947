import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import Layout from 'antd/lib/layout/layout';
import { ReactElement, useContext, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import UserController from '../../../structures/controllers/User';

const UpdatePassword = ({
  t,
  visible,
  onClose,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
}): ReactElement<unknown> => {
  const [error, setError] = useState<{
    message: string;
    type: 'error';
    description?: string;
  } | null>(null);

  const [form] = Form.useForm();
  const { Title } = Typography;
  const { userData, customerData } = useContext(UserContext);
  const [updatePasswordRequest, isUpdatePickUpLocationRequesting] = useRequest(
    UserController.updatePassword
  );
  const info = () => {
    message.success({
      content: t('pages.account.changePassword.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  const onFinish = (values: { newPassword: string }) => {
    if (userData) {
      updatePasswordRequest({
        id: userData.id,
        password: values.newPassword,
      })
        .then(() => {
          info();
          form.resetFields();
          onClose();
        })
        .catch(({ message, description }) => {
          setError({
            message: t(message),
            description: t(description),
            type: 'error',
          });
        });
    } else if (customerData) {
      updatePasswordRequest({
        id: customerData.id,
        password: values.newPassword,
      })
        .then(() => {
          info();
          form.resetFields();
          onClose();
        })
        .catch(({ message, description }) => {
          setError({
            message: t(message),
            description: t(description),
            type: 'error',
          });
        });
    }
  };

  const formUpdate = (
    <Form name="basic" layout="vertical" onFinish={onFinish} form={form}>
      {error && (
        <Alert
          message={error.message}
          description={error.description}
          type={error.type}
          closable
          style={{ marginBottom: 24 }}
        />
      )}
      <Row>
        <Form.Item
          label={t('pages.account.changePassword.labelNewPassword')}
          name="newPassword"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('pages.account.changePassword.rmNewPassword'),
            },
            {
              min: 6,
              message: t('pages.account.changePassword.rmMinLenPassword'),
            },
          ]}
        >
          <Input.Password
            placeholder={t('pages.account.changePassword.phNewPassword')}
          />
        </Form.Item>
      </Row>
      <Row>
        <Form.Item
          label={t('pages.account.changePassword.labelRepeatNewPassword')}
          name="repeatedNewPassword"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('pages.account.changePassword.rmRepeatNewPassword'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    t('pages.account.changePassword.rmComparePasswords')
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t('pages.account.changePassword.phRepeatNewPassword')}
          />
        </Form.Item>
      </Row>
    </Form>
  );

  const footerModal = [
    <Button key="back" danger onClick={onClose}>
      {t('pages.account.changePassword.cancelButton')}
    </Button>,
    <Button
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
        setError(null);
      }}
      loading={isUpdatePickUpLocationRequesting}
    >
      {t('pages.account.changePassword.confirmButton')}
    </Button>,
  ];
  return (
    <Layout>
      <Modal
        width={'30%'}
        open={visible}
        onCancel={onClose}
        footer={footerModal}
      >
        <Title level={5}>{t('pages.account.changePassword.title')}</Title>
        {formUpdate}
      </Modal>
    </Layout>
  );
};

export default withTranslation()(UpdatePassword);
