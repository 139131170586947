import { ReactElement, useContext, useEffect, useState } from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  Checkbox,
  Form,
  FormItemProps,
  Input,
  Row,
  message,
} from 'antd';

import User from '../../../classes/User';
import AuthContainer from '../../../components/authentication/authContainer';
import UserContext from '../../../contexts/user';
import UserController from '../../../structures/controllers/User';

import { useRequest } from '../../../hooks/useRequest';
import { clearStorage, getDataFromStorage } from '../../../utils';
import Authentication from '../../authentication';
import './index.scss';
interface ILogin {
  email: string;
  password: string;
  keepConnected: boolean;
}

interface ILoginProps {
  t: TFunction;
  bordered?: boolean;
}

const UserFirstSignIn = ({ t }: ILoginProps): ReactElement<unknown> => {
  const [firstLoginRequest, isFirstLoginRequesting] = useRequest(
    UserController.firstLogin
  );
  const [hasError, setHasError] = useState<FormItemProps>({});
  const { setDecodedToken, logout } = useContext(UserContext);
  const history = useHistory();

  const requiredRule = {
    required: true,
    message: t('pages.login.fields.requiredField'),
  };

  const showError = () => {
    message.error({
      content: 'Credenciais inválidas!',
      style: { marginTop: '4rem' },
    });
  };

  const onFinish = (values: ILogin) => {
    const lowerCaseValues: ILogin = {
      ...values,
      email: values.email.toLowerCase(),
    };
    firstLoginRequest(lowerCaseValues)
      .then(({ token }) => {
        User.setTokenToStorage(token, true)
          .then(decodedToken => {
            setDecodedToken(decodedToken);
            history.push('/area-usuario/configuracao');
          })
          .catch(() => {
            setHasError({
              hasFeedback: true,
              validateStatus: 'error',
              help: t('pages.login.fields.invalidCredentials'),
            });
            logout();
            history.push('/auth');
          });
      })
      .catch(() => {
        logout();
        history.push('/auth');
        showError();
        // setHasError({
        //   hasFeedback: true,
        //   validateStatus: 'error',
        //   help: t('pages.login.fields.invalidCredentials'),
        // });
      });
  };

  const LoginForm = (
    <Form
      className="form"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      initialValues={{ keepConnected: true }}
    >
      <Form.Item
        name="email"
        label={t('pages.login.fields.email.label')}
        {...hasError}
        rules={[
          requiredRule,
          {
            message: t('pages.login.fields.invalidEmail'),
            type: 'email',
            validateTrigger: 'click',
          },
        ]}
      >
        <Input placeholder={t('pages.login.fields.email.placeholder')} />
      </Form.Item>

      <Form.Item
        name="password"
        label={t('pages.login.fields.password.label')}
        {...hasError}
        rules={[
          requiredRule,
          {
            message: t('pages.login.fields.minimumDigits'),
            min: 6,
            validateTrigger: 'click',
          },
        ]}
      >
        <Input.Password
          placeholder={t('pages.login.fields.password.placeholder')}
        />
      </Form.Item>

      <Row align="middle" justify="space-between" style={{ rowGap: '12px' }}>
        <div className="keep-connected">
          <Form.Item name="keepConnected" valuePropName="checked">
            <Checkbox>{t('pages.login.fields.keepConnected')}</Checkbox>
          </Form.Item>
        </div>

        <Link to="/recuperar-senha" className="custom-link">
          {t('pages.login.fields.passwordRecovery')}
        </Link>
      </Row>

      <Row justify="end">
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          loading={isFirstLoginRequesting}
          style={{
            width: '100%',
          }}
        >
          {t('pages.login.fields.loginButton')}
        </Button>
      </Row>
    </Form>
  );

  useEffect(() => {
    const storageData = getDataFromStorage('token');
    if (storageData) {
      clearStorage();
    }
  }, []);

  return (
    <Authentication>
      <AuthContainer className="auth-container">
        <Card className="login-card" bordered={false}>
          <header>
            <div className="decoration"></div>
            <div className="header-container">
              <h1 className="title">{t('pages.login.userFirstLoginTitle')}</h1>
              <span className="subtitle">
                {t('pages.signUp.firstSignIn.subtitle')}
              </span>
            </div>
          </header>
          {LoginForm}
        </Card>
      </AuthContainer>
    </Authentication>
  );
};

export default withTranslation()(UserFirstSignIn);
