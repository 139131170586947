import client from '../../api/urql/customClient';
import { ISettings, IntegrationMod } from '../interfaces/Settings';
import ScheduleRulesQueries from '../queries/ScheduleRules';
import SettingsQueries from '../queries/Settings';

class SettingsController {
  static getSettings = ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<ISettings> =>
    new Promise((resolve, reject) =>
      client
        .query(ScheduleRulesQueries.getSettings(), {
          where: {
            organization_id: organizationId,
          },
        })
        .then(({ getSettings }) => {
          resolve(getSettings);
        })
        .catch(err => {
          reject(err);
        })
    );

  static updatePercentageTolerance = ({
    organizationId,
    tolerancePercentage,
  }: {
    organizationId: string;
    tolerancePercentage?: number | null;
  }): Promise<ISettings> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.updateTolerancePercentage(), {
          where: {
            organization_id: organizationId,
          },
          input: {
            tolerance_percentage: tolerancePercentage,
          },
        })
        .then(({ updateTolerancePercentage }) => {
          resolve(updateTolerancePercentage);
        })
        .catch(err => {
          reject(err);
        })
    );

  static updateTreatmentTime = ({
    organizationId,
    commonTsiSeedSchedulingTime,
    commonWhiteSeedSchedulingTime,
  }: {
    organizationId: string;
    commonTsiSeedSchedulingTime?: number | null;
    commonWhiteSeedSchedulingTime?: number | null;
  }): Promise<ISettings> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.updateTreatmentTime(), {
          where: {
            organization_id: organizationId,
          },
          input: {
            common_tsi_seed_scheduling_time: commonTsiSeedSchedulingTime,
            common_white_seed_scheduling_time: commonWhiteSeedSchedulingTime,
          },
        })
        .then(({ updateTreatmentTime }) => {
          resolve(updateTreatmentTime);
        })
        .catch(err => {
          reject(err);
        })
    );

  static updateSettings = ({
    organizationId,
    tolerancePercentage,
    commonTsiSeedSchedulingTime,
    commonWhiteSeedSchedulingTime,
  }: {
    organizationId: string;
    tolerancePercentage?: number | null;
    commonTsiSeedSchedulingTime?: number;
    commonWhiteSeedSchedulingTime?: number;
  }): Promise<ISettings> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.updateSettings(), {
          where: {
            organization_id: organizationId,
          },
          input: {
            tolerance_percentage: tolerancePercentage,
            common_tsi_seed_scheduling_time: commonTsiSeedSchedulingTime,
            common_white_seed_scheduling_time: commonWhiteSeedSchedulingTime,
          },
        })
        .then(({ updateSettings }) => {
          resolve(updateSettings);
        })
        .catch(err => {
          reject(err);
        })
    );

  static getIntegrationModules = (id: string): Promise<IntegrationMod> =>
    new Promise((resolve, reject) =>
      client
        .query(SettingsQueries.getIntegrationModules(), {
          where: {
            id: id,
          },
        })
        .then(({ modules }) => {
          resolve(modules);
        })
        .catch(err => {
          reject(err);
        })
    );
}

export default SettingsController;
