import { Descriptions } from 'antd';
import { ReactElement } from 'react';
import Formatter from '../../../../../classes/Formatter';

const { Item } = Descriptions;

export interface ICarrierData {
  documentNumberCnpj: string;
  tradeName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
}
interface IShippingCompanyDescriptionProps {
  data: ICarrierData | undefined;
}

const ShippingCompanyDescriptions = ({
  data,
}: IShippingCompanyDescriptionProps): ReactElement => {
  if (!data)
    data = {
      companyName: '---',
      email: '---',
      tradeName: '---',
      documentNumberCnpj: '---',
      phoneNumber: '---',
    };

  const { companyName, email, tradeName, documentNumberCnpj, phoneNumber } =
    data;

  return (
    <Descriptions labelStyle={{ fontWeight: 'bold' }}>
      <Item label="CNPJ da transportadora">
        {Formatter.formatCNPJ(documentNumberCnpj)}
      </Item>

      <Item label="Nome da transportadora">{tradeName}</Item>

      <Item label="Nome Fantasia">{companyName}</Item>

      <Item label="E-mail">{email}</Item>

      <Item label="Telefone">
        {Formatter.formatPhoneWithoutDDI(phoneNumber)}
      </Item>
    </Descriptions>
  );
};

export default ShippingCompanyDescriptions;
