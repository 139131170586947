import { Form, FormInstance, InputNumber, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  CSSProperties,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
} from 'react';
import UserContext from '../../../../../contexts/user';
import { IGetCustomer } from '../../../../../structures/interfaces/Clients';
import {
  IContItemsResponse,
  IStepTwoData,
} from '../../../../../structures/interfaces/TruckLoad';
import { GetCultivationColor, isColorLight } from '../LoadSteps.utils';

const quantityStyle: CSSProperties = {
  background: '#F0F0F0',
};
export interface ItemsToAddType {
  [key: string]: { index: number; value: number; data: IContItemsResponse };
}
interface IContractItemsTableProps {
  data: IContItemsResponse[];
  stepTwoData?: IStepTwoData;
  form: FormInstance;
  itemsToAdd: ItemsToAddType;
  setItemsToAdd: Dispatch<SetStateAction<ItemsToAddType>>;
  isLoading?: boolean;
}

const renderQuantityColumns = ({
  customerData,
  quantityChildrenColumns,
}: {
  customerData?: IGetCustomer | null;
  quantityChildrenColumns: ColumnsType<IContItemsResponse>;
}) => {
  if (customerData) {
    const renderColumns = quantityChildrenColumns.filter(column => {
      return column.key !== 'customerRemainingAmount';
    });
    return renderColumns;
  } else return quantityChildrenColumns;
};
const renderPickUpField = ({
  contractItem,
  form,
  index,
  itemsToAdd,
  setItemsToAdd,
}: {
  contractItem: IContItemsResponse;
  form: FormInstance;
  setItemsToAdd: Dispatch<SetStateAction<ItemsToAddType>>;
  itemsToAdd: ItemsToAddType;
  index: number;
}) => {
  const onChange = (val: number | null) => {
    if (val !== null && val < 0) {
      return form.setFieldValue([contractItem.id], 0);
    }
    if (val !== null && !Number.isNaN(val)) {
      if (val > 0) {
        const newItem = {
          ...itemsToAdd,
          [contractItem.id]: {
            ...contractItem,
            index,
            value: val,
          },
        } as ItemsToAddType;
        setItemsToAdd(newItem);
      } else {
        const res = { ...itemsToAdd };
        delete res[contractItem.id];
        setItemsToAdd(res);
      }
    }
  };
  const onBlur = () => {
    const inputValue = form.getFieldValue([contractItem.id]);
    if (!inputValue) {
      const res = { ...itemsToAdd };
      delete res[contractItem.id];
      setItemsToAdd(res);
      form.resetFields([contractItem.id]);
    }
  };

  return (
    <Form.Item name={contractItem.id} initialValue={0} noStyle>
      <InputNumber
        min={0}
        controls={true}
        name={contractItem.id}
        max={contractItem.remainingAmount}
        onBlur={onBlur}
        onChange={onChange}
      />
    </Form.Item>
  );
};
const renderRemainingAmount = ({
  amount,
  data,
  itemsToAdd,
}: {
  amount: number;
  data: IContItemsResponse;
  itemsToAdd: ItemsToAddType;
}) => {
  const result = amount - (itemsToAdd[data.id]?.value || 0);
  return result < 0 ? 0 : result;
};

const ContractItemsTable = ({
  data,
  form,
  itemsToAdd,
  setItemsToAdd,
  isLoading,
}: IContractItemsTableProps): ReactElement => {
  const { customerData } = useContext(UserContext);

  const quantityChildrenColumns: ColumnsType<IContItemsResponse> = [
    {
      title: 'Contrato',
      key: 'fullAmount',
      dataIndex: 'fullAmount',
      onHeaderCell: () => ({ style: quantityStyle }),
    },
    {
      title: 'Disponível',
      key: 'remainingAmount',
      dataIndex: 'remainingAmount',
      onHeaderCell: () => ({ style: quantityStyle }),
      render: (amount, data) =>
        renderRemainingAmount({ amount, data, itemsToAdd }),
    },
    // {
    //   title: 'Limite diário',
    //   key: 'customerRemainingAmount',
    //   dataIndex: ['customerCapacity', 'remainingAmount'],
    //   onHeaderCell: () => ({ style: quantityStyle }),
    // },
  ];
  const contractItemsColumns: ColumnsType<IContItemsResponse> = [
    {
      title: 'Cliente',
      key: 'farmName',
      dataIndex: ['contract', 'farm', 'name'],
      width: '20%',
    },
    {
      title: 'Insc. Estadual',
      key: 'stateRegistration',
      dataIndex: ['contract', 'farm', 'stateRegistration'],
    },
    {
      title: 'Contrato',
      key: 'contractNumber',
      dataIndex: ['contract', 'referenceNumber'],
    },
    {
      title: 'Data do contrato',
      key: 'contractDate',
      dataIndex: ['contract', 'contractDate'],
      render: text => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Item ID',
      key: 'referenceNumber',
      dataIndex: 'referenceNumber',
    },
    {
      title: 'Cultivar',
      key: 'cultivation',
      dataIndex: 'cultivation',
      render: cultivation => {
        const color = GetCultivationColor(cultivation);
        const textStyle = isColorLight(color) ? '#000000' : '#FFFF';
        return (
          <Tag
            color={color}
            style={{
              fontWeight: 'bold',
              color: textStyle,
              borderRadius: 8,
              width: '100%',
              textAlign: 'center',
            }}
          >
            {cultivation}
          </Tag>
        );
      },
    },
    {
      title: 'Peneira',
      key: 'sieve',
      dataIndex: 'sieve',
    },
    {
      title: 'Categoria',
      key: 'category',
      dataIndex: 'category',
    },
    {
      title: 'Embalagem',
      key: 'packing',
      dataIndex: 'packing',
    },
    {
      title: 'TSI',
      key: 'tsi',
      dataIndex: 'tsi',
      render: tsi => {
        const color = tsi ? 'blue' : 'volcano';
        const content = tsi ? 'Sim' : 'Não';
        return <Tag color={color}>{content}</Tag>;
      },
    },
    {
      title: 'Peso est total',
      key: 'estimatedWeight',
      dataIndex: 'estimatedWeight',
    },
    {
      title: 'QUANTIDADES',
      onHeaderCell: () => ({ style: quantityStyle }),
      children: renderQuantityColumns({
        quantityChildrenColumns,
        customerData,
      }),
    },
    {
      title: 'Retirar',
      render: (_, record, index) =>
        renderPickUpField({
          form,
          index,
          itemsToAdd,
          contractItem: record,
          setItemsToAdd,
        }),
    },
  ];
  const filteredColumns = contractItemsColumns.filter(col => {
    if (customerData) {
      return (
        col.key !== 'referenceNumber' &&
        col.key !== 'estimatedWeight' &&
        col.key !== 'sieve' &&
        col.key !== 'category'
      );
    } else {
      return col.key !== 'referenceNumber' && col.key !== 'estimatedWeight';
    }
  });

  return (
    <Form form={form}>
      <Table
        loading={isLoading}
        size="small"
        rowKey={contractItems => contractItems.id}
        columns={filteredColumns}
        dataSource={data}
      />
    </Form>
  );
};

export default ContractItemsTable;
