import { Tag } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';

interface ScheduleStatusProps {
  t: TFunction<'translation', undefined>;
  status?: string;
}

export const ScheduleStatus = ({ status, t }: ScheduleStatusProps) => {
  switch (status) {
    case 'WAITING_SCHEDULE':
      return <Tag color="orange">{t('pages.contracts.waitingSchedule')}</Tag>;
    case 'WAITING_RELEASE':
      return <Tag color="blue">{t('pages.contracts.waitingRelease')}</Tag>;
    case 'SCHEDULE_FINISHED':
      return <Tag color="green">{t('pages.contracts.scheduleFinished')}</Tag>;
  }
};
