import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const Translator = ({
  path,
}: {
  path: string;
}): ReactElement<unknown> => {
  const { t } = useTranslation();

  return t(path);
};

export const t = (path: string): string => {
  const { t } = useTranslation();

  return t(path) as string;
};
