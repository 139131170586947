import { CSSProperties } from 'react';
import { isColorLight } from '../CreateLoad/createLoadSteps/LoadSteps.utils';

export const boxDetailCommonStyle: CSSProperties = {
  position: 'absolute',
  width: 4,
  height: 8,
  background: '#F5A714',
  boxSizing: 'border-box',
  border: 'solid 1px #000',
};
// export const topLeftLatch: CSSProperties = {
//   ...boxDetailCommonStyle,
//   transform: 'rotate(-45deg)',
//   marginLeft: 2,
// };
// export const topRightLatch: CSSProperties = {
//   ...boxDetailCommonStyle,
//   transform: 'rotate(45deg)',
//   marginLeft: 25,
// };
// export const bottomLeftLatch: CSSProperties = {
//   ...boxDetailCommonStyle,
//   transform: 'rotate(-135deg)',
//   marginLeft: 2,
//   marginTop: 22,
// };
// export const bottomRightLatch: CSSProperties = {
//   ...boxDetailCommonStyle,
//   transform: 'rotate(135deg)',
//   marginTop: 22,
//   marginLeft: 25,
// };
export const boxContent = (background: string): CSSProperties => ({
  fontWeight: 'bold',
  color: isColorLight(background) ? '#000' : '#fff',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
});
export const boxesContainer = (
  background: string,
  isHovered: boolean | undefined
): CSSProperties => ({
  width: '100%',
  height: 32,
  boxSizing: 'border-box',
  border: 'solid 1px #000',
  borderRadius: 4,
  background: background,
  transition: 'all .2s ease',
  filter: `brightness(${isHovered ? 1.4 : 1})`,
  transform: `scale(${isHovered ? 1.2 : 1})`,
  marginBottom: 2,
});
export const hideTruck: CSSProperties = {
  marginTop: 16,
  height: 'fit-content',
  position: 'sticky',
  top: 70,
  overflow: 'hidden',
  paddingLeft: 'calc(50% - 48px)',
};
export const boxesPosition: CSSProperties = {
  padding: 15,
  marginLeft: 1,
  marginTop: -235,
  display: 'flex',
  gap: '1px 3px',
  width: 98,
};
