import { Col, Form, FormInstance, Input, message, Row } from 'antd';
import { ReactElement, useEffect } from 'react';
import Formatter from '../../../../../classes/Formatter';
import Normalizer from '../../../../../classes/Normalizer';
import { useRequest } from '../../../../../hooks/useRequest';
import ShippingCompanyController from '../../../../../structures/controllers/ShippingCompany';

export interface IShippingCompanyFormValues {
  shippingCompanyId: string;
  documentNumberCnpj: string;
  tradeName: string;
  companyName: string;
  phone: string;
  email: string;
}

interface IShippingCompanyFormProps {
  form: FormInstance<IShippingCompanyFormValues>;
  updateData?: IShippingCompanyFormValues;
}

const ShippingCompanyForm = ({
  form,
  updateData,
}: IShippingCompanyFormProps): ReactElement => {
  const [listShippingCompanies] = useRequest(
    ShippingCompanyController.listShippingCompanies
  );

  const onFinish = () => {
    return;
  };

  const handleSearch = (cnpj: string) => {
    if (cnpj) {
      const searchValue = Normalizer.onlyNumbers(cnpj);
      listShippingCompanies({ cnpj: searchValue })
        .then(res => {
          const shippingCompany = res[0];
          form.setFieldsValue({
            shippingCompanyId: shippingCompany?.id,
            companyName: shippingCompany?.companyName,
            tradeName: shippingCompany?.tradeName,
            email: shippingCompany?.email,
            phone: Formatter.formatPhoneWithoutDDI(
              shippingCompany?.phoneNumber
            ),
          });
        })
        .catch(() => {
          form.resetFields();
          message.error('Erro ao buscar transportadora');
        });
    }
  };

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        documentNumberCnpj: Formatter.formatCNPJ(
          updateData?.documentNumberCnpj
        ),
        shippingCompanyId: updateData?.shippingCompanyId,
        companyName: updateData?.companyName,
        tradeName: updateData?.tradeName,
        email: updateData?.email,
        phone: Formatter.formatPhoneWithoutDDI(updateData?.phone),
      });
    }
  }, [updateData]);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item name={'shippingCompanyId'} hidden />
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item
            label="CNPJ"
            name={'documentNumberCnpj'}
            normalize={Formatter.formatCNPJ}
          >
            <Input.Search onSearch={cnpj => handleSearch(cnpj)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Nome da transportadora" name={'tradeName'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Nome fantasia" name={'companyName'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="E-mail" name={'email'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Telefone"
            name={'phone'}
            normalize={Formatter.formatPhoneWithoutDDI}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ShippingCompanyForm;
