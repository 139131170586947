import { message, notification } from 'antd';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from '../classes/User';
import { useRequest } from '../hooks/useRequest';
import ClientsController from '../structures/controllers/Clients';
import CompanyController from '../structures/controllers/Company';
import UserController from '../structures/controllers/User';
import { IGetCustomer } from '../structures/interfaces/Clients';
import { IFindOrganizationForPickUpAdress } from '../structures/interfaces/Company/Company';
import { IGetLoginDataUser } from '../structures/interfaces/User';
import { clearStorage, getDataFromStorage } from '../utils';
import { IAuthToken } from '../utils/interfaces';
import LayoutContext from './layout';

import SettingsController from '../structures/controllers/Settings';
import { ISettings, IntegrationMod } from '../structures/interfaces/Settings';

type IPermissionFormat =
  | {
      userPermissions: {
        id: string;
        module: string;
        operation: string;
      };
    }
  | undefined;

type IPermissionFormatArray =
  | (
      | {
          userPermissions: {
            id: string;
            module: string;
            operation: string;
          };
        }
      | undefined
    )[]
  | undefined;
interface IUserProvider {
  organizationId?: string;
  userName?: string;
  setUserName?: (t: string) => void;
  customerName?: string;
  setCustomerName?: (t: string) => void;
  userData?: IGetLoginDataUser | null;
  setUserData?: (t: IGetLoginDataUser) => void;
  firstLoginData?: IGetLoginDataUser | null;
  setFirstLoginData?: (t: IGetLoginDataUser | null) => void;
  customerData?: IGetCustomer | null;
  firstLoginCustomer?: IGetCustomer | null;
  setFirstLoginCustomer?: (t: IGetCustomer) => void;
  decodedToken?: IAuthToken;
  company?: IFindOrganizationForPickUpAdress;
  setCompany: (t: IFindOrganizationForPickUpAdress) => void;
  settings?: ISettings;
  setSettings: (t: ISettings) => void;
  loadingSettings?: boolean;
  integrationMod?: IntegrationMod;
  setDecodedToken: (t: IAuthToken) => void;
  logout: () => void;
  setCustomerData: (t: IGetCustomer) => void;
  getPermissions?: IPermissionFormat[];
  filterByRead?: IPermissionFormatArray;
  filterByCreate?: IPermissionFormatArray;
  filterByDelete?: IPermissionFormatArray;
  filterByUpdate?: IPermissionFormatArray;
  homePermissions?: IPermissionFormat[];
  contractPermissions?: IPermissionFormat[];
  clientPermissions?: IPermissionFormat[];
  userPermissions?: IPermissionFormat[];
  truckLoadPermissions?: IPermissionFormat[];
  settingsPermissions?: IPermissionFormat[];
  changingCropFlag?: boolean;
  setChangingCropFlag: (changingCropFlag: boolean) => void;
  changingOrganizationFlag?: boolean;
  setChangingOrganizationFlag: (changingOrganizationFlag: boolean) => void;
}

export const useUserProviderContext = (): IUserProvider => {
  const { t } = useTranslation();

  const [userData, setUserData] = useState<IGetLoginDataUser | null>();
  const [firstLoginData, setFirstLoginData] =
    useState<IGetLoginDataUser | null>();
  const [customerData, setCustomerData] = useState<IGetCustomer | null>();

  const [firstLoginCustomer, setFirstLoginCustomer] =
    useState<IGetCustomer | null>();

  const [userName, setUserName] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();

  const [settings, setSettings] = useState<ISettings>();
  const [integrationMod, setIntegrationMod] = useState<IntegrationMod>();

  const [decodedToken, setDecodedToken] = useState<IAuthToken>();
  const [company, setCompany] = useState<IFindOrganizationForPickUpAdress>();
  const [changingCropFlag, setChangingCropFlag] = useState(false);
  const [changingOrganizationFlag, setChangingOrganizationFlag] =
    useState(false);

  const [findOrganization] = useRequest(CompanyController.findOrganizationById);
  const [findIntegrationModules] = useRequest(
    SettingsController.getIntegrationModules
  );
  const [findSettings, loadingSettings] = useRequest(
    SettingsController.getSettings
  );

  const { setIsLoading } = useContext(LayoutContext);

  const logout = () => {
    clearStorage();
    setUserData(null);
    setCustomerData(null);
    setDecodedToken(undefined);
  };

  const fetchUser = (decodedToken: IAuthToken) => {
    if (decodedToken.customer_id) {
      if (decodedToken.first_customer_login) {
        ClientsController.getDataOnCustomerFirstLogin(
          `${decodedToken.customer_id}`
        )
          .then(data => {
            setFirstLoginCustomer(data);
          })
          .catch(({ message, description }) => {
            notification.error({
              message: t(message),
              description: t(description),
            });
            logout();
          });
      } else {
        ClientsController.getCustomer(`${decodedToken.customer_id}`)
          .then(data => {
            setCustomerData(data);
          })
          .catch(({ message, description }) => {
            notification.error({
              message: t(message),
              description: t(description),
            });
            logout();
          });
      }
    } else {
      if (decodedToken.first_login) {
        UserController.getFirstUserData(`${decodedToken.id}`)
          .then(data => setFirstLoginData(data))
          .catch(({ message, description }) => {
            notification.error({
              message: t(message),
              description: t(description),
            });
            logout();
          });
      } else {
        UserController.getUserDataOnLogin(`${decodedToken.id}`)
          .then(data => {
            setUserData(data);
          })
          .catch(({ message, description }) => {
            notification.error({
              message: t(message),
              description: t(description),
            });
            logout();
          });
      }
    }
  };

  const getPermissions = userData?.userHasPermissions.map(permission => {
    if (permission.userPermissions.module) {
      return permission;
    }
  });

  const filterByRead = getPermissions?.filter(
    permission => permission?.userPermissions.operation === 'READ'
  );
  const filterByCreate = getPermissions?.filter(
    permission => permission?.userPermissions.operation === 'CREATE'
  );
  const filterByUpdate = getPermissions?.filter(
    permission => permission?.userPermissions.operation === 'UPDATE'
  );
  const filterByDelete = getPermissions?.filter(
    permission => permission?.userPermissions.operation === 'DELETE'
  );

  const homePermissions = getPermissions?.filter(
    home => home?.userPermissions.module === 'HOME'
  );
  const contractPermissions = getPermissions?.filter(
    contract => contract?.userPermissions.module === 'CONTRATO'
  );
  const clientPermissions = getPermissions?.filter(
    client => client?.userPermissions.module === 'CLIENTE'
  );
  const userPermissions = getPermissions?.filter(
    user => user?.userPermissions.module === 'USUARIOS'
  );
  const truckLoadPermissions = getPermissions?.filter(
    truckLoad => truckLoad?.userPermissions.module === 'CARGAS'
  );
  const settingsPermissions = getPermissions?.filter(
    settings => settings?.userPermissions.module === 'REGRAS'
  );

  const errorToGetSettings = (msg: string) => {
    return message.error(msg);
  };

  const organizationId =
    userData?.organization.id || customerData?.organizationId;

  useEffect(() => {
    if (userData) {
      setIsLoading(true);
      findOrganization({
        organizationId: userData.organization.id,
      }).then(comp => {
        setIsLoading(false);
        setCompany(comp);
      });
      // SETTINGS
      findSettings({ organizationId: userData.organization.id })
        .then(settings => setSettings(settings))
        .catch(() => errorToGetSettings('erro para pegar configs'));
      // INTEGRATION
      findIntegrationModules(userData.organization.id)
        .then(modules => setIntegrationMod(modules))
        .catch(() => errorToGetSettings('erro para pegar modules'));
    }
  }, [userData]);

  const organization = getDataFromStorage('organization');
  useEffect(() => {
    if (customerData && organization) {
      setIsLoading(true);
      findOrganization({ organizationId: organization }).then(comp => {
        setIsLoading(false);
        setCompany(comp);
      });
    }
  }, [customerData, organization]);

  const validateUserSession = () => {
    const encodedToken = User.getToken();

    if (encodedToken) {
      try {
        const token = jwtDecode<IAuthToken & JwtPayload>(`${encodedToken}`);
        setDecodedToken(token);
      } catch {
        notification.error({
          message: t('errors.invalidToken.message'),
          description: t('errors.invalidToken.description'),
        });

        logout();
      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    if (!decodedToken) {
      validateUserSession();
    } else {
      fetchUser(decodedToken);
    }
  }, [decodedToken]);

  return {
    organizationId,
    userName,
    setUserName,
    customerName,
    setCustomerName,
    userData,
    firstLoginData,
    setFirstLoginData,
    decodedToken,
    company,
    setCompany,
    settings,
    setSettings,
    loadingSettings,
    integrationMod,
    setDecodedToken,
    logout,
    customerData,
    setCustomerData,
    firstLoginCustomer,
    setFirstLoginCustomer,
    filterByRead,
    filterByCreate,
    filterByDelete,
    filterByUpdate,
    getPermissions,
    homePermissions,
    contractPermissions,
    clientPermissions,
    userPermissions,
    truckLoadPermissions,
    settingsPermissions,
    changingCropFlag,
    setChangingCropFlag,
    changingOrganizationFlag,
    setChangingOrganizationFlag,
  };
};

const UserContext = React.createContext<IUserProvider>({
  setDecodedToken: () => {
    return;
  },
  logout: () => {
    return;
  },
  setCompany: () => {
    return;
  },
  setSettings: () => {
    return;
  },
  setUserName: () => {
    return;
  },
  setUserData: () => {
    return;
  },
  setCustomerData: () => {
    return;
  },

  setFirstLoginData: () => {
    return;
  },
  setFirstLoginCustomer: () => {
    return;
  },
  setChangingCropFlag: () => {
    return;
  },
  setChangingOrganizationFlag: () => {
    return;
  },
});

export default UserContext;
