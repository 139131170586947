import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { ReactElement } from 'react';
import { IVehicle } from '../../../structures/interfaces/Vehicle';

interface IVehiclesFilterProps {
  setFilteredVehicles: React.Dispatch<React.SetStateAction<IVehicle[]>>;
  vehicles: IVehicle[];
}
const VehiclesFilter = ({
  setFilteredVehicles,
  vehicles,
}: IVehiclesFilterProps): ReactElement => {
  const [form] = Form.useForm();

  const onFinish = ({ search = '' }: { search: string }) => {
    const filteredItems = vehicles.filter(item =>
      item.slug?.includes(search.toLowerCase().trim())
    );
    setFilteredVehicles(filteredItems);
  };

  return (
    <Form layout="vertical" size="small" form={form} onFinish={onFinish}>
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item label="Pesquisa" name={'search'}>
            <Input.Search enterButton />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Space>
            <Button icon={<ClearOutlined />}>Limpar filtro</Button>
            <Button icon={<FilterOutlined />} type="primary">
              Filtrar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default VehiclesFilter;
