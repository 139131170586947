import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, InputNumber, message, Row } from 'antd';
import { ReactElement, useContext, useEffect } from 'react';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import SettingsController from '../../../structures/controllers/Settings';

interface ITreatmentFormValues {
  whiteSeedsTime: number;
  tsiSeedsTime: number;
}
const TreatmentForm = (): ReactElement => {
  const [form] = Form.useForm<ITreatmentFormValues>();
  const { settings, userData, setSettings } = useContext(UserContext);
  const [updateTreatmentTime, isLoading] = useRequest(
    SettingsController.updateTreatmentTime
  );

  const onFinish = (value: ITreatmentFormValues) => {
    if (userData) {
      updateTreatmentTime({
        organizationId: userData.organization.id,
        commonTsiSeedSchedulingTime: value.tsiSeedsTime,
        commonWhiteSeedSchedulingTime: value.whiteSeedsTime,
      })
        .then(res => {
          setSettings(res);
          message.success('Tempo de tratamento alterado');
        })
        .catch(() => message.error('Erro ao alterar tempo de tratamento'));
    }
  };

  useEffect(() => {
    const initialValue = {
      whiteSeedsTime: settings?.commonWhiteSeedSchedulingTime || 0,
      tsiSeedsTime: settings?.commonTsiSeedSchedulingTime || 0,
    };

    form.setFieldsValue(initialValue);
  }, [settings]);

  return (
    <Card title="Regra geral para tempo de tratamento">
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Form.Item
              label="Tratamento de sementes brancas"
              name={'whiteSeedsTime'}
            >
              <InputNumber
                style={{ width: '100%' }}
                addonAfter={'Dias'}
                min={0}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Tratamento de sementes com TSI"
              name={'tsiSeedsTime'}
            >
              <InputNumber
                style={{ width: '100%' }}
                addonAfter={'Dias'}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={form.submit}
        loading={isLoading}
      >
        Confirmar
      </Button>
    </Card>
  );
};

export default TreatmentForm;
