import { Descriptions } from 'antd';
import { ReactElement } from 'react';
import Formatter from '../../../../../classes/Formatter';

const { Item } = Descriptions;

export interface IDriverData {
  name: string;
  documentNumberCpf: string;
  phoneNumber: string;
  vehicleModel: string;
  vehicleLicensePlate: string;
  note: string;
  driverId: string;
  vehicleId: string;
  phone: string;
  email: string;
  notes: string;
}
interface IDriverDescriptionProps {
  data: IDriverData | undefined;
}

const DriverDescriptions = ({
  data,
}: IDriverDescriptionProps): ReactElement => {
  if (!data)
    data = {
      documentNumberCpf: '---',
      phoneNumber: '---',
      name: '---',
      driverId: '---',
      vehicleId: '---',
      phone: '---',
      email: '---',
      notes: '---',
      vehicleModel: '---',
      vehicleLicensePlate: '---',
      note: '---',
    };

  const {
    vehicleModel,
    vehicleLicensePlate,
    name,
    note,
    documentNumberCpf,
    phoneNumber,
  } = data;

  return (
    <Descriptions labelStyle={{ fontWeight: 'bold' }}>
      <Item label="Motorista">{name}</Item>

      <Item label="CPF do motorista">
        {Formatter.formatCPF(documentNumberCpf)}
      </Item>

      <Item label="Telefone">
        {Formatter.formatPhoneWithoutDDI(phoneNumber)}
      </Item>

      <Item label="Modelo do veículo">{vehicleModel}</Item>

      <Item label="Placa do veículo">{vehicleLicensePlate}</Item>

      <Item label="Observações">{note}</Item>
    </Descriptions>
  );
};

export default DriverDescriptions;
