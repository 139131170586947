import { Alert, Layout, PageHeader } from 'antd';
import { ReactElement } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import BreadCrumb from '../../../../structures/controllers/Breadcrumb';
import TolerancePercentageForm from './tolerancePercentageForm';
import TreatmentTimeForm from './treatmentTimeForm';
import VehicleSettings from './vehicles/vehicleSettings';

const baseString = 'pages.scheduleRules.ruleSettings';

const RuleSettings = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const vehicleListingAlert = (
    <div>
      <p>
        {t('pages.vehicles.alerts.listingAlert.description')} <br />
      </p>
    </div>
  );

  return (
    <Layout>
      <PageHeader
        title={t(`${baseString}.title`)}
        subTitle={t(`${baseString}.subtitle`)}
        ghost={false}
        breadcrumb={
          <BreadCrumb lastName={t('pages.settings.settingsBreadcrumb')} />
        }
      />
      <Layout style={{ margin: 24 }}>
        <TolerancePercentageForm />
        <TreatmentTimeForm />

        <div style={{ marginTop: 24 }}>
          <Alert
            style={{ marginBottom: 24 }}
            description={vehicleListingAlert}
          />
          <VehicleSettings />
        </div>
      </Layout>
    </Layout>
  );
};

export default withTranslation()(RuleSettings);
