import { gql, TypedDocumentNode } from 'urql';
import {
  ICityName,
  IEditPickUpLocation,
  IEditPickUpLocationInput,
  IPickUpLocation,
  IZipCodeAddressResponse,
} from '../interfaces/Address';
import { IOrganizationId } from '../interfaces/Company/Company';

const stateNameFragment = `
  initials
  name: state_name
`;

const cityNameFragment = `
  id
  name: city_name
`;

const districtNameFragment = `
  id
  name: district_name
`;

const queries = {
  findZipCodeAddress: (): TypedDocumentNode<
    IZipCodeAddressResponse,
    { zipCode: string }
  > => gql`
    query ($zipCode: String!) {
      zipCodeAddress: FindZipCode(input: { zip_code: $zipCode }) {
        zipCode: zip_code
        streetType: type
        streetName: street_name
        latitude
        longitude
        state {
          ${stateNameFragment}
        }
        city {
          ${cityNameFragment}
        }
        district {
          ${districtNameFragment}
        }
      }
    }
  `,

  findCitiesByState: (): TypedDocumentNode<
    { cities: ICityName[] },
    { where: { initials: string } }
  > => gql`
    query ($where: StateWhereUniqueInput!) {
      cities: GetCitiesByState(where: $where) {
        cityName: city_name
        id
        states {
          initials
        }
      }
    }
  `,

  registerNewPickUpLocation: (): TypedDocumentNode<
    { pickUpLoc: IPickUpLocation },
    {
      input: {
        organization_id: string;
        title: string;
        zip_code: string;
        country_name: string;
        state_short_name: string;
        state_name: string;
        city_name: string;
        district: string;
        street: string;
        street_number: string;
        complement: string;
      };
    }
  > => gql`
    mutation ($input: AddressRegistrationInput!) {
      pickUpLoc: PickupAddressRegistration(input: $input) {
        organizationId: organization_id
        title
        zipCode: zip_code
        countryName: country_name
        stateShortName: state_short_name
        stateName: state_name
        cityName: city_name
        district
        street
        streetNumber: street_number
        complement
      }
    }
  `,

  findPickUpLocation: (): TypedDocumentNode<
    { pickuplocation: IPickUpLocation[] },
    {
      where: {
        organization_id: IOrganizationId;
        pick_up_location: { equals: true };
      };
    }
  > => gql`
    query getPickUpLocation($where: AddressWhereInputDto!) {
      pickuplocation: FindAddresses(where: $where) {
        id
        title
        zipCode: zip_code
        stateShortName: state_short_name
        cityName: city_name
        pickUpLocation: pick_up_location
        district
        street
        streetNumber: street_number
        complement
      }
    }
  `,

  findAddressId: (): TypedDocumentNode<
    { addressId: { id: string } },
    {
      where: {
        organization_id: IOrganizationId;
      };
    }
  > => gql`
    query getPickUpLocation($where: AddressWhereInputDto!) {
      addressId: FindAddresses(where: $where) {
        id
      }
    }
  `,

  findDataPickUpLocation: (): TypedDocumentNode<
    { pickupdata: IEditPickUpLocation },
    {
      where: {
        organization_id: IOrganizationId;
        pick_up_location: { equals: true };
      };
    }
  > => gql`
    query getPickUpLocation($where: AddressWhereInputDto!) {
      pickupdata: FindAddresses(where: $where) {
        id
        title
        zipCode: zip_code
        stateShortName: state_short_name
        cityName: city_name
        pickUpLocation: pick_up_location
        district
        street
        streetNumber: street_number
        complement
      }
    }
  `,

  updatePickUpLocation: (): TypedDocumentNode<
    { updatePickUpLocation: IEditPickUpLocation },
    {
      where: { id: string };
      input: IEditPickUpLocationInput;
    }
  > => gql`
    mutation UpdatePickUpLocation(
      $where: UpdateAddressWhereInput!
      $input: AddressUpdateInput!
    ) {
      updatePickUpLocation: UpdateAddress(where: $where, input: $input) {
        title
        zipCode: zip_code
        stateShortName: state_short_name
        street
        streetNumber: street_number
        district
        complement
        cityName: city_name
      }
    }
  `,

  deletePickUpLocation: (): TypedDocumentNode<{
    status: boolean;
    where: { id: string };
  }> => gql`
    mutation DeletePickupAddress($where: DeletePickupAddressInput!) {
      status: DeletePickupAddress(where: $where) {
        success
      }
    }
  `,
};

export default queries;
