import { ReactElement, useEffect, useMemo, useState } from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button, Card, Form, FormItemProps, Input, Row } from 'antd';

import AuthContainer from '../../../components/authentication/authContainer';
import UserController from '../../../structures/controllers/User';

import jwtDecode from 'jwt-decode';
import Authentication from '..';
import { useRequest } from '../../../hooks/useRequest';
import { IUserId, ValidateCode } from '../../../structures/interfaces/User';
import './index.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface ILoginProps {
  t: TFunction;
  bordered?: boolean;
}

const ConfirmCode = ({
  t,
  bordered = true,
}: ILoginProps): ReactElement<unknown> => {
  const [codeConfirmRequest, isCodeConfirmRequesting] = useRequest(
    UserController.validateRecoveryCode
  );
  const [hasError, setHasError] = useState<FormItemProps>({});

  const history = useHistory();
  const location = useLocation<{ email: string }>();

  useEffect(() => {
    if (!location.state?.email) {
      history.replace('/recuperar-senha');
    }
  }, []);

  const onFinish = (values: ValidateCode) => {
    codeConfirmRequest({ ...values, email: location.state.email })
      .then(({ token }) => {
        const decodedToken = jwtDecode<IUserId>(token);
        history.push('/recuperar-senha/confirmar-codigo/atualizar-senha', {
          userId: decodedToken.customer_id || decodedToken.id,
          token,
        });
      })
      .catch(() => {
        setHasError({
          hasFeedback: true,
          validateStatus: 'error',
          help: t('pages.login.fields.invalidCredentials'),
        });
      });
  };

  const RecoveryCodeForm = useMemo(
    () => (
      <Form className="form" layout="vertical" onFinish={onFinish}>
        <div>
          <Form.Item
            name="recoveryCode"
            label={t('pages.login.recoveryPassword.codeMessage')}
            {...hasError}
            rules={[
              {
                required: true,
                message: t('pages.login.fields.requiredField'),
              },
              {
                message: t('pages.login.fields.invalidEmail'),
                validateTrigger: 'click',
              },
            ]}
          >
            <Input
              placeholder={t('pages.login.recoveryPassword.codePlaceholder')}
            />
          </Form.Item>

          <Form.Item
            style={{ textAlign: 'right', textDecoration: 'underline' }}
          >
            <Button
              onClick={() => history.push('/recuperar-senha')}
              type="link"
            >
              {t('pages.login.recoveryPassword.resendEmail')}
            </Button>
          </Form.Item>
        </div>

        <Row justify="end">
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={isCodeConfirmRequesting}
            style={{
              width: '100%',
            }}
          >
            {t('pages.login.recoveryPassword.confirmButton')}
          </Button>
        </Row>

        <Row justify="start">
          <Button
            onClick={() => history.push('/recuperar-senha')}
            type="link"
            icon={<ArrowLeftOutlined />}
          >
            {t('pages.truckLoads.createLoad.viewLoad.backToListing')}
          </Button>
        </Row>
      </Form>
    ),
    [isCodeConfirmRequesting, hasError]
  );

  return (
    <Authentication>
      <AuthContainer className="auth-container">
        <Card className="login-card" bordered={bordered}>
          <header>
            <div className="decoration"></div>
            <div className="header-container">
              <h1 className="title">
                {t('pages.login.recoveryPassword.title')}
              </h1>
              <span className="subtitle">
                {t('pages.login.recoveryPassword.subtitle')}
              </span>
            </div>
          </header>
          {RecoveryCodeForm}
        </Card>
      </AuthContainer>
    </Authentication>
  );
};

export default withTranslation()(ConfirmCode);
