import {
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Layout,
  message,
  Modal,
  PageHeader,
  Row,
  Space,
} from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useRequest } from '../../../hooks/useRequest';
import ShippingCompanyController from '../../../structures/controllers/ShippingCompany';
import { IFindShippingCompanyResponse } from '../../../structures/interfaces/ShippingCompany';
import { BuildCarrierSlug } from './ShippingCompany.utils';
import ShippingCompanyFilter from './ShippingCompanyPage.filter';
import NewShippingCompanyForm, {
  IShippingCompanyFormValues,
} from './ShippingCompanyPage.new';
import ShippingCompanyTable from './ShippingCompanyPage.table';

const ShippingCompanyPage = (): ReactElement => {
  const [carriers, setCarriers] = useState<IFindShippingCompanyResponse[]>([]);
  const [filteredCarriers, setFilteredCarriers] = useState(carriers);
  const [selectedCarrier, setSelectedCarrier] =
    useState<IFindShippingCompanyResponse>();

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<IShippingCompanyFormValues>();

  const [listShippingCompanies, isLoading] = useRequest(
    ShippingCompanyController.listShippingCompanies
  );
  const [addNewShippingCompany, isAdding] = useRequest(
    ShippingCompanyController.addNewShippingCompany
  );
  const [updateShippingCompany, isUpdating] = useRequest(
    ShippingCompanyController.updateShippingCompany
  );

  const newShippingCompany = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => setOpen(true)}
    >
      Cadastrar nova transportadora
    </Button>
  );
  const modalFooter = (
    <Space>
      <Button danger icon={<CloseOutlined />} onClick={() => setOpen(false)}>
        Cancelar
      </Button>

      <Button
        icon={<CheckOutlined />}
        type="primary"
        onClick={() => {
          form.submit();
          setOpen(false);
        }}
      >
        Finalizar
      </Button>
    </Space>
  );

  const doListingRequest = () => {
    listShippingCompanies({})
      .then(res => {
        const sortedRes = BuildCarrierSlug(res.reverse());
        setCarriers(sortedRes);
        setFilteredCarriers(sortedRes);
      })
      .catch(() => message.error('Não foram encontradas transportadoras!'));
  };

  const newCarrierTitle = (
    <Space>
      <ShopOutlined /> Adicionar transportadora
    </Space>
  );

  useEffect(() => {
    doListingRequest();
  }, []);

  return (
    <Layout>
      <PageHeader
        title="Transportadoras"
        subTitle="Acompanhe os dados das transportadoras registradas"
        ghost={false}
      />
      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card>
              <ShippingCompanyFilter
                setFilteredCarriers={setFilteredCarriers}
                carriers={carriers}
              />
            </Card>
          </Col>

          <Col span={24}>
            <Card
              title="Transportadoras cadastradas"
              extra={newShippingCompany}
            >
              <ShippingCompanyTable
                data={filteredCarriers}
                loading={isLoading || isAdding || isUpdating}
                setSelectedCarrier={setSelectedCarrier}
                setOpen={setOpen}
              />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
      <Modal
        open={open}
        width={1000}
        closable={false}
        footer={modalFooter}
        title={newCarrierTitle}
      >
        <NewShippingCompanyForm
          form={form}
          updateData={selectedCarrier}
          doListShippingCompanies={doListingRequest}
          addNewShippingCompany={addNewShippingCompany}
          updateShippingCompany={updateShippingCompany}
        />
      </Modal>
    </Layout>
  );
};

export default ShippingCompanyPage;
