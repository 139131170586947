import { gql, TypedDocumentNode } from 'urql';
import {
  Farm,
  ICustomerFirstSignInInput,
  ICustomerFirstSignInResponse,
  ICustomerLogin,
  ICustomerLoginResponse,
  IFarmResponse,
  IGetClient,
  IGetCustomer,
  IUpdateCustomerAccount,
  IUpdateCustomerInput,
  IUpdateCustomerOnFirstSignIn,
  IUpdateCustomerOnFirstSignInInput,
} from '../interfaces/Clients';

const ClientQueries = {
  login: (): TypedDocumentNode<ICustomerLoginResponse, ICustomerLogin> => gql`
    mutation ($email: String!, $password: String!) {
      Login(input: { email: $email, password: $password }) {
        token
      }
    }
  `,
  getClients: (): TypedDocumentNode<
    { getClients: IGetClient[] },
    { where: { organization_id: string } }
  > => gql`
    query ListCustomers($where: FindUsersByOrganizationInput!) {
      getClients: ListCustomers(where: $where) {
        id
        email
        name
        status
        tradeName: trade_name
        companyName: company_name
        documentNumberCnpj: document_number_cnpj
        documentNumberCpf: document_number_cpf
        phoneNumber: phone_number
        customerFarms: customer_farms {
          farm {
            stateRegistration: state_registration
            documentNumberCnpj: document_number_cnpj
            name
            address
          }
        }
        updatedAt: updated_at
      }
    }
  `,
  getCustomerDataOnFirstLogin: (): TypedDocumentNode<
    { getCustomerFirstLogin: IGetCustomer },
    { where: { id: string } }
  > => gql`
    query getCustomerFirstLogin($where: GetCustomerInput!) {
      getCustomerFirstLogin: GetCustomerFirstLogin(where: $where) {
        id
        name
        email
        referenceId: reference_id
        tradeName: trade_name
        companyName: company_name
        documentNumberCnpj: document_number_cnpj
        documentNumberCpf: document_number_cpf
        phoneNumber: phone_number
      }
    }
  `,
  getCustomer: (): TypedDocumentNode<
    { getCustomer: IGetCustomer },
    { where: { id: string } }
  > => gql`
    query getCustomer($where: GetCustomerInput!) {
      getCustomer: GetCustomer(where: $where) {
        id
        name
        email
        referenceId: reference_id
        tradeName: trade_name
        companyName: company_name
        documentNumberCnpj: document_number_cnpj
        documentNumberCpf: document_number_cpf
        phoneNumber: phone_number
        userHasOrganizations: user_has_organizations {
          organizationId: organization_id
        }
      }
    }
  `,
  getFarms: (): TypedDocumentNode<
    { getFarms: Farm[] },
    { where: { organization_id: string } }
  > => gql`
    query ListCustomers($where: FindUsersByOrganizationInput!) {
      getFarms: ListCustomers(where: $where) {
        id
        name
        tradeName: trade_name
      }
    }
  `,
  customerFirstSignIn: (): TypedDocumentNode<
    { token: ICustomerFirstSignInResponse },
    { input: ICustomerFirstSignInInput }
  > => gql`
    mutation FirstCustomerLogin($input: FirstCustomerLoginInput!) {
      token: FirstCustomerLogin(input: $input) {
        token
      }
    }
  `,
  updateCustomerAccount: (): TypedDocumentNode<
    {
      customer: IUpdateCustomerAccount;
    },
    {
      where: { id: string };
    }
  > => gql`
    mutation updateCustomer(
      $where: CustomerWhereInput!
      $input: CustomerUncheckedUpdateInput!
    ) {
      customer: UpdateCustomer(where: $where, input: $input) {
        name
        phoneNumber: phone_number
        email
      }
    }
  `,
  updateCustomerDataOnFirstSignIn: (): TypedDocumentNode<
    { customerData: IUpdateCustomerOnFirstSignIn },
    { where: { id: string }; input: IUpdateCustomerOnFirstSignInInput }
  > => gql`
    mutation UpdateCustomerFirstLoginData(
      $where: GetCustomerInput!
      $input: UpdateCustomerFirstLoginInput!
    ) {
      customerData: UpdateCustomerFirstLoginData(where: $where, input: $input) {
        id
        name
        documentNumberCpf: document_number_cpf
        tradeName: trade_name
        companyName: company_name
        documentNumberCnpj: document_number_cnpj
        phoneNumber: phone_number
        email
      }
    }
  `,
  allowCustomerAccess: (): TypedDocumentNode<
    { allowCustomerAccess: { success: boolean } },
    { input: { email: string; customer_id: string } }
  > => gql`
    mutation AllowCustomerAccess($input: AllowCustomerAccess!) {
      allowCustomerAccess: AllowCustomerAccess(input: $input) {
        success
      }
    }
  `,
  fetchOrgFarms: (): TypedDocumentNode<
    { farms: Array<IFarmResponse> },
    { where: { organization_id: string } }
  > => gql`
    query GetOrganizationFarms($where: OrganizationWhere!) {
      farms: GetOrganizationFarms(where: $where) {
        id
        name
        address
        stateRegistration: state_registration
        documentNumberCnpj: document_number_cnpj
        farmCustomers: farm_customers {
          customerId: customer_id
          farmId: farm_id
          customer {
            name
            email
            phoneNumber: phone_number
          }
        }
      }
    }
  `,
  createCustomer: (): TypedDocumentNode<
    { newCustomer: { id: string; name: string; email: string } },
    {
      input: {
        name: string;
        email: string;
        farm_id: string;
        organization_id: string;
        document_number_cpf: string;
        phone_number: string;
      };
    }
  > => gql`
    mutation CustomerCreate($input: CustomerCreateInput!) {
      newCustomer: CustomerCreate(input: $input) {
        id
        name
        email
      }
    }
  `,
  sendCustomerFirstAccess: (): TypedDocumentNode<
    { newAccess: { success: boolean } },
    { input: { ids: string[] } }
  > => gql`
    mutation SendCustomerFirstSignIn($input: CustomerPasswordResetInput!) {
      newAccess: SendCustomerFirstSignIn(input: $input) {
        success
      }
    }
  `,
  resetCustomerPass: (): TypedDocumentNode<
    { reset: Array<{ id: string; password: string }> },
    { input: { ids: string[] } }
  > => gql`
    mutation ResetCustomerPassword($input: CustomerPasswordResetInput!) {
      reset: ResetCustomerPassword(input: $input) {
        id
        password
      }
    }
  `,
  changeCustomerFromFarm: (): TypedDocumentNode<
    { updateCustomer: { customerId: string } },
    { input: { customer_id: string; farm_id: string } }
  > => gql`
    mutation ChangeCustomerFromFarm($input: ChangeCustomerFromFarmInput!) {
      updateCustomer: ChangeCustomerFromFarm(input: $input) {
        customer_id
      }
    }
  `,
  updateCustomer: (): TypedDocumentNode<
    { update: { id: string } },
    { where: { id: string }; input: IUpdateCustomerInput }
  > => gql`
    mutation updateCustomer(
      $where: CustomerWhereInput!
      $input: CustomerUncheckedUpdateInput!
    ) {
      UpdateCustomer(where: $where, input: $input) {
        id
      }
    }
  `,
  assignCustomerHasFarm: (): TypedDocumentNode<
    { assign: { success: boolean } },
    { input: { customer_id: string; farm_ids: string[] } }
  > => gql`
    mutation AssignCustomerHasFarms($input: AssignCustomeFarmsInput!) {
      assign: AssignCustomerHasFarms(input: $input) {
        success
      }
    }
  `,
  assignFarmHasCustomers: (): TypedDocumentNode<
    { assign: { success: boolean } },
    { input: { customer_ids: string[]; farm_id: string } }
  > => gql`
    mutation AssignFarmHasCustomers($input: AssignFarmCustomersInput!) {
      assign: AssignFarmHasCustomers(input: $input) {
        success
      }
    }
  `,
};

export default ClientQueries;
