import {
  Descriptions,
  Divider,
  Form,
  FormInstance,
  Layout,
  message,
  Select,
  Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import {
  IFarmResponse,
  IGetClient,
} from '../../../structures/interfaces/Clients';

interface ISyncNewContactProps {
  setSelectedContact: Dispatch<SetStateAction<IGetClient | undefined>>;
  selectedContact: IGetClient | undefined;
  farm: IFarmResponse | undefined;
  form: FormInstance;
}

const SyncNewContact = ({
  setSelectedContact,
  selectedContact,
  farm,
  form,
}: ISyncNewContactProps): ReactElement => {
  const { userData, customerData } = useContext(UserContext);
  const [fetchContacts] = useRequest(ClientsController.getClients);
  const [contacts, setContacts] = useState<IGetClient[]>([]);

  const contactOptions: DefaultOptionType[] = contacts.map(contact => ({
    value: contact.id,
    label: contact.name || contact.tradeName,
  }));

  const onSelect = (value: string) => {
    const selectedContact = contacts.find(contact => contact.id === value);
    setSelectedContact(selectedContact);
  };
  const handleFilterOptions = (input: string, option?: DefaultOptionType) => {
    const text = (option?.label || '') as string;
    return text.toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    const organizationId =
      customerData?.organizationId || userData?.organization.id || '';

    fetchContacts(organizationId)
      .then(setContacts)
      .catch(() => message.error('Clientes não encontrados'));
  }, []);

  return (
    <Layout style={{ background: '#fff' }}>
      <Descriptions labelStyle={{ fontWeight: 'bold' }}>
        <Descriptions.Item label="Fazenda">{farm?.name}</Descriptions.Item>
      </Descriptions>

      <Typography.Text>Contatos</Typography.Text>

      <Form form={form}>
        <Form.Item name={'contact'}>
          <Select
            options={contactOptions}
            showSearch
            allowClear
            onClear={() => setSelectedContact(undefined)}
            optionFilterProp="children"
            filterOption={handleFilterOptions}
            onSelect={onSelect}
          />
        </Form.Item>
      </Form>

      {selectedContact && (
        <>
          <Divider />

          <Descriptions column={2} labelStyle={{ fontWeight: 'bold' }}>
            <Descriptions.Item label="Nome">
              {selectedContact?.name || selectedContact?.tradeName}
            </Descriptions.Item>

            <Descriptions.Item label="CPF">
              {Formatter.formatCPF(selectedContact?.documentNumberCpf)}
            </Descriptions.Item>

            <Descriptions.Item label="E-mail">
              {selectedContact?.email}
            </Descriptions.Item>

            <Descriptions.Item label="Telefone">
              {Formatter.formatPhoneWithoutDDI(selectedContact?.phoneNumber)}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </Layout>
  );
};

export default SyncNewContact;
