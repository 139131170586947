import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';

import { TFunction, withTranslation } from 'react-i18next';

import { Button, Card, Form, FormItemProps, Input, Row } from 'antd';

import { useForm } from 'antd/es/form/Form';
import { useHistory } from 'react-router-dom';
import User from '../../../classes/User';
import AuthContainer from '../../../components/authentication/authContainer';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import { ICustomerFirstSignIn } from '../../../structures/interfaces/Clients';
import { clearStorage, getDataFromStorage } from '../../../utils';
import { scrollOptions } from '../../../utils/formOptions';
import Authentication from '../../authentication';
import './index.scss';

const FirstSignIn = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const [hasError, setHasError] = useState<FormItemProps>({});
  const [form] = useForm();
  const { setDecodedToken, logout } = useContext(UserContext);
  const history = useHistory();

  const [customerFirstSignInRequest, isCustomerFirstSignInRequesting] =
    useRequest(ClientsController.customerFirstSignIn);

  const onFinish = (values: ICustomerFirstSignIn) => {
    const lowerCaseValues: ICustomerFirstSignIn = {
      ...values,
      email: values.email.toLowerCase(),
    };

    customerFirstSignInRequest(lowerCaseValues)
      .then(({ token }) => {
        User.setTokenToStorage(token, true)
          .then(decodedToken => {
            setDecodedToken(decodedToken);
            history.push('/area-cliente/configuracao');
          })
          .catch(() => {
            setHasError({
              hasFeedback: true,
              validateStatus: 'error',
              help: t('pages.login.fields.invalidCredentials'),
            });
            logout();
            history.push('/auth');
          });
      })
      .catch(() => {
        logout();
        history.push('/auth');
        // setHasError({
        //   hasFeedback: true,
        //   validateStatus: 'error',
        //   help: t('pages.login.fields.invalidCredentials'),
        // });
      });
  };

  const LoginForm = useMemo(
    () => (
      <Form
        scrollToFirstError={scrollOptions}
        form={form}
        className="form"
        layout="vertical"
        onFinish={onFinish}
      >
        <div>
          <Form.Item
            name="contractNumber"
            label={t('pages.signUp.fields.labels.contract')}
            {...hasError}
            rules={[
              {
                required: true,
                message: t('pages.signUp.fields.ruleMessages.rmContract'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.signUp.fields.placeholders.contract')}
            />
          </Form.Item>

          <Form.Item
            name="email"
            label={t('pages.signUp.fields.labels.email')}
            {...hasError}
            rules={[
              {
                required: true,
                message: t('pages.signUp.fields.ruleMessages.rmEmail'),
              },
              {
                message: t('pages.signUp.fields.ruleMessages.rmInvalidEmail'),
                type: 'email',
                validateTrigger: 'click',
              },
            ]}
          >
            <Input placeholder={t('pages.signUp.fields.placeholders.email')} />
          </Form.Item>

          <Form.Item
            name="password"
            label={t('pages.signUp.fields.labels.tmpPassword')}
            {...hasError}
            rules={[
              {
                required: true,
                message: t('pages.signUp.fields.ruleMessages.rmPassword'),
              },
              {
                message: t(
                  'pages.signUp.fields.ruleMessages.rmPasswordMinSize'
                ),
                min: 6,
                validateTrigger: 'click',
              },
            ]}
          >
            <Input.Password
              placeholder={t('pages.signUp.fields.placeholders.password')}
            />
          </Form.Item>
        </div>

        <Row justify="end">
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{
              width: '100%',
            }}
            loading={isCustomerFirstSignInRequesting}
          >
            {t('pages.signUp.action.submit')}
          </Button>
        </Row>
      </Form>
    ),
    [hasError]
  );

  useEffect(() => {
    const storageData = getDataFromStorage('token');
    if (storageData) {
      clearStorage();
    }
  }, []);

  return (
    <Authentication>
      <AuthContainer className="auth-container">
        <Card className="login-card" bordered={false}>
          <header>
            <div className="decoration"></div>
            <div className="header-container">
              <h1 className="title">
                {t('pages.login.customerFirstLoginTitle')}
              </h1>
              <span className="subtitle">
                {t('pages.signUp.firstSignIn.subtitle')}
              </span>
            </div>
          </header>
          {LoginForm}
        </Card>
      </AuthContainer>
    </Authentication>
  );
};

export default withTranslation()(FirstSignIn);
