// microcopy messages
export const tsiMessage = 'pages.scheduleRules.tsiTimeLimitMessage';
export const schedulingMessage = 'pages.scheduleRules.schedulingMessage';
export const releaseSubmissionDateMessage =
  'pages.scheduleRules.releaseSubmissionDateMessage';
export const rulePeriodDateMessage =
  'pages.scheduleRules.rulePeriodDateMessage';
export const pickUpLocationMessage =
  'pages.scheduleRules.pickUpLocationMessage';
export const maximumPerDayMessage = 'pages.scheduleRules.maximumPerDayMessage';
export const maximumPerCompanyMessage =
  'pages.scheduleRules.maximumPerCompanyMessage';

// new
export const releaseSubmissionDateInfo =
  'pages.scheduleRules.releaseSubmissionDateInfo';
export const schedulingStartInfo = 'pages.scheduleRules.schedulingStartInfo';
export const boardingStartInfo = 'pages.scheduleRules.boardingStartInfo';
export const boardingEndInfo = 'pages.scheduleRules.boardingEndInfo';
