import { useContext, useState } from 'react';

import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Card, Checkbox, Form, FormItemProps, Input, Row } from 'antd';

import User from '../../../classes/User';
import AuthContainer from '../../../components/authentication/authContainer';
import UserContext from '../../../contexts/user';
import UserController from '../../../structures/controllers/User';

import Authentication from '..';
import { useRequest } from '../../../hooks/useRequest';
import './LoginPage.scss';

interface ILogin {
  email: string;
  password: string;
  keepConnected: boolean;
}
interface ILoginProps {
  bordered?: boolean;
}

const removeWhiteSpaces = (value: string) => {
  return value.trim();
};

const Login = ({ bordered = true }: ILoginProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [login, isLoading] = useRequest(UserController.login);
  const [hasError, setHasError] = useState<FormItemProps>({});
  const { setDecodedToken, logout } = useContext(UserContext);

  const onFinish = (values: ILogin) => {
    const lowerCaseValues: ILogin = {
      ...values,
      email: values.email.toLowerCase().trim(),
    };

    login(lowerCaseValues)
      .then(({ token }) => {
        User.setTokenToStorage(token, values.keepConnected)
          .then(setDecodedToken)
          .catch(() => {
            setHasError({
              hasFeedback: true,
              validateStatus: 'error',
              help: t('pages.login.fields.invalidCredentials'),
            });

            logout();
          });
      })
      .catch(() => {
        setHasError({
          hasFeedback: true,
          validateStatus: 'error',
          help: t('pages.login.fields.invalidCredentials'),
        });
      });
  };

  const LoginForm = (
    <Form
      className="form"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      initialValues={{ keepConnected: true }}
    >
      <Form.Item
        name="email"
        label={t('pages.login.fields.email.label')}
        {...hasError}
        normalize={removeWhiteSpaces}
        rules={[
          { required: true },
          {
            message: t('pages.login.fields.invalidEmail'),
            type: 'email',
            validateTrigger: 'click',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder={t('pages.login.fields.email.placeholder')} />
      </Form.Item>
      <Form.Item
        name="password"
        normalize={removeWhiteSpaces}
        label={t('pages.login.fields.password.label')}
        {...hasError}
        rules={[
          { required: true },
          {
            message: t('pages.login.fields.minimumDigits'),
            min: 6,
            validateTrigger: 'click',
          },
        ]}
      >
        <Input.Password
          placeholder={t('pages.login.fields.password.placeholder')}
        />
      </Form.Item>
      <Row align="middle" justify="space-between" style={{ rowGap: '12px' }}>
        <div className="keep-connected">
          <Form.Item name="keepConnected" valuePropName="checked">
            <Checkbox>{t('pages.login.fields.keepConnected')}</Checkbox>
          </Form.Item>
        </div>

        <Button onClick={() => history.push('/recuperar-senha')} type="link">
          {t('pages.login.fields.passwordRecovery')}
        </Button>
      </Row>
      <Row justify="end">
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{
            width: '100%',
          }}
        >
          {t('pages.login.fields.loginButton')}
        </Button>
      </Row>
    </Form>
  );

  return (
    <Authentication>
      <AuthContainer className="auth-container">
        <Card className="login-card" bordered={bordered}>
          <header>
            <div className="decoration"></div>
            <div className="header-container">
              <h1 className="title">{t('pages.login.title')}</h1>
              <span className="subtitle">{t('pages.login.subtitle')}</span>
            </div>
          </header>
          {LoginForm}
        </Card>
      </AuthContainer>
    </Authentication>
  );
};

export default withTranslation()(Login);
