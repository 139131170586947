import { ReactElement, Suspense, useContext } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import RenderComponent from './components/renderComponent';
import UserContext from './contexts/user';
import Reports from './pages/Reports';
import UserAccount from './pages/account/index';
import Login from './pages/authentication/login/LoginPage';
import ConfirmCode from './pages/authentication/passwordRecovery/confirmCode';
import SendRecoveryCode from './pages/authentication/passwordRecovery/index';
import ResetPassword from './pages/authentication/passwordRecovery/resetPassword';
import CreateLoad from './pages/cargos/CreateLoad/CreateTruckLoadsPage';
import ViewLoad from './pages/cargos/CreateLoad/createLoadSteps/viewLoad/ViewLoad';
import TruckLoadsPage from './pages/cargos/TruckLoadsPage';
import ContactsPage from './pages/clients/contacts/ContactsPage';
import FarmsPage from './pages/clients/farms/FarmsPage';
import ConfigureCustomerImport from './pages/clients/importCustomers/configImport';
import ContractsPage from './pages/contracts/ContractsPage';
import ConfigureContractsImport from './pages/contracts/importContracts/configImport';
import FirstSignIn from './pages/customerArea/firstSignIn';
import ConfigureCustomerAccount from './pages/customerArea/firstSignIn/configuration';
import Home from './pages/home/HomePage';
import LoadingPage from './pages/loadingPage';
import EditCompany from './pages/settings/organization/editCompany';
import MyCompany from './pages/settings/organization/myCompany';
import EditScheduleRule from './pages/rules/scheduleRules/register/editScheduleRule';
import NewScheduleRule from './pages/rules/scheduleRules/register/newScheduleRule';
import ScheduleRules from './pages/rules/scheduleRules/scheduleRules';
import RuleSettings from './pages/rules/scheduleRules/settings/ruleSettings';
import Users from './pages/users';
import UserConfigAccess from './pages/users/firstSignIn/configuration';
import UserFirstSignIn from './pages/users/firstSignIn/userFirstSignIn';
import RegisterNewUser from './pages/users/registerNewUser';
import Tutorials from './pages/tutorials/Tutorials';
import ShippingCompanyPage from './pages/settings/shippingCompany/ShippingCompanyPage';
import RuleSettingsPage from './pages/settings/rules/RulesSettingsPage';
import VehiclesPage from './pages/settings/vehicles/VehiclesPage';

const Routes = (): ReactElement => {
  const { t } = useTranslation();
  const { userData, customerData } = useContext(UserContext);

  return (
    <Router>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route
            exact
            path="/home"
            render={() => (
              <RenderComponent
                menuKey="HOME"
                useMainLayout
                tabTitle={t('tab.home')}
              >
                <Home />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/contratos"
            render={() => (
              <RenderComponent
                menuKey="CONTRATO"
                useMainLayout
                tabTitle={t('tab.contracts')}
              >
                <ContractsPage />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/contratos/importar"
            render={props => (
              <RenderComponent
                menuKey="CONTRATO"
                useMainLayout
                tabTitle={t('tab.contracts')}
              >
                <ConfigureContractsImport {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/clientes/contatos"
            render={() => (
              <RenderComponent
                menuKey="customers"
                useMainLayout
                tabTitle={t('tab.clients')}
              >
                <ContactsPage />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/clientes/fazendas"
            render={() => (
              <RenderComponent
                menuKey="farms"
                useMainLayout
                tabTitle={t('tab.clients')}
              >
                <FarmsPage />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/clientes/importar"
            render={props => (
              <RenderComponent
                menuKey="IMPORTAR_CLIENTE"
                useMainLayout
                tabTitle={t('tab.clients')}
              >
                <ConfigureCustomerImport {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/usuarios"
            render={props => (
              <RenderComponent
                menuKey="USUARIOS"
                useMainLayout
                tabTitle={t('tab.users')}
              >
                <Users {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/usuarios/novo"
            render={props => (
              <RenderComponent
                menuKey="CRIAR_USUARIO"
                useMainLayout
                tabTitle={t('tab.users')}
              >
                <RegisterNewUser {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/cargas"
            render={() => (
              <RenderComponent
                menuKey="CARGAS"
                useMainLayout
                tabTitle={t('tab.cargos')}
              >
                <TruckLoadsPage />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/cargas/formar-carga"
            render={() => (
              <RenderComponent
                menuKey="CRIAR_CARGA"
                useMainLayout
                tabTitle={t('tab.viewTruckLoad')}
              >
                <CreateLoad />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/cargas/:id"
            render={props => (
              <RenderComponent
                menuKey="VER_CARGA"
                useMainLayout
                tabTitle={t('tab.cargos')}
              >
                <ViewLoad {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path={`/conta`}
            render={props => (
              <RenderComponent
                menuKey="myAccount"
                useMainLayout
                tabTitle={t('tab.myAccount')}
              >
                <UserAccount {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path={'/configuracoes/empresas/:id'}
            render={props => (
              <RenderComponent
                menuKey={'MINHA_EMPRESA'}
                useMainLayout
                tabTitle={t('tab.myCompany')}
              >
                <MyCompany {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path={'/configuracoes/empresas/:id/editar'}
            render={props => (
              <RenderComponent
                menuKey="MINHA_EMPRESA"
                useMainLayout
                tabTitle={t('tab.myCompany')}
              >
                <EditCompany {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path={'/configuracoes/transportadoras'}
            render={() => (
              <RenderComponent
                useMainLayout
                menuKey="shipping_company"
                tabTitle={'Transportadoras'}
              >
                <ShippingCompanyPage />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path={'/configuracoes/regras'}
            render={() => (
              <RenderComponent
                useMainLayout
                menuKey="rule_settings"
                tabTitle={'Configurações de regras'}
              >
                <RuleSettingsPage />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path={'/configuracoes/veiculos'}
            render={() => (
              <RenderComponent
                useMainLayout
                menuKey="vehicles"
                tabTitle={'Veículos'}
              >
                <VehiclesPage />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/regras/configuracoes"
            render={props => (
              <RenderComponent
                menuKey="ruleSettings"
                useMainLayout
                tabTitle={t('tab.scheduleRules')}
              >
                <RuleSettings {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/regras"
            render={props => (
              <RenderComponent
                menuKey="REGRAS"
                useMainLayout
                tabTitle={t('tab.scheduleRules')}
              >
                <ScheduleRules {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/regras/nova-regra"
            render={props => (
              <RenderComponent
                menuKey="REGRAS"
                useMainLayout
                tabTitle={t('tab.scheduleRules')}
              >
                <NewScheduleRule {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/regras/editar"
            render={props => (
              <RenderComponent
                menuKey="REGRAS"
                useMainLayout
                tabTitle={t('tab.scheduleRules')}
              >
                <EditScheduleRule {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/tutoriais"
            render={props => (
              <RenderComponent
                menuKey="tutorials"
                useMainLayout
                tabTitle={t('tab.tutorials')}
              >
                <Tutorials {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/relatorios"
            render={props => (
              <RenderComponent
                menuKey="reports"
                useMainLayout
                tabTitle={t('tab.reports')}
              >
                <Reports {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/recuperar-senha"
            render={() => (
              <RenderComponent
                menuKey="recoveryPassword"
                publicRoute
                tabTitle={t('tab.scheduleRules')}
              >
                <SendRecoveryCode bordered={false} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/recuperar-senha/confirmar-codigo"
            render={() => (
              <RenderComponent
                menuKey="confirmCode"
                publicRoute
                tabTitle={t('tab.scheduleRules')}
              >
                <ConfirmCode bordered={false} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/recuperar-senha/confirmar-codigo/atualizar-senha"
            render={() => (
              <RenderComponent
                menuKey="recoveryPassword"
                publicRoute
                tabTitle={t('tab.scheduleRules')}
              >
                <ResetPassword bordered={false} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/area-cliente/configuracao"
            render={props => (
              <RenderComponent
                publicRoute
                menuKey="customerConfigPage"
                absoluteTabTitle
                useFirstAccessLayout
                tabTitle={t('tab.configureAccount')}
              >
                <ConfigureCustomerAccount {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/area-cliente/cadastro"
            render={() => (
              <RenderComponent
                absoluteTabTitle
                publicRoute
                tabTitle={t('tab.signUp')}
              >
                <FirstSignIn />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/area-usuario/primeiro-login"
            render={() => (
              <RenderComponent
                absoluteTabTitle
                publicRoute
                tabTitle={t('tab.signUp')}
              >
                <UserFirstSignIn />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path="/area-usuario/configuracao"
            render={props => (
              <RenderComponent
                publicRoute
                useFirstAccessLayout
                absoluteTabTitle
                menuKey="userConfigPage"
                tabTitle={t('tab.configureAccount')}
              >
                <UserConfigAccess {...props} />
              </RenderComponent>
            )}
          />

          <Route
            exact
            path={['/', '/auth']}
            render={() => (
              <RenderComponent
                authRoute
                publicRoute
                absoluteTabTitle
                tabTitle={t('tab.authentication')}
              >
                <Login bordered={false} />
              </RenderComponent>
            )}
          />

          {userData === undefined && customerData === undefined && (
            <Route path="*" render={() => <LoadingPage />} />
          )}
          {userData === null && customerData === null && (
            <Route path="*" render={() => <Redirect to="/auth" />} />
          )}
          {userData || customerData ? (
            <Route path="*" render={() => <Redirect to="/home" />} />
          ) : (
            <Route path="*" render={() => <Redirect to="/auth" />} />
          )}
        </Switch>
      </Suspense>
    </Router>
  );
};

export default withTranslation()(Routes);
