import { Form, Row, Col, Input, message, FormInstance } from 'antd';
import { ReactElement, useEffect } from 'react';
import { useRequest } from '../../../../../hooks/useRequest';
import ShippingCompanyController from '../../../../../structures/controllers/ShippingCompany';
import Normalizer from '../../../../../classes/Normalizer';
import Formatter from '../../../../../classes/Formatter';

export interface IDriverFormValues {
  driverId: string;
  vehicleId: string;
  documentNumberCpf: string;
  name: string;
  vehicleModel: string;
  vehicleLicensePlate: string;
  phone: string;
  email: string;
  notes: string;
}

interface IDriverFormProps {
  form: FormInstance<IDriverFormValues>;
  updateData?: IDriverFormValues;
}
const DriverForm = ({ form, updateData }: IDriverFormProps): ReactElement => {
  const [findDriver] = useRequest(ShippingCompanyController.findDriver);

  const handleSearch = (cpf: string) => {
    if (cpf) {
      const searchValue = Normalizer.onlyNumbers(cpf);
      findDriver({ cpf: searchValue })
        .then(res => {
          form.setFieldsValue({
            driverId: res?.id,
            vehicleId: res?.vehicleId,
            name: res?.driver,
            vehicleModel: res?.vehicle,
            vehicleLicensePlate: res?.licensePlate,
            phone: Formatter.formatPhoneWithoutDDI(res?.phoneNumber),
            email: res?.email,
            notes: res?.driverObservation,
          });
        })
        .catch(() => {
          form.resetFields();
          message.error('Erro ao buscar motorista');
        });
    }
  };

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        driverId: updateData?.driverId,
        vehicleId: updateData?.vehicleId,
        name: updateData?.name,
        vehicleModel: updateData?.vehicleModel,
        vehicleLicensePlate: updateData?.vehicleLicensePlate,
        phone: Formatter.formatPhoneWithoutDDI(updateData?.phone),
        email: updateData?.email,
        notes: updateData?.notes,
        documentNumberCpf: Formatter.formatCPF(updateData?.documentNumberCpf),
      });
    }
  }, [updateData]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name={'driverId'} hidden />
      <Form.Item name={'vehicleId'} hidden />

      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item
            label="CPF do motorista"
            name={'documentNumberCpf'}
            normalize={Formatter.formatCPF}
          >
            <Input.Search onSearch={handleSearch} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Nome do motorista" name={'name'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Modelo do veículo" name={'vehicleModel'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Telefone"
            name={'phone'}
            normalize={Formatter.formatPhoneWithoutDDI}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Placa do veículo" name={'vehicleLicensePlate'}>
            <Input />
          </Form.Item>
        </Col>
        {/* <Col span={6}>
          <Form.Item label="E-mail" name={'email'}>
            <Input />
          </Form.Item>
        </Col> */}
        <Col span={6}>
          <Form.Item label="Observações sobre o motorista" name={'notes'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DriverForm;
