import { Button, Modal, Typography, message } from 'antd';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { useRequest } from '../../../hooks/useRequest';
import UserController from '../../../structures/controllers/User';
import { IDeleteUser } from '../../../structures/interfaces/User';

const DeleteUser = ({
  t,
  visible,
  onClose,
  onConfirm,
  deleteData,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  deleteData: IDeleteUser;
}) => {
  const [deleteUser, isDeleteUserRequesting] = useRequest(
    UserController.deleteUser
  );

  const info = () => {
    message.success(t('pages.users.deleteUser.successMessage'));
  };
  const infoError = () => {
    message.error(t('pages.users.deleteUser.errorMessage'));
  };
  const { Title } = Typography;
  const footerModal = [
    <Button
      key="back"
      danger
      onClick={onClose}
      loading={isDeleteUserRequesting}
    >
      {t('pages.users.deleteUser.cancelButton')}
    </Button>,
    <Button
      type="primary"
      htmlType="submit"
      onClick={() => {
        deleteUser({ id: deleteData.id })
          .then(() => {
            info();
            onConfirm();
          })
          .catch(() => {
            infoError();
          });
      }}
      loading={isDeleteUserRequesting}
    >
      {t('pages.users.deleteUser.confirmButton')}
    </Button>,
  ];
  return (
    <Modal width={'50%'} open={visible} onCancel={onClose} footer={footerModal}>
      <Title level={5}>{t('pages.users.deleteUser.title')}</Title>
      <p>
        {t('pages.users.deleteUser.info') + ' '}
        <b>{deleteData.name}</b>
        {'.'}
      </p>
      <p>{t('pages.users.deleteUser.warning')}</p>
      <p>{t('pages.users.deleteUser.confirmMessage')}</p>
    </Modal>
  );
};

export default withTranslation()(DeleteUser);
