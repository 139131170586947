import { DefaultOptionType } from 'antd/lib/select';

export const CompanyFunctions = ['carrier', 'shipper'] as const;

export type ICompanyFunction = (typeof CompanyFunctions)[number];

export enum CompanyFunctionEnum {
  carrier = 'CARRIER',
  shipper = 'SHIPPER',
}

export enum CompanyFunctionReverseEnum {
  CARRIER = 'carrier',
  SHIPPER = 'shipper',
}

export const CompanyFunctionOptions: DefaultOptionType[] = CompanyFunctions.map(
  companyFunction => ({
    label: `enums.companyFunction.${companyFunction}`,
    value: companyFunction,
  })
);
