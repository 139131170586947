/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Button, Modal, Typography } from 'antd';
import { t } from 'i18next';

export interface IConfirmEditionProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const { Title } = Typography;

const ConfirmRuleEdition = ({
  visible,
  onOk,
  onCancel,
}: IConfirmEditionProps) => {
  const editFooter = [
    <Button key="back" danger onClick={onCancel}>
      {t('pages.settings.deleteBranchCompany.cancelButton')}
    </Button>,
    <Button type="primary" htmlType="submit" onClick={onOk}>
      {t('pages.settings.deleteBranchCompany.confirmButton')}
    </Button>,
  ];

  return (
    <>
      <Modal onCancel={onCancel} open={visible} onOk={onOk} footer={editFooter}>
        <Title level={5}>
          {t('pages.scheduleRules.editRules.confirmRuleCard.title')}
        </Title>
        <p>{t('pages.scheduleRules.editRules.confirmRuleCard.warningText')}</p>
        <p>
          {t(
            'pages.scheduleRules.editRules.confirmRuleCard.warningConfirmText'
          )}
        </p>
        <p> {t('pages.scheduleRules.editRules.confirmRuleCard.confirmText')}</p>
      </Modal>
    </>
  );
};

export default ConfirmRuleEdition;
