import { CheckOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Layout,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Space,
} from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Normalizer from '../../../classes/Normalizer';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import {
  IFarmResponse,
  IGetClient,
} from '../../../structures/interfaces/Clients';
import { defaultModalProps } from '../Clients.utils';
import AddNewContact, {
  IAddNewContactFormValues,
} from './FarmsPage.addNewContact';
import FarmsFilter from './FarmsPage.filter';
import SyncNewContact from './FarmsPage.syncNewContact';
import FarmsTable from './FarmsPage.table';
import { BuildFarmsSlug } from './FarmsPage.utils';

export interface IFarmOpenModal {
  action: 'add' | 'sync' | '';
}
const defaultPopconfirmProps = {
  okText: 'Sim',
  cancelText: 'Não',
};
const FarmsPage = (): ReactElement => {
  const [addNewContactForm] = Form.useForm<IAddNewContactFormValues>();
  const [syncContactForm] = Form.useForm();

  const { t } = useTranslation();
  const { userData } = useContext(UserContext);

  const [farms, setFarms] = useState<IFarmResponse[]>([]);
  const [filteredFarms, setFilteredFarms] = useState<IFarmResponse[]>([]);
  const [openModal, setOpenModal] = useState<IFarmOpenModal>({ action: '' });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedContact, setSelectedContact] = useState<IGetClient>();
  const [selectedFarm, setSelectedFarm] = useState<IFarmResponse>();

  const [fetchFarms, isLoading] = useRequest(ClientsController.fetchOrgFarms);
  const [syncContacts] = useRequest(ClientsController.assignFarmHasCustomers);
  const [createContact] = useRequest(ClientsController.createCustomer);
  const [sendNewAccess] = useRequest(ClientsController.sendNewCustomerAccess);

  const handleCreateContact = () => {
    const orgId = userData?.organization.id || '';
    const { cpf, email, name, phone } = addNewContactForm.getFieldsValue();
    createContact({
      orgId,
      email,
      name,
      cpf: Normalizer.onlyNumbers(cpf),
      phone: Normalizer.onlyNumbers(phone),
      farmId: selectedFarm?.id || '',
    })
      .then(() => {
        doFetchFarmsRequest();
        message.success('Contato criado com sucesso!');
        addNewContactForm.resetFields();
      })
      .catch(() => {
        message.error('Erro ao criar o contato!');
        addNewContactForm.resetFields();
      });
    setOpenModal({ action: '' });
  };
  const handleSyncContacts = () => {
    if (selectedContact?.id && selectedFarm?.id) {
      syncContacts({
        customerIds: [selectedContact.id],
        farmId: selectedFarm.id,
      })
        .then(() => {
          doFetchFarmsRequest();
          message.success(t('Contato vinculado com sucesso!'));
        })
        .catch(() => message.error(t('Erro ao vincular o contato!')));

      setOpenModal({ action: '' });
    }
  };

  const hasSelected = selectedRowKeys.length > 0;
  const extra = (
    <Popconfirm
      title="Confirmar operação?"
      {...defaultPopconfirmProps}
      onConfirm={() => {
        const keys = selectedRowKeys as string[];
        sendNewAccess({ ids: keys })
          .then(() => message.success('E-mails enviados com sucesso'))
          .catch(() => message.error('Não foi possível enviar os e-mails'));
      }}
    >
      <Button type="primary" icon={<SendOutlined />} disabled={!hasSelected}>
        Disparar primeiro acesso
      </Button>
    </Popconfirm>
  );
  const syncContactsFooter = (
    <Space>
      <Popconfirm
        title="Deseja cancelar a operação?"
        onConfirm={() => {
          syncContactForm.resetFields();
          setSelectedContact(undefined);
          setOpenModal({ action: '' });
        }}
        {...defaultPopconfirmProps}
      >
        <Button danger icon={<CloseOutlined />}>
          Cancelar
        </Button>
      </Popconfirm>

      <Popconfirm
        title="Concluir operação?"
        onConfirm={handleSyncContacts}
        {...defaultPopconfirmProps}
      >
        <Button type="primary" icon={<CheckOutlined />}>
          Finalizar
        </Button>
      </Popconfirm>
    </Space>
  );
  const addNewContactFooter = (
    <Space>
      <Popconfirm
        title="Deseja cancelar a operação?"
        onConfirm={() => setOpenModal({ action: '' })}
        {...defaultPopconfirmProps}
      >
        <Button danger icon={<CloseOutlined />}>
          Cancelar
        </Button>
      </Popconfirm>

      <Popconfirm
        title="Finalizar operação?"
        onConfirm={handleCreateContact}
        {...defaultPopconfirmProps}
      >
        <Button type="primary" icon={<CheckOutlined />}>
          Finalizar
        </Button>
      </Popconfirm>
    </Space>
  );
  const doFetchFarmsRequest = () => {
    if (userData?.organization.id) {
      fetchFarms({ orgId: userData.organization.id })
        .then(res => {
          const filteredResponse = BuildFarmsSlug(res);
          setFilteredFarms(filteredResponse);
          setFarms(filteredResponse);
        })
        .catch(() => message.error('Não foi possível buscar as fazendas!'));
    }
  };

  useEffect(() => {
    doFetchFarmsRequest();
  }, []);

  return (
    <Layout>
      <PageHeader
        title="Fazendas"
        subTitle="Veja informações das fazendas de seus clientes"
        ghost={false}
      />

      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card>
              <FarmsFilter
                setFilteredFarms={setFilteredFarms}
                filteredFarms={filteredFarms}
                farms={farms}
              />
            </Card>
          </Col>

          <Col span={24}>
            <Card title="Fazendas" loading={isLoading} extra={extra}>
              <FarmsTable
                filteredFarms={filteredFarms}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRowKeys={selectedRowKeys}
                setAddNewContact={setOpenModal}
                setFarm={setSelectedFarm}
              />
            </Card>
          </Col>
        </Row>
      </Layout.Content>

      <Modal
        {...defaultModalProps}
        footer={syncContactsFooter}
        title="Atribuir novo contato a fazenda"
        open={openModal.action === 'sync'}
      >
        <SyncNewContact
          setSelectedContact={setSelectedContact}
          selectedContact={selectedContact}
          farm={selectedFarm}
          form={syncContactForm}
        />
      </Modal>

      <Modal
        {...defaultModalProps}
        footer={addNewContactFooter}
        title="Adicionar novo contato"
        open={openModal.action === 'add'}
      >
        <AddNewContact form={addNewContactForm} />
      </Modal>
    </Layout>
  );
};

export default FarmsPage;
