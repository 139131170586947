import { countdownValueType } from 'antd/lib/statistic/utils';
import moment, { Moment } from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import VMasker from 'vanilla-masker';
import Normalizer from './Normalizer';

/**
 * @description Formats the values passed to the given format.
 */

class Formatter {
  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the CNPJ format
   * 00.000.000/0000-00. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatCNPJ = (text?: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '99.999.999/9999-99');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the CPF format
   * 000.000.000-00. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatCPF = (text?: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '999.999.999-99');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the CPF (000.000.000-00)
   * or CNPJ (00.000.000/0000-00) format. If formatting is not possible,
   * the value passed as a parameter without changes is returned.
   */
  formatCPF_CNPJ = (text: string) => {
    try {
      const cleanValue = `${text}`.replace(/[^0-9]/g, '');
      return cleanValue.length <= 11
        ? this.formatCPF(cleanValue)
        : this.formatCNPJ(cleanValue);
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the RG format
   * 00.000.000-0. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatRG = (text: string) => {
    try {
      let valorSemFormatacao = `${text}`.replaceAll(/[.]|[-]/g, '');
      if (valorSemFormatacao.match(/(^[[0-9]{8,9}X{0,1}$)/i)) {
        return VMasker.toPattern(
          valorSemFormatacao,
          '99.999.999-S'
        ).toUpperCase();
      }
      valorSemFormatacao = `${text}`.replace(/[^0-9]/g, '');
      return valorSemFormatacao;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the CEP format
   * 00000-00. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatZipCode = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '99999-999');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the expires at date format
   * 00/00. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatExpiresAt = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '99/99');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the credit card number format
   * 9999 9999 9999 9999, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatCreditCard = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9]/g, '');
        if (value.length > 16) {
          return VMasker.toPattern(value, '9999 9999 9999 9999999');
        }
        return VMasker.toPattern(value, '9999 9999 9999 9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the money format
   * 9999 9999 9999 9999, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatMoney = (text = '') => {
    try {
      if (!text) return '';

      const value = text.toString().replace(/[^0-9]/g, '');
      return VMasker.toMoney(value);
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the money format
   * R$ 999.999.999,99. If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatMoneyWithPrefix = (text = '', prefix = 'R$ ') => {
    try {
      if (!text) return '';

      const value = text.toString().replace(/[^0-9]/g, '');
      return prefix + VMasker.toMoney(value);
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats according to the number of characters
   * if it is 11 it returns in CPF format 000.000.000-00,
   * case 13 in CNPJ format 00.000.000/0000-00,
   * and if 10 is returned in RG format: 00.000.000-0.
   */
  formatDocument = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      if (value.length > 11) {
        return this.formatCNPJ(value);
      }
      return this.formatCPF(value);
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the phone format
   * +55 (44) 3698-5555, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatLandline = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9]/g, '');

        return VMasker.toPattern(value, '(99) 9999-9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the phone format
   * +55 (44) 9 9746-1823, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatCellphone = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9]/g, '');
        return VMasker.toPattern(value, '(99) 99999-9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the phone format
   * (44) 9 9746-1823, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatPhoneWithoutDDI = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9]/g, '');
        if (value.length === 11) {
          return VMasker.toPattern(value, '(99) 9 9999-9999');
        }
        if (value.length === 13) {
          return VMasker.toPattern(value, '+99(99) 9 9999-9999');
        }
        return VMasker.toPattern(value, '(99) 9999-9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the phone format
   * +55 (44) 9 9746-1823, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatPhoneWithDDI = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9 | *]/g, '');
        if (value.includes('*')) {
          return VMasker.toPattern(value, '+99 (99) 9999-****');
        }
        if (value.length === 13) {
          return VMasker.toPattern(value, '+99 (99) 9 9999-9999');
        }
        return VMasker.toPattern(value, '+99 (99) 9999-9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the date format
   * 99/99/9999. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatInputDate = (value: string) => {
    try {
      return VMasker.toPattern(value, '99/99/9999');
    } catch (error) {
      return value;
    }
  };

  /**
    //      * @function
    //      * @param {string} value Text aim to be formatted.
    //      * @returns {string}
    //      * @description Format the value passed to the date format
    //      * 99/99/9999. If formatting is not possible, the value
    //      * passed as a parameter without changes is returned.
    //  */

  /**
   * @function formatTimeWithoutTimezone
   * @param {string} text text to be converted.
   * @returns {string}
   * @description format a text on HH:mm
   */
  formatTimeWithoutTimezone = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '99:99');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function removeKeysWithoutValue
   * @param {object} object object to be formated.
   * @returns {object}
   * @description Used to remove the keys of an object, that doesn't have value.
   * If it fail you will receive the object without change.
   */
  removeKeysWithoutValue = (object: Record<string, never>) => {
    try {
      const formatedObject: Record<string, never> = {};

      Object.keys(object).forEach(key => {
        if (object[key]) {
          formatedObject[key] = object[key];
        }
      });

      return formatedObject;
    } catch (error) {
      return object;
    }
  };

  /**
   * @function formatToPercent
   * @param {number} text - Text aim to be formatted.
   * @returns {number}
   * @description Return the formatted percentage with % at the end, example
   * comes from backend 0.20 and its returned 20.00%.
   */
  formatToPercent = (text: number) => {
    let numbers = parseInt(Normalizer.onlyNumbers(`${text}` || '0'));
    numbers = numbers > 10000 ? 10000 : numbers;
    const floatValue = parseFloat(`${numbers / 100}`).toFixed(2);
    return text ? floatValue : text;
  };

  /**
   * @function formatPercentToFloatNumber
   * @param {number} text - Text aim to be formatted.
   * @returns {number}
   * @description Return the formatted percentage with % at the end, example
   * comes from backend 20.00% and its returned 0.20.
   */
  formatPercentToFloatNumber = (text: string) => {
    const removePercent = text.replace('%', '');
    const numbers = parseFloat(Normalizer.onlyNumbers(removePercent || '0'));
    const floatValue = parseFloat(`${numbers / 10000}`).toFixed(6);
    return text ? floatValue : text;
  };

  /**
   * @function formatMoneyToFloat
   * @param {number} text - Text aim to be formatted.
   * @returns {number}
   * @description Return the formatted without the currency symbol, example
   * comes from backend R$ 7,89 and its returned 78900.
   */
  formatMoneyToFloat = (text: string, symbol = 'R$ ') => {
    const removeSymbol = text.replace(symbol, '');
    const numbers = parseFloat(Normalizer.onlyNumbers(removeSymbol || '0'));
    const floatValue = parseFloat(`${numbers * 100}`).toFixed(6);
    return text ? floatValue : text;
  };

  /**
   * @function removeAccents
   * @param {string} text - Text aim to be formatted.
   * @returns {string}
   * @description Return a formated text whitout accents.
   */
  removeAccents = (text: string) => {
    try {
      return text.normalize('NFD').replaceAll(/[\u0300-\u036f]/g, '');
    } catch {
      return text;
    }
  };

  /**
   * @function formatLinkSlug
   * @param {string} text - Text aim to be formatted.
   * @returns {string}
   * @description Return a formated slug text by be applied in a url link text
   */
  formatLinkSlug = (text: string) => {
    try {
      return text
        .normalize('NFD')
        .replaceAll(/[\u0300-\u036f]/g, '')
        .replaceAll(' ', '-')
        .toLocaleLowerCase();
    } catch {
      return text;
    }
  };

  /**
   * @function formatDimension
   * @param {string} text - Text aim to be formatted.
   * @returns {string}
   * @description Return a formated dimension text, with 2 ca
   */
  formatDimension = (value: string) => {
    try {
      let text: string | string[] = value.replace(/[^0-9|,.]/g, '');
      text = text.split(/[.|,]/g);
      if (text.length >= 2) return `${text[0]}.${text[1].substring(0, 2)}`;
      return value;
    } catch {
      return value;
    }
  };

  /**
   * @function convertMinutesInHours
   * @param {string} text - Text aim to be formatted in minutes.
   * @returns {string}
   * @description Return a formated hours with 'HH:MM' format
   */
  convertMinutesInHours = (text: number) => {
    if (!text) return '';
    const time = text / 60;
    let timeInt: string | number = parseInt(`${time}`);
    let timeDec: string | number = parseInt(
      `${Math.round((time - timeInt) * 60)}`
    );

    if (`${timeInt}`.length < 2) timeInt = `0${timeInt}`;
    if (`${timeDec}`.length < 2) timeDec = `0${timeDec}`;

    return `${timeInt}:${timeDec}`;
  };

  /**
   * @function formatDate
   * @param {string} text - Text aim to be formatted in minutes.
   * @returns {string}
   * @description Return a formated hours with 'HH:MM' format
   */
  formatDate = (text: string) => {
    try {
      return moment(text).utc().format('DD/MM/YYYY');
    } catch {
      return text;
    }
  };

  formatDateWithMonthWrittenOut(value: Moment, locale?: string) {
    try {
      return moment(value)
        .locale(locale || 'pt-BR')
        .format('LL');
    } catch {
      return value;
    }
  }

  getDurationBetweenTwoDates(
    value1: Moment,
    value2: Moment,
    locale?: string,
    humanize = true
  ) {
    try {
      const diffInMilliseconds = value1.diff(value2);
      const duration = moment.duration(diffInMilliseconds);
      if (humanize) {
        return duration.locale(locale || 'pt-BR').humanize();
      }
      return moment('2000-01-01 00:00:00')
        .add(diffInMilliseconds, 'milliseconds')
        .format('HH:mm:ss');
    } catch {
      return value1;
    }
  }

  formatDuration(value: number | countdownValueType) {
    return moment('2000-01-01 00:00:00')
      .add(value, 'milliseconds')
      .format('HH:mm:ss');
  }

  formatKgInTon = (value: number) => {
    const newValue = `${value / 1000}`.replace('.', ',');
    return `${newValue} ton.`;
  };
}

export default new Formatter();
