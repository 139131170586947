import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { ReactElement, useContext, useEffect, useState } from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import CompanyController from '../../../structures/controllers/Company';
import UserController from '../../../structures/controllers/User';
import {
  IGetUserUpdate,
  IUserModulePermissions,
  IUserPermission,
  IUserPermissions,
  IUserPermissionUpdate,
  IVisibleOrganizations,
  RegStatusEnum,
} from '../../../structures/interfaces/User';
import { IErrorMessage } from '../../../utils/interfaces';
import CustomerNotified from '../../clients/customerNotified';

const { Text } = Typography;

const RegisterNewUser = ({
  t,
  visible,
  onClose,
  onConfirm,
  data,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  data: IGetUserUpdate;
}): ReactElement<unknown> => {
  const [userPermissionRequest, isUserPermissionRequesting] = useRequest(
    UserController.getUserPermissionOptions
  );
  const [initialUserPermissions, setInitialUserPermissions] =
    useState<IUserModulePermissions[]>();
  const [allowUserAccess] = useRequest(UserController.allowUserAccess);
  const [userRegisterRequest] = useRequest(UserController.registerUser);
  const [findBranchesRequest] = useRequest(
    CompanyController.getBranchCompanies
  );
  const [viewNotification, setViewNotification] = useState(false);
  const [organizations, setOrganizations] = useState<IVisibleOrganizations[]>();
  const [userPermissions, setUserPermissions] = useState<IUserPermissions[]>();
  const [selectedUserPermissions, setSelectedUserPermissions] =
    useState<IUserPermissions[]>();
  const { userData, company } = useContext(UserContext);
  const [form] = Form.useForm();
  const [formCheckbox] = Form.useForm();
  const [disabledContractCheckbox, setDisabledContractCheckbox] =
    useState<boolean>();
  const [disabledTruckLoadCheckbox, setDisabledTruckLoadCheckbox] =
    useState<boolean>();
  const [disabledHomeCheckbox, setDisabledHomeCheckbox] = useState<boolean>();
  const [disabledCustomerCheckbox, setDisabledCustomerCheckbox] =
    useState<boolean>();
  const [disabledUserCheckbox, setDisabledUserCheckbox] = useState<boolean>();
  const [disabledSettingsCheckbox, setDisabledSettingsCheckbox] =
    useState<boolean>();

  const { Option } = Select;

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      duration: 7,
      style: { marginTop: '4rem' },
    });
  };

  useEffect(() => {
    if (userData && company) {
      const tmpOrganizations: IVisibleOrganizations[] = [];
      tmpOrganizations.push({
        name: company.tradeName,
        id: userData.organization.id,
      });

      findBranchesRequest({ id: userData.organization.id }).then(branches => {
        branches.map(branch => {
          tmpOrganizations.push({
            name: branch.tradeName,
            id: branch.id,
          });
        });
      });
      setOrganizations(tmpOrganizations);
    }
  }, [userData, company]);

  useEffect(() => {
    if (userData) {
      userPermissionRequest({})
        .then(permissions => {
          setUserPermissions(permissions);
        })
        .catch(err => showError(err));
    }
  }, [userData]);

  const isReadChecked = (module: string) => {
    switch (module) {
      case 'HOME':
        return disabledHomeCheckbox;

      case 'CONTRATO':
        return disabledContractCheckbox;

      case 'REGRAS':
        return disabledSettingsCheckbox;

      case 'CARGAS':
        return disabledTruckLoadCheckbox;

      case 'USUARIOS':
        return disabledUserCheckbox;

      case 'CLIENTE':
        return disabledCustomerCheckbox;
    }
  };

  const columnsUsers: ColumnsType<IUserPermissions> = [
    {
      title: t('pages.users.newUser.userPermissions.pages'),
      render: value => {
        switch (value) {
          case 'HOME':
            return t('sidebar.options.home');
          case 'USUARIOS':
            return t('sidebar.options.users');
          case 'MINHA_EMPRESA':
            return t('sidebar.options.myCompany');
          case 'REGRAS':
            return t('sidebar.options.settings');
          case 'CONTRATO':
            return t('sidebar.options.contracts');
          case 'CLIENTE':
            return t('sidebar.options.clients');
          case 'CARGAS':
            return t('sidebar.options.truckLoads');
        }
      },
      dataIndex: ['module'],
      key: 'page',
    },
    {
      title: t('pages.users.newUser.userPermissions.reading'),
      dataIndex: ['READ'],
      key: 'read',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        return value ? (
          <Form.Item
            name={value}
            initialValue={value}
            noStyle
            valuePropName="checked"
            validateFirst
            rules={[
              {
                validator: () => {
                  const userPermissions =
                    selectedUserPermissions as IUserPermission[];

                  const permission = userPermissions?.find(
                    userPermission => userPermission.module === rowData.module
                  );

                  const formValues = formCheckbox.getFieldsValue();

                  if (permission) {
                    if (!formValues[permission.READ]) {
                      switch (permission.module) {
                        case 'HOME':
                          setDisabledHomeCheckbox(true);
                          break;
                        case 'CONTRATO':
                          setDisabledContractCheckbox(true);
                          break;
                        case 'REGRAS':
                          setDisabledSettingsCheckbox(true);
                          break;
                        case 'CARGAS':
                          setDisabledTruckLoadCheckbox(true);
                          break;
                        case 'USUARIOS':
                          setDisabledUserCheckbox(true);
                          break;
                        case 'CLIENTE':
                          setDisabledCustomerCheckbox(true);
                          break;
                      }
                    } else {
                      switch (permission.module) {
                        case 'HOME':
                          setDisabledHomeCheckbox(false);
                          break;
                        case 'CONTRATO':
                          setDisabledContractCheckbox(false);
                          break;
                        case 'REGRAS':
                          setDisabledSettingsCheckbox(false);
                          break;
                        case 'CARGAS':
                          setDisabledTruckLoadCheckbox(false);
                          break;
                        case 'USUARIOS':
                          setDisabledUserCheckbox(false);
                          break;
                        case 'CLIENTE':
                          setDisabledCustomerCheckbox(false);
                          break;
                      }
                    }
                  }
                },
              },
            ]}
          >
            {rowData.module === 'HOME' ? (
              <Tooltip
                title={t('pages.users.errors.mustHaveHomeReadPermission')}
              >
                <Checkbox disabled checked />
              </Tooltip>
            ) : (
              <Checkbox />
            )}
          </Form.Item>
        ) : (
          <Checkbox disabled />
        );
      },
    },
    {
      title: t('pages.users.newUser.userPermissions.edit'),
      dataIndex: ['UPDATE'],
      key: 'update',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        const userPermissions = selectedUserPermissions as IUserPermission[];

        const permission = userPermissions?.find(
          userPermission => userPermission.module === rowData.module
        );

        const checkboxChecked = permission
          ? isReadChecked(permission.module)
          : false;

        return value ? (
          <Form.Item
            name={value}
            initialValue={value}
            noStyle
            valuePropName={checkboxChecked ? '' : 'checked'}
          >
            <Checkbox
              key={value}
              checked={!checkboxChecked}
              disabled={checkboxChecked}
            />
          </Form.Item>
        ) : (
          <Checkbox key={value} disabled />
        );
      },
    },
    {
      title: t('pages.users.newUser.userPermissions.create'),
      dataIndex: ['CREATE'],
      key: 'create',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        const userPermissions = selectedUserPermissions as IUserPermission[];

        const permission = userPermissions?.find(
          userPermission => userPermission.module === rowData.module
        );

        const checkboxChecked = permission
          ? isReadChecked(permission.module)
          : false;

        return value ? (
          <Form.Item
            name={value}
            initialValue={value}
            noStyle
            valuePropName={checkboxChecked ? '' : 'checked'}
          >
            <Checkbox checked={!checkboxChecked} disabled={checkboxChecked} />
          </Form.Item>
        ) : (
          <Checkbox disabled />
        );
      },
    },
    {
      title: t('pages.users.newUser.userPermissions.delete'),
      dataIndex: ['DELETE'],
      key: 'delete',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        const userPermissions = selectedUserPermissions as IUserPermission[];

        const permission = userPermissions?.find(
          userPermission => userPermission.module === rowData.module
        );

        const checkboxChecked = permission
          ? isReadChecked(permission.module)
          : false;

        return value ? (
          <Form.Item
            name={value}
            initialValue={value}
            noStyle
            valuePropName={checkboxChecked ? '' : 'checked'}
          >
            <Checkbox
              key={value}
              checked={!checkboxChecked}
              disabled={checkboxChecked}
            />
          </Form.Item>
        ) : (
          <Checkbox key={value} disabled />
        );
      },
    },
  ];
  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 3 };

  const getInitialPermissions = () => {
    if (initialUserPermissions) {
      const permissions = initialUserPermissions.map(item => {
        const userPermissions = Object.getOwnPropertyNames(item).filter(
          key => key !== 'module'
        );
        const teste: string[] = userPermissions
          ?.map(userPermission =>
            item[userPermission].hasPermission ? item[userPermission].id : ''
          )
          .filter(item => item);

        return teste;
      });
      const formattedPermissions = permissions?.flat();
      const initialPermissions = formattedPermissions?.filter((item, index) => {
        if (item && formattedPermissions?.indexOf(item) === index) return item;
      });

      return initialPermissions;
    } else {
      return [];
    }
  };
  const getFinalPermissions = () => {
    const checkboxValues = formCheckbox.getFieldsValue();
    const finalPermissions = Object.keys(checkboxValues).filter(
      id => checkboxValues[id]
    );
    return finalPermissions;
  };

  const getAddedDeletedItems = (
    initialPermissions: string[],
    finalPermissions: string[]
  ) => {
    const addedItems = finalPermissions.filter(item => {
      if (initialPermissions?.indexOf(item) === -1) return item;
    });
    const deletedItems = initialPermissions?.filter(item => {
      if (finalPermissions.indexOf(item) === -1) return item;
    });

    return { addedItems, deletedItems };
  };

  const errorToActivate = (err: { message: string; description: string }) => {
    message.error({
      content: t(err.message),
      style: {
        marginTop: 60,
      },
    });
  };

  const onFinish = (values: any) => {
    if (userData && company) {
      const initialPermissions = getInitialPermissions();
      const finalPermissions = getFinalPermissions();
      const { addedItems, deletedItems } = getAddedDeletedItems(
        initialPermissions,
        finalPermissions
      );

      const allPermissions = selectedUserPermissions as IUserPermissionUpdate[];

      deletedItems.map(deletedItem => {
        const isDeletingReadPermissions = allPermissions.find(
          permission => permission.READ.id === deletedItem
        );

        if (isDeletingReadPermissions) {
          if (isDeletingReadPermissions.CREATE.hasPermission) {
            const createId = isDeletingReadPermissions.CREATE.id;
            if (!deletedItems.includes(createId)) deletedItems.push(createId);
          }
          if (!isDeletingReadPermissions.CREATE.hasPermission) {
            const createId = isDeletingReadPermissions.CREATE.id;
            const isAddingWritePermissionAndDeletingRead = addedItems.find(
              item => item === createId
            );
            if (isAddingWritePermissionAndDeletingRead) {
              const createIdPos = addedItems.indexOf(createId);
              addedItems.splice(createIdPos, 1);
            }
          }

          if (isDeletingReadPermissions.UPDATE.hasPermission) {
            const updateId = isDeletingReadPermissions.UPDATE.id;
            if (!deletedItems.includes(updateId)) deletedItems.push(updateId);
          }
          if (!isDeletingReadPermissions.UPDATE.hasPermission) {
            const updateId = isDeletingReadPermissions.UPDATE.id;
            const isAddingWritePermissionAndDeletingRead = addedItems.find(
              item => item === updateId
            );
            if (isAddingWritePermissionAndDeletingRead) {
              const updateIdPos = addedItems.indexOf(updateId);
              addedItems.splice(updateIdPos, 1);
            }
          }

          if (isDeletingReadPermissions.DELETE.hasPermission) {
            const deleteId = isDeletingReadPermissions.DELETE.id;
            if (!deletedItems.includes(deleteId)) deletedItems.push(deleteId);
          }
          if (!isDeletingReadPermissions.DELETE.hasPermission) {
            const deleteId = isDeletingReadPermissions.DELETE.id;
            const isAddingWritePermissionAndDeletingRead = addedItems.find(
              item => item === deleteId
            );
            if (isAddingWritePermissionAndDeletingRead) {
              const deleteIdPos = addedItems.indexOf(deleteId);
              addedItems.splice(deleteIdPos, 1);
            }
          }
        }
      });

      values.permissions = {
        add: addedItems,
        delete: deletedItems,
      };

      const allowUserInput = {
        email: values.email,
        organizationId: values.organizationId,
        permissions: values.permissions,
        userId: data.id,
        userType: values.typeId,
      };

      allowUserAccess(allowUserInput)
        .then(() => {
          onConfirm();
          setViewNotification(true);
          form.resetFields();
          formCheckbox.resetFields();
          setSelectedUserPermissions(undefined);
        })
        .catch(err => {
          errorToActivate(err);
        });
    }
  };

  const footerModal = [
    <Button
      key="back"
      danger
      onClick={() => {
        onClose();
        form.resetFields();
        formCheckbox.resetFields();
        setSelectedUserPermissions(undefined);
      }}
    >
      {t('pages.users.updateUser.cancelButton')}
    </Button>,
    <Button
      key="confirm"
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
      }}
    >
      {t('pages.users.updateUser.confirmButton')}
    </Button>,
  ];

  const formRegularizeUser = (
    <>
      <Form name="basic" layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={[24, 0]}>
          <Col {...colSize}>
            <Form.Item
              label={t('pages.users.updateUser.typeId')}
              name="typeId"
              rules={[
                {
                  required: true,
                  message: t('pages.users.updateUser.rmTypeId'),
                },
              ]}
            >
              <Select
                size="small"
                placeholder={t('pages.users.newUser.phUserType')}
                onChange={item => {
                  const tmpObjPermission: any = userPermissions?.find(
                    permissions => permissions.type === item
                  );
                  if (tmpObjPermission) {
                    let props = Object.getOwnPropertyNames(tmpObjPermission);

                    props = props.filter(item => item !== 'type');

                    const permissions: IUserPermissions[] = [];
                    props.map(item => {
                      permissions.push(tmpObjPermission[item]);
                    });

                    setSelectedUserPermissions(permissions);
                  }
                }}
              >
                {userPermissions
                  ?.filter(item => item)
                  .map(item => (
                    <Option value={item.type} key={item.type}>
                      {item.type}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col {...colSize}>
            <Form.Item
              label={t('pages.users.updateUser.email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('pages.users.updateUser.rmEmail'),
                },
                {
                  type: 'email',
                  message: t('pages.editCompany.rmEmail'),
                },
              ]}
            >
              <Input
                size="small"
                placeholder={t('pages.users.updateUser.phEmail')}
              />
            </Form.Item>
          </Col>

          <Col {...colSize}>
            <Form.Item
              label={t('pages.users.updateUser.userCompany')}
              name="organizationId"
              rules={[
                {
                  required: true,
                  message: t('pages.users.updateUser.rmUserCompany'),
                },
              ]}
            >
              <Select
                size="small"
                placeholder={t('pages.users.updateUser.phUserCompany')}
              >
                {organizations?.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Alert
        style={{ marginBottom: 24 }}
        message={t('pages.users.errors.hasNoReadPermission')}
        type="warning"
      />
      <Form form={formCheckbox}>
        <Table
          columns={columnsUsers}
          dataSource={selectedUserPermissions}
          loading={isUserPermissionRequesting}
          size="small"
          pagination={false}
        />
      </Form>
    </>
  );
  const regularizeModalContent = () => {
    return (
      <>
        <Text>
          {t('pages.activate.feedbackModal.contentText.partOne')}{' '}
          <b>{data?.name}</b>{' '}
          {t('pages.activate.feedbackModal.contentText.partTwo')}
          <Tag color="green">{RegStatusEnum.ACTIVATED}</Tag>
        </Text>
      </>
    );
  };

  return (
    <>
      <Modal
        width={'70%'}
        title="Regularize seus clientes!"
        open={visible}
        onCancel={() => {
          setSelectedUserPermissions(undefined);
          form.resetFields();
          formCheckbox.resetFields();
          onClose();
        }}
        footer={footerModal}
      >
        {formRegularizeUser}
      </Modal>
      <CustomerNotified
        title={t('pages.activate.feedbackModal.title')}
        visible={viewNotification}
        onClose={() => setViewNotification(false)}
        onConfirm={() => setViewNotification(false)}
        content={regularizeModalContent}
      />
    </>
  );
};

export default withTranslation()(RegisterNewUser);
