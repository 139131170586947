import { createClient, dedupExchange, debugExchange, Exchange } from 'urql';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';

import User from '../../classes/User';

const getBaseURL = () => {
  const { REACT_APP_API_URL } = process.env;

  const baseURL = REACT_APP_API_URL;

  return baseURL;
};

const getDefaultRequestHeaders = () => {
  const token = User.getToken();
  const crop = User.getCrop();
  const organization = User.getOrganization();

  const headers: Record<string, string> = {
    'Access-Control-Request-Header': 'Authorization',
    'x-origin-request': 'Manager-panel',
    'request-source': 'panel',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  if (crop) {
    headers['Safra'] = crop;
  }
  if (organization) {
    headers['Organization'] = organization;
  }
  return headers;
};

const getDefaultExchanges = () => {
  const defaultExchanges = [dedupExchange, multipartFetchExchange];

  if (process.env.NODE_ENV === 'development') {
    defaultExchanges.push(debugExchange);
  }

  return defaultExchanges;
};

const client = createClient({
  url: getBaseURL() ?? '',
  fetchOptions: () => {
    const headers = getDefaultRequestHeaders();

    return {
      headers,
    };
  },
  exchanges: [...getDefaultExchanges()] as Exchange[],
});

export default client;
