import moment, { Moment } from 'moment';
import { IContItemsResponse } from '../../../../structures/interfaces/TruckLoad';
import { ICultivationType } from './stepTwo/StepTwo.filter';
import { FormInstance } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { ItemsToAddType } from './stepTwo/StepTwo.contractItemsTable';

export const colors = [
  '#FF4500',
  '#FFA07A',
  '#00FF00',
  '#FF1493',
  '#CD5C5C',
  '#8A2BE2',
  '#FFFF00',
  '#3CB371',
  '#00BFFF',
  '#4169E1',
  '#DA70D6',
  '#0000FF',
  '#D8BFD8',
  '#66CDAA',
  '#A52A2A',
  '#2F4F4F',
];

export const handleCalendarScroll = (): void => {
  const calendarElement = document.getElementById('calendar');
  if (calendarElement) {
    calendarElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};
export const isColorLight = (color?: string): boolean => {
  if (!color) {
    return false;
  }
  // Remove "#" se estiver presente
  if (color.startsWith('#')) {
    color = color.slice(1);
  }

  // Converte a cor hex para RGB
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  // Calcula a luminosidade
  const luminosity = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Verifica se a cor é clara ou escura
  return luminosity > 128; // true se claro, false se escuro
};
export const removeDuplicates = (
  data: IContItemsResponse[] | undefined
): IContItemsResponse[] | undefined => {
  const seen = new Set<string>(); // Set para armazenar combinações únicas

  // Filtra e remove duplicatas
  return data?.filter(item => {
    const combination = `${item?.contract?.referenceNumber}-${item?.cultivation}`;

    // Se a combinação já existe, é duplicado
    if (seen.has(combination)) {
      return false;
    }

    // Marca a combinação como já vista
    seen.add(combination);
    return true;
  });
};
export const removeDuplicatesContractItems = (
  data: IContItemsResponse[]
): IContItemsResponse[] => {
  const seen = new Set<string>(); // Set para armazenar combinações únicas
  // Filtra e remove duplicatas
  return data.filter(item => {
    const combination = `${item?.contract?.referenceNumber}-${item?.cultivation}`;
    // Se a combinação já existe, é duplicado
    if (seen.has(combination)) {
      return false;
    }

    // Marca a combinação como já vista
    seen.add(combination);
    return true;
  });
};
export const removeDuplicatesCultivation = (
  data: ICultivationType[]
): ICultivationType[] => {
  const seen = new Set<string>(); // Set para armazenar combinações únicas
  // Filtra e remove duplicatas
  return data.filter(item => {
    const combination = `${item.cultivation}-${item.value}`;
    // Se a combinação já existe, é duplicado
    if (seen.has(combination)) {
      return false;
    }

    // Marca a combinação como já vista
    seen.add(combination);
    return true;
  });
};

// FILTER FUNCTIONS

export const BuildSlug = (
  contractItems: IContItemsResponse[]
): IContItemsResponse[] =>
  contractItems.map(item => ({
    ...item,
    slug: `${item.contract.farm.name} ${item.contract.farm.stateRegistration} ${item.contract.contractDate} ${item.cultivation} ${item.sieve} ${item.category} ${item.packing} ${item.tsi} ${item.fullAmount} ${item.remainingAmount} ${item?.customerCapacity?.remainingAmount}`
      .toLowerCase()
      .trim(),
    contractSlug: `${item.contract.referenceNumber}`.toLowerCase().trim(),
  }));

export const FilterContractItemsByCultivation = (
  contractItems: IContItemsResponse[],
  cultivation?: string
): IContItemsResponse[] =>
  cultivation != undefined
    ? contractItems.filter(item => item.cultivation === cultivation)
    : contractItems;

export const FilterContractsByDate = (
  contractItems: IContItemsResponse[],
  date?: Moment[]
): IContItemsResponse[] =>
  date != undefined
    ? contractItems.filter(({ contract: { contractDate } }) => {
        return moment(contractDate).isBetween(date[0], date[1], 'day', '[]');
      })
    : contractItems;

interface ICultivationColorData {
  index: number;
  cultivation: Record<string, { color: string }>;
}
export const GetCultivationColor = (cultivation: string): string => {
  const cultivationColorKey = 'cultivationColor';
  const cultivationColorDefaultValue = { index: 0, cultivation: {} };
  const cultivationColorValue = localStorage.getItem(cultivationColorKey);
  try {
    const cultivationColorData: ICultivationColorData = cultivationColorValue
      ? JSON.parse(cultivationColorValue)
      : cultivationColorDefaultValue;

    const cultivationColor = cultivationColorData.cultivation[cultivation];

    if (cultivationColor) {
      return cultivationColor.color;
    } else {
      const color = colors[cultivationColorData.index++ % colors.length];
      cultivationColorData.cultivation[cultivation] = {
        color,
      };
      localStorage.setItem(
        cultivationColorKey,
        JSON.stringify(cultivationColorData)
      );
      return color;
    }
  } catch (err) {
    localStorage.setItem(
      cultivationColorKey,
      JSON.stringify(cultivationColorDefaultValue)
    );
    return GetCultivationColor(cultivation);
  }
};

// UPDATE FUNCTIONS

export interface IHandleLoadAddition {
  unity: string;
  itemsToAdd: ItemsToAddType;
  setAddedItems: Dispatch<SetStateAction<IContItemsResponse[]>>;
  setItemsToAdd: Dispatch<SetStateAction<ItemsToAddType>>;
  setContractItems: Dispatch<SetStateAction<IContItemsResponse[]>>;
  setFilterContItems: Dispatch<SetStateAction<IContItemsResponse[]>>;
  contractItems: IContItemsResponse[];
  addedItems: IContItemsResponse[];
  contractItemsForm: FormInstance;
}
export const handleLoadAddition = ({
  unity,
  itemsToAdd,
  setAddedItems,
  addedItems,
  contractItemsForm,
  setItemsToAdd,
  contractItems,
  setContractItems,
  setFilterContItems,
}: IHandleLoadAddition): void => {
  let newLoads: IContItemsResponse[] = [];
  if (unity === 'BAG') {
    newLoads = Object.values(itemsToAdd).flatMap(item => {
      const formattedItem = {
        ...item,
        index: item.index,
        allocatedAmount: item.value,
      };
      const addedItemsRows = Array(item.value).fill(formattedItem);
      return addedItemsRows;
    });
  } else {
    newLoads = Object.values(itemsToAdd).flatMap(item => {
      const formattedItem = {
        ...item.data,
        index: item.index,
        allocatedAmount: item.value,
      };
      return formattedItem;
    });
  }

  setAddedItems([...addedItems, ...newLoads]);
  contractItemsForm.resetFields();

  const updatedContractItems = [...contractItems];
  newLoads.forEach(item => {
    if (item.index !== undefined && item.allocatedAmount) {
      updatedContractItems[item.index].remainingAmount -= 1;
    }
  });

  setContractItems(updatedContractItems);
  setFilterContItems(updatedContractItems);
  setItemsToAdd({});
};

export interface IHandleDeleteAddition {
  selectedItemId: string;
  allocatedAmount: number;
  setAddedItems: Dispatch<SetStateAction<IContItemsResponse[]>>;
  setContractItems: Dispatch<SetStateAction<IContItemsResponse[]>>;
  contractItems: IContItemsResponse[];
  contractItemsForm: FormInstance;
}
export const handleRemoveLoad = ({
  setAddedItems,
  contractItemsForm,
  contractItems,
  setContractItems,
  selectedItemId,
  allocatedAmount,
}: IHandleDeleteAddition): void => {
  setAddedItems(data => data.filter(item => item.id !== selectedItemId));
  contractItemsForm.resetFields();

  const aux = contractItems.find(item => item.id === selectedItemId);
  if (aux) {
    aux.remainingAmount += allocatedAmount;
  }

  setContractItems([...contractItems]);
};
