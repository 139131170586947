import { ReactElement, ReactNode } from 'react';

import { Col, Row } from 'antd';

import LogmetricsLogo from '../../LogmetricsLogo';

interface IAuthContainerProps {
  className?: string;
  children: ReactNode;
  logoColumnSize?: 'middle' | 'small';
}

import './index.scss';

const AuthContainer = ({
  children,
  className,
  logoColumnSize = 'middle',
}: IAuthContainerProps): ReactElement => {
  const logoCol = (
    <Col
      className={`logo-col${
        logoColumnSize === 'small' ? ' logo-col-small' : ''
      }`}
    >
      <div className="logo-container">
        <LogmetricsLogo />
      </div>
    </Col>
  );

  return (
    <Row className={`auth-container${className ? ` ${className}` : ''}`}>
      {logoCol}
      <Col className="form-col">{children}</Col>
    </Row>
  );
};

export default AuthContainer;
