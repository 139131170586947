import {
  LogoutOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';

import userImg from '../../assets/user_icon.svg';

import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { clearStorage } from '../../utils';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import UserController from '../../structures/controllers/User';
import { IGetUserResponse } from '../../structures/interfaces/User';
import { IGetCustomer } from '../../structures/interfaces/Clients';
import ClientsController from '../../structures/controllers/Clients';

import './avatarDropDown.scss';

const AvatarMenu = (): ReactElement => {
  const [userRequesting] = useRequest(UserController.getUser);
  const [customerRequesting] = useRequest(ClientsController.getCustomer);
  const [user, setUser] = useState<IGetUserResponse>();
  const [customer, setCustomer] = useState<IGetCustomer>();

  const { userData, customerData, userName, customerName } =
    useContext(UserContext);

  useEffect(() => {
    if (customerData) {
      customerRequesting(customerData.id).then(cstData => {
        setCustomer(cstData);
      });
    } else if (userData) {
      userRequesting(userData.id).then(usrData => {
        setUser(usrData);
      });
    }
  }, [customerData, userData]);

  const handleMenuClick = () => {
    document.location.reload();
    clearStorage();
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/conta">
          <UserOutlined /> {t('headerMenu.myAccount')}
        </Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleMenuClick}>
        <LogoutOutlined /> {t('headerMenu.logOut')}
      </Menu.Item>
    </Menu>
  );

  const name = customer
    ? customerName || customer.name || customer.tradeName
    : userName || user?.name;

  return (
    <div
      className="avatar_dropdown-container"
      style={{
        marginLeft: 'auto',
        marginRight: '10px',
        display: 'flex',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#fff',
      }}
    >
      <Link
        to={'/tutoriais'}
        style={{ marginRight: 15, display: 'flex', justifyContent: 'center' }}
      >
        <Tooltip title={t('headerMenu.help.tutorialPage')}>
          <QuestionCircleOutlined style={{ color: '#FFFF', fontSize: 20 }} />
        </Tooltip>
      </Link>
      <Dropdown overlay={menu} trigger={['click']}>
        <div style={{ cursor: 'pointer', display: 'flex' }}>
          <img className="default_img" src={userImg} />
          <span
            className="avatar_username"
            style={{
              paddingLeft: 15,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {name}
          </span>
        </div>
      </Dropdown>
    </div>
  );
};

export default AvatarMenu;
