import { ReactElement } from 'react';

import ptBR from 'antd/lib/locale/pt_BR';

import { ConfigProvider } from 'antd';

import Providers from './providers';
import Routes from './routes';

function App(): ReactElement {
  return (
    <ConfigProvider locale={ptBR}>
      <Providers>
        <Routes />
      </Providers>
    </ConfigProvider>
  );
}

export default App;
