import { Col, Layout, message, PageHeader, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ContractsFilter from './ContractsPage.filter';
import ContractsTable from './ContractsPage.table';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { IGetContract } from '../../structures/interfaces/Contract';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ContractController from '../../structures/controllers/Contract';
import { BuildSlug } from './Contracts.utils';
import ViewContract from './ViewContract';

const ContractsPage = (): ReactElement => {
  const { t } = useTranslation();
  const { userData, customerData } = useContext(UserContext);

  const [fetchUbsContracts, isLoadingUbs] = useRequest(
    ContractController.getContractsById
  );
  const [fetchCustomerContracts, isLoadingCustomer] = useRequest(
    ContractController.getCustomerContracts
  );

  const [contracts, setContracts] = useState<IGetContract[]>([]);
  const [filterContracts, setFilterContracts] = useState<IGetContract[]>([]);
  const [viewContracts, setViewContracts] = useState(false);
  const [contractToView, setContractToView] = useState<IGetContract>();

  const pageHeader = (
    <PageHeader
      title={t('pages.contracts.title')}
      subTitle={t('pages.contracts.subtitle')}
      ghost={false}
    />
  );

  useEffect(() => {
    if (customerData) {
      fetchCustomerContracts(customerData.id).then().catch();
    } else if (userData) {
      const orgId = userData.organization.id;
      fetchUbsContracts({
        organization_id: orgId,
        subordinates_filter: null,
      })
        .then(res => {
          const data = BuildSlug(res);
          setContracts(data);
          setFilterContracts(data);
        })
        .catch(() => message.error('Não foi possível buscar seus contratos'));
    }
  }, []);

  return (
    <Layout>
      {pageHeader}
      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <ContractsFilter
              contracts={contracts}
              setFilteredContracts={setFilterContracts}
            />
          </Col>

          <Col span={24}>
            <ContractsTable
              loading={isLoadingCustomer || isLoadingUbs}
              dataSource={filterContracts}
              setViewContracts={setViewContracts}
              setContractToView={setContractToView}
            />
          </Col>
        </Row>
      </Layout.Content>
      <ViewContract
        contractData={contractToView}
        open={viewContracts}
        onClose={() => setViewContracts(false)}
      />
    </Layout>
  );
};

export default ContractsPage;
