import {
  Card,
  Col,
  Layout,
  message,
  Modal,
  PageHeader,
  Row,
  Steps,
} from 'antd';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import BreadCrumb from '../../../structures/controllers/Breadcrumb';
import StepOne from './createLoadSteps/stepOne/StepOne';
import {
  IStepOneData,
  IStepTwoData,
} from '../../../structures/interfaces/TruckLoad';
import StepTwo from './createLoadSteps/stepTwo/StepTwo';
import { useRequest } from '../../../hooks/useRequest';
import TruckLoadsController from '../../../structures/controllers/TruckLoad';
import StepThree from './createLoadSteps/stepThree/StepThree';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../contexts/user';

interface IStepsControl {
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const next = ({ setCurrentStep }: IStepsControl) => {
  window.scrollTo(0, 0);
  setCurrentStep(currentStep => currentStep + 1);
};
const previous = ({ setCurrentStep }: IStepsControl) => {
  setCurrentStep(currentStep => currentStep - 1);
};

const timeoutModal = (t: TFunction, redirect: () => void) => {
  return (
    <Modal
      title={t('pages.truckLoads.createLoad.timeout')}
      closable={false}
      centered={true}
      onOk={redirect}
    />
  );
};
const CreateLoad = (): ReactElement => {
  const { t } = useTranslation();
  const { userData, customerData } = useContext(UserContext);

  const history = useHistory();

  const [cancelPreSchedule] = useRequest(
    TruckLoadsController.cancelPreSchedule
  );
  const [getPreScheduleByCreator] = useRequest(
    TruckLoadsController.getPreScheduleByCreator
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [stepOneData, setStepOneData] = useState<IStepOneData>();
  const [stepTwoData, setStepTwoData] = useState<IStepTwoData>();
  const [customOrder, setCustomOrder] = useState(false);
  const [createdTruckLoadId, setCreatedTruckLoadId] = useState('');

  const handlePreScheduleCancel = (id: string, isTimeout = false) => {
    cancelPreSchedule({ id: id })
      .then(() => {
        message.warning({ content: 'Pré agendamento cancelado' });
        setCreatedTruckLoadId('-1');
      })
      .catch();

    if (isTimeout) return history.push('/cargas');
  };

  const steps = [
    {
      title: t('pages.truckLoads.createLoad.steps.one'),
      content: (
        <StepOne
          nextStep={() => next({ setCurrentStep })}
          setStepOneData={(values: IStepOneData) => setStepOneData(values)}
        />
      ),
    },
    {
      title: t('pages.truckLoads.createLoad.steps.two'),
      content: (
        <StepTwo
          nextStep={() => next({ setCurrentStep })}
          stepOneData={stepOneData ? stepOneData : {}}
          cancelPreSchedule={handlePreScheduleCancel}
          setStepTwoData={setStepTwoData}
          isCustomOrdering={customOrder}
          setIsCustomOrdering={setCustomOrder}
        />
      ),
    },
    {
      title: t('pages.truckLoads.createLoad.steps.three'),
      content: (
        <StepThree
          nextStep={() => next({ setCurrentStep })}
          stepOneData={stepOneData ? stepOneData : {}}
          stepTwoData={stepTwoData ? stepTwoData : {}}
          previousStep={() => previous({ setCurrentStep })}
          customOrder={customOrder}
          setCreatedTruckLoadId={setCreatedTruckLoadId}
          cancelPreSchedule={handlePreScheduleCancel}
        />
      ),
    },
  ];

  const stepsCard = (
    <Card size="small">
      <Steps
        current={currentStep}
        style={{ maxWidth: 900, margin: '12px auto' }}
      >
        {steps.map(step => (
          <Steps.Step key={step.title} title={step.title} />
        ))}
      </Steps>
    </Card>
  );

  useEffect(() => {
    const preScheduleAuthor = userData ? userData.id : customerData?.id;
    if (preScheduleAuthor) {
      getPreScheduleByCreator({ creatorId: preScheduleAuthor })
        .then(values => {
          values.forEach(preSchedule =>
            handlePreScheduleCancel(preSchedule.id)
          );
        })
        .catch();
    }
  }, []);
  useEffect(() => {
    if (createdTruckLoadId && createdTruckLoadId !== '-1') {
      message.config({ top: 64 });
      message.success({
        content: 'Carga criada com sucesso!',
        duration: 5,
      });
      history.push('/cargas');
    }

    return () => {
      if (stepOneData && !createdTruckLoadId) {
        const pathname = window.location.pathname.split('/');
        const lastIndex = pathname.length - 1;
        if (pathname[lastIndex] !== 'formar-carga') {
          if (stepOneData?.cancel?.id) {
            handlePreScheduleCancel(stepOneData.cancel.id);
          }
        }
      }
    };
  }, [stepOneData, createdTruckLoadId]);

  return (
    <Layout>
      <PageHeader
        className="site-page-header"
        title={t('pages.truckLoads.createLoad.title')}
        subTitle={t('pages.truckLoads.createLoad.subtitle')}
        ghost={false}
        breadcrumb={<BreadCrumb />}
      />

      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>{stepsCard}</Col>

          <Col span={24}>{steps[currentStep].content}</Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default CreateLoad;
