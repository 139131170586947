import { Descriptions, Layout } from 'antd';
import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import { IGetClient } from '../../../structures/interfaces/Clients';

type IDescriptionProps = {
  name: string;
  email: string;
  documentNumberCpf: string | undefined;
  phoneNumber: string | undefined;
};
const ViewContact = ({ client }: { client?: IGetClient }): ReactElement => {
  const { t } = useTranslation();

  let descriptionData: IDescriptionProps | undefined;
  if (client) {
    const { name, tradeName } = client;

    descriptionData = {
      ...client,
      documentNumberCpf: Formatter.formatCPF(client.documentNumberCpf),
      phoneNumber: Formatter.formatPhoneWithoutDDI(client.phoneNumber),
      name: name || tradeName,
    };
  } else {
    descriptionData = {
      name: '---',
      documentNumberCpf: '---',
      email: '---',
      phoneNumber: '---',
    };
  }

  return (
    <Layout.Content>
      <Descriptions column={2}>
        <Descriptions.Item label={t('pages.clients.clientName')}>
          {descriptionData.name}
        </Descriptions.Item>

        <Descriptions.Item label={t('pages.clients.documentNumberCpf')}>
          {descriptionData.documentNumberCpf}
        </Descriptions.Item>

        <Descriptions.Item label={t('pages.clients.clientEmail')}>
          {descriptionData.email}
        </Descriptions.Item>

        <Descriptions.Item label={t('pages.clients.clientPhoneNumber')}>
          {descriptionData.phoneNumber}
        </Descriptions.Item>
      </Descriptions>
    </Layout.Content>
  );
};

export default ViewContact;
