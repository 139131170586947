import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import { TFunction } from 'i18next';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import UserController from '../../../structures/controllers/User';
import { IUpdateUserAccount } from '../../../structures/interfaces/User';
import { validatePhoneNumber } from '../../../utils/inputRules';

const { Title } = Typography;

const UpdateAccount = ({
  t,
  visible,
  onClose,
  data,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  data: IUpdateUserAccount;
}): ReactElement<unknown> => {
  const [error, setError] = useState<{
    message: string;
    type: 'error';
    description?: string;
  } | null>(null);

  const [updateUserRequest, isUpdateUserRequesting] = useRequest(
    UserController.updateUserAccount
  );
  const [updateCustomerRequest, isUpdateCustomerRequesting] = useRequest(
    ClientsController.updateCustomerAccount
  );

  const { userData, customerData, setUserName, setCustomerName } =
    useContext(UserContext);
  const [form] = Form.useForm();

  const info = () => {
    message.success({
      content: t('pages.account.updateAccount.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        phoneNumber: Formatter.formatCellphone(data.phoneNumber),
        email: data.email,
      });
    }
  }, [data]);

  const onFinish = (values: IUpdateUserAccount) => {
    values.phoneNumber = values.phoneNumber.replace(/[^0-9]/g, '');
    let changed = true;
    if (
      values.email === data.email &&
      values.name === data.name &&
      values.phoneNumber === data.phoneNumber
    ) {
      changed = false;
      info();
      onClose();
    }

    if (userData && data && changed) {
      updateUserRequest({
        user: values,
        id: data.id,
      })
        .then(value => {
          info();
          onClose();
          setUserName && setUserName(value.name);
        })
        .catch(({ message, description }) =>
          setError({
            message: t(message),
            description: t(description),
            type: 'error',
          })
        );
    } else if (customerData && data && changed) {
      updateCustomerRequest({
        customer: values,
        id: data.id,
      })
        .then(value => {
          info();
          onClose();
          if (!value.name && value.tradeName) {
            setCustomerName && setCustomerName(value.tradeName);
          }
          setCustomerName && setCustomerName(value.name);
        })
        .catch(({ message, description }) =>
          setError({
            message: t(message),
            description: t(description),
            type: 'error',
          })
        );
    }
  };

  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 6 };

  const formPickUp = (
    <Form name="basic" layout="vertical" onFinish={onFinish} form={form}>
      {error && (
        <Alert
          message={error.message}
          description={error.description}
          type={error.type}
          closable
          style={{ marginBottom: 24 }}
        />
      )}

      <Row gutter={[24, 0]}>
        <Col {...colSize}>
          <Form.Item
            label={t('pages.account.updateAccount.name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('pages.account.updateAccount.rmName'),
              },
            ]}
          >
            <Input placeholder={t('pages.account.updateAccount.phName')} />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.account.updateAccount.phoneNumber')}
            normalize={Formatter.formatCellphone}
            name="phoneNumber"
            validateFirst
            rules={[
              {
                required: true,
                message: t('pages.account.updateAccount.rmPhoneNumber'),
              },
              {
                ...validatePhoneNumber,
                message: t(validatePhoneNumber.message),
              },
            ]}
          >
            <Input
              placeholder={t('pages.account.updateAccount.phPhoneNumber')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.account.updateAccount.email')}
            name="email"
            rules={[
              {
                required: true,
                message: t('pages.account.updateAccount.rmEmail'),
              },
            ]}
          >
            <Input placeholder={t('pages.account.updateAccount.phEmail')} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const footerModal = [
    <Button key="back" danger onClick={onClose}>
      {t('pages.account.updateAccount.cancelButton')}
    </Button>,
    <Button
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
        setError(null);
      }}
      loading={userData ? isUpdateUserRequesting : isUpdateCustomerRequesting}
    >
      {t('pages.account.updateAccount.confirmButton')}
    </Button>,
  ];

  return (
    <Modal width={'70%'} open={visible} onCancel={onClose} footer={footerModal}>
      <Title level={5}>{t('pages.account.updateAccount.title')}</Title>
      {formPickUp}
    </Modal>
  );
};

export default withTranslation()(UpdateAccount);
