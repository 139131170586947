import { Alert, Col, Layout, PageHeader, Row } from 'antd';
import { ReactElement } from 'react';
import ToleranceForm from './RuleSettingsPage.tolerance';
import TreatmentForm from './RuleSettingsPage.treatment';

const explanationMessageAlert = (
  <div>
    <p>
      A regra de tolerância para agendamento de cargas permite que o cliente
      ultrapasse seu limite diário de agendamento caso este tenha separado todos
      os seus itens para o respectivo dia e ainda exista uma quantidade X para
      preencher a capacidade do veículo selecionado. Para isso o cliente deve
      ter itens disponíveis para retirada em seu contrato e a sementeira deve
      ter itens disponíveis para retirada no mesmo dia
    </p>
    <p>
      Exemplo <br />
      João tinha 50 bags de limite restante para o dia, o caminhão selecionado
      tinha capacidade de 64 bags, ele separou suas 50 bags disponíveis e
      esgotou seu limite diário, porém ainda haviam bags para retirada em seu
      contrato e a sementeira possuía bags disponíveis naquele dia, logo João
      pôde retirar 64 bags de seu contrato pois o seu limite restante diário
      contemplava mais que 70% da capacidade do caminhão.
    </p>
  </div>
);
const treatmentMessageAlert =
  'Período de carência (em dias) para sementes com e sem tratamento de TSI. Estes valores serão utilizados para preencher automaticamente os campos de carência durante a criação de novas regras de agendamento.';

const toleranceAlert = (
  <Col span={24}>
    <Alert message={'Funcionamento'} description={explanationMessageAlert} />
  </Col>
);
const treatmentAlert = (
  <Col span={24}>
    <Alert message={'Funcionamento'} description={treatmentMessageAlert} />
  </Col>
);
const RuleSettingsPage = (): ReactElement => {
  return (
    <Layout>
      <PageHeader
        ghost={false}
        title="Configurações de regras"
        subTitle="Defina padrões para suas regras de agendamento"
      />
      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[24, 24]}>
          {toleranceAlert}
          <Col span={24}>
            <ToleranceForm />
          </Col>

          {treatmentAlert}
          <Col span={24}>
            <TreatmentForm />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default RuleSettingsPage;
