import { ReactElement } from 'react';

import { TFunction, withTranslation } from 'react-i18next';

import logmetricsLogoxSvg from '../../assets/images/logmetrics-logo.svg';

interface LogmetricsLogoProps {
  t: TFunction;
  alt?: string;
  className?: string;
  style?: React.ImgHTMLAttributes<HTMLImageElement>;
}

const LogmetricsLogo = ({
  t,
  alt,
  className,
  style,
}: LogmetricsLogoProps): ReactElement => {
  const defaultStyle: React.ImgHTMLAttributes<HTMLImageElement> = {
    width: '100%',
    height: 'auto',
  };

  return (
    <picture className="logmetrics-logo_container">
      <source srcSet={`${logmetricsLogoxSvg} 1x`} type="image/svg" />
      <img
        src={logmetricsLogoxSvg}
        alt={alt || t('general.logoAlt')}
        className={className || 'logmetrics-logo'}
        style={style ?? defaultStyle}
      />
    </picture>
  );
};

export default withTranslation()(LogmetricsLogo);
