import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Modal } from 'antd';
import { ReactElement, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { getDataFromStorage, setDataToStorage } from '../../utils';

interface ICustomerNotified {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  content: () => JSX.Element;
  title: string;
}

const customerNotified = ({
  t,
  visible,
  onClose,
  onConfirm,
  title,
  content,
}: ICustomerNotified): ReactElement<unknown> => {
  const [notSeeAgain, setNotSeeAgain] = useState(false);
  const sessionInfo = getDataFromStorage('notSeeAgain', true);

  const footer = () => [
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Checkbox
        checked={notSeeAgain}
        onChange={value => setNotSeeAgain(value.target.checked)}
      >
        {t('pages.activate.notification.notShowMore')}
      </Checkbox>
      <Button
        type="primary"
        onClick={() => {
          onConfirm();
          setDataToStorage('notSeeAgain', notSeeAgain, true);
        }}
      >
        {t('pages.activate.notification.okButton')}
      </Button>
    </div>,
  ];
  const titleContent = () => (
    <>
      <CheckCircleTwoTone style={{ marginRight: 12 }} twoToneColor="#52c41a" />{' '}
      {title}
    </>
  );

  return (
    <>
      <Modal
        title={titleContent()}
        open={sessionInfo ? false : visible}
        footer={footer()}
        onCancel={onClose}
      >
        {content()}
      </Modal>
    </>
  );
};

export default withTranslation()(customerNotified);
