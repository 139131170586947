import React, { ReactElement } from 'react';

import { Layout } from 'antd';

import menuLogo from '../../assets/logotipo.svg';
import { layoutStyles } from '../../utils/constants';
import TranslateMenu from '../layout/translateDropDown';

const { Content, Header } = Layout;

/**
 * @description Panel Main layout (Sidebar and header).
 * @property {Node} children Main layout children (content)
 * @example
 * <FirstAccessLayout>
 *   {children}
 * </FirstAccessLayout>
 */

const FirstAccessLayout = ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement => {
  const { backgroundColor: layoutColor } = layoutStyles;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <Header
          className="layout-header"
          style={{
            position: 'fixed',
            width: '100%',
            background: layoutColor,
            textAlign: 'center',
            alignItems: 'center',
            zIndex: 1051,
            justifyContent: 'end',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              paddingRight: '24px',
            }}
          >
            <img src={menuLogo} alt="" />
            <TranslateMenu />
          </div>
        </Header>
        <Content
          style={{
            minHeight: 'calc(100% - 65px)',
            margin: '60px 0 0',
            width: '100%',
            paddingTop: '4px',
            transition: '0.2s all',
            animationTimingFunction: 'ease-out',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default FirstAccessLayout;
