import { Button, message, Modal, Typography } from 'antd';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { useRequest } from '../../../../hooks/useRequest';
import ScheduleRulesController from '../../../../structures/controllers/ScheduleRules';
import {
  ICreateCustomerRule,
  IEditCustomerRule,
  IFindRules,
} from '../../../../structures/interfaces/ScheduleRules';

export interface IConfirmEditionProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  selectedRule?: IFindRules | undefined;
  selectedCustomerRule: IEditCustomerRule | undefined;
  setCustomerRules: Dispatch<SetStateAction<ICreateCustomerRule[]>>;
}

const { Title } = Typography;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const DeleteCustomerRule = ({
  visible,
  onCancel,
  selectedRule,
  selectedCustomerRule,
  onOk,
  setCustomerRules,
}: IConfirmEditionProps) => {
  const [deleteCustomerRuleRequest] = useRequest(
    ScheduleRulesController.deleteCustomerRule
  );

  const editFooter = [
    <Button key="back" danger onClick={onCancel}>
      {t('pages.settings.deleteBranchCompany.cancelButton')}
    </Button>,
    <Button
      type="primary"
      htmlType="submit"
      onClick={() => {
        onOk();
        if (selectedCustomerRule) deleteCustomerRule(selectedCustomerRule);
      }}
    >
      {t('pages.settings.deleteBranchCompany.confirmButton')}
    </Button>,
  ];

  const deleteCustomerRuleError = () => {
    message.error({
      content: t('pages.settings.deletePickUpLocation.errors.message'),
      className: 'custom-class',
      style: {
        marginTop: 50,
      },
    });
  };

  const deleteCustomerRuleSuccess = () => {
    message.success({
      content: t('pages.scheduleRules.editRules.feedbacks.deletedCustomerRule'),
      className: 'custom-class',
      style: {
        marginTop: 50,
      },
    });
  };

  const deleteCustomerRule = (selectedCustomerRule: ICreateCustomerRule) => {
    const existCustomerRule = selectedRule?.customerRules.some(
      cr => cr.id === selectedCustomerRule.id
    );
    if (existCustomerRule) {
      deleteCustomerRuleRequest({ customerRuleId: selectedCustomerRule.id })
        .then(() => {
          setCustomerRules(customerData => [
            ...customerData.filter(
              customer => customer.id !== selectedCustomerRule.id
            ),
          ]);
          deleteCustomerRuleSuccess();
        })
        .catch(() => deleteCustomerRuleError());
    } else {
      setCustomerRules(customerData => [
        ...customerData.filter(
          customer => customer.id !== selectedCustomerRule.id
        ),
      ]);
    }
  };

  return (
    <>
      <Modal onCancel={onCancel} open={visible} footer={editFooter}>
        <Title level={5}>
          {t('pages.scheduleRules.customerRuleModals.delete.title')}
        </Title>
        <p>
          {t('pages.scheduleRules.customerRuleModals.delete.confirmDeletion')}
        </p>
        <p>
          {t(
            'pages.scheduleRules.customerRuleModals.delete.warningOfActionDelete'
          )}
        </p>
      </Modal>
    </>
  );
};

export default DeleteCustomerRule;
