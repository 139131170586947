import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';

interface IBreadCrumb {
  lastName?: string;
  penultimateName?: string;
}

// Regular expression to check if string is a valid UUID
const regexExp =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

const BreadCrumb = ({ lastName, penultimateName }: IBreadCrumb) => {
  const location = useLocation();
  const breadcrumbView = () => {
    const { pathname } = location;
    const pathnames = pathname.split('/').filter(item => item);
    const capatilize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
    return (
      <div>
        <Breadcrumb>
          {pathnames.map((name, index) => {
            let sanitizedName = name.replace(/\W/g, ' ');
            const isValidUUID = regexExp.test(name);
            if (penultimateName) {
              isValidUUID ? (sanitizedName = penultimateName) : sanitizedName;
            }
            const routesTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
              <Breadcrumb.Item key={sanitizedName}>
                {capatilize(lastName || sanitizedName)}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item key={sanitizedName}>
                <Link to={`${routesTo}`}>{capatilize(sanitizedName)}</Link>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </div>
    );
  };
  return <>{breadcrumbView()}</>;
};

export default BreadCrumb;
