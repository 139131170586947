import {
  ApartmentOutlined,
  AuditOutlined,
  CarOutlined,
  FileSyncOutlined,
  FormOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  PhoneOutlined,
  SettingOutlined,
  ShopOutlined,
  SolutionOutlined,
  TeamOutlined,
  TruckOutlined,
} from '@ant-design/icons';
import { Image, Layout, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { CSSProperties, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import menuLogo from '../../../assets/logotipo.svg';
import smallLogo from '../../../assets/small-logotipo.svg';
import LayoutContext from '../../../contexts/layout';
import UserContext from '../../../contexts/user';
import { IMenuKey } from '../../../utils/menuOptions';

interface ISidebarProps {
  collapsed: boolean;
  layoutColor?: string;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  menuKey: IMenuKey;
}
const siderStyle = (layoutColor?: string): CSSProperties => ({
  position: 'fixed',
  height: '100%',
  zIndex: 1032,
  background: layoutColor,
});
const collapsedButtonStyle: CSSProperties = {
  color: '#FFFF',
  fontSize: 18,
  position: 'absolute',
  bottom: 20,
  left: 24,
};

const { Sider } = Layout;

const Sidebar = ({
  menuKey,
  collapsed,
  setCollapsed,
  layoutColor,
}: ISidebarProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { organizationId, getPermissions, customerData } =
    useContext(UserContext);
  const { setSelectedMenu } = useContext(LayoutContext);

  const customerChildren: ItemType[] = [
    {
      label: 'Fazendas',
      key: 'farms',
      icon: <ShopOutlined />,
      onClick: () => history.push('/clientes/fazendas'),
    },
    {
      label: 'Contatos',
      key: 'customers',
      icon: <PhoneOutlined />,
      onClick: () => history.push('/clientes/contatos'),
    },
  ];
  const settingsChildren: ItemType[] = [
    {
      label: t('sidebar.options.myCompany'),
      key: 'MINHA_EMPRESA',
      icon: <ShopOutlined />,
      onClick: () => history.push(`/configuracoes/empresas/${organizationId}`),
    },
    {
      label: 'Transportadoras',
      key: 'shipping_company',
      icon: <ShopOutlined />,
      onClick: () => history.push('/configuracoes/transportadoras'),
    },
    {
      label: 'Regras',
      key: 'rule_settings',
      icon: <FormOutlined />,
      onClick: () => history.push('/configuracoes/regras'),
    },
    {
      label: 'Veículos',
      key: 'vehicles',
      icon: <CarOutlined />,
      onClick: () => history.push('/configuracoes/veiculos'),
    },
  ];
  const menuItems: ItemType[] = [
    {
      label: t('sidebar.options.home'),
      key: 'HOME',
      icon: <HomeOutlined />,
      onClick: () => history.push('/home'),
    },
    {
      label: t('sidebar.options.contracts'),
      key: 'CONTRATO',
      icon: <AuditOutlined />,
      onClick: () => history.push('/contratos'),
    },
    {
      label: t('sidebar.options.clients'),
      key: 'CLIENTE',
      icon: <SolutionOutlined />,
      children: customerChildren,
    },
    {
      label: t('sidebar.options.users'),
      key: 'USUARIOS',
      icon: <TeamOutlined />,
      onClick: () => history.push('/usuarios'),
    },
    {
      label: t('sidebar.options.truckLoads'),
      key: 'CARGAS',
      icon: <TruckOutlined />,
      onClick: () => history.push('/cargas'),
    },
    {
      label: t('sidebar.options.settings'),
      key: 'REGRAS',
      icon: <ApartmentOutlined />,
      onClick: () => history.push('/regras'),
    },
    {
      label: t('sidebar.options.tutorials'),
      key: 'tutorials',
      icon: <FundProjectionScreenOutlined />,
      onClick: () => history.push('/tutoriais'),
    },
    {
      label: t('sidebar.options.reports'),
      key: 'reports',
      icon: <FileSyncOutlined />,
      onClick: () => history.push('/relatorios'),
    },
    {
      label: 'Configurações',
      key: 'settings',
      icon: <SettingOutlined />,
      children: settingsChildren,
    },
  ];
  const customerItems: ItemType[] = [
    {
      label: t('sidebar.options.home'),
      key: 'HOME',
      icon: <HomeOutlined />,
      onClick: () => history.push('/home'),
    },
    {
      label: t('sidebar.options.contracts'),
      key: 'CONTRATO',
      icon: <AuditOutlined />,
      onClick: () => history.push('/contratos'),
    },
    {
      label: t('sidebar.options.truckLoads'),
      key: 'CARGAS',
      icon: <TruckOutlined />,
      onClick: () => history.push('/cargas'),
    },
    {
      label: t('sidebar.options.tutorials'),
      key: 'tutorials',
      icon: <FundProjectionScreenOutlined />,
      onClick: () => history.push('/tutoriais'),
    },
    {
      label: t('sidebar.options.reports'),
      key: 'reports',
      icon: <FileSyncOutlined />,
      onClick: () => history.push('/relatorios'),
    },
  ];

  const allowedRoutes = menuItems.map(route => {
    const permissions = getPermissions?.find(permission => {
      const currPermissions = permission?.userPermissions.module === route?.key;
      return (
        currPermissions ||
        route?.key === 'tutorials' ||
        route?.key === 'reports' ||
        route?.key === 'settings'
      );
    });

    if (permissions) {
      return route;
    } else {
      return null;
    }
  });

  const renderRoutes = customerData ? customerItems : allowedRoutes;

  return (
    <Sider
      trigger={null}
      style={siderStyle(layoutColor)}
      collapsible
      collapsed={collapsed}
    >
      <Image
        src={collapsed ? smallLogo : menuLogo}
        preview={false}
        height={60}
        style={{ padding: 14 }}
      />

      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[menuKey]}
        items={renderRoutes}
        selectedKeys={[menuKey]}
        onSelect={({ key }) => {
          const mKey = key as IMenuKey;
          setSelectedMenu(mKey);
        }}
      />

      <MenuFoldOutlined
        onClick={() => setCollapsed(collapsed => !collapsed)}
        style={collapsedButtonStyle}
      />
    </Sider>
  );
};

export default Sidebar;
