import { EditOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import Formatter from '../../../classes/Formatter';
import { IFindShippingCompanyResponse } from '../../../structures/interfaces/ShippingCompany';

interface ICarrierTableProps extends TableProps<IFindShippingCompanyResponse> {
  data: IFindShippingCompanyResponse[];
  setSelectedCarrier: Dispatch<
    SetStateAction<IFindShippingCompanyResponse | undefined>
  >;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const renderData = (value?: string) => {
  if (!value) return '---';
  return value;
};
const ShippingCompanyTable = ({
  data,
  setOpen,
  setSelectedCarrier,
}: ICarrierTableProps): ReactElement => {
  const columns: ColumnsType<IFindShippingCompanyResponse> = [
    {
      title: 'Nome',
      dataIndex: 'companyName',
      key: 'companyName',
      render: renderData,
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'tradeName',
      key: 'tradeName',
      render: renderData,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      render: renderData,
    },
    {
      title: 'CNPJ',
      dataIndex: 'documentNumberCnpj',
      key: 'cnpj',
      render: text => (text ? Formatter.formatCNPJ(text) : renderData(text)),
    },
    {
      title: 'Telefone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: text =>
        text ? Formatter.formatPhoneWithoutDDI(text) : renderData(text),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedCarrier(record);
              setOpen(true);
            }}
          >
            Editar
          </Button>
        </Space>
      ),
    },
  ];
  return <Table columns={columns} dataSource={data} size="small" />;
};

export default ShippingCompanyTable;
