import { gql, TypedDocumentNode } from 'urql';
import {
  IGetTruckLoadsResponse,
  IGetVehicles,
  IPreScheduleInput,
  ITruckLoadRules,
  ITruckLoadRulesWhere,
  IContItemsResponse,
  IContItemsWhere,
  ICreatePreScheduleResponse,
  ICreateTruckLoadInput,
  IGetTruckLoad,
  IUpdateTruckLoadsInput,
  IAddTruckLoadItemsInput,
} from '../interfaces/TruckLoad';

const TruckLoadsQueries = {
  getTruckLoads: (): TypedDocumentNode<
    { truckLoads: IGetTruckLoadsResponse[] },
    { where: { organization_id?: string } }
  > => gql`
    query GetTruckLoads($where: GetTruckLoadsInput!) {
      truckLoads: GetTruckLoads(where: $where) {
        id
        loadedAmount: loaded_amount
        status
        name
        expiresAt: expires_at
        createdAt: created_at
        loadingOrder: loading_order {
          loadingOrder: loading_order
        }
        pickUpLocation: pick_up_location {
          title
        }
        driver {
          name
        }
        cadence {
          startTime: start_time
        }
        vehicle {
          vehicleModel: vehicle_model
        }
        truckLoadItems: truck_load_items {
          contractItem: contract_item {
            contract {
              farm {
                name
              }
            }
          }
        }
      }
    }
  `,
  downloadLoadingOrder: (): TypedDocumentNode<
    { pdf: { url: string } },
    { where: { truck_load_id: string } }
  > => gql`
    query downloadLoadingOrder($where: GetLoadingOrderPdfWhere!) {
      pdf: GetLoadingOrderPdf(where: $where) {
        url: pdf_url
      }
    }
  `,
  cancelTruckLoad: (): TypedDocumentNode<
    { cancel: { success: boolean } },
    { where: { truck_load_id: string } }
  > => gql`
    mutation cancelTruckLoad($where: FindTruckLoadInput!) {
      cancel: CancelTruckLoad(where: $where) {
        success
      }
    }
  `,
  createPreSchedule: (): TypedDocumentNode<
    { preSchedule: ICreatePreScheduleResponse },
    { input: IPreScheduleInput }
  > => gql`
    mutation createPreSchedule($input: PreScheduleUncheckedCreateInput!) {
      preSchedule: CreatePreSchedule(input: $input) {
        id
        cadence {
          rule {
            capacityPerCompany: capacity_per_company
          }
        }
        vehicle {
          id
          vehicleModel: vehicle_model
          bagsCapacity: bags_capacity
        }
        createdBy: created_by
        startedAt: started_at
        expiresIn: expires_in
        unitsAmount: units_amount
        pickUpLocation: pick_up_location {
          organizationId: organization_id
        }
      }
    }
  `,
  listTruckLoadRules: (): TypedDocumentNode<
    { rule: ITruckLoadRules[] },
    { where: ITruckLoadRulesWhere }
  > => gql`
    query listLoadsRules($where: ListRulesWhere!) {
      rule: ListRules(where: $where) {
        id
        unity
        seedType: seed_type {
          nameWithoutAccent: name_without_accent
          name
        }
        pickUpLocation: pick_up_location {
          id
          title
        }
        cadences {
          id
          customerCapacityPerDay: customer_capacity_per_day {
            remainingAmount: remaining_amount
          }
          capacityPerDay: capacity_per_day
          availableUnits: available_units
          startTime: start_time
          endTime: end_time
        }
      }
    }
  `,
  getVehicles: (): TypedDocumentNode<
    { vehicles: IGetVehicles[] },
    { where: { organization_id: string; take_common_vehicles: boolean } }
  > => gql`
    query ListVehicles($where: GetVehicleWhereInput!) {
      vehicles: ListVehicles(where: $where) {
        id
        vehicleModel: vehicle_model
        bagsCapacity: bags_capacity
        weightCapacity: weight_capacity
      }
    }
  `,
  listContractItems: (): TypedDocumentNode<
    { list: { contractItems: IContItemsResponse[] } },
    { where: IContItemsWhere }
  > => gql`
    query listContractItems($where: ListContractItemsInput!) {
      list: ListContractItems(where: $where) {
        contractItems: contract_items {
          id
          cultivation
          sieve
          category
          packing
          tsi
          estimatedWeight: estimated_weight
          customerCapacity: customer_capacity {
            remainingAmount: remaining_amount
          }
          contract {
            referenceNumber: reference_number
            contractDate: contract_date
            farm {
              name
              stateRegistration: state_registration
            }
          }
          fullAmount: full_amount
          remainingAmount: remaining_amount
        }
      }
    }
  `,

  cancelPreSchedule: (): TypedDocumentNode<
    { cancel: { success: boolean } },
    { where: { id: string } }
  > => gql`
    mutation CancelPreSchedule($where: PreScheduleWhereUniqueInput!) {
      cancel: CancelPreSchedule(where: $where) {
        success
      }
    }
  `,

  createTruckLoad: (): TypedDocumentNode<
    { truckLoad: { id: string } },
    { input: ICreateTruckLoadInput }
  > => gql`
    mutation formTruckLoad($input: TruckLoadCreateInput!) {
      truckLoad: FormTruckLoad(input: $input) {
        id
      }
    }
  `,

  getPreScheduleByCreator: (): TypedDocumentNode<
    { getPreScheduleByCreator: Array<{ id: string; created_by: string }> },
    { where: { creator_id: string } }
  > => gql`
    query getPreSchedule($where: GetPreScheduleByCreatorInput!) {
      getPreScheduleByCreator: GetPreScheduleByCreator(where: $where) {
        id
        createdBy: created_by
      }
    }
  `,

  authorizeTruckLoad: (): TypedDocumentNode<
    { authorizeTruckLoad: { id: string; authorizedAt: string } },
    { input: { truck_load_id: string } }
  > => gql`
    mutation AuthorizeTruckLoad($input: LoadAuthorizationInput!) {
      authorizeTruckLoad: AuthorizeTruckLoad(input: $input) {
        id
        authorizedAt: authorized_at
      }
    }
  `,

  fetchTruckLoad: (): TypedDocumentNode<
    { getTruckLoad: IGetTruckLoad },
    { where: { truck_load_id: string } }
  > => gql`
    query FindTruckLoad($where: FindTruckLoadInput!) {
      getTruckLoad: FindTruckLoad(where: $where) {
        id
        name
        status
        allocatedAmount: allocated_amount
        loadedAmount: loaded_amount
        noShippingCompany: no_shipping_company
        preScheduleId: pre_schedule_id
        loadNote: load_note
        vehicle {
          vehicleModel: vehicle_model
          bagsCapacity: bags_capacity
        }
        pickUpLocation: pick_up_location {
          title
        }
        cadence {
          startTime: start_time
          rule {
            seedType: seed_type {
              name
            }
          }
        }
        truckLoadItems: truck_load_items {
          id
          allocatedAmount: allocated_amount
          contractItem: contract_item {
            id
            cultivation
            sieve
            category
            packing
            tsi
            estimatedWeight: estimated_weight
            contract {
              referenceNumber: reference_number
              contractDate: contract_date
              farm {
                name
                stateRegistration: state_registration
              }
            }
            fullAmount: full_amount
            remainingAmount: remaining_amount
          }
        }
        shippingCompany: shipping_company {
          documentNumberCnpj: document_number_cnpj
          tradeName: trade_name
          companyName: company_name
          email: email
          phoneNumber: phone_number
        }
        driver {
          name
          documentNumberCpf: document_number_cpf
          phoneNumber: phone_number
          vehicleModel: vehicle_model
          vehicleLicensePlate: vehicle_license_plate
          note
        }
        authorization {
          userName: user_name
          userCpf: user_cpf
          userPhone: user_phone
          userEmail: user_email
        }
      }
    }
  `,

  updateTruckLoad: (): TypedDocumentNode<
    { updateTruckLoad: { id: string } },
    { input: IUpdateTruckLoadsInput; where: { truck_load_id: string } }
  > => gql`
    mutation updateTruckLoad(
      $input: TruckLoadUpdateInput!
      $where: TruckLoadUpdateWhere!
    ) {
      updateTruckLoad: UpdateTruckLoad(input: $input, where: $where) {
        id
      }
    }
  `,

  addTruckLoadItems: (): TypedDocumentNode<
    { addTruckLoadItems: { id: string } },
    { input: IAddTruckLoadItemsInput }
  > => gql`
    mutation addTruckLoadItems($input: AddTruckLoadItemsInput!) {
      addTruckLoadItems: AddTruckLoadItems(input: $input) {
        id
      }
    }
  `,
  deleteTruckLoadItems: (): TypedDocumentNode<
    { deleteItems: { success: boolean } },
    { input: { data: { id: string } } }
  > => gql`
    mutation delete($input: DeleteTruckLoadItemInput!) {
      deleteItems: DeleteTruckLoadItems(input: $input) {
        success
      }
    }
  `,
};

export default TruckLoadsQueries;
