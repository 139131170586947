import moment from 'moment';
import client from '../../api/urql/customClient';
import {
  IAddTruckLoadItemsInput,
  IContItemsRequest,
  IContItemsResponse,
  ICreatePreScheduleResponse,
  ICreateTruckLoadInput,
  ICreateTruckLoadInputRequest,
  IGetTruckLoad,
  IGetTruckLoadsResponse,
  IGetVehicles,
  IPreScheduleInput,
  ITruckLoadRules,
  IUpdateTruckLoadsInput,
} from '../interfaces/TruckLoad';
import TruckLoadsQueries from '../queries/TruckLoad';
import Formatter from '../../classes/Formatter';

const buildSlug = (truckLoads: IGetTruckLoadsResponse[]) => {
  return truckLoads?.map(truckLoad => ({
    ...truckLoad,
    slug: Formatter.removeAccents(
      ` ${truckLoad.loadingOrder?.loadingOrder ?? ''}
        ${truckLoad.name ?? ''}
        ${truckLoad.cadence.startTime ?? ''}
        ${truckLoad.vehicle.vehicleModel ?? ''}
        ${truckLoad.loadedAmount ?? ''}
        ${truckLoad?.driver?.name ?? ''}
      `.toLowerCase()
    ),
  }));
};

const filterTruckLoadByStatus = (
  truckLoads: IGetTruckLoadsResponse[],
  truckLoadStatus?: string
) => {
  return truckLoadStatus != undefined
    ? truckLoads.filter(truckLoad => {
        return truckLoad.status === truckLoadStatus;
      })
    : truckLoads;
};

const filterTruckLoadByPickUpLocation = (
  truckLoad: IGetTruckLoadsResponse[],
  truckLoadPickUpLocation?: string
) => {
  if (truckLoadPickUpLocation != undefined) {
    return truckLoad.filter(truckLoad => {
      return truckLoad.pickUpLocation.title === truckLoadPickUpLocation;
    });
  } else {
    return truckLoad;
  }
};
const filterTruckLoadByDate = (
  truckLoads: IGetTruckLoadsResponse[],
  date?: [moment.Moment, moment.Moment]
) =>
  date != undefined
    ? truckLoads.filter(truckLoad => {
        return moment(truckLoad.cadence.startTime).isBetween(
          date[0],
          date[1],
          'day',
          '[]'
        );
      })
    : truckLoads;

class TruckLoadsController {
  static filterTruckLoad = (
    getTruckLoads: IGetTruckLoadsResponse[],
    {
      search = '',
      truckLoadStatus,
      truckLoadDate,
      truckLoadPickUpLocation,
    }: {
      search?: string;
      truckLoadStatus?: string;
      truckLoadDate?: [moment.Moment, moment.Moment];
      truckLoadPickUpLocation?: string;
    }
  ): IGetTruckLoadsResponse[] => {
    return filterTruckLoadByDate(
      filterTruckLoadByPickUpLocation(
        filterTruckLoadByStatus(
          buildSlug(getTruckLoads).filter(truckLoad => {
            return truckLoad.slug.includes(
              Formatter.removeAccents(search.toLowerCase().trim())
            );
          }),
          truckLoadStatus
        ),
        truckLoadPickUpLocation
      ),
      truckLoadDate
    );
  };
  static getTruckLoads = ({
    orgId,
  }: {
    orgId?: string;
  }): Promise<IGetTruckLoadsResponse[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadsQueries.getTruckLoads(), {
          where: orgId ? { organization_id: orgId } : {},
        })
        .then(({ truckLoads }) => resolve(truckLoads))
        .catch(err => reject(err))
    );

  static fetchTruckLoad = ({
    truckLoadId,
  }: {
    truckLoadId: string;
  }): Promise<IGetTruckLoad> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadsQueries.fetchTruckLoad(), {
          where: { truck_load_id: truckLoadId },
        })
        .then(({ getTruckLoad }) => resolve(getTruckLoad))
        .catch(err => reject(err))
    );

  static downloadLoadingOrder = ({
    loadId,
  }: {
    loadId: string;
  }): Promise<{ url: string }> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadsQueries.downloadLoadingOrder(), {
          where: { truck_load_id: loadId },
        })
        .then(({ pdf }) => {
          resolve(pdf);
        })
        .catch(err => {
          reject(err);
        });
    });

  static cancelTruckLoad = ({
    loadId,
  }: {
    loadId: string;
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) => {
      client
        .mutation(TruckLoadsQueries.cancelTruckLoad(), {
          where: { truck_load_id: loadId },
        })
        .then(({ cancel }) => {
          resolve(cancel);
        })
        .catch(err => {
          reject(err);
        });
    });

  static createPreSchedule = ({
    input,
  }: {
    input: IPreScheduleInput;
  }): Promise<ICreatePreScheduleResponse> =>
    new Promise((resolve, reject) => {
      client
        .mutation(TruckLoadsQueries.createPreSchedule(), { input })
        .then(({ preSchedule }) => {
          resolve(preSchedule);
        })
        .catch(err => {
          reject(err);
        });
    });

  static listTruckLoadsRules = ({
    organizationId,
    seedTypeId,
    pickUpLocation,
    unity,
  }: {
    organizationId: string;
    seedTypeId?: string;
    pickUpLocation?: string;
    unity?: string;
  }): Promise<ITruckLoadRules[]> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadsQueries.listTruckLoadRules(), {
          where: {
            organization_id: organizationId,
            seed_type_id: seedTypeId,
            pickup_location_id: pickUpLocation,
            unity_type: unity,
          },
        })
        .then(({ rule }) => {
          resolve(rule);
        })
        .catch(err => {
          reject(err);
        });
    });

  static getVehicles = ({
    organizationId,
    takeCommonVehicles,
  }: {
    organizationId: string;
    takeCommonVehicles: boolean;
  }): Promise<IGetVehicles[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadsQueries.getVehicles(), {
          where: {
            organization_id: organizationId,
            take_common_vehicles: takeCommonVehicles,
          },
        })
        .then(({ vehicles }) => {
          resolve(vehicles);
        })
        .catch(err => reject(err))
    );

  static listContractItems = ({
    excludeTsi,
    farmId,
    organizationId,
    preScheduleId,
    seedTypeId,
    unity,
  }: IContItemsRequest): Promise<IContItemsResponse[]> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadsQueries.listContractItems(), {
          where: {
            exclude_tsi: excludeTsi,
            farm_id: farmId,
            organization_id: organizationId,
            pre_schedule_id: preScheduleId,
            seed_type_id: seedTypeId,
            unity,
          },
        })
        .then(({ list }) => {
          resolve(list.contractItems);
        })
        .catch(err => {
          reject(err);
        });
    });

  static cancelPreSchedule = ({
    id,
  }: {
    id: string;
  }): Promise<{ success: boolean }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.cancelPreSchedule(), { where: { id } })
        .then(({ cancel }) => resolve(cancel))
        .catch(reject)
    );
  };

  static createTruckLoad = (
    values: ICreateTruckLoadInputRequest
  ): Promise<{
    id: string;
  }> => {
    const inputValues = {
      can_sort_truck_load_item: values.canSortTruckLoadItem,

      create_driver: values.createDriver
        ? {
            name: values.createDriver?.name,
            document_number_cpf: values.createDriver?.documentNumberCpf,
            vehicle_license_plate: values.createDriver?.vehicleLicensePlate,
          }
        : null,
      shipping_company_data: values.shippingCompanyData
        ? { company_name: values.shippingCompanyData?.companyName }
        : null,

      driver_id: values.driverId,
      estimated_weight: 0,
      load_note: values.loadNote ? values.loadNote : null,
      name: values.name,
      no_shipping_company: values.noShippingCompany,
      organization_id: values.organizationId,
      pre_schedule_id: values.preScheduleId,
      reference_vehicle_id: values.referenceVehicleId,
      shipping_company_id: values.shippingCompanyId,
      vehicle_id: values.vehicleId,

      truck_load_items: values.truckLoadItems.map(item => ({
        allocated_amount: item.allocatedAmount,
        contract_item_id: item.contractItemId,
        load_sort: item.loadSort,
      })),
    } as ICreateTruckLoadInput;

    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.createTruckLoad(), { input: inputValues })
        .then(({ truckLoad }) => resolve(truckLoad))
        .catch(reject)
    );
  };

  static getPreScheduleByCreator = ({
    creatorId,
  }: {
    creatorId: string;
  }): Promise<Array<{ id: string; created_by: string }>> => {
    return new Promise((resolve, reject) => {
      client
        .query(TruckLoadsQueries.getPreScheduleByCreator(), {
          where: { creator_id: creatorId },
        })
        .then(({ getPreScheduleByCreator }) => {
          resolve(getPreScheduleByCreator);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  static authorizeTruckLoad = ({
    truckLoadId,
  }: {
    truckLoadId: string;
  }): Promise<{ id: string; authorizedAt: string }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.authorizeTruckLoad(), {
          input: { truck_load_id: truckLoadId },
        })
        .then(({ authorizeTruckLoad }) => {
          resolve(authorizeTruckLoad);
        })
        .catch(err => {
          reject(err);
        })
    );
  };

  static updateTruckLoad = ({
    truckLoadId,
    input,
  }: {
    truckLoadId: string;
    input: IUpdateTruckLoadsInput;
  }): Promise<{ id: string }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.updateTruckLoad(), {
          input: input,
          where: { truck_load_id: truckLoadId },
        })
        .then(({ updateTruckLoad }) => {
          resolve(updateTruckLoad);
        })
        .catch(err => {
          reject(err);
        })
    );
  };

  static addTruckLoadItems = ({
    input,
  }: {
    input: IAddTruckLoadItemsInput;
  }): Promise<{ id: string }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.addTruckLoadItems(), { input })
        .then(({ addTruckLoadItems }) => resolve(addTruckLoadItems))
        .catch(err => reject(err))
    );
  };

  static deleteTruckLoadItems = ({
    input,
  }: {
    input: { data: { id: string } };
  }): Promise<{ success: boolean }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.deleteTruckLoadItems(), { input })
        .then(({ deleteItems }) => resolve(deleteItems))
        .catch(err => reject(err))
    );
  };
}

export default TruckLoadsController;
