/**
 * @description Normalizes the values passed according to the selected normalization.
 */

class Normalizer {
  /**
   * @function
   * @description Removes the first space in text fields.
   */
  removesFirstSpace = (text: string) => {
    if (text === ' ') {
      return '';
    }
    return text;
  };

  /**
   * @function
   * @description Removes unnecessary spaces from the value.
   */
  removesMultiplesSpacesForOne = (text: string) => {
    try {
      return text.replace(/ {2,}/g, ' ');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @description Removes anything other than a number.
   */
  onlyNumbers = (text: string) => {
    try {
      const cleanText = text.replace(/[^0-9]/g, '');
      return String(cleanText || parseInt(cleanText));
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @description Removes anything other than a number or letter.
   */
  onlyCharacters = (text: string) => {
    try {
      return text.replace(/[^a-zA-Zà-úÀ-Ú ]/g, '');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @description Removes anything other than a number
   * or letter (no special characters).
   */
  onlyNumbersLetters = (text: string) => {
    try {
      return text.replace(/[^A-Za-z0-9]/g, '');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @description Put texts to uppercase.
   */
  toUpperCase = (text: string) => {
    try {
      return text.toUpperCase();
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @description Put the text to the standard without special characters and with uppercase.
   */
  defaultNormalizeWithUpercase = (text: string) => {
    let formatedValue = this.removesMultiplesSpacesForOne(text);
    formatedValue = this.onlyNumbersLetters(formatedValue);
    formatedValue = this.toUpperCase(formatedValue);
    return formatedValue;
  };

  /**
   * @function capitalizeFirtsLetter
   * @description Put the first letter to be uppercase and the other lowercase.
   */
  capitalizeFirtsLetter = (text: string) =>
    text
      .toLowerCase()
      .replace(
        /([^a-zà-ú]|^)([a-z])(?=[a-z]{2})/g,
        (_, g1, g2) => g1 + g2.toUpperCase()
      );

  /**
   * @function removeAccent
   * @param {string} text -Text aim to be formatted.
   * @returns {string}
   * @description Removes all symbols and accents from the string.
   */
  removeAccent = (text: string, uppercase = false) => {
    const base = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(
        /[«‘“πøˆ¨¥†®´∑œåß∂ƒ©˙∆˚¬…æ÷≥≤µ˜∫√`¡™£¢∞§¶•ªº≠≈»’”∏¨Áˇ‰´„Œ‚·°‡ﬂﬁ›‹€<>?:"'^$#!@%*]/g,
        ''
      );

    return uppercase ? base.toUpperCase() : base;
  };

  /**
   * @function sliceWithEllipsis
   * @param {string} text - Text aim to be formatted.
   * @param {number} maxCharAmount - Max amount of characters.
   * @returns {string}
   * @description Return text sliced with '...'in the end
   */
  sliceWithEllipsis = (text: string, maxCharAmount: number) => {
    if (!text || typeof text !== 'string') return text;
    if (text.length <= maxCharAmount) return text;
    return `${text.slice(0, maxCharAmount)} ...`;
  };

  /**
   * @function serializeString
   * @param {string} text - Text aim to be normalized.
   * @returns {string}
   * @description Return text with " and \ replaced by \" and \\", respectively.
   */
  serializeString = (text: string) => {
    return text.replace(/(\\|")/g, '\\$1');
  };

  /**
   * @function onlyNumbersAndCommas
   * @param {string} text - Text aim to be normalized.
   * @returns {string}
   * @description Returns only values that may (or may not) contain numbers and commas.
   */
  onlyNumbersAndCommas = (text: string) => {
    return text.replace(/[^\d,]/g, '');
  };
}

export default new Normalizer();
