import { Card, Col, Layout, Row } from 'antd';
import {
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ContractController from '../../structures/controllers/Contract';
import {
  IDashboardContractTable,
  IGetContract,
} from '../../structures/interfaces/Contract';
import { BuildSlug } from '../contracts/Contracts.utils';
import ContractsFilter from '../contracts/ContractsPage.filter';
import HomeTiles from './HomePage.tiles';
import HomeTable from './HomePage.table';
import ViewContracts from '../contracts/ViewContract';

const HomePage = (): ReactElement => {
  const { userData, customerData } = useContext(UserContext);
  const [contracts, setContracts] = useState<IGetContract[]>([]);
  const [filterContracts, setFilterContracts] = useState<IGetContract[]>([]);
  const [selectedContract, setSelectedContract] =
    useState<IDashboardContractTable>();
  const [open, setOpen] = useState(false);

  const [fetchContracts, isFetching] = useRequest(
    ContractController.getContractsById
  );

  useEffect(() => {
    const orgId = userData?.organization.id || customerData?.organizationId;
    if (orgId) {
      fetchContracts({ organization_id: orgId, subordinates_filter: null })
        .then(res => {
          const slugRes = BuildSlug(res);
          setContracts(slugRes);
          setFilterContracts(slugRes);
        })
        .catch();
    }
  }, []);

  return (
    <Layout style={{ margin: 24 }}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <HomeTiles />
        </Col>

        <Col span={24}>
          <ContractsFilter
            contracts={contracts}
            setFilteredContracts={setFilterContracts}
          />
        </Col>

        <Col span={24}>
          <Card title="Pedidos">
            <HomeTable
              data={filterContracts}
              isLoading={isFetching}
              setSelectedContract={setSelectedContract}
              setOpen={setOpen}
            />
          </Card>
        </Col>
      </Row>

      <ViewContracts
        open={open}
        onClose={() => setOpen(false)}
        contractData={selectedContract as IGetContract}
      />
    </Layout>
  );
};

export default HomePage;
