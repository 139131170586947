import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Row,
  Tooltip,
} from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { RangePickerProps } from 'antd/lib/date-picker';
import { FormInstance, Rule } from 'antd/lib/form';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Normalizer from '../../../../../../classes/Normalizer';
import UserContext from '../../../../../../contexts/user';

interface ISeedsTreatmentProps {
  t: TFunction;
  requiredRule: Rule;
  inputsColSize: any;
  validValueRule: any;
  form: FormInstance;
}
const SeedsTreatmentCard = ({
  t,
  requiredRule,
  inputsColSize,
  validValueRule,
  form,
}: ISeedsTreatmentProps) => {
  const { settings } = useContext(UserContext);

  const whiteSeedsValue = useWatch('whiteSeedSchedulingTime', form);
  const releaseSubmissionDate = useWatch('releaseSubmissionDate', form);
  const startSchedulingnDate = useWatch('schedulingStart', form);
  const startDateValue = useWatch('startDate', form);

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    if (releaseSubmissionDate) {
      return (
        current <=
        moment(releaseSubmissionDate)
          .add(whiteSeedsValue, 'days')
          .startOf('day')
      );
    } else {
      return current <= moment().add(whiteSeedsValue, 'days').startOf('day');
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      whiteSeedSchedulingTime: settings?.commonWhiteSeedSchedulingTime,
      tsiSeedSchedulingTime: settings?.commonTsiSeedSchedulingTime,
    });
  }, [settings]);

  return (
    <Card
      title={t('pages.scheduleRules.seedsTreatment')}
      style={{ marginBottom: 24 }}
    >
      <Row gutter={24}>
        <Col {...inputsColSize}>
          <Form.Item
            dependencies={['tsiSeedSchedulingTime']}
            validateFirst
            name="whiteSeedSchedulingTime"
            label={
              <Tooltip
                title={
                  <>
                    {t('pages.scheduleRules.newRules.hoursInputMessages')}
                    <hr />
                    {t('pages.scheduleRules.newRules.tsiTooltipTitle')}
                  </>
                }
              >
                {t(
                  'pages.scheduleRules.newRules.unityInformation.whiteSeedsTreatment'
                )}{' '}
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[
              { ...requiredRule },
              { type: 'number', min: 0, message: 'Insira um valor positivo!' },
            ]}
          >
            <InputNumber
              onChange={() => form.resetFields(['startDate', 'endDate'])}
              style={{ width: '100%' }}
              placeholder={t('pages.scheduleRules.newRules.quantityInDays')}
              addonAfter={t('pages.scheduleRules.newRules.addonAfterDays')}
            />
          </Form.Item>
        </Col>

        <Col {...inputsColSize}>
          <Form.Item
            dependencies={['whiteSeedSchedulingTime']}
            validateFirst
            name="tsiSeedSchedulingTime"
            label={
              <Tooltip
                title={
                  <>
                    {t('pages.scheduleRules.newRules.hoursInputMessages')}
                    <hr />
                    {t('pages.scheduleRules.newRules.tsiTooltipTitle')}
                  </>
                }
              >
                {t(
                  'pages.scheduleRules.newRules.unityInformation.tsiSeedsTreatment'
                )}{' '}
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[
              { ...requiredRule },
              { type: 'number', min: 0, message: 'Insira um valor positivo!' },
              {
                validator: (_, value) =>
                  value >= whiteSeedsValue
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t('pages.settings.treatmentTimeTsi'))
                      ),
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder={t('pages.scheduleRules.newRules.quantityInDays')}
              addonAfter={t('pages.scheduleRules.newRules.addonAfterDays')}
            />
          </Form.Item>
        </Col>

        <Col {...inputsColSize}>
          <Form.Item
            dependencies={['schedulingStart']}
            validateFirst
            name="releaseSubmissionDate"
            label={
              <Tooltip
                title={t('pages.scheduleRules.releaseSubmissionDateInfo')}
              >
                {t(
                  'pages.scheduleRules.newRules.basicInformation.dateReleaseSentLabel'
                )}{' '}
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[{ ...requiredRule }]}
          >
            <DatePicker
              allowClear={false}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={curr => curr <= moment().subtract(1, 'day')}
              onChange={() =>
                form.resetFields(['schedulingStart', 'startDate', 'endDate'])
              }
            />
          </Form.Item>
        </Col>

        <Col {...inputsColSize}>
          <Form.Item
            dependencies={['releaseSubmissionDate']}
            validateFirst
            name="schedulingStart"
            label={
              <Tooltip title={t('pages.scheduleRules.schedulingStartInfo')}>
                {t(
                  'pages.scheduleRules.newRules.basicInformation.startSchedulingLabel'
                )}{' '}
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[
              { ...requiredRule },
              {
                validator: (_, value) =>
                  moment(value).isSameOrAfter(releaseSubmissionDate, 'day')
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          t(
                            'pages.scheduleRules.newRules.schedulingStartDateError'
                          )
                        )
                      ),
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={curr => curr <= moment().subtract(1, 'day')}
            />
          </Form.Item>
        </Col>

        <Col {...inputsColSize}>
          <Form.Item
            validateFirst
            name="startDate"
            label={
              <Tooltip title={t('pages.scheduleRules.startShipmentInfo')}>
                {t(
                  'pages.scheduleRules.newRules.basicInformation.startShipmentLabel'
                )}{' '}
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[
              { ...requiredRule },
              {
                validator: (_, value) =>
                  moment(value) >= startSchedulingnDate
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          t('pages.scheduleRules.newRules.startDateError')
                        )
                      ),
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>

        <Col {...inputsColSize}>
          <Form.Item
            validateFirst
            name="endDate"
            label={
              <Tooltip title={t('pages.scheduleRules.endShipmentInfo')}>
                {t(
                  'pages.scheduleRules.newRules.basicInformation.endShipmentLabel'
                )}{' '}
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[
              { ...requiredRule },
              {
                validator: (_, value) =>
                  moment(value) >= startDateValue
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          t('pages.scheduleRules.newRules.endDateError')
                        )
                      ),
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>

        <Col {...inputsColSize}>
          <Form.Item
            name="loadReportTimeout"
            normalize={Normalizer.onlyNumbers}
            label={
              <Tooltip
                title={
                  <>
                    {t(
                      'pages.scheduleRules.newRules.basicInformation.limitTimeToInformLoadMessage'
                    )}
                    <Divider />
                    {t('pages.scheduleRules.newRules.hoursInputMessages')}
                  </>
                }
              >
                {t(
                  'pages.scheduleRules.newRules.basicInformation.limitTimeToInformLoadLabel'
                )}{' '}
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[{ ...requiredRule }, () => validValueRule]}
          >
            <InputNumber
              addonAfter={'Minutos'}
              placeholder={'Insira o tempo para a formação de cargas'}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col {...inputsColSize}>
          <Form.Item
            normalize={Normalizer.onlyNumbers}
            name="driverReportTimeout"
            label={
              <Tooltip
                title={
                  <>
                    {t(
                      'pages.scheduleRules.newRules.basicInformation.limitTimeToInformDriverMessage'
                    )}
                    <Divider />
                    {t('pages.scheduleRules.newRules.hoursInputMessages')}
                  </>
                }
              >
                {t(
                  'pages.scheduleRules.newRules.basicInformation.limitTimeToInformDriverLabel'
                )}{' '}
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[{ ...requiredRule }, () => validValueRule]}
          >
            <InputNumber
              placeholder={t('pages.scheduleRules.newRules.quantityInHours')}
              addonAfter={t('pages.scheduleRules.newRules.addonAfterHours')}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(SeedsTreatmentCard);
