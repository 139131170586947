import { CompanyFunctionEnum } from './Company/CompanyFunction';

export interface IUserLogin {
  email: string;
  password: string;
}

export interface IUserLoginResponse {
  Login: {
    token: string;
  };
}

export interface IUser {
  id: string;
  organization: string;
  userType: string;
}

export interface IUserResponse {
  User: IUser;
}

export interface IUserOrganization {
  id: string;
  name?: string;
}

export interface IUserOrganizationsResponse {
  data: {
    OrganizationManagers: {
      organization: {
        id: string;
        name: string;
      };
    }[];
  };
}

export interface ICreateUser {
  name: string;
  email: string;
  password: string;
}

export interface ICreateUserResponse {
  token: string;
}

export interface IValidateEmailResponse {
  validateEmail: {
    token: string;
  };
}

export interface IDecodedTokenUser {
  id: string;
  name: string;
  email: string;
  phone: string;
  company_function: CompanyFunctionEnum;
  iat: number;
}

export interface IDecodedTokenCreatedUser {
  id: string;
  email: string;
  active: string;
  created_at: string;
  iat: number;
  exp: number;
}

export interface IGetUserResponse {
  id: string;
  name: string;
  email: string;
  documentNumberCpf: string;
  phoneNumber: string;
  receiveNotifications: boolean;
  ignoreDriverExpiration: boolean;
  userHasPermissions: {
    userPermissions: {
      id: string;
      module: string;
      operation: string;
    };
  }[];
  userPermissionsData?: {
    id: string;
    module: string;
    operation: string;
  }[];
  userType: {
    id: string;
  };
  organization: {
    id: string;
    documentNumberCnpj: string;
    tradeName: string;
  };
}

export interface IGetLoginDataUser {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  userType: { id: string };
  documentNumberCpf: string;
  organization: { id: string };
  userHasPermissions: {
    userPermissions: {
      id: string;
      module: string;
      operation: string;
    };
  }[];
}

export interface IGetLoginCustomerUser {
  customer_id: string;
}
export interface IUserId {
  id: string;
  customer_id: string;
}
export interface sendCode {
  email: string;
}
export interface ISendRecoveryCode {
  sendCode: sendCode;
  success: boolean;
}

export interface ValidateCode {
  email: string;
  recoveryCode: string;
}
export interface IValidateRecoveryCode {
  validateToken: {
    token: string;
  };
}

export interface ResetPassword {
  password: string;
  id: string;
}
export interface IResetPasswordResponse {
  resetpassword: { success: boolean };
}

export interface IUserId {
  id: string;
}

export interface UpdatePassword {
  password: string;
  id: string;
}
export interface IUpdatePasswordResponse {
  updatepassword: { success: boolean };
}
export interface formValues {
  password: string;
}
export interface IResetPassword {
  password: string;
  id: string;
}
export interface ResetPasswordStateControl {
  success: boolean;
  update: IResetPassword;
}

type IUserPermissionOperation = 'READ' | 'UPDATE' | 'CREATE' | 'DELETE';

export interface IUserPermissionsResponse {
  type: string;
  userType: {
    id: string;
  };
  userPermissions: {
    type: string;
    id: string;
    module: string;
    operation: IUserPermissionOperation;
  }[];
}

export interface IUserPermission {
  module: string;
  READ: string;
  UPDATE: string;
  CREATE: string;
  DELETE: string;
}

export interface IUserPermissionUpdate {
  module: string;
  READ: { id: string; hasPermission: boolean };
  UPDATE: { id: string; hasPermission: boolean };
  CREATE: { id: string; hasPermission: boolean };
  DELETE: { id: string; hasPermission: boolean };
}

export interface IUserPermissionsResponse {
  id: string;
  module: string;
  operation: string;
}

export const IUserModules = [
  'HOME',
  'CLIENTE',
  'CONTRATO',
  'USUARIOS',
  'REGRAS',
  'CARGAS',
] as const;

export type IUserPermissionsNOVO = {
  type?: string;
} & Record<(typeof IUserModules)[number], IUserModulePermissions>;

export const IUserModulePermissionsType = [
  'READ',
  'CREATE',
  'UPDATE',
  'DELETE',
] as const;

export interface IUserPermissions {
  type?: string;
  HOME?: {
    module?: string;
    READ?: { id: string; hasPermission: boolean };
    CREATE?: { id: string; hasPermission: boolean };
    UPDATE?: { id: string; hasPermission: boolean };
    DELETE?: { id: string; hasPermission: boolean };
  };
  CLIENTE?: {
    module?: string;
    READ?: { id: string; hasPermission: boolean };
    CREATE?: { id: string; hasPermission: boolean };
    UPDATE?: { id: string; hasPermission: boolean };
    DELETE?: { id: string; hasPermission: boolean };
  };
  CONTRATO?: {
    module?: string;
    READ?: { id: string; hasPermission: boolean };
    CREATE?: { id: string; hasPermission: boolean };
    UPDATE?: { id: string; hasPermission: boolean };
    DELETE?: { id: string; hasPermission: boolean };
  };
  USUARIOS?: {
    module?: string;
    READ?: { id: string; hasPermission: boolean };
    CREATE?: { id: string; hasPermission: boolean };
    UPDATE?: { id: string; hasPermission: boolean };
    DELETE?: { id: string; hasPermission: boolean };
  };
  REGRAS?: {
    module?: string;
    READ?: { id: string; hasPermission: boolean };
    CREATE?: { id: string; hasPermission: boolean };
    UPDATE?: { id: string; hasPermission: boolean };
    DELETE?: { id: string; hasPermission: boolean };
  };
  CARGAS?: {
    module?: string;
    READ?: { id: string; hasPermission: boolean };
    CREATE?: { id: string; hasPermission: boolean };
    UPDATE?: { id: string; hasPermission: boolean };
    DELETE?: { id: string; hasPermission: boolean };
  };
}

export interface IPermission {
  id: string;
  hasPermission: boolean;
}

export type IUserModulePermissions = {
  module?: string;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const RegStatusEnum = {
  ACTIVATED: 'Ativado',
  NOTIFIED: 'Notificado',
  WAITING_ACTIVATION: 'Aguardando ativação',
  NOT_ACTIVATED: 'Inativo',
};
export interface IGetUser {
  id: string;
  name: string;
  email: string;
  documentNumberCpf: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  status: keyof typeof RegStatusEnum;
  organization: {
    tradeName: string;
    id: string;
  };
  userType: {
    id: string;
    typeDescription: string;
  };
  slug: string;
}

export interface IGetUserUpdate {
  id: string;
  name: string;
  email: string;
  documentNumberCpf: string;
  phoneNumber: string;
  receiveNotifications: boolean;
  ignoreDriverExpiration: boolean;
  userHasPermissions: {
    userPermissions: {
      id: string;
      module: string;
      operation: string;
    };
  }[];
  userPermissionsData: {
    id: string;
    module: string;
    operation: string;
  }[];
  userType: {
    id: string;
    typeDescription: string;
  };
  organization?: {
    id: string;
    tradeName: string;
  };
}

export interface IGetPermission {
  userType: {
    id: string;
    typeDescription: string;
  };
}

export interface IOptions {
  value: string;
  label: string | JSX.Element;
}

export interface IUpdateUserAccount {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
}

export interface IUpdateUser {
  typeId: string;
  name: string;
  email: string;
  receiveNotifications: boolean;
  ignoreDriverExpiration: boolean;
  documentNumberCpf: string;
  phoneNumber: string;
  organizationId: string;
  permissions: {
    add: string[];
    delete: string[];
  };
}
export interface IDeleteUser {
  id: string;
  name: string;
}
export interface IRegisterUserInput {
  userType: string;
  name: string;
  documentNumberCpf: string;
  organizationId: string;
  phoneNumber: string;
  email: string;
  permissions: Array<string | boolean>;
  receive_notifications: boolean;
  ignoreDriverExpiration: boolean;
}

export interface IRegisterUserResponse {
  password: string;
}

export interface IVisibleOrganizations {
  name: string;
  id: string;
}

export interface IAllowUserAccess {
  userId: string;
  email: string;
  organizationId: string;
  userType: string;
  permissions: {
    add: string[];
    delete: string[];
  };
}

export interface ICrop {
  id: string;
  seedTypeId: string;
  referenceCode: string;
  year: string;
  startDate: string;
  endDate: string;
  current: boolean;
}

export interface ICustomerOrganization {
  id: string;
  logoUrl: string;
  documentNumberCnpj: string;
  tradeName: string;
  referenceName: string;
  companyName: string;
}
