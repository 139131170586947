import { gql, TypedDocumentNode } from 'urql';
import { IGetLoadingOrder, IReports } from '../interfaces/Reports';

const ReportQueries = {
  getReports: (): TypedDocumentNode<
    { reports: IReports[] },
    { where: { operator_id: string } }
  > => gql`
    query getReports($where: GetReportsWhere!) {
      reports: GetReports(where: $where) {
        id
        operatorId: operator_id
        loadingOrderId: loading_order_id
        processing
        loadingOrder: loading_order {
          pdfName: pdf_name
          pdfUrl: pdf_url
          loadingOrder: loading_order
        }
      }
    }
  `,

  getLoadingOrder: (): TypedDocumentNode<
    { loadingOrder: IGetLoadingOrder },
    { where: { loading_order_id: string } }
  > => gql`
    query getLoadingOrder($where: FindLoadingOrderInput!) {
      loadingOrder: FindLoadingOrder(where: $where) {
        id
        pdfUrl: pdf_url
        pdfName: pdf_name
        loadingOrder: loading_order
      }
    }
  `,
};

export default ReportQueries;
