import { ReactElement } from 'react';

import { Layout } from 'antd';

/**
 * @description Styled panel Header.
 * @property {boolean} [collapsed=false] If true, sidebar menu collapse
 * @property {string} [layoutColor=null] Layout color
 * @property {function} setCollapsed Set sidebar collapsed
 * @example
 */

interface IFooter {
  children?: ReactElement;
}

const { Footer } = Layout;

const LayoutFooter = ({ children }: IFooter): ReactElement => (
  <Footer
    style={{
      position: 'fixed',
      zIndex: 1031,
      bottom: 0,
      width: '91%',
      backgroundColor: '#FFF',
      textAlign: 'right',
      boxShadow:
        '0 -6px 16px -8px rgba(0,0,0,.08),0 -9px 28px 0 rgba(0,0,0,.05),0 -12px 48px 16px rgba(0,0,0,.03)',
    }}
  >
    {children || null}
  </Footer>
);

export default LayoutFooter;
