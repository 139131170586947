import { Col, Form, FormInstance, Input, Row } from 'antd';
import { ReactElement } from 'react';
import Formatter from '../../../classes/Formatter';

export interface IAddNewContactFormValues {
  name: string;
  email: string;
  cpf: string;
  phone: string;
}
interface IAddNewContactProps {
  form: FormInstance<IAddNewContactFormValues>;
}
const AddNewContact = ({ form }: IAddNewContactProps): ReactElement => {
  const onFinish = () => {
    return;
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Row gutter={[24, 24]} align={'middle'}>
        <Col span={12}>
          <Form.Item label="Nome" name="name" rules={[{ required: true }]}>
            <Input placeholder="Insira o nome do cliente" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            rules={[{ type: 'email' }, { required: true }]}
            label="E-mail"
            name="email"
          >
            <Input placeholder="Insira o e-mail do cliente" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item normalize={Formatter.formatCPF} label="CPF" name="cpf">
            <Input placeholder="Insira o CPF do cliente" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            normalize={Formatter.formatPhoneWithoutDDI}
            label="Telefone"
            name="phone"
          >
            <Input placeholder="Insira o telefone do cliente" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddNewContact;
