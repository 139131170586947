import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { MenuInfo } from 'rc-menu/lib/interface';

import { Menu, Dropdown } from 'antd';
import Icon from '@ant-design/icons';

import { clearStorage } from '../../utils/index';

import translateIcon from '../../assets/translate_icon.svg';
import UsaSVG from '../../assets/IconComponents/USFlag';
import SpainSVG from '../../assets/IconComponents/SpainFlag';
import BrazilSVG from '../../assets/IconComponents/BrazilFlag';

import './avatarDropDown.scss';

const TranslateMenu = (): ReactElement => {
  const { t, i18n } = useTranslation();

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key == 'pt-BR') {
      i18n.changeLanguage('pt-BR');
    } else if (e.key == 'en-US') {
      i18n.changeLanguage('en-US');
    } else if (e.key == 'es') {
      i18n.changeLanguage('es');
    } else {
      document.location.reload();
      clearStorage();
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="pt-BR">
        <Icon component={BrazilSVG} />
        <span style={{ fontSize: '14px', marginLeft: '10px' }}>
          {t('languages.ptBR')}
        </span>
      </Menu.Item>
      <Menu.Item key="en-US">
        <Icon component={UsaSVG} />
        <span style={{ fontSize: '14px', marginLeft: '10px' }}>
          {t('languages.enUS')}
        </span>
      </Menu.Item>
      <Menu.Item key="es">
        <Icon component={SpainSVG} />
        <span style={{ fontSize: '14px', marginLeft: '10px' }}>
          {t('languages.es')}
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="avatar_dropdown-container"
      style={{
        marginLeft: 'auto',
        marginRight: '10px',
        display: 'flex',
      }}
    >
      <span
        className="avatar_translate"
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '14px',
          marginRight: '10px',
          alignItems: 'center',
        }}
      ></span>
      <Dropdown overlay={menu} trigger={['click']}>
        <div style={{ cursor: 'pointer' }}>
          <img className="default_img" src={translateIcon} />
        </div>
      </Dropdown>
    </div>
  );
};

export default TranslateMenu;
