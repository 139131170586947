import { gql, TypedDocumentNode } from 'urql';
import {
  IAddShippingCompany,
  IFindDriverResponse,
  IFindShippingCompanyResponse,
  INewShippingCompany,
  IUpdateShippingCompany,
} from '../interfaces/ShippingCompany';

const ShippingCompanyQueries = {
  listShippingCompanies: (): TypedDocumentNode<
    { shippingCompanies: IFindShippingCompanyResponse[] },
    { where: { document_number_cnpj?: string } }
  > => gql`
    query listShippingCompanies($where: ListShippingCompanyWhereInput!) {
      shippingCompanies: ListShippingCompanies(where: $where) {
        id: id
        email: email
        tradeName: trade_name
        companyName: company_name
        phoneNumber: phone_number
        documentNumberCnpj: document_number_cnpj
      }
    }
  `,

  findDriver: (): TypedDocumentNode<
    { driver: IFindDriverResponse },
    { where: { document_number_cpf: string } }
  > => gql`
    query getDriver($where: GetDriverWhereInput!) {
      driver: GetDriver(where: $where) {
        id
        driver: name
        driverDocument: document_number_cpf
        vehicle: vehicle_model
        vehicleId: vehicle_id
        licensePlate: vehicle_license_plate
        phoneNumber: phone_number
        email
        driverObservation: note
      }
    }
  `,

  addNewShippingCompany: (): TypedDocumentNode<{
    input: IAddShippingCompany;
    newShippingCompany: INewShippingCompany;
  }> => gql`
    mutation registerShippingCompany($input: ShippingCompanyCreateInput!) {
      newShippingCompany: CreateShippingCompany(input: $input) {
        id
        companyName: company_name
        tradeName: trade_name
        documentNumberCnpj: document_number_cnpj
        phoneNumber: phone_number
        email
      }
    }
  `,

  updateShippingCompany: (): TypedDocumentNode<
    { update: { id: string } },
    { where: { id: string }; input: IUpdateShippingCompany }
  > => gql`
    mutation UpdateShippingCompany(
      $where: ShippingCompanyWhereUniqueInput!
      $input: ShippingCompanyUncheckedUpdateInput!
    ) {
      update: UpdateShippingCompany(where: $where, input: $input) {
        id
      }
    }
  `,
};

export default ShippingCompanyQueries;
