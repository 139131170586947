import { Card, Row, Col, Form, Select } from 'antd';
import { FormInstance, Rule } from 'antd/lib/form';
import { TFunction } from 'i18next';
import { ReactElement, useEffect } from 'react';
import {
  IGetPickUpLocations,
  IGetSeedTypes,
} from '../../../../../../structures/interfaces/ScheduleRules';
import { withTranslation } from 'react-i18next';

interface IBasicInformationCardProps {
  t: TFunction;
  requiredRule: Rule;
  inputsColSize: any;
  cultures: IGetSeedTypes[] | undefined;
  locales: IGetPickUpLocations[] | undefined;
  form: FormInstance;
}

const { Option } = Select;

const BasicInformationCard = ({
  t,
  requiredRule,
  inputsColSize,
  cultures,
  locales,
  form,
}: IBasicInformationCardProps): ReactElement<unknown> => {
  const translateBase = 'pages.scheduleRules.newRules';

  const pickUpLocationOptions = locales
    ?.filter(local => local.title)
    .map(local => (
      <Option key={local.id} value={local.id}>
        {local.title}
      </Option>
    ));

  const cultureOptions = cultures?.map(item => (
    <Option key={item.nameWithoutAccent} value={item.nameWithoutAccent}>
      {item.name}
    </Option>
  ));

  useEffect(() => {
    if (pickUpLocationOptions?.length === 1) {
      form.setFieldsValue({
        pickUpLocationIds: [pickUpLocationOptions[0].key],
      });
    }
  }, [locales, pickUpLocationOptions]);

  return (
    <Card
      title={t(`${translateBase}.basicInformation.title`)}
      style={{ marginBottom: 24 }}
    >
      <Row gutter={24}>
        <Col {...inputsColSize}>
          <Form.Item
            name="seedTypeId"
            label={t(`${translateBase}.basicInformation.cultureLabel`)}
            rules={[requiredRule]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder={t(`${translateBase}.defaultValueSelect`)}
            >
              {cultureOptions}
            </Select>
          </Form.Item>
        </Col>

        <Col {...inputsColSize}>
          <Form.Item
            name="pickUpLocationIds"
            label={t(`${translateBase}.basicInformation.locationLabel`)}
            rules={[requiredRule]}
            initialValue={pickUpLocationOptions?.[0]}
          >
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              showSearch
              optionFilterProp="children"
              placeholder={t(`${translateBase}.defaultValueSelect`)}
            >
              {pickUpLocationOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(BasicInformationCard);
