import { Image, Layout, message, Select, Space, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import moment from 'moment';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import UserController from '../../structures/controllers/User';
import { getDataFromStorage, setDataToStorage } from '../../utils';
import AvatarMenu from './avatarDropDown';

interface IHeader {
  collapsed: boolean;
  layoutColor?: string;
  menuKey: string;
  setCollapsed: (b: boolean) => void;
}
interface CropsType extends DefaultOptionType {
  current: boolean;
}

const modules = [
  'HOME',
  'CONTRATO',
  'CLIENTE',
  'USUARIOS',
  'CARGAS',
  'MINHA_EMPRESA',
  'scheduleRules',
  'ruleSettings',
  'tutorials',
  'reports',
];

const Header = ({ menuKey }: IHeader): ReactElement => {
  const { t } = useTranslation();

  const { company, userData, changingCropFlag, setChangingCropFlag } =
    useContext(UserContext);

  const [cropOptions, setCropOptions] = useState<CropsType[]>();
  const [fetchCrops, isLoadingCrops] = useRequest(UserController.getCrops);
  const cropFromStorage = getDataFromStorage('crop');
  const isChangeCropAvailable = modules.includes(menuKey);

  const renderLogo = userData && (
    <Image
      preview={false}
      src={company?.logoUrl ?? company?.logoUrl}
      style={{ width: 65, height: 50, marginLeft: 30, borderRadius: 8 }}
    />
  );

  useEffect(() => {
    if (userData) {
      fetchCrops(userData.organization.id)
        .then(crops => {
          const sortedCrops = crops
            .sort((a, b) => {
              const startDateA = moment(a.startDate).clone();
              const startDateB = moment(b.startDate).clone();
              return startDateB.isAfter(startDateA) ? 1 : -1;
            })
            .map(crop => ({
              value: crop.id,
              label: crop.year,
              current: crop.current,
            }));

          if (!cropFromStorage) {
            const currCrop = sortedCrops.find(({ current }) => current);
            if (currCrop) setDataToStorage('crop', currCrop.value);
          }

          setCropOptions(sortedCrops);
        })
        .catch(() => message.error(t('headerMenu.crop.errors.cantFind')));
    }
  }, [userData]);

  return (
    <Layout.Header
      style={{
        padding: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: userData ? 'space-between' : 'end',
        position: 'sticky',
        top: 0,
        zIndex: 1051,
      }}
    >
      {renderLogo}

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Space size={24}>
          <Tooltip
            title={
              isChangeCropAvailable ? '' : t(`headerMenu.crop.canNotChange`)
            }
          >
            <Select
              size="small"
              options={cropOptions}
              style={{ width: 150 }}
              disabled={!isChangeCropAvailable}
              loading={isLoadingCrops}
              dropdownStyle={{ textAlign: 'center' }}
              defaultValue={cropFromStorage}
              onChange={value => {
                setDataToStorage('crop', value);
                setChangingCropFlag(!changingCropFlag);
              }}
            />
          </Tooltip>
          <AvatarMenu />
        </Space>
      </div>
    </Layout.Header>
  );
};

export default Header;
