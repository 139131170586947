import {
  Card,
  Col,
  Collapse,
  Form,
  Input,
  Layout,
  PageHeader,
  Row,
} from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import Formatter from '../../classes/Formatter';
import { useRequest } from '../../hooks/useRequest';
import TutorialsController from '../../structures/controllers/Tutorials';
import { ITutorial } from '../../structures/interfaces/Tutorials';
import { scrollOptions } from '../../utils/formOptions';

const { Panel } = Collapse;

const { Search } = Input;

const Tutorials = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const [form] = Form.useForm();

  const [tutorials, setTutorials] = useState<Array<ITutorial>>([]);
  const [filteredTutorials, setFilteredTutorials] = useState<Array<ITutorial>>(
    []
  );
  const [getTutorialsRequest, isGetTutorialsRequesting] = useRequest(
    TutorialsController.tutorials
  );

  const baseString = 'pages.settings.tutorials';

  const buildSlug = (tutorials: ITutorial[]) =>
    tutorials?.map(tutorial => ({
      ...tutorial,
      slug: Formatter.removeAccents(
        `
        ${tutorial.title}
        ${tutorial.description}
      `.toLowerCase()
      ),
    }));

  const onFinish = ({ search = '' }: { search?: string }) => {
    if (tutorials) {
      setFilteredTutorials(() =>
        tutorials.filter(tutorial => tutorial.slug?.includes(search.trim()))
      );
    }
  };

  useEffect(() => {
    getTutorialsRequest({})
      .then(value => {
        const slugVideos = buildSlug(value);
        setTutorials(slugVideos);
        setFilteredTutorials(slugVideos);
      })
      .catch(() => 'teste');
  }, []);

  return (
    <Layout>
      <PageHeader
        title={t(`${baseString}.title`)}
        subTitle={t(`${baseString}.subtitle`)}
        ghost={false}
      />
      <Layout style={{ margin: 24 }}>
        <Card
          loading={isGetTutorialsRequesting}
          title={`${t(`${baseString}.totalVideos`)} ${tutorials.length}`}
        >
          <Form
            scrollToFirstError={scrollOptions}
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                <Form.Item name="search" label={' '}>
                  <Search
                    enterButton
                    onSearch={() => form.submit()}
                    placeholder={t(`${baseString}.searchVideoPlaceholder`)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Collapse>
            {filteredTutorials?.map(tutorial => (
              <Panel key={tutorial.id} header={tutorial.title}>
                <a target={'_blank'} href={tutorial.url}>
                  {tutorial.url}{' '}
                </a>
              </Panel>
            ))}
          </Collapse>
        </Card>
      </Layout>
    </Layout>
  );
};

export default withTranslation()(Tutorials);
