import { Alert, Button, Modal, Typography, message } from 'antd';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { useRequest } from '../../../../hooks/useRequest';
import ScheduleRulesController from '../../../../structures/controllers/ScheduleRules';
import { useEffect, useState } from 'react';

const DeleteRule = ({
  t,
  visible,
  onClose,
  onConfirm,
  ruleId,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  ruleId: string;
}) => {
  const [deleteScheduleRule, isDeleteScheduleRuleRequesting] = useRequest(
    ScheduleRulesController.deleteScheduleRule
  );
  const [countRulesRequest] = useRequest(
    ScheduleRulesController.countRuleTruckLoads
  );
  const [ruleAlreadyUse, setRuleAlreadyUse] = useState<number>(0);

  const info = () => {
    message.success({
      content: t('pages.settings.deleteScheduleRule.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };
  const infoError = () => {
    message.error(t('pages.settings.deleteScheduleRule.errors.message'));
  };
  const { Title } = Typography;
  const footerModal = [
    <Button
      key="back"
      danger
      onClick={onClose}
      disabled={isDeleteScheduleRuleRequesting}
    >
      {t('pages.settings.deleteBranchCompany.cancelButton')}
    </Button>,
    <Button
      type="primary"
      htmlType="submit"
      onClick={() => {
        deleteScheduleRule({ id: ruleId })
          .then(() => {
            info();
            onConfirm();
          })
          .catch(() => {
            infoError();
          });
      }}
      loading={isDeleteScheduleRuleRequesting}
    >
      {t('pages.settings.deleteBranchCompany.confirmButton')}
    </Button>,
  ];

  useEffect(() => {
    countRulesRequest({ ruleId }).then(value => setRuleAlreadyUse(value));
  }, [visible]);

  return (
    <Modal width={'50%'} open={visible} onCancel={onClose} footer={footerModal}>
      <Title level={5}>{t('pages.settings.deleteScheduleRule.title')}</Title>
      <p>
        {t('pages.settings.deleteScheduleRule.info')}
        {'.'}
      </p>
      <p>{t('pages.settings.deleteScheduleRule.warning')}</p>
      <p>{t('pages.settings.deleteScheduleRule.confirmMessage')}</p>
      {ruleAlreadyUse > 0 && (
        <Alert
          type="warning"
          message={
            <>
              {t(
                'pages.scheduleRules.customerRuleModals.delete.ruleAlreadyUse.firstPart'
              )}
              {'  '}
              {ruleAlreadyUse}
              {'  '}
              {t(
                'pages.scheduleRules.customerRuleModals.delete.ruleAlreadyUse.secondPart'
              )}
            </>
          }
          description={t(
            'pages.scheduleRules.customerRuleModals.delete.ruleAlreadyUse.warningUserOfAction'
          )}
        />
      )}
    </Modal>
  );
};

export default withTranslation()(DeleteRule);
