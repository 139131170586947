import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Layout,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactElement, useContext, useEffect, useState } from 'react';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import VehiclesController from '../../../structures/controllers/Vehicle';
import { IVehicle } from '../../../structures/interfaces/Vehicle';
import { BuildVehiclesSlug } from './Vehicles.utils';
import VehiclesFilter from './VehiclesPage.filter';
import UpdateVehicle, { IUpdateVehicleFormValues } from './VehiclesPage.update';

const VehiclesPage = (): ReactElement => {
  const [form] = Form.useForm<IUpdateVehicleFormValues>();
  const { userData } = useContext(UserContext);

  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [filteredVehicles, setFilteredVehicles] = useState<IVehicle[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();
  const [openForm, setOpenForm] = useState(false);

  const [fetchVehicles, isLoading] = useRequest(VehiclesController.getVehicles);
  const [deleteVehicle, isDeleting] = useRequest(
    VehiclesController.deleteVehicle
  );

  const doFetchVehiclesRequest = () => {
    if (userData) {
      fetchVehicles({ organizationId: userData.organization.id })
        .then(res => {
          const slugRes = BuildVehiclesSlug(res);
          setVehicles(slugRes);
          setFilteredVehicles(slugRes);
        })
        .catch(() => message.error('Erro ao buscar veículos'));
    }
  };
  const handleDeleteVehicle = (vehicleId: string) => {
    deleteVehicle({ vehicleId })
      .then(() => {
        message.success('Veiculo excluído com sucesso!');
        doFetchVehiclesRequest();
      })
      .catch(() => message.error('Erro ao excluir o veículo!'));
  };

  const vehiclesColumns: ColumnsType<IVehicle> = [
    {
      title: 'Modelo',
      dataIndex: 'vehicleModel',
      key: 'vehicleModel',
    },
    {
      title: 'Capacidade em KG',
      dataIndex: 'weightCapacity',
      key: 'weightCapacity',
      render: (weightCapacity: number) =>
        Formatter.formatKgInTon(weightCapacity),
    },
    {
      title: 'Capacidade em BAGS',
      dataIndex: 'bagsCapacity',
      key: 'bagsCapacity',
    },
    {
      title: 'Quantidade de eixos',
      dataIndex: 'axlesAmount',
      key: 'axlesAmount',
    },
    {
      title: 'Carroceria',
      dataIndex: 'bodywork',
      key: 'bodywork',
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedVehicle(record);
              setOpenForm(true);
            }}
          >
            Editar
          </Button>

          <Popconfirm
            title={'Tem certeza que deseja excluir?'}
            onConfirm={() => handleDeleteVehicle(record.id)}
          >
            <Button type="text" danger icon={<DeleteOutlined />}>
              Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    doFetchVehiclesRequest();
  }, []);

  return (
    <Layout>
      <PageHeader
        ghost={false}
        title="Veículos"
        subTitle="Configure e visualize os veículos cadastrados"
      />
      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card>
              <VehiclesFilter
                setFilteredVehicles={setFilteredVehicles}
                vehicles={vehicles}
              />
            </Card>
          </Col>
          <Col span={24}>
            <Alert
              message="Atenção!"
              description="Os veículos desta página se referem apenas aos cadastrados pela empresa, não incluindo os veículos padrão do sistema."
              type="warning"
            />
          </Col>
          <Col span={24}>
            <Card
              title="Veículos cadastrados"
              extra={
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setOpenForm(true)}
                >
                  Cadastrar novo veículo
                </Button>
              }
            >
              <Table
                size="small"
                rowKey={vehicle => vehicle.id}
                columns={vehiclesColumns}
                dataSource={filteredVehicles}
                loading={isLoading || isDeleting}
              />
            </Card>
          </Col>
        </Row>
      </Layout.Content>

      <Modal
        open={openForm}
        width={1000}
        title={selectedVehicle ? 'Atualizar veículo' : 'Cadastrar veículo'}
        okText={'Finalizar'}
        okButtonProps={{ icon: <CheckOutlined /> }}
        cancelButtonProps={{ icon: <CloseOutlined />, danger: true }}
        closable={false}
        onCancel={() => setOpenForm(false)}
        onOk={() => {
          form.submit(), setOpenForm(false);
        }}
      >
        <UpdateVehicle
          form={form}
          updateData={selectedVehicle}
          doListVehicles={doFetchVehiclesRequest}
        />
      </Modal>
    </Layout>
  );
};

export default VehiclesPage;
