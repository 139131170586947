import { StopOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { CSSProperties, ReactElement } from 'react';

const cellStyle: CSSProperties = {
  width: '100%',
  height: 'calc(100% - 24px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: 'gray',
};
const DefaultDisableAntdCell = ({ date }: { date: Moment }): ReactElement => {
  const classNames = ['ant-picker-cell-inner', 'ant-picker-calendar-date'];
  if (date.isSame(moment(), 'day')) {
    classNames.push('ant-picker-calendar-date-today');
  }

  return (
    <div
      className={classNames.join(' ')}
      style={{ backgroundColor: 'lightgray', marginBottom: 8 }}
    >
      <div className={`ant-picker-calendar-date-value`}>
        {date.format('DD')}
      </div>
      <div className={`ant-picker-calendar-date-content`} style={{}}>
        <div style={cellStyle}>
          <StopOutlined style={{ fontSize: 18 }} />
          <b>Indisponível</b>
        </div>
      </div>
    </div>
  );
};

export default DefaultDisableAntdCell;
