import Normalizer from './Normalizer';

class Validator {
  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {bool}
   * @description Returns true if the CNPJ is valid, false otherwise.
   */
  validateCNPJ = (text: string) => {
    const cnpj = text.replace(/[^0-9]/g, '');

    if (cnpj === '00000000000000') return false;
    const invalids = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].filter(
      number => {
        const regexp = new RegExp(`ˆ${number.repeat(14)}$`);
        return regexp.test(cnpj);
      }
    );

    if (cnpj.length !== 14) {
      return false;
    }

    if (invalids.length > 0) {
      return false;
    }

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    const digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i -= 1) {
      sum += parseInt(numbers.charAt(length - i)) * pos;
      pos -= 1;
      if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(0))) {
      return false;
    }

    length += 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i -= 1) {
      sum += parseInt(numbers.charAt(length - i)) * pos;
      pos -= 1;
      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(1))) {
      return false;
    }

    return true;
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {bool}
   * @description Returns true if the CPF is valid, false otherwise.
   */
  validateCPF = (text: string) => {
    const cpf = text.replace(/[^\d]+/g, '');

    if (
      cpf === '' ||
      cpf.length !== 11 ||
      [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ].includes(cpf)
    ) {
      return false;
    }

    let add = 0;
    for (let i = 0; i < 9; i += 1) {
      add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(9))) {
      return false;
    }

    add = 0;
    for (let i = 0; i < 10; i += 1) {
      add += parseInt(cpf.charAt(i)) * (11 - i);
    }

    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(10))) {
      return false;
    }

    return true;
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {bool}
   * @description Returns true if the RG is valid, false otherwise.
   */
  validateRG = (text: string) => {
    const rg = text.replace(/[.]|[-]/g, '');

    if (rg.length > 9 || rg.length < 8) {
      return false;
    }
    return true;
  };

  /**
   * @function
   * @param {string} text Text to be formatted
   * @returns {bool}
   * @description Returns true if the cellphone is in the format (XX) X XXXX-XXXX or (XX) XXXX-XXXX
   */
  validateCellphoneWithoutDDI = (text: string) =>
    /^([0-9]{2})([0-9]{8,9}){1}$/g.test(Normalizer.onlyNumbers(text));

  /**
   * @function
   * @param {string} text - Text to be formatted
   * @returns {boolean} - Returns True if the number is in valid format
   * @description Validated land and mobile phone numbers, with or without ninth digit, and with different formats.
   */
  validatePhoneNumber = (text: string): boolean => {
    // Função auxiliar para remover caracteres não numéricos
    const cleanNumber = (text: string): string => text.replace(/\D/g, '');

    // Limpa o texto de caracteres não numéricos
    const cleanedText = cleanNumber(text);

    // Valida números de telefone com diferentes formatos
    const phoneRegex = /^(?:\d{2}\d{4,5}\d{4})$/;

    return phoneRegex.test(cleanedText);
  };

  /**
   * @function
   * @param {string} text Text to be formatted.
   * @returns {bool}
   * @description Returns true if the Email is valid, false otherwise.
   */
  validateEmail = (text: string) =>
    /^[a-zA-Z]+[a-zA-Z0-9._+-]+@([a-zA-Z0-9._-]+\.)[a-zA-Z-0-9]{2,3}/.test(
      text
    );
}

export default new Validator();
