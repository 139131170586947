import {
  ArrowRightOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Popconfirm,
  Row,
  Space,
  Statistic,
} from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../../../contexts/user';
import { useRequest } from '../../../../../hooks/useRequest';
import TruckLoadController from '../../../../../structures/controllers/TruckLoad';
import {
  IContItemsResponse,
  IStepOneData,
  IStepTwoData,
} from '../../../../../structures/interfaces/TruckLoad';
import TruckLoadFooter from '../../../components/TruckLoadFooter';
import {
  BuildSlug,
  GetCultivationColor,
  handleLoadAddition,
  removeDuplicatesCultivation,
} from '../LoadSteps.utils';
import ContractItemsTable from './StepTwo.contractItemsTable';
import TruckLoadsFilter, { ICultivationType } from './StepTwo.filter';
import LoadData from './StepTwo.loadData';
import OrderingTable from './StepTwo.orderTable';

export type ItemsToAddType = {
  [key: string]: { index: number; value: number; data: IContItemsResponse };
};
interface IStepTwoProps {
  stepOneData: IStepOneData;
  stepTwoData?: IStepTwoData;
  cancelPreSchedule: (id: string, timeout?: boolean) => void;
  setStepTwoData: (values: IStepTwoData) => void;
  nextStep: () => void;
  isCustomOrdering: boolean;
  setIsCustomOrdering: (value: boolean) => void;
}

const StepTwo = ({
  stepOneData,
  stepTwoData,
  setStepTwoData,
  cancelPreSchedule,
  nextStep,
  isCustomOrdering,
  setIsCustomOrdering,
}: IStepTwoProps): ReactElement => {
  const [contractItemsForm] = Form.useForm();
  const { userData, customerData } = useContext(UserContext);
  const [contractItems, setContractItems] = useState<IContItemsResponse[]>([]);
  const [filterContItems, setFilterContItems] = useState<IContItemsResponse[]>(
    []
  );

  const value = stepTwoData?.addedItems || [];
  const [itemsToAdd, setItemsToAdd] = useState<ItemsToAddType>({});
  const [addedItems, setAddedItems] = useState<IContItemsResponse[]>(value);
  const [cultivations, setCultivations] = useState<ICultivationType[]>([]);

  const [fetchContractItems, isFetchingCI] = useRequest(
    TruckLoadController.listContractItems
  );

  const history = useHistory();

  const { cancel } = stepOneData;

  const handleFinish = () => {
    setStepTwoData({ addedItems, contractItems });
    nextStep();
  };

  const addItemsButton = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => {
        handleLoadAddition({
          addedItems,
          contractItems,
          contractItemsForm,
          itemsToAdd,
          setAddedItems,
          setContractItems,
          setItemsToAdd,
          unity: stepOneData?.unity || 'BAG',
          setFilterContItems,
        });
      }}
    >
      Adicionar itens a carga
    </Button>
  );
  const removeAllButton = (
    <Popconfirm
      title="Tem certeza que deseja remover todos os itens da carga?"
      okText="Sim"
      cancelText="Não"
      onConfirm={() => setAddedItems([])}
    >
      <Button danger icon={<DeleteOutlined />}>
        Remover tudo
      </Button>
    </Popconfirm>
  );

  const loadData = {
    availabilityBags: stepOneData.cadence?.availableUnits || 0,
    name: stepOneData.loadName || '---',
    pickupLocation: stepOneData.pickupLocation?.title || '---',
    seedType: stepOneData.seedType || '---',
    selectedDate: stepOneData.selectedDate?.toISOString() || '---',
    selectedVehicle: stepOneData.vehicle?.vehicleModel || '---',
    vehicleMaxCapacity: stepOneData.vehicle?.bagsCapacity || 0,
  };

  useEffect(() => {
    const organizationId =
      userData?.organization.id || customerData?.organizationId || '';

    fetchContractItems({
      unity: 'BAG',
      organizationId,
      seedTypeId: 'Soja',
      excludeTsi: false,
      preScheduleId: stepOneData?.preScheduleId,
    })
      .then(res => {
        const map = new Map<string, { cultivation: string; color: string }>();
        const filterRemainingItems = res
          .filter(item => item.remainingAmount > 0)
          .map(item => {
            if (map.has(item.cultivation)) {
              return {
                ...item,
                contractKey: item.contract.referenceNumber,
                color: map.get(item.cultivation)?.color,
              };
            } else {
              const color = GetCultivationColor(item.cultivation);
              map.set(item.cultivation, {
                color,
                cultivation: item.cultivation,
              });
              return {
                ...item,
                contractKey: item.contract.referenceNumber,
                color,
              };
            }
          });
        const contractItemsControl = BuildSlug(filterRemainingItems);
        const cultivationOptions = contractItemsControl.map(item => {
          return {
            value: item.cultivation,
            color: item.color || '#000000',
            name: item.cultivation,
            label: item.cultivation,
          };
        });
        const aux = removeDuplicatesCultivation(cultivationOptions);
        setCultivations(aux);
        setContractItems(contractItemsControl);
        setFilterContItems(contractItemsControl);
      })
      .catch(() => null);
  }, []);

  return (
    <>
      <Row gutter={[24, 24]} style={{ marginBottom: 94 }}>
        <Col span={24}>
          <Card title="Informe os dados básicos da carga">
            <LoadData data={loadData} />
          </Card>
        </Col>

        <Col span={24}>
          <Card>
            <TruckLoadsFilter
              cultivations={cultivations}
              contractItems={contractItems}
              setFilteredContractItems={setFilterContItems}
              isCustomOrdering={isCustomOrdering}
              setIsCustomOrdering={setIsCustomOrdering}
            />
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Itens do pedido">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <ContractItemsTable
                  data={filterContItems}
                  form={contractItemsForm}
                  itemsToAdd={itemsToAdd}
                  setItemsToAdd={setItemsToAdd}
                  isLoading={isFetchingCI}
                />
              </Col>

              <Col span={24} style={{ textAlign: 'right' }}>
                {addItemsButton}
              </Col>
            </Row>
          </Card>
        </Col>

        {isCustomOrdering && (
          <Col span={24}>
            <Card title="Carga formada" extra={removeAllButton}>
              <OrderingTable
                filterItems={filterContItems}
                setFilterItems={setFilterContItems}
                itemsToAdd={itemsToAdd}
                data={addedItems}
                contractItemsForm={contractItemsForm}
                setData={setAddedItems}
              />
            </Card>
          </Col>
        )}
      </Row>

      <TruckLoadFooter>
        <Space size={32} style={{ margin: '24px 0' }}>
          <Statistic.Countdown
            title={'Tempo restante'}
            valueStyle={{ lineHeight: '20px' }}
            value={cancel?.expiresIn}
            onFinish={() => {
              if (cancel?.id) return cancelPreSchedule(cancel.id, true);
            }}
          />

          <Space>
            <Popconfirm
              title={'Tem certeza que deseja cancelar o agendamento?'}
              onConfirm={() => history.push('/cargas')}
            >
              <Button danger icon={<CloseOutlined />}>
                Cancelar
              </Button>
            </Popconfirm>

            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              onClick={handleFinish}
              disabled={addedItems.length === 0}
            >
              Próximo
            </Button>
          </Space>
        </Space>
      </TruckLoadFooter>
    </>
  );
};

export default StepTwo;
