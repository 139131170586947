import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
  TimePicker,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { TFunction } from 'i18next';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { withTranslation } from 'react-i18next';
import Normalizer from '../../../../../../classes/Normalizer';

interface IWeekendCardProps {
  t: TFunction;
  weekendFormView: boolean;
  setWeekendFormView: Dispatch<SetStateAction<boolean>>;
  formCadences: FormInstance;
  form: FormInstance;
  cadencesColSize: any;
  validValueRule: any;
  capacityPerCompany: number;
  setConfirmBtn: Dispatch<SetStateAction<boolean>>;
  clickConfirmBtn: boolean;
  setClickConfirmBtn: Dispatch<SetStateAction<boolean>>;
  generalCapacity: number;
  saturdayCapacity: number;
  sundayCapacity: number;
}

const { Text } = Typography;

const WeekendCard = ({
  t,
  weekendFormView,
  cadencesColSize,
  capacityPerCompany,
  form,
  formCadences,
  setClickConfirmBtn,
  setConfirmBtn,
  setWeekendFormView,
  validValueRule,
  clickConfirmBtn,
}: IWeekendCardProps): ReactElement<unknown> => {
  return (
    <Card bordered={false}>
      <Form.Item>
        <Checkbox
          name="weekDetails"
          checked={weekendFormView}
          onChange={value => {
            setWeekendFormView(value.target.checked);
            if (value.target.checked === false) {
              formCadences.resetFields();
            } else {
              form.validateFields(['generalHours']);
            }
          }}
        >
          {t('pages.scheduleRules.weekDetails')}
        </Checkbox>
      </Form.Item>

      {weekendFormView && (
        <>
          <div>
            <Text>{t('pages.scheduleRules.newRules.cadences.saturday')}</Text>
            <Row style={{ marginTop: 12 }} gutter={24}>
              <Col {...cadencesColSize}>
                <Form.Item
                  validateFirst
                  normalize={Normalizer.onlyNumbers}
                  name={['saturday', 'quantity']}
                  label={t('pages.scheduleRules.newRules.cadences.quantity')}
                  rules={[
                    () => validValueRule,
                    {
                      validator: (_, value) => {
                        if (capacityPerCompany && value) {
                          return value > capacityPerCompany
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  t(
                                    'pages.scheduleRules.maxCapacityPerDayError'
                                  )
                                )
                              );
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    onChange={() => {
                      form.validateFields();
                    }}
                    style={{ width: '100%' }}
                    placeholder={t(
                      'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
                    )}
                  />
                </Form.Item>
              </Col>

              <Col {...cadencesColSize}>
                <Form.Item
                  validateFirst
                  name={['saturday', 'hours']}
                  label={t('pages.scheduleRules.newRules.cadences.schedule')}
                  rules={[() => validValueRule]}
                >
                  <TimePicker.RangePicker
                    format={'HH:mm'}
                    style={{ width: '100%' }}
                    allowClear={false}
                    placeholder={[
                      t('pages.scheduleRules.editRules.startTime'),
                      t('pages.scheduleRules.editRules.endTime'),
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Text>{t('pages.scheduleRules.newRules.cadences.sunday')}</Text>
            <Row style={{ marginTop: 12 }} gutter={24}>
              <Col {...cadencesColSize}>
                <Form.Item
                  validateFirst
                  normalize={Normalizer.onlyNumbers}
                  name={['sunday', 'quantity']}
                  label={t('pages.scheduleRules.newRules.cadences.quantity')}
                  rules={[
                    () => validValueRule,
                    {
                      validator: (_, value) => {
                        if (capacityPerCompany && value) {
                          return value > capacityPerCompany
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  t(
                                    'pages.scheduleRules.maxCapacityPerDayError'
                                  )
                                )
                              );
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder={t(
                      'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
                    )}
                    onChange={() => {
                      form.validateFields();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col {...cadencesColSize}>
                <Form.Item
                  name={['sunday', 'hours']}
                  label={t('pages.scheduleRules.newRules.cadences.schedule')}
                >
                  <TimePicker.RangePicker
                    format={'HH:mm'}
                    style={{ width: '100%' }}
                    allowClear={false}
                    placeholder={[
                      t('pages.scheduleRules.editRules.startTime'),
                      t('pages.scheduleRules.editRules.endTime'),
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </>
      )}

      <Row>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setConfirmBtn(true);
              setClickConfirmBtn(!clickConfirmBtn);
            }}
          >
            {t('pages.editCompany.confirmBtn')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(WeekendCard);
