import { CheckOutlined, PercentageOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, InputNumber, message, Row } from 'antd';
import { ReactElement, useContext, useEffect } from 'react';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import SettingsController from '../../../structures/controllers/Settings';
import { RuleObject } from 'antd/lib/form';

const toleranceTooltip =
  'A regra é opcional, caso deseje desativá-la, insira a porcentagem como 0';

const ToleranceForm = (): ReactElement => {
  const [form] = Form.useForm<{ tolerancePercentage: number }>();
  const { settings, userData, setSettings } = useContext(UserContext);

  const [updateTolerance, isLoading] = useRequest(
    SettingsController.updatePercentageTolerance
  );

  const validValueRule = {
    validator(rule: RuleObject, value: number) {
      if (value === null && !rule.required) {
        return Promise.resolve();
      }
      if (value < 0) {
        return Promise.reject('A tolerência não pode ser menor que 0% (zero)');
      }
      if (value > 100) {
        return Promise.reject('A tolerância não pode ser maior que 100%');
      }

      return Promise.resolve();
    },
  };

  const onFinish = (value: { tolerancePercentage: number }) => {
    if (userData) {
      updateTolerance({ organizationId: userData.organization.id, ...value })
        .then(res => {
          message.success('Porcentagem de tolerência alterada');
          setSettings(res);
        })
        .catch(() => message.error('Erro ao alterar tolerência'));
    }
  };

  useEffect(() => {
    const initialValue = settings?.tolerancePercentage || 0;
    form.setFieldsValue({ tolerancePercentage: initialValue });
  }, [settings]);

  return (
    <Card title="Porcentagem de tolerancia">
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row>
          <Col span={6}>
            <Form.Item
              name={'tolerancePercentage'}
              rules={[() => validValueRule]}
              label="Regra geral de tolerância"
              tooltip={toleranceTooltip}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                addonAfter={<PercentageOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={form.submit}
        loading={isLoading}
      >
        Confirmar
      </Button>
    </Card>
  );
};

export default ToleranceForm;
