import { useContext, useEffect } from 'react';

import { TFunction, withTranslation } from 'react-i18next';

import { Redirect, useLocation } from 'react-router-dom';

import { setPageTitle } from '../../utils';
import { IMenuKey } from '../../utils/menuOptions';

import LayoutContext from '../../contexts/layout';
import UserContext from '../../contexts/user';
import MainLayout from '../mainLayout/MainLayout';
import LoadingPage from '../../pages/loadingPage';
import FirstAccessLayout from '../firstAccessLayout';

export interface IRenderComponentProps {
  children: JSX.Element;
  menuKey?: IMenuKey;
  useMainLayout?: boolean;
  useFirstAccessLayout?: boolean;
  tabTitle: string;
  absoluteTabTitle?: boolean;
  publicRoute?: boolean;
  authRoute?: boolean;
  t: TFunction;
}
/**
 * @description Render page component inside Route.
 * @property {boolean | undefined} [absoluteTabTitle = false] Set navigator tab title without any modification
 * @property {boolean | undefined} [authRoute = false] Whether is an authentication route
 * @property {boolean | undefined} [publicRoute = false] Whether the route should'nt need a authentication token
 * @property {boolean | undefined} [useMainLayout = false] Render Component inside MainLayout
 * @property {IMenuKey | undefined} [menuKey] Sidebar Menu option key
 * @property {JSX.Element} [children] Component to render
 * @property {string} [tabTitle] Navigator tab title
 *
 * @example
 * <Route
 *   path="/home"
 *   render={() => (
 *     <RenderComponent
 *       menuKey="home"
 *       absoluteTabTitle
 *       tabTitle={t('tab.authentication')}
 *     >
 *       <Home />
 *     </RenderComponent>
 *   )}
 * />;
 */

const RenderComponent = ({
  children,
  tabTitle,
  menuKey,
  absoluteTabTitle = false,
  useMainLayout = false,
  useFirstAccessLayout = false,
  publicRoute = false,
  authRoute = false,
  t,
}: IRenderComponentProps): JSX.Element => {
  const { pathname } = useLocation();
  const {
    userData,
    customerData,
    firstLoginCustomer,
    firstLoginData,
    filterByRead = [],
    filterByCreate = [],
    filterByUpdate = [],
  } = useContext(UserContext);

  const customerPaths = [
    '/home',
    '/contratos',
    '/cargas',
    '/cargas/:id',
    '/cargas/formar-carga',
    '/conta',
  ];

  const allowedReadPaths: Array<string | string[]> = filterByRead
    .map(item => {
      const permissions: Record<string, string | string[]> = {
        HOME: '/home',
        CONTRATO: '/contratos',
        CLIENTE: ['/clientes', '/clientes/contatos', '/clientes/fazendas'],
        CARGAS: ['/cargas'],
        USUARIOS: ['/usuarios'],
        REGRAS: ['/regras/configuracoes', '/regras'],
      };

      return item ? permissions[item.userPermissions.module] : '';
    })
    .flat()
    .filter(path => path !== undefined);
  allowedReadPaths.push('/conta');

  const allowedCreatePaths: Array<string | string[]> = filterByCreate
    .map(item => {
      const permissions: Record<string, string | string[]> = {
        CARGAS: ['/cargas/formar-carga'],
        CONTRATO: ['/contratos/importar'],
        CLIENTE: [
          '/clientes/importar',
          '/clientes/contatos',
          '/clientes/fazendas',
        ],
        USUARIOS: ['/usuarios/novo'],
        REGRAS: ['/regras/nova-regra'],
      };

      return item ? permissions[item.userPermissions.module] : '';
    })
    .flat()
    .filter(path => path !== undefined);

  const allowedUpdatePaths: Array<string | string[]> = filterByUpdate
    .map(item => {
      const permissions: Record<string, string | string[]> = {
        REGRAS: ['/regras/editar'],
        CARGAS: ['/cargas/:id'],
      };

      return item ? permissions[item.userPermissions.module] : '';
    })
    .flat()
    .filter(path => path !== undefined);

  const allowedPaths = [];
  allowedPaths.push(allowedReadPaths, allowedCreatePaths, allowedUpdatePaths);
  const paths = allowedPaths.flat();

  const isDynamicRoute = (path: string | string[]) => {
    if (Array.isArray(path)) {
      return false;
    }

    const dividedPathname = path.split('/').filter(value => value.length);

    if (!dividedPathname.some(currPath => currPath.includes(':'))) {
      return false;
    }

    const dividedPathGoal = pathname.split('/').filter(value => value.length);

    return dividedPathname.reduce((prev, curr, index) => {
      if (curr.includes(':')) {
        return true;
      }
      return prev && curr === dividedPathGoal[index];
    }, true);
  };

  const hasUserPermission =
    userData && paths.some(path => path === pathname || isDynamicRoute(path));

  const hasCustomerPermission =
    customerData &&
    customerPaths.some(path => path === pathname || isDynamicRoute(path));

  if (
    userData === undefined &&
    customerData === undefined &&
    firstLoginData === undefined &&
    firstLoginCustomer === undefined
  ) {
    return <LoadingPage />;
  }

  if (authRoute && (userData || customerData)) {
    return <Redirect to="/home" />;
  }

  if (publicRoute || userData || customerData) {
    setPageTitle(t(tabTitle), absoluteTabTitle);
    if (publicRoute || hasUserPermission || hasCustomerPermission) {
      if (useMainLayout && menuKey) {
        return <MainLayout menuKey={menuKey}>{children}</MainLayout>;
      } else if (useFirstAccessLayout) {
        return <FirstAccessLayout>{children}</FirstAccessLayout>;
      } else {
        return <>{children}</>;
      }
    } else {
      return <Redirect to="/home" />;
    }
  } else {
    return <Redirect to="/auth" />;
  }
};

export default withTranslation()(RenderComponent);
