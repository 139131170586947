import { Button, Form, FormInstance, InputNumber, TimePicker } from 'antd';
import { TFunction } from 'i18next';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { withTranslation } from 'react-i18next';
import { ICreateCadencesWeekDayForm } from '../../../../../structures/interfaces/ScheduleRules';

interface ICreateCalendar {
  t: TFunction;
  form: FormInstance;
  onFinish: (values: ICreateCadencesWeekDayForm) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const CreateCadenceInCalendar = ({
  t,
  form,
  onFinish,
  setShowModal,
}: ICreateCalendar): ReactElement => {
  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="quantity" label="Max: ">
          <InputNumber
            style={{ width: '100%' }}
            placeholder={t('pages.scheduleRules.editCalendarInputPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name="hours"
          label={t('pages.scheduleRules.editCalendarLabelPlaceholder')}
        >
          <TimePicker.RangePicker
            placeholder={[
              t('pages.scheduleRules.editRules.startTime'),
              t('pages.scheduleRules.editRules.endTime'),
            ]}
            format={'HH:mm'}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button
              style={{ marginRight: 24 }}
              danger
              onClick={e => {
                e.stopPropagation();
                setShowModal(false);
              }}
            >
              {t('pages.settings.deleteBranchCompany.cancelButton')}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              onClick={e => {
                e.stopPropagation();
                setShowModal(false);
              }}
            >
              {t('pages.scheduleRules.createCadenceBtn')}
            </Button>
          </Form.Item>
        </Form.Item>
      </Form>
    </>
  );
};

export default withTranslation()(CreateCadenceInCalendar);
