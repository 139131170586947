import {
  Button,
  Card,
  Form,
  Popconfirm,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import Formatter from '../../../../../classes/Formatter';
import UserContext from '../../../../../contexts/user';
import { useRequest } from '../../../../../hooks/useRequest';
import VehiclesController from '../../../../../structures/controllers/Vehicle';
import {
  IUpdateVehicle,
  IVehicle,
} from '../../../../../structures/interfaces/Vehicle';
import { IErrorMessage } from '../../../../../utils/interfaces';
import CreateUpdateVehicleModal from './createUpdateVehicleModal';

const TRANSLATION_PATH = 'pages.vehicles';

const Vehicles = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const [vehicles, setVehicles] = useState<IVehicle[]>();
  const [vehicleToUpdate, setVehicleToUpdate] = useState<IUpdateVehicle>();

  const [pageSize, setPageSize] = useState(5);
  const [
    isCreateUpdateVehicleModalVisible,
    setIsCreateUpdateVehicleModalVisible,
  ] = useState(false);

  const { userData } = useContext(UserContext);

  const [getVehiclesRequest, isGetVehiclesRequesting] = useRequest(
    VehiclesController.getVehicles
  );
  const [deleteVehicleRequest, isDeleteVehicleRequesting] = useRequest(
    VehiclesController.deleteVehicle
  );

  const { filterByCreate, filterByUpdate, filterByDelete } =
    useContext(UserContext);

  const createVehiclePermission = filterByCreate?.find(
    permission => permission?.userPermissions.module === 'REGRAS'
  );
  const updateVehiclePermission = filterByUpdate?.find(
    permission => permission?.userPermissions.module === 'REGRAS'
  );
  const deleteVehiclePermission = filterByDelete?.find(
    permission => permission?.userPermissions.module === 'REGRAS'
  );

  const AddVehicle = (
    <>
      {createVehiclePermission && (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setIsCreateUpdateVehicleModalVisible(true);
          }}
          loading={isGetVehiclesRequesting || isDeleteVehicleRequesting}
        >
          {t(`${TRANSLATION_PATH}.card.add`)}
        </Button>
      )}
    </>
  );

  const handleUpdateVehicle = (vehicle: IUpdateVehicle) => {
    setIsCreateUpdateVehicleModalVisible(true);
    setVehicleToUpdate(vehicle);
  };

  const fetchVehicles = () => {
    if (userData) {
      getVehiclesRequest({ organizationId: userData?.organization.id })
        .then(vehicles => {
          setVehicles(vehicles);
        })
        .catch(err => {
          showError(err);
        })
        .finally(() => {
          setVehicleToUpdate(undefined);
        });
    }
  };

  const createUpdateVehicleModalProps = {
    visible: isCreateUpdateVehicleModalVisible,
    setVisible: setIsCreateUpdateVehicleModalVisible,
    onConfirm: () => {
      fetchVehicles();
    },
  };

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      style: { marginTop: '4rem' },
    });
  };

  const vehiclesColumns: ColumnsType<IVehicle> = [
    {
      title: t(`${TRANSLATION_PATH}.table.vehicleModel`),
      dataIndex: 'vehicleModel',
      key: 'vehicleModel',
    },
    {
      title: t(`${TRANSLATION_PATH}.table.weightCapacity`),
      dataIndex: 'weightCapacity',
      key: 'weightCapacity',
      render: (weightCapacity: number) =>
        Formatter.formatKgInTon(weightCapacity),
    },
    {
      title: t(`${TRANSLATION_PATH}.table.bagsCapacity`),
      dataIndex: 'bagsCapacity',
      key: 'bagsCapacity',
    },
    {
      title: t(`${TRANSLATION_PATH}.table.axlesAmount`),
      dataIndex: 'axlesAmount',
      key: 'axlesAmount',
    },
    {
      title: t(`${TRANSLATION_PATH}.table.bodywork`),
      dataIndex: 'bodywork',
      key: 'bodywork',
    },
    {
      title: t(`${TRANSLATION_PATH}.table.action`),
      key: 'action',
      render: (_, record: IVehicle) => (
        <Space size="middle">
          {updateVehiclePermission && (
            <Typography.Link onClick={() => handleUpdateVehicle(record)}>
              {t(`${TRANSLATION_PATH}.table.update`)}
            </Typography.Link>
          )}

          {deleteVehiclePermission && (
            <Popconfirm
              title={t(`${TRANSLATION_PATH}.table.confirm.delete`)}
              onConfirm={() => {
                deleteVehicleRequest({ vehicleId: record.id })
                  .then(() => {
                    fetchVehicles();
                    message.success({
                      content: t(`${TRANSLATION_PATH}.success.delete`),
                      style: { marginTop: '4rem' },
                    });
                  })
                  .catch(err => {
                    showError(err);
                  });
              }}
            >
              <Typography.Link type="danger">
                {t(`${TRANSLATION_PATH}.table.delete`)}
              </Typography.Link>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchVehicles();
  }, [userData]);

  return (
    <>
      <Card
        loading={isGetVehiclesRequesting}
        title={t(`${TRANSLATION_PATH}.card.title`)}
        extra={AddVehicle}
      >
        <Table
          rowKey={vehicle => vehicle.id}
          scroll={{ x: 400 }}
          columns={vehiclesColumns}
          size="small"
          dataSource={vehicles}
          loading={isGetVehiclesRequesting}
          pagination={{
            hideOnSinglePage: true,
            pageSize: pageSize,
            onChange(_, size) {
              setPageSize(size);
            },
          }}
        />
      </Card>
      {vehicleToUpdate ? (
        <CreateUpdateVehicleModal
          key={vehicleToUpdate.id}
          action="update"
          vehicleToUpdate={vehicleToUpdate}
          setVehicleToUpdate={setVehicleToUpdate}
          {...createUpdateVehicleModalProps}
        />
      ) : (
        <CreateUpdateVehicleModal
          action="create"
          {...createUpdateVehicleModalProps}
        />
      )}
    </>
  );
};

export default withTranslation()(Vehicles);
