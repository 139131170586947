import {
  CheckOutlined,
  CloseOutlined,
  RedoOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Layout,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Space,
} from 'antd';
import {
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import {
  IFarmResponse,
  IGetClient,
} from '../../../structures/interfaces/Clients';
import { defaultModalProps } from '../Clients.utils';
import ContactsFilter from './ContactsPage.filter';
import ResetContactPassword from './ContactsPage.resetPassword';
import SyncNewFarm from './ContactsPage.syncNewFarm';
import ContactsTable from './ContactsPage.table';
import UpdateContact from './ContactsPage.updateContact';
import ViewContact from './ContactsPage.viewContact';
import { BuildSlug } from './ContactsPage.utils';

export interface IOpenModalType {
  action: '' | 'syncToFarm' | 'view' | 'reset' | 'update';
}
const ContactsPage = (): ReactElement => {
  const { t } = useTranslation();
  const { userData, customerData } = useContext(UserContext);
  const [form] = Form.useForm();
  const [syncFarmsForm] = Form.useForm();

  const [contacts, setContacts] = useState<IGetClient[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<IGetClient[]>([]);
  const [selectedContact, setSelectedContact] = useState<IGetClient>();
  const [selectedFarm, setSelectedFarm] = useState<IFarmResponse>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openModal, setOpenModal] = useState<IOpenModalType>({ action: '' });

  const [sendNewAccess] = useRequest(ClientsController.sendNewCustomerAccess);
  const [fetchContacts, isFetching] = useRequest(ClientsController.getClients);
  const [syncFarms] = useRequest(ClientsController.assignCustomerHasFarm);

  const handleSyncFarms = () => {
    if (selectedContact?.id && selectedFarm?.id) {
      syncFarms({
        customerId: selectedContact.id,
        farmIds: [selectedFarm.id],
      })
        .then(() => {
          doFetchFarmsRequest();
          message.success(t('Contato vinculado com sucesso!'));
        })
        .catch(() => message.error(t('Erro ao vincular o contato!')));

      setOpenModal({ action: '' });
    }
  };

  const extraButton = (
    <Popconfirm
      title="Tem certeza que deseja disparar o e-mail?"
      okText="Sim"
      cancelText="Não"
      onConfirm={() => {
        const keys = selectedRowKeys as string[];
        sendNewAccess({ ids: keys })
          .then(() => message.success('E-mails enviados com sucesso'))
          .catch(() => message.error('Não foi possível enviar os e-mails'));
      }}
    >
      <Button type="primary" icon={<SendOutlined />}>
        Disparar e-mail de primeiro acesso
      </Button>
    </Popconfirm>
  );

  const updateContactFooter = (
    <Space>
      <Popconfirm
        title={'Tem certeza que deseja cancelar a edição?'}
        okText={'Sim'}
        cancelText={'Não'}
        onConfirm={() => setOpenModal({ action: '' })}
      >
        <Button danger icon={<CloseOutlined />}>
          Cancelar
        </Button>
      </Popconfirm>

      <Popconfirm
        title={'Concluir edição?'}
        okText={'Sim'}
        cancelText={'Não'}
        onConfirm={() => setOpenModal({ action: '' })}
      >
        <Button type="primary" icon={<CheckOutlined />}>
          Finalizar
        </Button>
      </Popconfirm>
    </Space>
  );
  const resetPasswordFooter = (
    <Space>
      <Popconfirm
        title="Tem certeza que deseja cancelar?"
        okText="Sim"
        cancelText="Não"
        onConfirm={() => setOpenModal({ action: '' })}
      >
        <Button danger icon={<CloseOutlined />}>
          Cancelar
        </Button>
      </Popconfirm>

      <Popconfirm
        title="Tem certeza que deseja redefinir a senha?"
        okText="Sim"
        cancelText="Não"
        onConfirm={() => setOpenModal({ action: '' })}
      >
        <Button type="primary" icon={<RedoOutlined />}>
          Resetar
        </Button>
      </Popconfirm>
    </Space>
  );
  const viewContactFooter = (
    <Button
      icon={<CloseOutlined />}
      onClick={() => setOpenModal({ action: '' })}
    >
      Fechar
    </Button>
  );
  const syncToFarmFooter = (
    <Space>
      <Popconfirm
        title="Tem certeza que deseja cancelar?"
        okText="Sim"
        cancelText="Não"
        onConfirm={() => {
          syncFarmsForm.resetFields();
          setSelectedFarm(undefined);
          setOpenModal({ action: '' });
        }}
      >
        <Button danger icon={<CloseOutlined />}>
          Cancelar
        </Button>
      </Popconfirm>

      <Popconfirm
        title="Concluir operação?"
        okText="Sim"
        cancelText="Não"
        onConfirm={handleSyncFarms}
      >
        <Button type="primary" icon={<CheckOutlined />}>
          Finalizar
        </Button>
      </Popconfirm>
    </Space>
  );

  const doFetchFarmsRequest = () => {
    const organizationId =
      userData?.organization.id || customerData?.organizationId;
    if (organizationId) {
      fetchContacts(organizationId)
        .then(res => {
          const slugResponse = BuildSlug(res);
          setContacts(slugResponse);
          setFilteredContacts(slugResponse);
        })
        .catch(() => message.error('Dados não encontrados!'));
    }
  };

  useEffect(() => {
    doFetchFarmsRequest();
  }, []);

  return (
    <Layout>
      <PageHeader
        title={t('pages.clients.title')}
        subTitle={t('pages.clients.subtitle')}
        ghost={false}
      />
      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card>
              <ContactsFilter
                contacts={contacts}
                setContacts={setContacts}
                filteredContacts={filteredContacts}
                setFilteredContacts={setFilteredContacts}
              />
            </Card>
          </Col>

          <Col span={24}>
            <Card title="Meus clientes cadastrados" extra={extraButton}>
              <ContactsTable
                contacts={filteredContacts}
                selectedRowKeys={selectedRowKeys}
                isLoading={isFetching}
                setSelectedRowKeys={setSelectedRowKeys}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setSelectedContact={setSelectedContact}
              />
            </Card>
          </Col>
        </Row>
      </Layout.Content>

      <Modal
        {...defaultModalProps}
        open={openModal.action === 'view'}
        title="Contato"
        footer={viewContactFooter}
      >
        <ViewContact client={selectedContact} />
      </Modal>

      <Modal
        {...defaultModalProps}
        open={openModal.action === 'syncToFarm'}
        title="Vincular contato a fazenda"
        footer={syncToFarmFooter}
      >
        <SyncNewFarm
          setSelectedFarm={setSelectedFarm}
          selectedFarm={selectedFarm}
          form={syncFarmsForm}
          selectedContact={selectedContact}
        />
      </Modal>

      <Modal
        {...defaultModalProps}
        open={openModal.action === 'reset'}
        title="Resetar senha"
        footer={resetPasswordFooter}
      >
        <ResetContactPassword data={selectedContact} form={form} />
      </Modal>

      <Modal
        {...defaultModalProps}
        open={openModal.action === 'update'}
        title="Atualizar contato"
        footer={updateContactFooter}
      >
        <UpdateContact
          data={selectedContact}
          setData={() => setOpenModal({ action: '' })}
        />
      </Modal>
    </Layout>
  );
};

export default ContactsPage;
