import Validator from '../classes/Validator';

/**
 * Inputs validation rules
 * WARNING: Neither all message returns are translated
 */

/**
 * @var nameRegex;
 * @description Regex to validate names.
 */
export const nameRegex = {
  // eslint-disable-next-line max-len
  pattern:
    /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{3,}([0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ])*([ ]+[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2,})/,
  message: 'input.rules.name_lastname',
};

/**
 * @var onlyWordsRegex;
 * @description Regex that validates fields that ca not have numbers or special characters.
 */
export const onlyWordsRegex = {
  pattern: /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ, ]+$/,
  message: 'input.rules.special_characters_number',
};

/**
 * @var specialCharactersRegex;
 * @description Regex tha validates fields that can not have special characters.
 */
export const specialCharactersRegex = {
  pattern: /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ, ]+$/,
  message: 'input.rules.special_characters',
};

/**
 * @var addressRegex;
 * @description Regex that validates address fields.
 */
export const addressRegex = {
  pattern:
    /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2,}[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ/.,\-_ ]+$/,
  message: 'input.rules.special_characters',
};

/**
 * @var numberRegex;
 * @description Regex that validates address' number fields.
 */
export const numberRegex = {
  pattern: /^[0-9A-Z]+$/,
  message: 'input.rules.number_address',
};

/**
 * @var requiredField;
 * @description Validates required fields in AntDesign fields.
 */
export const requiredField = {
  required: true,
  message: 'input.rules.required',
};

/**
 * @var minRule;
 * @description Validate if has at last 2 caracters.
 */
export const minRule = {
  min: 2,
  message: 'input.rules.min2',
};

/**
 * @var validateCpf;
 * @description Validates if CPF is valid.
 */
export const validateCpf = {
  validator: (_: unknown, value: string): Promise<void> => {
    const cleanValue = `${value}`.replace(/[^0-9]+/g, '');

    const isValid = Validator.validateCPF(cleanValue);

    if (isValid) return Promise.resolve();
    if (cleanValue === '') return Promise.resolve();
    return Promise.reject();
  },
  message: 'input.rules.invalidDocumentCPF',
};

/**
 * @var validateCnpj;
 * @description Validates if CNPJ is valid.
 */
export const validateCnpj = {
  validator: (_: unknown, value: string): Promise<void> => {
    const cleanValue = `${value}`.replace(/[^0-9]+/g, '');

    const isValid = Validator.validateCNPJ(cleanValue);

    if (isValid) return Promise.resolve();
    if (cleanValue === '') return Promise.resolve();
    return Promise.reject();
  },
  message: 'input.rules.invalidDocumentCNPJ',
};

/**
 * @var validateCpfCnpj;
 * @description Validates if CPF or CNPJ is valid.
 */
export const validateCpfCnpj = {
  validator: (_: unknown, value: string): Promise<void> => {
    let isValid = false;

    const cleanValue = `${value}`.replace(/[^0-9]+/g, '');

    if (cleanValue.length === 11) {
      isValid = Validator.validateCPF(cleanValue);
    } else if (cleanValue.length === 14) {
      isValid = Validator.validateCNPJ(cleanValue);
    }

    if (isValid) return Promise.resolve();
    return Promise.reject();
  },
  message: 'input.rules.invalidDocument',
};

/**
 * @var validatePhoneNumber;
 * @description Validates if phone number is valid.
 */
export const validatePhoneNumber = {
  validator: (_: unknown, value: string): Promise<void> => {
    const cleanValue = `${value}`.replace(/[^0-9]+/g, '');

    if (
      cleanValue.length === 10 ||
      (cleanValue.length === 11 && cleanValue[2] === '9')
    )
      return Promise.resolve();
    return Promise.reject();
  },
  message: 'input.rules.invalidPhoneNumber',
};

/**
 * @var validateZipCode;
 * @description Validates if zip code number is valid.
 */
export const validateZipCode = {
  validator: (_: unknown, value: string): Promise<void> => {
    const cleanValue = `${value}`.replace(/[^0-9]+/g, '');

    if (cleanValue.length === 8) return Promise.resolve();
    return Promise.reject();
  },
  message: 'input.rules.invalidZipCode',
};
