import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { ReactElement } from 'react';
import { IFindShippingCompanyResponse } from '../../../structures/interfaces/ShippingCompany';

interface IShippingCompanyFilterProps {
  setFilteredCarriers: (carriers: IFindShippingCompanyResponse[]) => void;
  carriers: IFindShippingCompanyResponse[];
}

const ShippingCompanyFilter = ({
  carriers,
  setFilteredCarriers,
}: IShippingCompanyFilterProps): ReactElement => {
  const [form] = Form.useForm();

  const onFinish = ({ search = '' }: { search: string }) => {
    const filteredItems = carriers.filter(item =>
      item.slug?.includes(search.toLowerCase().trim())
    );

    setFilteredCarriers(filteredItems);
  };

  return (
    <Form layout="vertical" size="small" form={form} onFinish={onFinish}>
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item label="Pesquisa" name={'search'}>
            <Input.Search
              placeholder="Pesquise itens na tabela"
              onSearch={form.submit}
              enterButton
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Space>
            <Button icon={<ClearOutlined />} onClick={() => form.resetFields()}>
              Limpar filtro
            </Button>
            <Button
              icon={<FilterOutlined />}
              type="primary"
              onClick={() => form.submit()}
            >
              Filtrar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ShippingCompanyFilter;
