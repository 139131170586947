import { Col, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import UserContext from '../../../../../contexts/user';
import { useRequest } from '../../../../../hooks/useRequest';
import TruckLoadController from '../../../../../structures/controllers/TruckLoad';
import { ITruckLoadCadences } from '../../../../../structures/interfaces/TruckLoad';
import { scrollOptions } from '../../../../../utils/formOptions';

const columns = { xs: 24, sm: 24, md: 12, lg: 12, xl: 4, xxl: 4 };

export interface IBasicDataFormValues {
  loadName: string;
  vehicle: string;
  seedType: string;
  pickUpLocation: { label: string; value: string };
  unity: string;
}
interface IBasicDataProps {
  vehicles: DefaultOptionType[];
  seedTypes: DefaultOptionType[];
  pickUpLocations: DefaultOptionType[];
  form: FormInstance<IBasicDataFormValues>;
  onFinish: (values: IBasicDataFormValues) => void;
  getPickUpLocation: (orgId: string) => void;
  setCadences: Dispatch<SetStateAction<ITruckLoadCadences[]>>;
}
const BasicData = ({
  pickUpLocations,
  seedTypes,
  vehicles,
  form,
  getPickUpLocation,
  setCadences,
  onFinish,
}: IBasicDataProps): ReactElement => {
  const { userData, customerData } = useContext(UserContext);
  const [enableUnities, setEnableUnities] = useState(false);
  const [listTruckLoadsRule, isListingTruckLoadsRule] = useRequest(
    TruckLoadController.listTruckLoadsRules
  );

  const unityOptions = [
    { value: 'BAG', label: 'BAGS' },
    { value: 'SMALL_BAG', label: 'SACAS' },
  ];

  const orgId = userData?.organization.id || customerData?.organizationId || '';

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      scrollToFirstError={scrollOptions}
    >
      <Row gutter={[24, 24]}>
        <Col {...columns}>
          <Form.Item label="Nome da Carga" name={'loadName'}>
            <Input />
          </Form.Item>
        </Col>

        <Col {...columns}>
          <Form.Item
            label="Veículo"
            name={'vehicle'}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              options={vehicles}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>

        <Col {...columns}>
          <Form.Item
            label="Cultura"
            name={'seedType'}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              options={seedTypes}
              showSearch
              optionFilterProp="children"
              disabled={seedTypes.length === 0}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onSelect={() =>
                listTruckLoadsRule({
                  organizationId: orgId,
                  seedTypeId: form.getFieldValue('seedType'),
                })
                  .then(res => {
                    if (res.length == 1) {
                      const rule = res[0];
                      form.setFieldsValue({
                        pickUpLocation: {
                          label: rule.pickUpLocation.title,
                          value: rule.pickUpLocation.id,
                        },
                        unity: rule.unity,
                      });
                      setCadences(rule.cadences);
                    } else {
                      getPickUpLocation(orgId);
                      setCadences([]);
                      form.resetFields(['pickUpLocation', 'unity']);
                    }
                  })
                  .catch()
              }
            />
          </Form.Item>
        </Col>

        <Col {...columns}>
          <Form.Item
            label="Local de embarque"
            name={'pickUpLocation'}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              options={pickUpLocations}
              disabled={pickUpLocations.length === 0}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onSelect={() =>
                listTruckLoadsRule({
                  organizationId: orgId,
                  seedTypeId: form.getFieldValue('seedType'),
                  pickUpLocation: form.getFieldValue('pickUpLocation'),
                })
                  .then(res => {
                    if (res.length == 1) {
                      const rule = res[0];
                      form.setFieldsValue({
                        pickUpLocation: {
                          label: rule.pickUpLocation.title,
                          value: rule.pickUpLocation.id,
                        },
                        unity: rule.unity,
                      });
                      setCadences(rule.cadences);
                    } else {
                      setEnableUnities(true);
                      setCadences([]);
                      form.resetFields(['unity']);
                    }
                  })
                  .catch()
              }
            />
          </Form.Item>
        </Col>

        <Col {...columns}>
          <Form.Item
            label="Unidade de medida"
            name={'unity'}
            rules={[{ required: true }]}
          >
            <Select
              options={enableUnities ? unityOptions : []}
              disabled={!enableUnities}
              onSelect={() =>
                listTruckLoadsRule({
                  organizationId: orgId,
                  seedTypeId: form.getFieldValue('seedType'),
                  pickUpLocation: form.getFieldValue('pickUpLocation'),
                  unity: form.getFieldValue('unity'),
                })
                  .then(res => {
                    if (res.length == 1) {
                      const rule = res[0];
                      form.setFieldsValue({
                        pickUpLocation: {
                          label: rule.pickUpLocation.title,
                          value: rule.pickUpLocation.id,
                        },
                        unity: rule.unity,
                      });
                      setCadences(rule.cadences);
                    } else {
                      setCadences([]);
                    }
                  })
                  .catch()
              }
            />
          </Form.Item>
        </Col>

        <Col {...columns} />
      </Row>
    </Form>
  );
};

export default BasicData;
