import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  PageHeader,
  Row,
} from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { ReactElement, useContext, useEffect } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import { IUpdateCustomerOnFirstSignIn } from '../../../structures/interfaces/Clients';
import { getDataFromStorage } from '../../../utils';
import { scrollOptions } from '../../../utils/formOptions';
import { validateCnpj, validateCpf } from '../../../utils/inputRules';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { IAuthToken } from '../../../utils/interfaces';

const ConfigureCustomerAccount = ({
  t,
}: {
  t: TFunction;
}): ReactElement<unknown> => {
  const [form] = Form.useForm();
  const { logout, firstLoginCustomer, setFirstLoginCustomer } =
    useContext(UserContext);
  const history = useHistory();

  const [
    updateCustomerOnFirstSignInRequest,
    isUpdateCustomerOnFirstSignInRequesting,
  ] = useRequest(ClientsController.updateCustomerFirstSignIn);

  const info = () => {
    message.success({
      content: t('pages.customerAccount.firstConfig.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  const infoError = () => {
    message.error({
      content: t('pages.customerAccount.firstConfig.error.message'),
      style: { marginTop: '4rem' },
    });
  };

  const onFinish = (values: IUpdateCustomerOnFirstSignIn) => {
    const reqValues = { ...values };

    delete reqValues.repeatNewPassword;
    reqValues.phoneNumber = reqValues.phoneNumber.replace(/[^0-9]/g, '');
    if (reqValues.documentNumberCpf !== null)
      reqValues.documentNumberCpf = reqValues.documentNumberCpf
        ? reqValues.documentNumberCpf.replace(/[^0-9]/g, '')
        : null;
    if (reqValues.documentNumberCnpj !== null)
      reqValues.documentNumberCnpj = reqValues.documentNumberCnpj
        ? reqValues.documentNumberCnpj.replace(/[^0-9]/g, '')
        : null;

    if (firstLoginCustomer) {
      updateCustomerOnFirstSignInRequest({
        customerData: reqValues,
        id: firstLoginCustomer.id,
      })
        .then(res => {
          setFirstLoginCustomer?.({ ...res, id: firstLoginCustomer.id });
          info();
          logout();
          history.push('/auth');
        })
        .catch(err => {
          message.error({
            content: t(err.message),
            style: { marginTop: '4rem' },
          });
        });
    }
  };

  useEffect(() => {
    const token = getDataFromStorage('token');
    if (token) {
      const decodedToken = jwtDecode<IAuthToken & JwtPayload>(`${token}`);
      if (!decodedToken.first_customer_login) {
        history.push('/auth');
      }
    } else {
      history.push('/auth');
    }
    if (firstLoginCustomer) {
      const formatedData = {
        ...firstLoginCustomer,
        documentNumberCpf: firstLoginCustomer.documentNumberCpf
          ? Formatter.formatCPF(firstLoginCustomer.documentNumberCpf)
          : '',
        documentNumberCnpj: firstLoginCustomer.documentNumberCnpj
          ? Formatter.formatCNPJ(firstLoginCustomer.documentNumberCnpj)
          : '',
        phoneNumber: firstLoginCustomer.phoneNumber
          ? Formatter.formatCellphone(firstLoginCustomer.phoneNumber)
          : '',
      };
      form.setFieldsValue(formatedData);
    }
  }, [firstLoginCustomer]);

  return (
    <>
      <Layout
        style={{
          minHeight: '100%',
          minWidth: '100%',
        }}
      >
        <PageHeader
          className="site-page-header"
          title={t('pages.customerAccount.firstConfig.title')}
          subTitle={t('pages.customerAccount.firstConfig.subtitle')}
          ghost={false}
        />
        <Layout
          style={{
            height: '100%',
            padding: 24,
          }}
        >
          <Form
            scrollToFirstError={scrollOptions}
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Card title={t('pages.customerAccount.firstConfig.basicData')}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={7} xl={5} xxl={5}>
                  <Form.Item
                    label={t('pages.customerAccount.firstConfig.name')}
                    name="name"
                  >
                    <Input
                      placeholder={t(
                        'pages.customerAccount.firstConfig.phName'
                      )}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.customerAccount.firstConfig.documentNumberCpf'
                    )}
                    name="documentNumberCpf"
                    normalize={Formatter.formatCPF}
                    validateFirst
                    rules={[
                      {
                        min: 11,
                        message: t(
                          'pages.customerAccount.firstConfig.rmDocumentNumberCpfMinSize'
                        ),
                      },
                      { ...validateCpf, message: t(validateCpf.message) },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'pages.customerAccount.firstConfig.phDocumentNumberCpf'
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card title={t('pages.customerAccount.firstConfig.companyData')}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={7} xl={5} xxl={5}>
                  <Form.Item
                    label={t('pages.customerAccount.firstConfig.tradeName')}
                    name="tradeName"
                  >
                    <Input
                      placeholder={t(
                        'pages.customerAccount.firstConfig.phTradeName'
                      )}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={7} xl={5} xxl={5}>
                  <Form.Item
                    label={t('pages.customerAccount.firstConfig.companyName')}
                    name="companyName"
                  >
                    <Input
                      placeholder={t(
                        'pages.customerAccount.firstConfig.phCompanyName'
                      )}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.customerAccount.firstConfig.documentNumberCnpj'
                    )}
                    name="documentNumberCnpj"
                    normalize={Formatter.formatCNPJ}
                    validateFirst
                    rules={[
                      {
                        min: 14,
                        message: t(
                          'pages.customerAccount.firstConfig.rmDocumentNumberCnpjMinSize'
                        ),
                      },
                      { ...validateCnpj, message: t(validateCnpj.message) },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'pages.customerAccount.firstConfig.phDocumentNumberCnpj'
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card title={t('pages.customerAccount.firstConfig.contact')}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={7} xl={6} xxl={4}>
                  <Form.Item
                    label={t('pages.customerAccount.firstConfig.phoneNumber')}
                    name="phoneNumber"
                    normalize={Formatter.formatCellphone}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.customerAccount.firstConfig.rmPhoneNumber'
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'pages.customerAccount.firstConfig.phPhoneNumber'
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card
              title={t('pages.customerAccount.firstConfig.security')}
              style={{ marginBottom: 84 }}
            >
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
                  <Form.Item
                    label={t('pages.customerAccount.firstConfig.password')}
                    name="password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.customerAccount.firstConfig.rmPassword'
                        ),
                      },
                      {
                        min: 6,
                        message: t(
                          'pages.customerAccount.firstConfig.rmMinLenPassword'
                        ),
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t(
                        'pages.customerAccount.firstConfig.phPassword'
                      )}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.customerAccount.firstConfig.repeatNewPassword'
                    )}
                    name="repeatNewPassword"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.customerAccount.firstConfig.rmRepeatNewPassword'
                        ),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              t(
                                'pages.customerAccount.firstConfig.rmComparePasswords'
                              )
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder={t(
                        'pages.customerAccount.firstConfig.phRepeatNewPassword'
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </Layout>
        <Footer
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            backgroundColor: '#FFF',
            textAlign: 'center',
            boxShadow:
              '0 -6px 16px -8px rgba(0,0,0,.08),0 -9px 28px 0 rgba(0,0,0,.05),0 -12px 48px 16px rgba(0,0,0,.03)',
          }}
        >
          <div style={{ textAlign: 'right' }}>
            <Button
              style={{ marginRight: 24 }}
              danger
              htmlType="reset"
              onClick={() => {
                history.push('/home');
              }}
            >
              {t('pages.editCompany.cancelBtn')}
            </Button>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={isUpdateCustomerOnFirstSignInRequesting}
            >
              {t('pages.editCompany.confirmBtn')}
            </Button>
          </div>
        </Footer>
      </Layout>
    </>
  );
};

export default withTranslation()(ConfigureCustomerAccount);
