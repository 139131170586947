import { PercentageOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Form,
  InputNumber,
  Row,
  Tooltip,
  message,
} from 'antd';
import { RuleObject } from 'antd/lib/form';
import { TFunction } from 'i18next';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import UserContext from '../../../../contexts/user';
import { useRequest } from '../../../../hooks/useRequest';
import SettingsController from '../../../../structures/controllers/Settings';
import { ITolerancePercentageInputFormValues } from '../../../../structures/interfaces/Settings';
import { scrollOptions } from '../../../../utils/formOptions';

const baseString = 'pages.scheduleRules.ruleSettings';

const TolerancePercentageForm = ({
  t,
}: {
  t: TFunction;
}): ReactElement<unknown> => {
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  const [changedFields, setChangedFields] =
    useState<ITolerancePercentageInputFormValues>();
  const [initialValues, setInitialValues] =
    useState<ITolerancePercentageInputFormValues>();
  const { userData, settings, loadingSettings, setSettings } =
    useContext(UserContext);

  const [form] = Form.useForm<ITolerancePercentageInputFormValues>();

  const [
    updateTolerancePercentageRequest,
    isUpdateTolerancePercentageRequesting,
  ] = useRequest(SettingsController.updatePercentageTolerance);

  const validValueRule = {
    validator(rule: RuleObject, value: number) {
      if (value === null && !rule.required) {
        return Promise.resolve();
      }
      if (value < 0) {
        return Promise.reject(
          t(`${baseString}.feedbacks.errors.percentageUnderLimit`)
        );
      }
      if (value > 100) {
        return Promise.reject(
          t(`${baseString}.feedbacks.errors.percentageOverLimit`)
        );
      }

      return Promise.resolve();
    },
  };

  const explanationMessageAlert = (
    <div>
      <p>{t(`${baseString}.alerts.explanationMessage`)}</p>
      <p>
        {t(`${baseString}.alerts.example`)} <br />
        {t(`${baseString}.alerts.exampleMessage`)}
      </p>
    </div>
  );

  const secondAlert = (
    <div>
      <p>{t(`${baseString}.alerts.optionalRuleAlert.start`)}</p>
      <p>{t(`${baseString}.alerts.optionalRuleAlert.content`)}</p>
    </div>
  );

  const handleValuesChange = (
    _: ITolerancePercentageInputFormValues,
    allFinalValues: ITolerancePercentageInputFormValues
  ) => {
    const changedFields = Object.fromEntries(
      Object.entries(allFinalValues).reduce<[string, string | undefined][]>(
        (acc, curr) => {
          if (curr[1] !== (initialValues as Record<string, unknown>)[curr[0]]) {
            return [...acc, curr];
          }
          return acc;
        },
        []
      )
    );
    setChangedFields(changedFields);
    if (Object.keys(changedFields).length > 0) setIsSubmitAvailable(true);
    else setIsSubmitAvailable(false);
  };

  const onFinish = () => {
    if (userData) {
      if (changedFields) {
        return updateTolerancePercentageRequest({
          organizationId: userData.organization.id,
          tolerancePercentage: changedFields.percentage,
        })
          .then(returnedValues => {
            setSettings(returnedValues);
            setInitialValues({
              percentage: returnedValues.tolerancePercentage,
            });
            setIsSubmitAvailable(false);
            message.success({
              content: t(`${baseString}.feedbacks.success.operationSuccess`),
              className: 'custom-class',
              style: {
                marginTop: 60,
              },
            });
          })
          .catch(error => message.error(t(error.message)));
      }
    }
  };

  useEffect(() => {
    const initialValue = {
      percentage:
        settings && settings.tolerancePercentage
          ? settings.tolerancePercentage
          : 0,
    };
    form.setFieldsValue(initialValue);
    setInitialValues(initialValue);
  }, [settings]);

  return (
    <Form
      scrollToFirstError={scrollOptions}
      form={form}
      onFinish={onFinish}
      layout="vertical"
      onValuesChange={handleValuesChange}
    >
      <Alert
        style={{ marginBottom: 24 }}
        message={t(`${baseString}.operation`)}
        description={explanationMessageAlert}
      />
      <Card
        loading={loadingSettings}
        title={t(`${baseString}.percentageTolerance`)}
      >
        <Row>
          <Form.Item
            name="percentage"
            rules={[() => validValueRule]}
            style={{ marginRight: 24 }}
            label={
              <>
                <Tooltip title={secondAlert} overlayStyle={{ width: 500 }}>
                  {t(`${baseString}.defaultPercentageRuleLabel`)}{' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <InputNumber
              size="middle"
              placeholder={t(`${baseString}.toleranceRulePlaceholder`)}
              addonAfter={<PercentageOutlined />}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label=" ">
            <Button
              loading={loadingSettings || isUpdateTolerancePercentageRequesting}
              disabled={!isSubmitAvailable}
              size="middle"
              type="primary"
              htmlType="submit"
            >
              {t(`${baseString}.confirmButton`)}
            </Button>
          </Form.Item>
        </Row>
      </Card>
    </Form>
  );
};

export default withTranslation()(TolerancePercentageForm);
