import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import { TFunction } from 'i18next';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import AddressController from '../../../structures/controllers/Address';
import {
  IEditPickUpLocation,
  ICityName,
} from '../../../structures/interfaces/Address';
import { UFNameOptions } from '../../../utils/UF';
import { scrollOptions } from '../../../utils/formOptions';

const { Title } = Typography;

const EditPickUpLocation = ({
  t,
  visible,
  onClose,
  editData,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  editData: IEditPickUpLocation;
}): ReactElement<unknown> => {
  const [ufOptions, setUfOptions] = useState<string>();
  const [cityOptions, setCityOptions] = useState<ICityName[]>([]);
  // This state ensures the error is displayed when needed
  const [error, setError] = useState<{
    message: string;
    type: 'error';
    description?: string;
  } | null>(null);

  const setState = (selectedValue: string) => {
    setUfOptions(selectedValue);
  };

  const [updatePickUpLocation, isUpdatePickUpLocationRequesting] = useRequest(
    AddressController.updatePickUpLocation
  );
  const [findCitiesForStateRequest] = useRequest(
    AddressController.findCitiesByState
  );

  const { userData, company } = useContext(UserContext);
  const [form] = Form.useForm();

  const { Option } = Select;
  const info = () => {
    message.success({
      content: t('pages.settings.editPickUpLocation.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  useEffect(() => {
    if (company && editData) {
      form.setFieldsValue({
        title: editData.title,
        zipCode: Formatter.formatZipCode(editData.zipCode),
        stateShortName: editData.stateShortName,
        cityName: editData.cityName,
        district: editData.district,
        street: editData.street,
        streetNumber: editData.streetNumber,
        complement: editData.complement === '---' ? null : editData.complement,
      });

      if (company.addresses) setState(company.addresses.stateShortName);
    }
  }, [company, editData]);

  useEffect(() => {
    if (ufOptions) {
      findCitiesForStateRequest(ufOptions)
        .then(city => {
          setCityOptions(city);
          if (
            cityOptions.find(
              city => form.getFieldValue('cityName') !== city.cityName
            )
          ) {
            form.setFieldsValue({
              cityName: null,
            });
          }
        })
        .catch(({ message, description }) =>
          setError({
            message: t(message),
            description: t(description),
            type: 'error',
          })
        );
    }
  }, [ufOptions]);

  const handleOnChange = (selectedValue: string) => {
    setUfOptions(selectedValue);
  };

  const onFinish = (values: IEditPickUpLocation) => {
    if (userData && company) {
      if (values.complement === '' || values.complement === undefined) {
        values.complement = null;
      }
      values.zipCode = values.zipCode.replace(/[^0-9]/g, '');

      updatePickUpLocation({
        updatePickUpLocation: values,
        id: editData.id,
      })
        .then(() => {
          info();
          onClose();
        })
        .catch(({ message, description }) =>
          setError({
            message: t(message),
            description: t(description),
            type: 'error',
          })
        );
    }
  };

  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 6 };

  const formPickUp = (
    <Form
      scrollToFirstError={scrollOptions}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      {error && (
        <Alert
          message={error.message}
          description={error.description}
          type={error.type}
          closable
          style={{ marginBottom: 24 }}
        />
      )}
      <Row gutter={[24, 0]}>
        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editPickUpLocation.name')}
            name="title"
            rules={[
              {
                required: true,
                message: t('pages.settings.editPickUpLocation.rmInfoName'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editPickUpLocation.phInfoName')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            normalize={Formatter.formatZipCode}
            label={t('pages.settings.editPickUpLocation.zipCode')}
            name="zipCode"
            rules={[
              {
                required: true,
                message: t('pages.settings.editPickUpLocation.rmZipCode'),
              },
              {
                min: 9,
                max: 9,
                message: t(
                  'pages.settings.editPickUpLocation.zipCodeRuleMinSize'
                ),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editPickUpLocation.phZipCode')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editPickUpLocation.stateShortName')}
            name="stateShortName"
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.editPickUpLocation.rmStateShortName'
                ),
              },
            ]}
          >
            <Select
              placeholder={t(
                'pages.settings.editPickUpLocation.phStateShortName'
              )}
              onSelect={(value: string) => handleOnChange(value)}
            >
              {UFNameOptions.map(uf => {
                return (
                  <Option key={uf.value} value={uf.value}>
                    {uf.value} - {uf.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editPickUpLocation.cityName')}
            name="cityName"
            rules={[
              {
                required: true,
                message: t('pages.settings.editPickUpLocation.rmCityName'),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t('pages.settings.editPickUpLocation.phCityName')}
              optionFilterProp="children"
            >
              {cityOptions?.map(city => {
                return (
                  <Option
                    key={`${city.cityName}_${city.id}`}
                    value={city.cityName}
                  >
                    {city.cityName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editPickUpLocation.district')}
            name="district"
            rules={[
              {
                required: true,
                message: t('pages.settings.editPickUpLocation.rmDistrict'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editPickUpLocation.phDistrict')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editPickUpLocation.street')}
            name="street"
            rules={[
              {
                required: true,
                message: t('pages.settings.editPickUpLocation.rmStreet'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editPickUpLocation.phStreet')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editPickUpLocation.streetNumber')}
            name="streetNumber"
            rules={[
              {
                required: true,
                message: t('pages.settings.editPickUpLocation.rmStreetNumber'),
              },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.editPickUpLocation.phStreetNumber'
              )}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.editPickUpLocation.complement')}
            name="complement"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.editPickUpLocation.phComplement')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const footerModal = [
    <Button key="back" danger onClick={onClose}>
      {t('pages.settings.editPickUpLocation.cancelButton')}
    </Button>,
    <Button
      key="confirm"
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
        setError(null);
      }}
      loading={isUpdatePickUpLocationRequesting}
    >
      {t('pages.settings.editPickUpLocation.confirmButton')}
    </Button>,
  ];

  return (
    <Modal width={'70%'} open={visible} onCancel={onClose} footer={footerModal}>
      <Title level={5}>{t('pages.settings.editPickUpLocation.title')}</Title>
      {formPickUp}
    </Modal>
  );
};

export default withTranslation()(EditPickUpLocation);
