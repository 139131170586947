import { Button, Card, Col, message, Row, Statistic } from 'antd';
import {
  CSSProperties,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import HomeController from '../../structures/controllers/Home';
import { IHomeTiles } from '../../structures/queries/Home';
import { useHistory } from 'react-router-dom';

const tilesInitialValue: IHomeTiles = {
  authorizedLoads: 0,
  totalLoads: 0,
  waitingDriverLoads: 0,
  loadedLoads: 0,
  canceledLoads: 0,
  finalizedContracts: 0,
  pendingContracts: 0,
};
const HomeTiles = (): ReactElement => {
  const { t } = useTranslation();
  const { userData, customerData } = useContext(UserContext);
  const [fetchTiles] = useRequest(HomeController.fetchHomeTiles);
  const [tiles, setTiles] = useState<IHomeTiles>(tilesInitialValue);
  const history = useHistory();

  useEffect(() => {
    const orgId = userData?.organization.id || customerData?.organizationId;
    if (orgId) {
      fetchTiles({ orgId })
        .then(setTiles)
        .catch(() => message.error('Erro ao buscar tiles'));
    }
  }, []);

  const statisticDefaultProps = {
    groupSeparator: '.',
  };
  const tilesetCardsStyle: CSSProperties = {
    height: '100%',
  };

  return (
    <Row gutter={[24, 24]} align={'stretch'}>
      <Col span={6}>
        <Card>
          <Statistic
            value={tiles.totalLoads}
            title={t('pages.home.totalLoads')}
            {...statisticDefaultProps}
          />

          <Button
            type="primary"
            onClick={() => history.push('/cargas/formar-carga')}
          >
            {t('pages.home.formLoadBtn')}
          </Button>
        </Card>
      </Col>

      <Col span={3}>
        <Card style={tilesetCardsStyle}>
          <Statistic
            title={t('pages.home.loadsWaitingForDriver')}
            value={tiles.waitingDriverLoads}
            {...statisticDefaultProps}
          />
        </Card>
      </Col>

      <Col span={3}>
        <Card style={tilesetCardsStyle}>
          <Statistic
            title={t('pages.home.loadsReleased')}
            value={tiles.authorizedLoads}
            {...statisticDefaultProps}
          />
        </Card>
      </Col>

      <Col span={3}>
        <Card style={tilesetCardsStyle}>
          <Statistic
            title={t('pages.home.loadedLoads')}
            value={tiles.loadedLoads}
            {...statisticDefaultProps}
          />
        </Card>
      </Col>

      <Col span={3}>
        <Card style={tilesetCardsStyle}>
          <Statistic
            title={t('pages.home.canceledLoads')}
            value={tiles.canceledLoads}
            {...statisticDefaultProps}
          />
        </Card>
      </Col>

      <Col span={3}>
        <Card style={tilesetCardsStyle}>
          <Statistic
            title={t('pages.home.pendingContracts')}
            value={tiles.pendingContracts}
            {...statisticDefaultProps}
          />
        </Card>
      </Col>

      <Col span={3}>
        <Card style={tilesetCardsStyle}>
          <Statistic
            title={t('pages.home.finishedContracts')}
            value={tiles.finalizedContracts}
            {...statisticDefaultProps}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default HomeTiles;
