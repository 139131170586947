import { Checkbox, Descriptions, Tag } from 'antd';
import moment from 'moment';
import { ReactElement, useContext } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import UserContext from '../../../../../contexts/user';
import { IGetLoginDataUser } from '../../../../../structures/interfaces/User';
import { IGetCustomer } from '../../../../../structures/interfaces/Clients';

export interface ILoadDataProps {
  name: string;
  selectedDate: string;
  selectedVehicle: string;
  vehicleMaxCapacity: number;
  availabilityBags?: number;
  pickupLocation: string;
  seedType: string;
  status?: string;
  notes?: string;
}

const renderStatus = (
  status: string,
  userData: IGetLoginDataUser | null | undefined,
  customerData: IGetCustomer | null | undefined,
  t: TFunction<'translations', 'pages.truckLoads'>
) => {
  const allStatus = {
    WAITING_DRIVER: 'orange',
    WAITING_RELEASE: 'purple',
    WAITING_COMERCIAL: 'purple',
    WAITING_ACTIONS: `${userData ? 'orange' : 'purple'}`,
    LOADED: 'green',
    LOAD_RELEASED: 'blue',
    AUTHORIZED_LOAD: 'blue',
    EXPIRED: 'default',
    LOADING: 'magenta',
  };

  const showWaitingComercial = customerData && status === 'WAITING_ACTIONS';

  const color = allStatus[status as keyof typeof allStatus] || 'red';

  if (status === '---') {
    return <Tag color="default"> --- </Tag>;
  } else {
    return (
      <Tag color={color}>
        {t(
          `viewLoad.status.${showWaitingComercial ? 'WAITING_RELEASE' : status}`
        )}
      </Tag>
    );
  }
};
const LoadData = ({
  data,
  isStepThree,
  customOrder,
  setCustomOrder,
}: {
  data: ILoadDataProps | undefined;
  isStepThree?: boolean;
  customOrder?: boolean;
  setCustomOrder?: (value: boolean) => void;
}): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.truckLoads',
  });
  const { userData, customerData } = useContext(UserContext);

  if (!data) return <></>;

  const {
    availabilityBags,
    status,
    name,
    pickupLocation,
    seedType,
    selectedDate,
    selectedVehicle,
    vehicleMaxCapacity,
    notes,
  } = data;

  return (
    <Descriptions column={4} labelStyle={{ fontWeight: 'bold' }}>
      <Descriptions.Item label="Nome da carga">{name}</Descriptions.Item>

      <Descriptions.Item label="Dia selecionado">
        {moment(selectedDate).format('DD/MM/YYYY')}
      </Descriptions.Item>

      <Descriptions.Item label="Veículo selecionado">
        {selectedVehicle}
      </Descriptions.Item>

      {status && (
        <Descriptions.Item label="Status">
          {renderStatus(status, userData, customerData, t)}
        </Descriptions.Item>
      )}

      <Descriptions.Item label="Capacidade máxima do veículo">
        {vehicleMaxCapacity}
      </Descriptions.Item>

      <Descriptions.Item label="Quantidade alocada">
        {availabilityBags}
      </Descriptions.Item>

      <Descriptions.Item label="Endereço de retirada">
        {pickupLocation}
      </Descriptions.Item>

      <Descriptions.Item label="Cultura">{seedType}</Descriptions.Item>

      {notes && (
        <Descriptions.Item label="Observação sobre a carga">
          {notes}
        </Descriptions.Item>
      )}

      {isStepThree && (
        <Descriptions.Item label="Ordenar manualmente a carga">
          <Checkbox
            checked={customOrder}
            onChange={value => setCustomOrder?.(value.target.checked)}
          />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default LoadData;
