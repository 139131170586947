import {
  CheckCircleOutlined,
  DownloadOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Card, Layout, PageHeader, Table, Tag, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ReportsController from '../../structures/controllers/Reports';
import { IReports } from '../../structures/interfaces/Reports';

const ReportsPage = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const { userData, customerData } = useContext(UserContext);
  const [pageSize, setPageSize] = useState(10);
  const [reports, setReports] = useState<IReports[]>();

  const [getReports] = useRequest(ReportsController.getReports);

  const errToGetReports = () => {
    message.error(t('pages.reports.errors.message'));
  };

  useEffect(() => {
    if (userData) {
      getReports(userData.id)
        .then(response => setReports(response))
        .catch(() => errToGetReports());
    }
    if (customerData) {
      getReports(customerData.id)
        .then(response => setReports(response))
        .catch(() => errToGetReports());
    }
  }, []);

  const tableColumnsString = 'pages.reports.table.columns';
  const tableContentString = 'pages.reports.table.content';
  const baseString = 'pages.reports';

  const reportsColumns: ColumnsType<IReports> = [
    {
      title: t(`${tableColumnsString}.type`),
      dataIndex: ['loadingOrder', 'pdfName'],
      key: 'pdfName',
      render: (_, record) => {
        if (record.loadingOrder?.pdfName) {
          return t(`${tableContentString}.loadingOrder`);
        } else {
          return '---';
        }
      },
    },
    {
      title: t(`${tableColumnsString}.loadingOrder`),
      dataIndex: ['loadingOrder', 'loadingOrder'],
      render: (_, record) => (
        <Tag color="cyan">{record.loadingOrder?.loadingOrder}</Tag>
      ),
      key: 'customerName',
    },
    {
      title: t(`${tableColumnsString}.status`),
      dataIndex: ['processing'],
      render: (_, record) => {
        if (record.processing === true) {
          return (
            <Tag icon={<SyncOutlined spin />} color="processing">
              {t(`${tableContentString}.status.processing`)}.
            </Tag>
          );
        } else {
          return (
            <Tag icon={<CheckCircleOutlined />} color="success">
              {t(`${tableContentString}.status.processed`)}
            </Tag>
          );
        }
      },
      key: 'processing',
    },
    {
      title: t(`${tableColumnsString}.downloads`),
      dataIndex: ['pdfUrl'],
      render: (_, record) => (
        <a href={record.loadingOrder?.pdfUrl} download>
          <Button
            type="primary"
            shape="round"
            ghost
            disabled={record.processing}
            loading={record.processing}
            icon={<DownloadOutlined />}
          >
            {t(`${tableContentString}.downloadBtn`)}
          </Button>
        </a>
      ),
      key: 'pdf_url',
    },
  ];

  return (
    <>
      <Layout>
        <PageHeader
          className="site-page-header"
          title={t(`${baseString}.pageheader.title`)}
          subTitle={t(`${baseString}.pageheader.subtitle`)}
          ghost={false}
        />

        <Card
          size="small"
          title={t(`${baseString}.table.title`)}
          style={{ marginRight: 24, marginLeft: 24, marginTop: 24 }}
        >
          <Table
            scroll={{ x: 1300 }}
            rowKey={record => record.id}
            size="small"
            columns={reportsColumns}
            dataSource={reports}
            pagination={{
              hideOnSinglePage: true,
              pageSize: pageSize,
              onChange(_, size) {
                setPageSize(size);
              },
            }}
          />
        </Card>
      </Layout>
    </>
  );
};

export default withTranslation()(ReportsPage);
