import React, { ReactElement } from 'react';

import UserContext, { useUserProviderContext } from './contexts/user';
import LayoutContext, { useLayoutContextProvider } from './contexts/layout';

import LoadingPage from './pages/loadingPage';

const Providers = ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement => {
  const userProviderValue = useUserProviderContext();
  const layoutProviderValue = useLayoutContextProvider();

  return (
    <LayoutContext.Provider value={layoutProviderValue}>
      <UserContext.Provider value={userProviderValue}>
        {layoutProviderValue.isLoading && <LoadingPage asOverlay />}
        {children}
      </UserContext.Provider>
    </LayoutContext.Provider>
  );
};

export default Providers;
