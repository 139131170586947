import { DefaultOptionType } from 'antd/lib/select';

export const CompanyTypes = ['parent', 'branch', 'unit'] as const;

export type ICompanyType = (typeof CompanyTypes)[number];

export enum CompanyTypeEnum {
  parent = 'PARENT',
  branch = 'BRANCH',
  unit = 'UNIT',
}

export enum CompanyTypeReverseEnum {
  PARENT = 'parent',
  BRANCH = 'branch',
  UNIT = 'unit',
}

export const CompanyTypeOptions: DefaultOptionType[] = CompanyTypes.map(
  type => ({
    label: `enums.companyType.${type}`,
    value: type,
  })
);
