import {
  Button,
  Form,
  FormInstance,
  InputNumber,
  Switch,
  TimePicker,
} from 'antd';
import { TFunction } from 'i18next';
import { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import './editCadenceForm.scss';

interface ICreateCalendar {
  t: TFunction;
  form: FormInstance;
  onFinish: (values: any) => void;
  disableCadence: () => void;
  isSwitchChecked: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  validatorRule: any;
  enableConfirm: boolean;
  isFilledHours: any;
  isFilledQuantity: any;
}

const EditCadenceInCalendar = ({
  t,
  form,
  onFinish,
  setShowModal,
  isSwitchChecked,
  disableCadence,
  validatorRule,
  enableConfirm,
  isFilledHours,
  isFilledQuantity,
}: ICreateCalendar): ReactElement => {
  useEffect(() => {
    if (isSwitchChecked) {
      form.resetFields(['quantity', 'hours']);
    }
  }, [isSwitchChecked]);
  return (
    <>
      <Form
        className="edit-cadence-form"
        form={form}
        onFinish={values => {
          isSwitchChecked ? disableCadence() : onFinish(values);
        }}
      >
        <Form.Item name="quantity" label="Max: " rules={[() => validatorRule]}>
          <InputNumber
            style={{ width: '100%' }}
            disabled={isSwitchChecked}
            placeholder={t('pages.scheduleRules.editCalendarInputPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name="hours"
          label={t('pages.scheduleRules.editCalendarLabelPlaceholder')}
        >
          <TimePicker.RangePicker
            disabled={isSwitchChecked}
            placeholder={[
              t('pages.scheduleRules.editRules.startTime'),
              t('pages.scheduleRules.editRules.endTime'),
            ]}
            format={'HH:mm'}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item name="enableCadence" className="custom-switch">
          <Switch
            unCheckedChildren="Habilitado"
            checkedChildren="Desabilitado"
          />
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button
              style={{ marginRight: 24 }}
              danger
              onClick={e => {
                e.stopPropagation();
                form.resetFields();
                setShowModal(false);
              }}
            >
              {t('pages.settings.deleteBranchCompany.cancelButton')}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              onClick={e => {
                e.stopPropagation();
                setShowModal(false);
              }}
              disabled={
                !enableConfirm ||
                (!isSwitchChecked && !(isFilledHours && isFilledQuantity))
              }
            >
              {t('pages.scheduleRules.confirmChangeBtn')}
            </Button>
          </Form.Item>
        </Form.Item>
      </Form>
    </>
  );
};

export default withTranslation()(EditCadenceInCalendar);
