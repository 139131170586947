interface ILanguage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const en: ILanguage = {
  translations: {
    tab: {
      authentication: 'Authentication',
      signUp: 'Sign Up',
      home: 'Home',
      settings: 'Settings',
      icons: 'Icons',
      validateEmail: 'Verifying Email...',
      registerCompany: 'Register Company',
      createPassword: 'Create Password',
      termsOfUse: 'Terms of Use',
      companyCreated: 'Company Registered',
    },
    languages: {
      ptBR: 'Português',
      enUS: 'English',
      es: 'Español',
    },
    headerMenu: {
      logOut: 'Log out',
    },
    general: {
      logoAlt: 'LogMetrics Logo',
      yes: 'Yes',
      no: 'No',
      close: 'Close',
      back: 'Back',
      save: 'Save',
    },
    sidebar: {
      options: {
        home: 'Home',
        settings: 'Settings',
        icons: 'Icons',
      },
    },
    input: {
      rules: {
        required: 'Required field',
        invalidEmail: 'Invalid email',
        minPassword: 'Min 6 characters',
        invalidDocument: 'Invalid document number',
        invalidDocumentCPF: 'Invalid document number',
        invalidDocumentCNPJ: 'Invalid document number',
        invalidPhoneNumber: 'Invalid phone number',
        invalidZipCode: 'Invalid zip code',
      },
    },
    pages: {
      loading: {
        text: 'Loading',
      },
      login: {
        title: 'Login',
        subtitle: 'Enter your data to log into your account.',
        fields: {
          email: {
            label: 'E-mail',
            placeholder: 'Your E-mail',
          },
          password: {
            label: 'Password',
            placeholder: 'Your password',
          },
          keepConnected: 'Keep me connected',
          passwordRecovery: 'Forgot password',
          loginButton: 'Sign in',
        },
        footer: {
          text: "Don't have an account? ",
          signup: 'Signup!',
        },
        success: {
          message: 'Login done!',
          description: 'Redirecting to Home',
        },
      },
      signUp: {
        title: 'Create your account',
        subtitle: 'Enter your data below to create your account.',
        fields: {
          labels: {
            name: 'Name',
            email: 'E-mail',
            phone: 'Phone',
            companyFunction: 'Type of your company',
          },
          placeholders: {
            name: 'Your name',
            email: 'Your e-mail',
            phone: 'Your phone number',
            companyFunction: 'Type of your company',
          },
        },
        action: {
          cancel: 'Cancel',
          submit: 'Create account',
          return: 'Go Back',
        },
        terms: {
          text: 'By clicking on <strong>create account</strong> you agree to the',
          link: 'terms of use',
        },
        confirm: {
          cancel: 'Do you really want to cancel the registration?',
        },
        messages: {
          accountCreated: {
            title: 'Account created!',
            description:
              'An email was sent to your email address with a link to validate your account. Complete your registration from the link in the email.',
            buttonText: 'Back to login',
          },
          shipperCreated: {
            title: 'Shipper created!',
            description:
              'We are validating your data. Wait for the reply to your email.',
            buttonText: 'Back to login',
          },
          carrierCreated: {
            title: 'Carrier created!',
            description:
              'We are validating your data. Wait for the reply to your email.',
            buttonText: 'Back to login',
          },
          accountInApproval: {
            title: 'Account in approval',
            description: [
              'Your account is awaiting approval.',
              'You will soon receive an email with news.',
            ],
            buttonText: 'Back to login',
          },
          accountDisapproved: {
            title: 'Account disapproved',
            explanation: {
              reasons: 'Reasons',
              description: 'Description',
            },
            buttonText: 'Regularize registration',
          },
          completeRegistration: {
            title: 'Complete registration',
            description:
              'We need more information about your company for it to be active.',
            buttonText: 'Complete registration',
          },
        },
      },
      home: {
        // title: 'Welcome to home page!',
      },
      settings: {
        // title: 'Settings Page',
      },
      icons: {
        title: 'Icons Page',
      },
      validateEmail: {
        notification: {
          success: {
            message: 'Email successfully verified!',
            description:
              'Your email has been successfully verified! Please proceed with registration',
          },
          error: {
            message: 'Error verifying email...',
            description: 'The link you accessed is expired or invalid',
          },
        },
      },
      registerCompany: {
        shipperTitle: 'Register Shipper',
        carrierTitle: 'Register Carrier',
        subtitle: 'Choose your company type',
        sections: {
          companyData: {
            title: 'Company data',
            fields: {
              labels: {
                companyType: 'Company type',
                hasFleet: 'Do you have your own fleet?',
                fleetQuantity: 'Fleet size',
                logo: 'Click or drag file to this area to upload',
                logoDescription:
                  'The file must have a maximum of 5 MB and must be of type .png, .jpg or .jpeg',
              },
              placeholders: {
                companyType: 'Your company type',
                fleetQuantity: 'Enter the number of trucks',
              },
              rules: {
                companyFunctionNotMatch:
                  'Company registered for another segment',
                companyTypeNotMatch:
                  'Your company type is incompatible with this one',
                companyStatusBlocked:
                  'Company unavailable to link new dependents',
                tradeNameLength: 'Trade name must be less than 100 characters',
                companyNameLength:
                  'Company name must be less than 100 characters',
                minimumFleetQuantity: 'Minimum fleet size is 1 truck',
                maximumFileSize: 'Maximum file size is 5 MB',
                acceptedFileTypes:
                  'Only .jpg, .jpeg and .png files are accepted',
              },
            },
            parent: {
              fields: {
                labels: {
                  companyDocument: 'Document number',
                  companyTradeName: 'Trade name',
                  companyName: 'Company name',
                },
                placeholders: {
                  companyDocument: 'Your company document number',
                  companyTradeName: 'Your company trade name',
                  companyName: 'Your company name',
                },
                rules: {
                  invalidCompanyDocument: 'Document number in use',
                },
              },
              notifications: {
                preRegisteredCompany: {
                  message: 'Pre-registered company',
                  description:
                    'Some data have already been filled in by a branch. Please fill in the remaining data to complete the registration',
                },
              },
            },
            branch: {
              fields: {
                labels: {
                  motherCompanyDocument: 'Parent company document number',
                  motherCompanyName: 'Parent company name:',
                  companyDocument: 'Branch document number',
                  companyTradeName: 'Trade name',
                  companyName: 'Company name',
                },
                placeholders: {
                  motherCompanyDocument: 'Enter parent company document number',
                  motherCompanyName: 'Enter parent company name',
                  companyDocument: 'Your company document number',
                  companyTradeName: 'Your company trade name',
                  companyName: 'Your company name',
                },
                rules: {
                  invalidCompanyDocument: 'Document number in use',
                  sameDocumentNumber:
                    'Your document number cannot be the same as the parent one',
                },
              },
              notifications: {
                preRegisteredCompany: {
                  message: 'Pre-registered company',
                  description:
                    'Some data has already been filled in by some unit. Please fill in the remaining data to complete the registration',
                },
              },
            },
            unit: {
              fields: {
                labels: {
                  motherCompanyDocument: 'Branch or parent document number',
                  motherCompanyName: 'Branch or parent company name:',
                  companyDocument: 'Unit document number',
                  companyTradeName: 'Trade name',
                  companyName: 'Company name',
                },
                placeholders: {
                  motherCompanyDocument:
                    'Enter branch or parent document number',
                  motherCompanyName: 'Enter branch or parent name',
                  companyDocument: 'Your company document number',
                  companyTradeName: 'Your company trade name',
                  companyName: 'Your company name',
                },
                rules: {
                  invalidCompanyDocument: 'Document number in use',
                },
              },
            },
            modal: {
              title: {
                details: 'Parent Data',
                createParent: 'Register Parent',
                createBranchOrUnit: 'Register Parent or Branch',
              },
              fields: {
                labels: {
                  motherCompanyType: 'Company type',
                  motherCompanyDocument: 'Document number',
                  motherCompanyTradeName: 'Trade name',
                  motherCompanyName: 'Company name',
                },
                placeholders: {
                  motherCompanyTradeName: 'Company trade name',
                  motherCompanyName: 'Company name',
                },
                rules: {
                  tradeNameLength:
                    'Trade name must be less than 100 characters',
                  companyNameLength:
                    'Company name must be less than 100 characters',
                },
              },
            },
          },
          companyAddress: {
            title: 'Company address',
            fields: {
              labels: {
                zipCode: 'Zip code',
                state: 'State',
                city: 'City',
                address: 'Address',
                district: 'District',
                addressNumber: 'Number',
                complement: 'Complement/Note',
              },
              placeholders: {
                zipCode: 'Your zip code',
                state: 'State',
                city: 'City where the company is located',
                address: 'Street where the company is located',
                district: 'District where the company is located',
              },
              rules: {
                addressMaxLength: 'Address must be less than 100 characters',
                districtMaxLength: 'District must be less than 100 characters',
                numberMaxLength: 'Number must be less than 10 characters',
                complementMaxLength:
                  'Complement must be less than 100 characters',
              },
            },
          },
          companyContact: {
            title: 'Company contact',
            fields: {
              labels: {
                email: 'E-mail',
                phoneNumber: 'Phone number',
              },
              placeholders: {
                email: 'Company e-mail',
                phoneNumber: 'Company phone number',
              },
            },
          },
          carrierPolicies: {
            title: 'Policies',
            fields: {
              labels: {
                expiresIn: 'Expiration',
                name: 'Name',
                logo: 'Click or drag file to this area to upload',
                logoDescription:
                  'The file must have a maximum of 5 MB and must be of type .png, .jpg, .jpeg or .pdf',
              },
              placeholders: {
                name: 'Ex. insurance policy',
                expiresIn: 'dd/mm/yy',
              },
              rules: {
                maximumFileSize: 'Maximum file size is 5 MB',
                acceptedFileTypes:
                  'Only .jpg, .jpeg, .png or .pdf files are accepted',
                nameLength: 'The file name must be less than 100 characters',
                maximumFileAmount: {
                  message: 'File limit reached',
                  description: 'The maximum number of files is 10',
                },
              },
            },
            fileList: {
              title: 'Policy files',
              tooltips: {
                remove: 'Remove',
                preview: 'Preview',
                retry: 'Resend',
              },
            },
            action: {
              addPolicy: 'Add policy',
            },
          },
          carrierCompanyOperationAreas: {
            title: 'Operation area',
          },
        },
        action: {
          cancel: 'Cancel',
          submit: 'Create account',
        },
        confirm: {
          cancel: 'Do you really want to cancel the registration?',
        },
      },
      createPassword: {
        title: 'Security',
        description: 'Register and confirm a secure password for your account',
        fields: {
          labels: {
            password: 'Password',
            confirmPassword: 'Confirm password',
          },
          placeholders: {
            password: 'Please enter a valid password',
            confirmPassword: 'Confirm your password',
          },
          rules: {
            passwordNotMatch: 'Passwords do not match',
            passwordMinLength: 'Password must be at least 6 characters',
            passwordMaxLength: 'Password must be less than 128 characters',
          },
        },
        action: {
          cancel: 'Cancel',
          submit: 'Confirm',
        },
        confirm: {
          cancel: 'Do you really want to cancel the registration?',
        },
      },
      termsOfUse: {
        subtitle: 'Read and review the terms of use',
        carrier: {
          title: 'Carrier Terms of Use and Consent',
        },
        shipper: {
          title: 'Shipper Terms of Use and Consent',
        },
        fields: {
          labels: {
            acceptTerms: 'I have read and agree to the terms of use',
          },
        },
        action: {
          cancel: "I don't accept",
          submit: 'Agree and continue',
          return: 'Go back',
        },
        confirm: {
          cancel: 'Do you really want to decline the terms?',
        },
      },
    },
    enums: {
      reasonType: {
        COMPANY_DATA: 'Company data',
        COMPANY_ADDRESS: 'Company address',
        COMPANY_CONTACT: 'Company contact',
        COMPANY_POLICIES: 'Policies',
        COMPANY_OPERATION: 'Operating Areas',
        OTHER: 'Other',
      },
      companyType: {
        parent: 'Parent',
        branch: 'Branch',
        unit: 'Unit',
      },
      companyFunction: {
        carrier: 'Carrier',
        shipper: 'Shipper',
      },
    },
    errors: {
      default: {
        message: 'An unexpected error has occurred',
        description:
          'Reload the page and try to perform the action again, if the error persists, contact our SAC Support: suporte@suporte.com.br',
      },
      invalidToken: {
        message: 'Invalid token',
        description: 'Log in again to keep using the system',
      },
      201: {
        message: 'User not found',
        description: 'We could not find this user in our database',
      },
      202: {
        message: 'User already registered',
        description:
          "There's a user already registered with this email or document number",
      },
      203: {
        message: 'User not found',
        description: 'We could not find this user in our database',
      },
      204: {
        message: 'Invalid credentials',
        description:
          'You must enter an email address or your document number to log in',
      },
      404: {
        message: 'Message 404',
        description: 'Error 404',
      },
      500: {
        message: 'Company not found',
        description:
          'We could not find a company with the informed document number',
      },
      501: {
        message: 'Incomplete data',
        description:
          'The Email and Phone fields are required, please fill them and try again',
      },
    },
  },
};
export default en;
