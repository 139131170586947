import client from '../../api/urql/customClient';
import addressQueries from '../queries/Address';
import {
  ICityName,
  IEditPickUpLocation,
  IPickUpLocation,
  IZipCodeAddress,
} from '../interfaces/Address';
import ZipCodeAddressModel from '../models/Address';
import queries from '../queries/Address';
import Formatter from '../../classes/Formatter';

class AddressController {
  static findZipCodeAddress = (zipCode: string): Promise<IZipCodeAddress> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.findZipCodeAddress(), { zipCode })
        .then(({ zipCodeAddress }) => {
          resolve(new ZipCodeAddressModel(zipCodeAddress));
        })
        .catch(err => {
          reject(err);
        })
    );

  static findCitiesByState = (initials: string): Promise<ICityName[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.findCitiesByState(), {
          where: {
            initials,
          },
        })
        .then(({ cities }) => {
          resolve(cities);
        })
        .catch(reject)
    );

  static newPickUpLoc = ({
    organizationId,
    title,
    zipCode,
    countryName,
    stateShortName,
    stateName,
    cityName,
    district,
    street,
    streetNumber,
    complement,
  }: IPickUpLocation): Promise<IPickUpLocation> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.registerNewPickUpLocation(), {
          input: {
            organization_id: organizationId,
            title,
            zip_code: zipCode,
            country_name: countryName,
            state_short_name: stateShortName,
            state_name: stateName,
            city_name: cityName,
            district,
            street,
            street_number: streetNumber,
            complement,
          },
        })
        .then(({ pickUpLoc }) => {
          resolve(pickUpLoc);
        })
        .catch(err => {
          reject(err);
        })
    );

  static findPickUpLocations = ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<IPickUpLocation[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.findPickUpLocation(), {
          where: {
            pick_up_location: { equals: true },
            organization_id: { equals: organizationId },
          },
        })
        .then(({ pickuplocation }) => {
          pickuplocation.map(item => {
            if (!item.complement) item.complement = '---';
            item.zipCode = Formatter.formatZipCode(item.zipCode);
          });
          resolve(pickuplocation);
        })
        .catch(reject)
    );

  static findAddressId = ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<{ id: string }> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.findAddressId(), {
          where: {
            organization_id: { equals: organizationId },
          },
        })
        .then(({ addressId }) => {
          resolve(addressId);
        })
        .catch(reject)
    );

  static findDataPickUpLocations = ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<IEditPickUpLocation> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.findDataPickUpLocation(), {
          where: {
            pick_up_location: { equals: true },
            organization_id: { equals: organizationId },
          },
        })
        .then(({ pickupdata }) => {
          resolve(pickupdata);
        })
        .catch(reject)
    );

  static updatePickUpLocation = ({
    updatePickUpLocation,
    id,
  }: {
    updatePickUpLocation: IEditPickUpLocation;
    id: string;
  }): Promise<IEditPickUpLocation> =>
    new Promise((resolve, reject) => {
      client
        .mutation(addressQueries.updatePickUpLocation(), {
          input: {
            title: updatePickUpLocation.title,
            zip_code: updatePickUpLocation.zipCode,
            street: updatePickUpLocation.street,
            street_number: updatePickUpLocation.streetNumber,
            district: updatePickUpLocation.district,
            state_name: updatePickUpLocation.stateName,
            state_short_name: updatePickUpLocation.stateShortName,
            complement: updatePickUpLocation.complement,
            city_name: updatePickUpLocation.cityName,
          },
          where: { id },
        })
        .then(({ updatePickUpLocation }) => {
          resolve(updatePickUpLocation);
        })
        .catch(err => {
          reject(err);
        });
    });

  static deletePickUpLocation = ({ id }: { id: string }): Promise<boolean> =>
    new Promise((resolve, reject) => {
      client
        .mutation(addressQueries.deletePickUpLocation(), {
          where: { id },
        })
        .then(({ status }) => {
          resolve(status);
        })
        .catch(err => {
          reject(err);
        });
    });
}

export default AddressController;
