import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import Table, { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import CompanyController from '../../../structures/controllers/Company';
import UserController from '../../../structures/controllers/User';
import {
  IGetUserUpdate,
  IUpdateUser,
  IUserModulePermissions,
  IUserModulePermissionsType,
  IUserPermission,
  IUserPermissions,
  IUserPermissionUpdate,
  IVisibleOrganizations,
} from '../../../structures/interfaces/User';
import { validateCpf } from '../../../utils/inputRules';
import { IErrorMessage } from '../../../utils/interfaces';
import Validator from '../../../classes/Validator';
import { Rule } from 'antd/lib/form';
import { scrollOptions } from '../../../utils/formOptions';

const { Title } = Typography;

const UpdateUser = ({
  t,
  visible,
  onClose,
  onConfirm,
  data,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  data: IGetUserUpdate;
}): ReactElement<unknown> => {
  const [receiveEmails, setReceiveEmails] = useState(false);
  const [ignoreDriverTimeout, setIgnoreDriverTimeout] = useState(false);
  const [userPermissionRequest, isUserPermissionRequesting] = useRequest(
    UserController.getUserPermissionOptions
  );
  const [findBranchesRequest] = useRequest(
    CompanyController.getBranchCompanies
  );
  const [updateUserRequest, isUpdateUserRequesting] = useRequest(
    UserController.updateUser
  );

  const [organizations, setOrganizations] = useState<IVisibleOrganizations[]>();
  const [possibleUserPermissions, setPossibleUserPermissions] =
    useState<IUserPermissions[]>();
  const [selectedUserPermissions, setSelectedUserPermissions] =
    useState<IUserModulePermissions[]>();
  const [initialUserPermissions, setInitialUserPermissions] =
    useState<IUserModulePermissions[]>();

  const [disabledContractCheckbox, setDisabledContractCheckbox] =
    useState<boolean>();
  const [disabledTruckLoadCheckbox, setDisabledTruckLoadCheckbox] =
    useState<boolean>();
  const [disabledHomeCheckbox, setDisabledHomeCheckbox] = useState<boolean>();
  const [disabledCustomerCheckbox, setDisabledCustomerCheckbox] =
    useState<boolean>();
  const [disabledUserCheckbox, setDisabledUserCheckbox] = useState<boolean>();
  const [disabledMyCompany, setDisabledMyCompany] = useState<boolean>();
  const [disabledSettingsCheckbox, setDisabledSettingsCheckbox] =
    useState<boolean>();

  const { userData, company } = useContext(UserContext);

  const [form] = Form.useForm();
  const [formCheckbox] = Form.useForm();
  const userType: string = useWatch('typeId', form);
  const { Option } = Select;

  const info = () => {
    message.success(t('pages.users.updateUser.success.message'));
  };

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      duration: 7,
      style: { marginTop: '4rem' },
    });
  };

  const validPhoneNumber = (rule: Rule, value: string) => {
    if (!value) return Promise.resolve();
    if (Validator.validateCellphoneWithoutDDI(value)) return Promise.resolve();
    return Promise.reject(
      new Error(t(`pages.truckLoads.createLoad.validData.invalidCellphone`))
    );
  };

  useEffect(() => {
    if (userData && company && data && data.organization) {
      const tmpOrganizations: IVisibleOrganizations[] = [];
      tmpOrganizations.push({
        name: data.organization.tradeName,
        id: data.organization.id,
      });

      findBranchesRequest({ id: userData.organization.id }).then(branches => {
        branches.map(branch => {
          tmpOrganizations.push({
            name: branch.tradeName,
            id: branch.id,
          });
        });
        setOrganizations(tmpOrganizations);
      });
    }
  }, [userData, company, data]);

  const disableCheckboxes = (module: string) => {
    switch (module) {
      case 'HOME':
        setDisabledHomeCheckbox(true);
        break;
      case 'CONTRATO':
        setDisabledContractCheckbox(true);
        break;
      case 'REGRAS':
        setDisabledSettingsCheckbox(true);
        break;
      case 'CARGAS':
        setDisabledTruckLoadCheckbox(true);
        break;
      case 'USUARIOS':
        setDisabledUserCheckbox(true);
        break;
      case 'MINHA_EMPRESA':
        setDisabledMyCompany(true);
        break;
      case 'CLIENTE':
        setDisabledCustomerCheckbox(true);
        break;
    }
  };

  const enableCheckboxes = (module: string) => {
    switch (module) {
      case 'HOME':
        setDisabledHomeCheckbox(false);
        break;
      case 'CONTRATO':
        setDisabledContractCheckbox(false);
        break;
      case 'REGRAS':
        setDisabledSettingsCheckbox(false);
        break;
      case 'CARGAS':
        setDisabledTruckLoadCheckbox(false);
        break;
      case 'USUARIOS':
        setDisabledUserCheckbox(false);
        break;
      case 'MINHA_EMPRESA':
        setDisabledMyCompany(false);
        break;
      case 'CLIENTE':
        setDisabledCustomerCheckbox(false);
        break;
    }
  };

  useEffect(() => {
    if (company && data) {
      setReceiveEmails(data.receiveNotifications);
      setIgnoreDriverTimeout(data.ignoreDriverExpiration);

      form.setFieldsValue({
        typeId: data?.userType?.id ? data.userType.id : null,
        name: data.name,
        documentNumberCpf: Formatter.formatCPF(data.documentNumberCpf),
        organizationId: data.organization?.id,
        phoneNumber: Formatter.formatCellphone(data.phoneNumber),
        email: data.email,
      });

      const tmpObjPermission: any = possibleUserPermissions?.find(
        permissions => permissions.type === data?.userType?.id
      );
      if (tmpObjPermission) {
        // const props = IUserModules;
        let props = Object.getOwnPropertyNames(tmpObjPermission);
        props = props.filter(item => item !== 'type');

        const possiblePermissions: IUserModulePermissions[] = [];
        props.map(item => {
          possiblePermissions.push(tmpObjPermission[item]);
        });

        const userPermissions: IUserModulePermissions[] = [];
        const allUserPermissions = data.userPermissionsData.map(item => {
          const keyName = item.operation;

          const existsModule = userPermissions.find(
            permission => item.module === permission?.module
          );

          if (existsModule) {
            const permissionType = item.operation;
            const tmp = userPermissions.indexOf(existsModule);
            userPermissions[tmp][permissionType] = {
              id: item.id,
              hasPermission: true,
            };
          } else {
            const permission = {
              module: item.module,
              [keyName]: {
                id: item.id,
                hasPermission: true,
              },
            };
            userPermissions.push(permission);
          }
          return {
            module: item.module,
            [keyName]: { id: item.id, hasPermission: true },
          };
        });
        setInitialUserPermissions(userPermissions);

        // popula userPermissions com todas as possiveis permissoes com hasPermission false
        possiblePermissions.map(possiblePermission => {
          const props = Object.getOwnPropertyNames(possiblePermission).filter(
            prop => prop !== 'module'
          );
          const existsModule = userPermissions.find(
            userPermission =>
              userPermission.module === possiblePermission.module
          );
          if (existsModule) {
            const indexOfUserPermission = userPermissions.indexOf(existsModule);
            props.map(prop => {
              if (!userPermissions[indexOfUserPermission][prop]) {
                if (typeof possiblePermission[prop] == 'object')
                  possiblePermission[prop] = possiblePermission[prop].id;
                userPermissions[indexOfUserPermission][prop] = {
                  id: possiblePermission[prop],
                  hasPermission: false,
                };
              }
            });
          } else {
            props.map(prop => {
              if (typeof possiblePermission[prop] == 'object')
                possiblePermission[prop] = possiblePermission[prop].id;

              possiblePermission[prop] = {
                id: possiblePermission[prop],
                hasPermission: false,
              };
            });
            userPermissions.push(possiblePermission);
          }
        });

        userPermissions.map(permission => {
          const correctPermission = permission as IUserPermissionUpdate;

          if (correctPermission.READ.hasPermission === false) {
            disableCheckboxes(correctPermission.module);
          }
          if (correctPermission.READ.hasPermission === true) {
            enableCheckboxes(correctPermission.module);
          }
        });

        setSelectedUserPermissions(userPermissions);

        userPermissions.forEach(userPermission => {
          IUserModulePermissionsType.forEach(permissionType => {
            const permission = userPermission[permissionType];
            if (permission) {
              formCheckbox.setFieldsValue({
                [permission.id]: permission.hasPermission,
              });
            }
          });
        });
      }
    }
  }, [company, data]);

  useEffect(() => {
    if (userData) {
      userPermissionRequest({})
        .then(permissions => {
          setPossibleUserPermissions(permissions);
        })
        .catch(err => showError(err));
    }
  }, [userData, userType]);

  const getInitialPermissions = () => {
    if (initialUserPermissions) {
      const permissions = initialUserPermissions.map(item => {
        const userPermissions = Object.getOwnPropertyNames(item).filter(
          key => key !== 'module'
        );
        const teste: string[] = userPermissions
          ?.map(userPermission =>
            item[userPermission].hasPermission ? item[userPermission].id : ''
          )
          .filter(item => item);

        return teste;
      });
      const formattedPermissions = permissions?.flat();
      const initialPermissions = formattedPermissions?.filter((item, index) => {
        if (item && formattedPermissions?.indexOf(item) === index) return item;
      });

      return initialPermissions;
    } else {
      return [];
    }
  };

  const getFinalPermissions = () => {
    const checkboxValues = formCheckbox.getFieldsValue();
    const finalPermissions = Object.keys(checkboxValues).filter(
      id => checkboxValues[id]
    );
    return finalPermissions;
  };

  const getAddedDeletedItems = (
    initialPermissions: string[],
    finalPermissions: string[]
  ) => {
    const addedItems = finalPermissions.filter(item => {
      if (initialPermissions?.indexOf(item) === -1) return item;
    });
    const deletedItems = initialPermissions?.filter(item => {
      if (finalPermissions.indexOf(item) === -1) return item;
    });

    return { addedItems, deletedItems };
  };

  const onFinish = (values: IUpdateUser) => {
    if (userData && company) {
      const initialPermissions = getInitialPermissions();
      const finalPermissions = getFinalPermissions();
      const { addedItems, deletedItems } = getAddedDeletedItems(
        initialPermissions,
        finalPermissions
      );

      values.phoneNumber = values.phoneNumber.replace(/[^0-9]/g, '');
      values.documentNumberCpf = values.documentNumberCpf.replace(
        /[^0-9]/g,
        ''
      );
      values.receiveNotifications = receiveEmails;
      values.ignoreDriverExpiration = ignoreDriverTimeout;

      const allPermissions = selectedUserPermissions as IUserPermissionUpdate[];

      deletedItems.map(deletedItem => {
        const isDeletingReadPermissions = allPermissions.find(
          permission => permission.READ.id === deletedItem
        );

        if (isDeletingReadPermissions) {
          if (isDeletingReadPermissions.CREATE.hasPermission) {
            const createId = isDeletingReadPermissions.CREATE.id;
            if (!deletedItems.includes(createId)) deletedItems.push(createId);
          }
          if (!isDeletingReadPermissions.CREATE.hasPermission) {
            const createId = isDeletingReadPermissions.CREATE.id;
            const isAddingWritePermissionAndDeletingRead = addedItems.find(
              item => item === createId
            );
            if (isAddingWritePermissionAndDeletingRead) {
              const createIdPos = addedItems.indexOf(createId);
              addedItems.splice(createIdPos, 1);
            }
          }

          if (isDeletingReadPermissions.UPDATE.hasPermission) {
            const updateId = isDeletingReadPermissions.UPDATE.id;
            if (!deletedItems.includes(updateId)) deletedItems.push(updateId);
          }
          if (!isDeletingReadPermissions.UPDATE.hasPermission) {
            const updateId = isDeletingReadPermissions.UPDATE.id;
            const isAddingWritePermissionAndDeletingRead = addedItems.find(
              item => item === updateId
            );
            if (isAddingWritePermissionAndDeletingRead) {
              const updateIdPos = addedItems.indexOf(updateId);
              addedItems.splice(updateIdPos, 1);
            }
          }

          if (isDeletingReadPermissions.DELETE.hasPermission) {
            const deleteId = isDeletingReadPermissions.DELETE.id;
            if (!deletedItems.includes(deleteId)) deletedItems.push(deleteId);
          }
          if (!isDeletingReadPermissions.DELETE.hasPermission) {
            const deleteId = isDeletingReadPermissions.DELETE.id;
            const isAddingWritePermissionAndDeletingRead = addedItems.find(
              item => item === deleteId
            );
            if (isAddingWritePermissionAndDeletingRead) {
              const deleteIdPos = addedItems.indexOf(deleteId);
              addedItems.splice(deleteIdPos, 1);
            }
          }
        }
      });

      values.permissions = {
        add: addedItems,
        delete: deletedItems,
      };

      updateUserRequest({
        id: data.id,
        user: values,
      })
        .then(() => {
          onConfirm();
          info();
        })
        .catch(err => showError(err));
    }
  };

  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 6 };

  const isReadChecked = (module: string) => {
    switch (module) {
      case 'HOME':
        return disabledHomeCheckbox;

      case 'CONTRATO':
        return disabledContractCheckbox;

      case 'REGRAS':
        return disabledSettingsCheckbox;

      case 'CARGAS':
        return disabledTruckLoadCheckbox;

      case 'USUARIOS':
        return disabledUserCheckbox;

      case 'MINHA_EMPRESA':
        return disabledMyCompany;

      case 'CLIENTE':
        return disabledCustomerCheckbox;
    }
  };

  const columnsUsers: ColumnsType<IUserModulePermissions> = [
    {
      title: t('pages.users.newUser.userPermissions.pages'),
      render: value => {
        switch (value) {
          case 'HOME':
            return t('sidebar.options.home');
          case 'USUARIOS':
            return t('sidebar.options.users');
          case 'MINHA_EMPRESA':
            return t('sidebar.options.myCompany');
          case 'REGRAS':
            return t('sidebar.options.settings');
          case 'CONTRATO':
            return t('sidebar.options.contracts');
          case 'CLIENTE':
            return t('sidebar.options.clients');
          case 'CARGAS':
            return t('sidebar.options.truckLoads');
        }
      },
      dataIndex: ['module'],
      key: 'page',
    },
    {
      title: t('pages.users.newUser.userPermissions.reading'),
      dataIndex: ['READ'],
      key: 'read',
      render: (value, record) => {
        const rowData = record;

        return value ? (
          <Form.Item
            name={value.id}
            noStyle
            valuePropName="checked"
            rules={[
              {
                validator: () => {
                  const userPermissions =
                    selectedUserPermissions as IUserPermissionUpdate[];

                  const permission = userPermissions?.find(
                    userPermission => userPermission.module === rowData.module
                  );

                  const formValues = formCheckbox.getFieldsValue();

                  if (permission) {
                    if (!formValues[permission.READ.id]) {
                      disableCheckboxes(permission.module);
                    } else {
                      enableCheckboxes(permission.module);
                    }
                  }
                },
              },
            ]}
          >
            {rowData.module === 'HOME' ? (
              <Tooltip
                title={t('pages.users.errors.mustHaveHomeReadPermission')}
              >
                <Checkbox disabled checked />
              </Tooltip>
            ) : (
              <Checkbox />
            )}
          </Form.Item>
        ) : (
          <Checkbox disabled />
        );
      },
    },
    {
      title: t('pages.users.newUser.userPermissions.edit'),
      dataIndex: ['UPDATE'],
      key: 'update',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        const userPermissions = selectedUserPermissions as IUserPermission[];

        const permission = userPermissions?.find(
          userPermission => userPermission.module === rowData.module
        );

        const checkboxDisabled = permission
          ? isReadChecked(permission.module)
          : false;

        return value ? (
          <Form.Item
            name={value.id}
            noStyle
            valuePropName={checkboxDisabled ? '' : 'checked'}
          >
            <Checkbox checked={!checkboxDisabled} disabled={checkboxDisabled} />
          </Form.Item>
        ) : (
          <Checkbox disabled />
        );
      },
    },
    {
      title: t('pages.users.newUser.userPermissions.create'),
      dataIndex: ['CREATE'],
      key: 'create',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        const userPermissions = selectedUserPermissions as IUserPermission[];

        const permission = userPermissions?.find(
          userPermission => userPermission.module === rowData.module
        );

        const checkboxDisabled = permission
          ? isReadChecked(permission.module)
          : false;

        return value ? (
          <Form.Item
            name={value.id}
            noStyle
            valuePropName={checkboxDisabled ? '' : 'checked'}
          >
            <Checkbox checked={!checkboxDisabled} disabled={checkboxDisabled} />
          </Form.Item>
        ) : (
          <Checkbox disabled />
        );
      },
    },
    {
      title: t('pages.users.newUser.userPermissions.delete'),
      dataIndex: ['DELETE'],
      key: 'delete',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        const userPermissions = selectedUserPermissions as IUserPermission[];

        const permission = userPermissions?.find(
          userPermission => userPermission.module === rowData.module
        );

        const checkboxDisabled = permission
          ? isReadChecked(permission.module)
          : false;

        return value ? (
          <Form.Item
            name={value.id}
            noStyle
            valuePropName={checkboxDisabled ? '' : 'checked'}
          >
            <Checkbox checked={!checkboxDisabled} disabled={checkboxDisabled} />
          </Form.Item>
        ) : (
          <Checkbox disabled />
        );
      },
    },
  ];

  const formUpdateUser = (
    <>
      <Form
        scrollToFirstError={scrollOptions}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={[24, 0]}>
          <Col {...colSize}>
            <Form.Item
              label={t('pages.users.updateUser.typeId')}
              name="typeId"
              rules={[
                {
                  required: true,
                  message: t('pages.users.updateUser.rmTypeId'),
                },
              ]}
            >
              <Select
                size="small"
                placeholder={t('pages.users.newUser.phUserType')}
                onChange={item => {
                  const tmpObjPermission: any = possibleUserPermissions?.find(
                    permissions => permissions.type === item
                  );
                  if (tmpObjPermission) {
                    let props = Object.getOwnPropertyNames(tmpObjPermission);

                    props = props.filter(item => item !== 'type');

                    const permissions: IUserModulePermissions[] = [];
                    props.map(item => {
                      const itemProps = Object.getOwnPropertyNames(
                        tmpObjPermission[item]
                      ).filter(prop => prop !== 'module');

                      itemProps.map(itemProp => {
                        tmpObjPermission[item][itemProp] = {
                          id: tmpObjPermission[item][itemProp],
                          hasPermission: true,
                        };
                      });

                      permissions.push(tmpObjPermission[item]);
                    });

                    const newPermissions =
                      initialUserPermissions as IUserPermissionUpdate[];
                    permissions.map(permission => {
                      const hasModule = newPermissions?.find(
                        newPermission =>
                          newPermission.module === permission.module
                      );

                      if (
                        !hasModule ||
                        (hasModule && hasModule.READ.hasPermission === false)
                      ) {
                        if (permission.module)
                          disableCheckboxes(permission.module);
                      } else if (permission.READ.hasPermission === true) {
                        if (permission.module)
                          enableCheckboxes(permission.module);
                      }
                    });

                    setSelectedUserPermissions(permissions);
                  }
                }}
              >
                {possibleUserPermissions
                  ?.filter(item => item)
                  .map(item => (
                    <Option value={item.type} key={item.type}>
                      {item.type}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...colSize}>
            <Form.Item
              label={t('pages.users.updateUser.name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('pages.users.updateUser.rmName'),
                },
              ]}
            >
              <Input
                size="small"
                placeholder={t('pages.users.updateUser.phName')}
              />
            </Form.Item>
          </Col>

          <Col {...colSize}>
            <Form.Item
              normalize={Formatter.formatCPF}
              label={t('pages.users.updateUser.documentNumberCpf')}
              name="documentNumberCpf"
              validateFirst
              rules={[
                {
                  required: true,
                  message: t('pages.users.updateUser.rmDocumentNumberCpf'),
                },
                {
                  min: 11,
                  message: t(
                    'pages.users.updateUser.rmMinSizeDocumentNumberCpf'
                  ),
                },
                { ...validateCpf, message: t(validateCpf.message) },
              ]}
            >
              <Input
                size="small"
                placeholder={t('pages.users.updateUser.phDocumentNumberCpf')}
              />
            </Form.Item>
          </Col>

          {organizations && (
            <Col {...colSize}>
              <Form.Item
                label={t('pages.users.updateUser.userCompany')}
                name="organizationId"
                rules={[
                  {
                    required: true,
                    message: t('pages.users.updateUser.rmUserCompany'),
                  },
                ]}
              >
                <Select
                  size="small"
                  placeholder={t('pages.users.updateUser.phUserCompany')}
                >
                  {organizations?.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col {...colSize}>
            <Form.Item
              label={t('pages.users.updateUser.phoneNumber')}
              normalize={Formatter.formatCellphone}
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: t('pages.users.updateUser.rmPhoneNumber'),
                },
                { validator: validPhoneNumber },
              ]}
            >
              <Input
                size="small"
                placeholder={t('pages.users.updateUser.phPhoneNumber')}
              />
            </Form.Item>
          </Col>

          <Col {...colSize}>
            <Form.Item
              label={t('pages.users.updateUser.email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('pages.users.updateUser.rmEmail'),
                },
                {
                  type: 'email',
                  message: t('pages.editCompany.rmEmail'),
                },
              ]}
            >
              <Input
                size="small"
                placeholder={t('pages.users.updateUser.phEmail')}
              />
            </Form.Item>
          </Col>

          <Col {...colSize}>
            <Form.Item label=" " name={'receiveNotifications'}>
              <Checkbox
                checked={receiveEmails}
                onChange={v => setReceiveEmails(v.target.checked)}
                children={'Receber e-mails'}
              />
            </Form.Item>
          </Col>

          <Col {...colSize}>
            <Form.Item label=" ">
              <Checkbox
                checked={ignoreDriverTimeout}
                onChange={v => setIgnoreDriverTimeout(v.target.checked)}
                children={'Ignorar expiração de motorista'}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Alert
        style={{ marginBottom: 24 }}
        message={t('pages.users.errors.hasNoReadPermission')}
        type="warning"
      />

      <Form scrollToFirstError={scrollOptions} form={formCheckbox}>
        <Table
          columns={columnsUsers}
          dataSource={selectedUserPermissions}
          loading={isUserPermissionRequesting}
          size="small"
          pagination={false}
        />
      </Form>
    </>
  );

  const footerModal = [
    <Button
      key="back"
      danger
      onClick={() => {
        onClose();
        form.resetFields();
        formCheckbox.resetFields();
      }}
      disabled={isUpdateUserRequesting}
    >
      {t('pages.users.updateUser.cancelButton')}
    </Button>,
    <Button
      key="confirm"
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
      }}
      loading={isUpdateUserRequesting}
    >
      {t('pages.users.updateUser.confirmButton')}
    </Button>,
  ];

  return (
    <Modal width={'70%'} open={visible} onCancel={onClose} footer={footerModal}>
      <Title level={5}>{t('pages.users.updateUser.title')}</Title>
      {formUpdateUser}
    </Modal>
  );
};

export default withTranslation()(UpdateUser);
