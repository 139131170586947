import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Layout,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import Table, { ColumnsType } from 'antd/lib/table';
import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ReactChild } from 'react-router/node_modules/@types/react';
import Spreadsheet from 'react-spreadsheet';
import axios from '../../../api/requester';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import BreadCrumb from '../../../structures/controllers/Breadcrumb';
import CompanyController from '../../../structures/controllers/Company';
import {
  IColumnsOfImportedContractsDocument,
  IErrorOnImportColumns,
  IImportColumns,
  IImportContractsAPIResponse,
  IImportData,
  IUserDataOnImport,
} from '../../../structures/interfaces/Clients';
import { BaseSelectRef } from 'rc-select';
import { scrollOptions } from '../../../utils/formOptions';

const { Option } = Select;

const ConfigureContractsImport = ({
  t,
}: {
  t: TFunction;
}): ReactElement<unknown> => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { state }: { state: IImportData } = useLocation();
  if (!state) history.push('/contratos');
  const [columnsOptions, setColumnsOptions] = useState<
    Array<{ title: string; value: string }>
  >([]);

  const { userData } = useContext(UserContext);

  const [getOrganizationApiKey] = useRequest(
    CompanyController.getOrganizationApiKey
  );

  const [pageSize, setPageSize] = useState(10);

  const contractItemIdRef = useRef<BaseSelectRef | null>(null);
  const cultivationRef = useRef<BaseSelectRef | null>(null);
  const packingRef = useRef<BaseSelectRef | null>(null);
  const sieveRef = useRef<BaseSelectRef | null>(null);
  const seedTypeRef = useRef<BaseSelectRef | null>(null);
  const categoryRef = useRef<BaseSelectRef | null>(null);
  const tsiRef = useRef<BaseSelectRef | null>(null);
  const estimatedWeightRef = useRef<BaseSelectRef | null>(null);
  const fullAmountRef = useRef<BaseSelectRef | null>(null);
  const remainingAmountRef = useRef<BaseSelectRef | null>(null);
  const contractIdRef = useRef<BaseSelectRef | null>(null);
  const stateRegistrationRef = useRef<BaseSelectRef | null>(null);
  const contractUnityRef = useRef<BaseSelectRef | null>(null);
  const contractUnityWeightRef = useRef<BaseSelectRef | null>(null);
  const contractDateRef = useRef<BaseSelectRef | null>(null);
  const salesmanCpfRef = useRef<BaseSelectRef | null>(null);
  const contractReferenceIdRef = useRef<BaseSelectRef | null>(null);
  const pickupLocationIdRef = useRef<BaseSelectRef | null>(null);

  const showSuccessMessage = () => {
    message.success({
      content: t('pages.contracts.importConfig.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  const infoErrorSameValue = () => {
    message.error(t('pages.contracts.importConfig.errors.sameValue'));
  };

  const infoAPIError = () => {
    message.error(t('pages.contracts.importConfig.errors.api'));
  };

  const infoWrongInputs = () => {
    Modal.warning({
      title: t('pages.contracts.importConfig.errors.message'),
      content: t('pages.contracts.importConfig.errors.verifyInputs'),
      centered: true,
      width: '50%',
    });
  };

  const infoContractsNotImported = () => {
    message.warning({
      content: t('pages.contracts.importConfig.errors.contractsNotImporteds'),
      duration: 7,
    });
  };

  const errorColumns: ColumnsType<IErrorOnImportColumns> = [
    {
      title: t(
        'pages.contracts.importConfig.errors.importFailed.contractReferenceNumber'
      ),
      dataIndex: ['contract', 'reference_number'],
      key: 'referenceNumber',
    },
    {
      title: t(
        'pages.contracts.importConfig.errors.importFailed.customerStateRegistration'
      ),
      dataIndex: ['contract', 'customer_state_registration'],
      key: 'stateRegistration',
    },
    {
      title: t('pages.contracts.importConfig.errors.importFailed.salesmanCpf'),
      dataIndex: ['contract', 'salesman_cpf'],
      key: 'stateRegistration',
    },
    {
      title: t('pages.contracts.importConfig.errors.importFailed.reason'),
      dataIndex: ['motivos'],
      key: 'reasons',
      render: motivos => {
        return motivos.map((reason: string) => <p>{reason}</p>);
      },
    },
  ];

  const errorTable = (contracts: IUserDataOnImport[]) => (
    <>
      <p>{t('pages.contracts.importConfig.errors.importFailed.description')}</p>
      <Table
        rowKey={errOnImpContracts => errOnImpContracts.state_registration}
        columns={errorColumns}
        dataSource={contracts}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          onChange(_, size) {
            setPageSize(size);
          },
        }}
        size="small"
      />
    </>
  );

  const modalErrorWhileImporting = (contracts: IUserDataOnImport[]) => {
    Modal.warning({
      title: t('pages.contracts.importConfig.errors.importFailed.title'),
      content: errorTable(contracts),
      centered: true,
      width: '50%',
      onOk: () => {
        infoContractsNotImported();
      },
      afterClose: () => {
        history.push('/contratos');
      },
    });
  };

  const onFinish = (values: IColumnsOfImportedContractsDocument) => {
    const formValues = form.getFieldsValue();
    let existsError = 0;
    Object.entries(formValues).map(formValue => {
      Object.entries(formValues).map(formValue2 => {
        if (formValue2[1] !== undefined && formValue[1] !== undefined)
          if (formValue2[0] !== formValue[0] && formValue2[0] !== 'firstLine') {
            if (formValue[1] === formValue2[1]) {
              existsError = 1;
            }
          }
      });
    });
    if (existsError === 0 && values.firstLine !== undefined) {
      const firstContentLine = (values.firstLine + 1) as unknown;

      Object.keys(values).forEach(
        key =>
          values[key as keyof IColumnsOfImportedContractsDocument] ===
            undefined &&
          delete values[key as keyof IColumnsOfImportedContractsDocument]
      );
      const columns = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [value, key])
      );

      const formData = new FormData();
      if (state.file.originFileObj)
        formData.append('file', state.file.originFileObj);

      formData.append('columns', JSON.stringify(columns));
      formData.append('start_row', firstContentLine as string | Blob);

      if (userData)
        getOrganizationApiKey({
          organizationId: userData.organization.id,
        }).then(res => {
          axios
            .post('/importations/contracts', formData, {
              headers: {
                'x-access-key': res.apiKey,
              },
            })
            .then((apiResponse: unknown) => {
              const res = apiResponse as IImportContractsAPIResponse;
              if (res.status) {
                infoWrongInputs();
              } else {
                if (res.nao_importados && res.nao_importados.length > 0) {
                  modalErrorWhileImporting(res.nao_importados);
                } else {
                  showSuccessMessage();
                  history.push('/contratos');
                }
              }
            })
            .catch(() => infoAPIError());
        });
    }
    if (existsError === 1) {
      infoErrorSameValue();
    }
  };

  let intervalCode: NodeJS.Timeout | string = '';
  // let intervalCode: NodeJS.Timer | string = '';

  const getTableNodesLines = () => {
    const el = document.getElementsByClassName('Spreadsheet__table')[0];

    if (!el) return [];

    const tBody = el.childNodes[1];
    return tBody.childNodes;
  };

  const getPreviewColumnNames = () => {
    const nodesLines = getTableNodesLines();

    if (!nodesLines.length) return [];

    const [tr1]: Array<ReactChild> | NodeListOf<ChildNode> = nodesLines;
    const columnsName: Array<string> = Array.prototype.map.call(
      tr1.childNodes,
      th => th.innerHTML
    ) as Array<string>;

    clearInterval(intervalCode);
    intervalCode = '';

    return columnsName.slice(1, columnsName.length);
  };

  const fillSelectColumnsOptions = () => {
    if (!intervalCode) {
      setColumnsOptions(
        getPreviewColumnNames().reduce((acc: IImportColumns[], col: string) => {
          acc.push({ title: col, value: col });
          return acc;
        }, [])
      );
    }
  };

  useEffect(() => {
    fillSelectColumnsOptions();
  }, [state?.file]);

  return (
    <>
      <Layout
        style={{
          flex: 1,
        }}
      >
        <PageHeader
          className="site-page-header"
          title={t('pages.contracts.importConfig.title')}
          breadcrumb={<BreadCrumb />}
          subTitle={t('pages.contracts.importConfig.subtitle')}
          ghost={false}
        />

        <Layout
          style={{
            height: '100%',
            padding: 24,
          }}
        >
          <Form
            scrollToFirstError={scrollOptions}
            onFinish={onFinish}
            layout="vertical"
            form={form}
          >
            <Card
              title={t('pages.contracts.importConfig.formTitle')}
              extra={
                <Tooltip
                  title={t(
                    'pages.clients.importConfig.downloadTemplateWorksheet'
                  )}
                >
                  <a href="https://logmetrics-dev.s3.us-east-2.amazonaws.com/templates/template-contrato.xlsx">
                    <Button type="primary" icon={<DownloadOutlined />}>
                      {t('pages.clients.importConfig.templateWorksheet')}
                    </Button>
                  </a>
                </Tooltip>
              }
            >
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelFirstLine')}
                    name="firstLine"
                    rules={[
                      {
                        required: true,
                        message: t('pages.contracts.importConfig.rmFirstLine'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t(
                        'pages.contracts.importConfig.phFirstLine'
                      )}
                      onSelect={() => contractItemIdRef.current?.focus()}
                    >
                      {Array(15)
                        .fill(1)
                        .map((_, index) => (
                          <Option key={index} value={index}>
                            {t('pages.clients.importConfig.selectLine')}{' '}
                            {index + 1}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={
                      <Tooltip
                        title={t(
                          'pages.contracts.importConfig.tooltipReferenceNumber'
                        )}
                      >
                        {t('pages.contracts.importConfig.labelReferenceNumber')}{' '}
                        <QuestionCircleOutlined />
                      </Tooltip>
                    }
                    name="reference_number"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmReferenceNumber'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => cultivationRef.current?.focus()}
                      ref={contractItemIdRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelCultivation')}
                    name="cultivation"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmCultivation'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => packingRef.current?.focus()}
                      ref={cultivationRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelPacking')}
                    name="packing"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.contracts.importConfig.rmPacking'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => sieveRef.current?.focus()}
                      ref={packingRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelSieve')}
                    name="sieve"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.contracts.importConfig.rmSieve'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => seedTypeRef.current?.focus()}
                      ref={sieveRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelSeedType')}
                    name="seed_type"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.contracts.importConfig.rmSeedType'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => categoryRef.current?.focus()}
                      ref={seedTypeRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelCategory')}
                    name="category"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.contracts.importConfig.rmCategory'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => tsiRef.current?.focus()}
                      ref={categoryRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelTsi')}
                    name="tsi"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.contracts.importConfig.rmTsi'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => estimatedWeightRef.current?.focus()}
                      ref={tsiRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.contracts.importConfig.labelEstimatedWeight'
                    )}
                    name="estimated_weight"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmEstimatedWeight'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => fullAmountRef.current?.focus()}
                      ref={estimatedWeightRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelFullAmount')}
                    name="full_amount"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.contracts.importConfig.rmFullAmount'),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => remainingAmountRef.current?.focus()}
                      ref={fullAmountRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.contracts.importConfig.labelRemainingAmount'
                    )}
                    name="remaining_amount"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmRemainingAmount'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => contractIdRef.current?.focus()}
                      ref={remainingAmountRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={
                      <Tooltip
                        title={t(
                          'pages.contracts.importConfig.tooltipContractReferenceNumber'
                        )}
                      >
                        {t(
                          'pages.contracts.importConfig.labelContractReferenceNumber'
                        )}{' '}
                        <QuestionCircleOutlined />
                      </Tooltip>
                    }
                    name="contract_reference_number"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmContractReferenceNumber'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => stateRegistrationRef.current?.focus()}
                      ref={contractIdRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.contracts.importConfig.labelContractCustomerStateRegistration'
                    )}
                    name="contract_customer_state_registration"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmContractCustomerStateRegistration'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => contractUnityRef.current?.focus()}
                      ref={stateRegistrationRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelContractUnity')}
                    name="contract_unity"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmContractUnity'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => contractUnityWeightRef.current?.focus()}
                      ref={contractUnityRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.contracts.importConfig.labelContractUnityWeight'
                    )}
                    name="contract_unity_weight"
                    validateFirst
                    rules={[
                      {
                        message: t(
                          'pages.contracts.importConfig.rmContractUnityWeight'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => contractDateRef.current?.focus()}
                      ref={contractUnityWeightRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t('pages.contracts.importConfig.labelContractDate')}
                    name="contract_date"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmContractDate'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => salesmanCpfRef.current?.focus()}
                      ref={contractDateRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={t(
                      'pages.contracts.importConfig.labelContractSalesmanDocumentNumberCpf'
                    )}
                    name="contract_salesman_cpf"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmContractSalesmanDocumentNumberCpf'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => contractReferenceIdRef.current?.focus()}
                      ref={salesmanCpfRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={
                      <Tooltip
                        title={t(
                          'pages.contracts.importConfig.tooltipContractReferenceContract'
                        )}
                      >
                        {t(
                          'pages.contracts.importConfig.labelContractReferenceContract'
                        )}{' '}
                        <QuestionCircleOutlined />
                      </Tooltip>
                    }
                    name="contract_reference_contract"
                    validateFirst
                    rules={[
                      {
                        message: t(
                          'pages.contracts.importConfig.rmContractReferenceContract'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      onSelect={() => pickupLocationIdRef.current?.focus()}
                      ref={contractReferenceIdRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={4}>
                  <Form.Item
                    label={
                      <Tooltip
                        title={t(
                          'pages.contracts.importConfig.tooltipContractPickupLocation'
                        )}
                      >
                        {t(
                          'pages.contracts.importConfig.labelContractPickuLocation'
                        )}{' '}
                        <QuestionCircleOutlined />
                      </Tooltip>
                    }
                    name="contract_pick_up_location"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t(
                          'pages.contracts.importConfig.rmContractPickupLocation'
                        ),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t('pages.contracts.importConfig.phSelect')}
                      ref={pickupLocationIdRef}
                      showAction={['focus']}
                    >
                      {columnsOptions.map(column => {
                        return (
                          <Option key={column.value} value={column.value}>
                            {column.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card
              title={t('pages.contracts.importConfig.tableTitle')}
              style={{ marginBottom: 84 }}
            >
              <Row
                style={{
                  width: '100% !important',
                  overflowX: 'auto',
                }}
              >
                <div
                  style={{
                    pointerEvents: 'none',
                  }}
                >
                  {state && <Spreadsheet data={state.table} />}
                </div>
              </Row>
            </Card>
          </Form>
        </Layout>
      </Layout>

      <Footer
        style={{
          position: 'fixed',
          left: 24,
          bottom: 0,
          width: '100%',
          backgroundColor: '#FFF',
          textAlign: 'center',
          boxShadow:
            '0 -6px 16px -8px rgba(0,0,0,.08),0 -9px 28px 0 rgba(0,0,0,.05),0 -12px 48px 16px rgba(0,0,0,.03)',
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <Button
            style={{ marginRight: 24 }}
            danger
            htmlType="reset"
            onClick={() => {
              form.resetFields();
              history.push('/contratos');
            }}
          >
            {t('pages.contracts.importConfig.cancelButton')}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            {t('pages.contracts.importConfig.confirmButton')}
          </Button>
        </div>
      </Footer>
    </>
  );
};

export default withTranslation()(ConfigureContractsImport);
