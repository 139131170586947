import client from '../../api/urql/customClient';
import {
  IAddShippingCompany,
  IFindDriverResponse,
  IFindShippingCompanyResponse,
  INewShippingCompany,
  IUpdateShippingCompany,
} from '../interfaces/ShippingCompany';
import ShippingCompanyQueries from '../queries/ShippingCompany';

class ShippingCompanyController {
  static listShippingCompanies = ({
    cnpj,
  }: {
    cnpj?: string;
  }): Promise<IFindShippingCompanyResponse[]> =>
    new Promise((resolve, reject) => {
      client
        .query(ShippingCompanyQueries.listShippingCompanies(), {
          where: cnpj ? { document_number_cnpj: cnpj } : {},
        })
        .then(({ shippingCompanies }) => resolve(shippingCompanies))
        .catch(err => reject(err));
    });

  static findDriver = ({
    cpf,
  }: {
    cpf: string;
  }): Promise<IFindDriverResponse> =>
    new Promise((resolve, reject) => {
      client
        .query(ShippingCompanyQueries.findDriver(), {
          where: { document_number_cpf: cpf },
        })
        .then(({ driver }) => resolve(driver))
        .catch(err => reject(err));
    });

  static addNewShippingCompany = ({
    documentNumberCnpj,
    email,
    companyName,
    phoneNumber,
    tradeName,
  }: IAddShippingCompany): Promise<INewShippingCompany> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ShippingCompanyQueries.addNewShippingCompany(), {
          input: {
            email,
            document_number_cnpj: documentNumberCnpj,
            company_name: companyName,
            phone_number: phoneNumber,
            trade_name: tradeName,
          },
        })
        .then(({ newShippingCompany }) => resolve(newShippingCompany))
        .catch(err => reject(err))
    );

  static updateShippingCompany = ({
    input,
    where,
  }: {
    where: { id: string };
    input: IUpdateShippingCompany;
  }): Promise<{ id: string }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ShippingCompanyQueries.updateShippingCompany(), {
          input,
          where,
        })
        .then(({ update }) => resolve(update))
        .catch(err => reject(err))
    );
}

export default ShippingCompanyController;
