import React, { useEffect, useState } from 'react';

import { IMenuKey } from '../utils/menuOptions';

/**
 * It can be user for layout context data, such as:
 * sidebar selected option,
 * screen width and height for responsiveness
 * and other possible layout data
 */

interface ILayoutProvider {
  screenWidth?: number;
  selectedMenu?: string;
  isLoading: boolean;
  setSelectedMenu: (menuKey: IMenuKey) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const useLayoutContextProvider = (): ILayoutProvider => {
  const [isLoading, setIsLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState('');

  useEffect(() => {
    setScreenWidth(window.screen.width);

    window.addEventListener('resize', (e: UIEvent) => {
      const { innerWidth } = e.target as Window;
      setScreenWidth(innerWidth);
    });
  }, []);

  return {
    screenWidth,
    selectedMenu,
    isLoading,
    setSelectedMenu,
    setIsLoading,
  };
};

const LayoutContext = React.createContext<ILayoutProvider>({
  isLoading: true,
  setSelectedMenu: () => {
    return;
  },
  setIsLoading: () => {
    return;
  },
});

export default LayoutContext;
