import { Button, Modal, Typography, message } from 'antd';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { useRequest } from '../../../hooks/useRequest';
import AddressController from '../../../structures/controllers/Address';
import { IDeletePickUpLocation } from '../../../structures/interfaces/Address';

const DeletePickUpAddress = ({
  t,
  visible,
  onClose,
  onConfirm,
  deleteData,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  deleteData: IDeletePickUpLocation;
}) => {
  const [deletePickUpAddress, isDeletePickUpAddressRequesting] = useRequest(
    AddressController.deletePickUpLocation
  );

  const info = () => {
    message.success({
      content: t('pages.settings.deletePickUpLocation.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };
  const infoError = () => {
    message.error({
      content: t('pages.settings.deletePickUpLocation.errors.message'),
      className: 'custom-class',
      style: {
        marginTop: 50,
      },
    });
  };
  const { Title } = Typography;
  const footerModal = [
    <Button
      key="back"
      danger
      onClick={onClose}
      loading={isDeletePickUpAddressRequesting}
    >
      {t('pages.settings.deletePickUpLocation.cancelButton')}
    </Button>,
    <Button
      type="primary"
      htmlType="submit"
      onClick={() => {
        deletePickUpAddress({ id: deleteData.id })
          .then(() => {
            info();
            onConfirm();
          })
          .catch(() => {
            infoError();
          });
      }}
      loading={isDeletePickUpAddressRequesting}
    >
      {t('pages.settings.deletePickUpLocation.confirmButton')}
    </Button>,
  ];
  return (
    <Modal width={'50%'} open={visible} onCancel={onClose} footer={footerModal}>
      <Title level={5}>{t('pages.settings.deletePickUpLocation.title')}</Title>
      <p>
        {t('pages.settings.deletePickUpLocation.info') + ' '}
        <b>{deleteData.title}</b>
        {'.'}
      </p>
      <p>{t('pages.settings.deletePickUpLocation.warning')}</p>
      <p>{t('pages.settings.deletePickUpLocation.confirmMessage')}</p>
    </Modal>
  );
};

export default withTranslation()(DeletePickUpAddress);
