import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Row,
  message,
} from 'antd';
import { RuleObject } from 'antd/lib/form';
import { TFunction } from 'i18next';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import UserContext from '../../../../contexts/user';
import { useRequest } from '../../../../hooks/useRequest';
import SettingsController from '../../../../structures/controllers/Settings';
import { ITreatmentTimeInputFormValues } from '../../../../structures/interfaces/Settings';
import { scrollOptions } from '../../../../utils/formOptions';

const baseString = 'pages.scheduleRules.ruleSettings';

const TreatmentTimeForm = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  const [changedFields, setChangedFields] =
    useState<ITreatmentTimeInputFormValues>();
  const [initialValues, setInitialValues] =
    useState<ITreatmentTimeInputFormValues>();

  const { userData, settings, loadingSettings, setSettings } =
    useContext(UserContext);

  const [form] = Form.useForm<ITreatmentTimeInputFormValues>();

  const [updateTreatmentTimeRequest, isUpdateTreatmentTimeRequesting] =
    useRequest(SettingsController.updateTreatmentTime);

  const requiredRule = {
    required: true,
    message: t('pages.settings.pickUpLocations.requiredRuleMessage'),
  };

  const negativeDayValidator = {
    validator(rule: RuleObject, value: number) {
      if (value < 0) {
        return Promise.reject(t('pages.settings.negativeDayError'));
      }
      if (value > 100) {
        return Promise.reject(
          t(`${baseString}.feedbacks.errors.percentageOverLimit`)
        );
      }

      return Promise.resolve();
    },
  };

  const handleValuesChange = (
    _: ITreatmentTimeInputFormValues,
    allFinalValues: ITreatmentTimeInputFormValues
  ) => {
    const changedFields = Object.fromEntries(
      Object.entries(allFinalValues).reduce<[string, string | undefined][]>(
        (acc, curr) => {
          if (curr[1] !== (initialValues as Record<string, unknown>)[curr[0]]) {
            return [...acc, curr];
          }
          return acc;
        },
        []
      )
    );

    setChangedFields(changedFields);
    if (Object.keys(changedFields).length > 0) setIsSubmitAvailable(true);
    else setIsSubmitAvailable(false);
  };

  const onFinish = () => {
    if (userData) {
      if (changedFields) {
        return updateTreatmentTimeRequest({
          organizationId: userData.organization.id,
          commonTsiSeedSchedulingTime:
            changedFields.commonTsiSeedSchedulingTime,
          commonWhiteSeedSchedulingTime:
            changedFields.commonWhiteSeedSchedulingTime,
        })
          .then(returnedValues => {
            setSettings(returnedValues);
            setInitialValues({
              commonTsiSeedSchedulingTime:
                returnedValues.commonTsiSeedSchedulingTime,
              commonWhiteSeedSchedulingTime:
                returnedValues.commonWhiteSeedSchedulingTime,
            });
            setIsSubmitAvailable(false);

            message.success({
              content: t(`${baseString}.feedbacks.success.operationSuccess`),
              className: 'custom-class',
              style: {
                marginTop: 60,
              },
            });
          })
          .catch(error => message.error(t(error.message)));
      }
    }
  };

  useEffect(() => {
    const initialValue = {
      commonTsiSeedSchedulingTime:
        settings && settings.commonTsiSeedSchedulingTime
          ? settings.commonTsiSeedSchedulingTime
          : 0,
      commonWhiteSeedSchedulingTime:
        settings && settings.commonWhiteSeedSchedulingTime
          ? settings.commonWhiteSeedSchedulingTime
          : 0,
    };
    form.setFieldsValue(initialValue);
    setInitialValues(initialValue);
  }, [settings]);

  return (
    <Form
      scrollToFirstError={scrollOptions}
      form={form}
      onFinish={onFinish}
      layout="vertical"
      onValuesChange={handleValuesChange}
    >
      <Alert
        style={{ marginBottom: 24, marginTop: 24 }}
        message={t(`${baseString}.operation`)}
        description={t(`${baseString}.tsiExplainMessage`)}
      />
      <Card
        style={{ marginTop: 24 }}
        loading={loadingSettings}
        title={t(`${baseString}.tsiCardTitle`)}
      >
        <Row>
          <Col span={6}>
            <Form.Item
              dependencies={['commonTsiSeedSchedulingTime']}
              validateFirst
              name="commonWhiteSeedSchedulingTime"
              style={{ marginRight: 24 }}
              label={t(`${baseString}.whiteSeedTreatment`)}
              rules={[{ ...requiredRule }, () => negativeDayValidator]}
            >
              <InputNumber
                size="middle"
                placeholder={t(`${baseString}.tsiPlaceholder`)}
                addonAfter={t('pages.scheduleRules.newRules.addonAfterDays')}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              dependencies={['commonWhiteSeedSchedulingTime']}
              validateFirst
              name="commonTsiSeedSchedulingTime"
              style={{ marginRight: 24 }}
              label={t(`${baseString}.tsiSeedTreatment`)}
              rules={[
                { ...requiredRule },
                {
                  validator: (_, value) => {
                    const whiteSeedsValue = form.getFieldValue(
                      'commonWhiteSeedSchedulingTime'
                    );
                    if (whiteSeedsValue >= 0) {
                      return value >= whiteSeedsValue
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(t('pages.settings.treatmentTimeTsi'))
                          );
                    }
                  },
                },
                () => negativeDayValidator,
              ]}
            >
              <InputNumber
                size="middle"
                placeholder={t(`${baseString}.tsiPlaceholder`)}
                addonAfter={t('pages.scheduleRules.newRules.addonAfterDays')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item label=" ">
            <Button
              size="middle"
              type="primary"
              htmlType="submit"
              loading={loadingSettings || isUpdateTreatmentTimeRequesting}
              disabled={!isSubmitAvailable}
            >
              {t(`${baseString}.confirmButton`)}
            </Button>
          </Form.Item>
        </Row>
      </Card>
    </Form>
  );
};

export default withTranslation()(TreatmentTimeForm);
