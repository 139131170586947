import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { IGetClient } from '../../../structures/interfaces/Clients';
import { DefaultOptionType } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { FilterContactsByStatus } from './ContactsPage.utils';

interface IContactsFilterProps {
  contacts: IGetClient[];
  setContacts: Dispatch<SetStateAction<IGetClient[]>>;
  filteredContacts: IGetClient[];
  setFilteredContacts: Dispatch<SetStateAction<IGetClient[]>>;
}
interface IContactsFilterFormValues {
  search: string;
  status: string;
}
const ContactsFilter = ({
  contacts,
  setFilteredContacts,
}: IContactsFilterProps): ReactElement => {
  const [form] = Form.useForm<IContactsFilterFormValues>();
  const { t } = useTranslation();

  const statusOptions: DefaultOptionType[] = [
    {
      label: t('enums.customerStatus.NOT_ACTIVATED'),
      value: 'NOT_ACTIVATED',
    },
    {
      label: t('enums.customerStatus.NOTIFIED'),
      value: 'NOTIFIED',
    },
    {
      label: t('enums.customerStatus.WAITING_ACTIVATION'),
      value: 'WAITING_ACTIVATION',
    },
    {
      label: t('enums.customerStatus.ACTIVATED'),
      value: 'ACTIVATED',
    },
  ];

  const onFinish = ({ search = '', status }: IContactsFilterFormValues) => {
    const filteredItems = contacts.filter(item =>
      item.slug?.includes(search.toLocaleLowerCase().trim())
    );
    const filterStatus = FilterContactsByStatus(filteredItems, status);
    setFilteredContacts(filterStatus);
  };

  return (
    <Form layout="vertical" size="small" form={form} onFinish={onFinish}>
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item label="Pesquisa" name="search">
            <Input.Search enterButton onSearch={() => form.submit()} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Status" name="status">
            <Select onChange={() => form.submit()} options={statusOptions} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Space>
            <Button icon={<ClearOutlined />} onClick={() => form.resetFields()}>
              Limpar filtro
            </Button>
            <Button
              icon={<FilterOutlined />}
              type="primary"
              onClick={() => form.submit()}
            >
              Filtrar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactsFilter;
