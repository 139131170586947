import client from '../../api/urql/customClient';
import { ITutorial } from '../interfaces/Tutorials';
import TutorialQueries from '../queries/Tutorials';

class TutorialsController {
  static tutorials = (): Promise<Array<ITutorial>> =>
    new Promise((resolve, reject) => {
      client
        .query(TutorialQueries.getTutorials())
        .then(({ tutorials }) => {
          resolve(tutorials);
        })
        .catch(err => {
          reject(err);
        });
    });
}

export default TutorialsController;
