import { Alert, Button, Card, Col, Form, message, Row, Space } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../../../contexts/user';
import { useRequest } from '../../../../../hooks/useRequest';
import ScheduleRulesController from '../../../../../structures/controllers/ScheduleRules';
import TruckLoadFooter from '../../../components/TruckLoadFooter';
import BasicData, { IBasicDataFormValues } from './StepOne.basicData';
import TruckLoadsCalendar from './StepOne.calendar';
import {
  ITruckLoadRules,
  ITruckLoadCadences,
  IStepOneData,
} from '../../../../../structures/interfaces/TruckLoad';
import TruckLoadsController from '../../../../../structures/controllers/TruckLoad';
import moment from 'moment';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';

interface IStepOneProps {
  nextStep: () => void;
  setStepOneData: (values: IStepOneData) => void;
}

const defaultSeedType = 'Soja';

const StepOne = ({ nextStep, setStepOneData }: IStepOneProps): ReactElement => {
  const { userData, customerData } = useContext(UserContext);
  const [form] = Form.useForm<IBasicDataFormValues>();
  const history = useHistory();

  const [fetchVehicles, isFetchingVehicles] = useRequest(
    TruckLoadsController.getVehicles
  );
  const [fetchSeedType, isFetchingSeedType] = useRequest(
    ScheduleRulesController.getCulture
  );
  const [fetchLocations, isFetchingLocations] = useRequest(
    ScheduleRulesController.getPickUpLocations
  );
  const [listTruckLoadsRule, isListingTruckLoadsRule] = useRequest(
    TruckLoadsController.listTruckLoadsRules
  );
  const [createPreSchedule, isCreatingPreSchedule] = useRequest(
    TruckLoadsController.createPreSchedule
  );

  const [vehicles, setVehicles] = useState<DefaultOptionType[]>([]);
  const [seedTypes, setSeedTypes] = useState<DefaultOptionType[]>([]);
  const [locations, setLocations] = useState<DefaultOptionType[]>([]); //pick up locations
  const [cadences, setCadences] = useState<ITruckLoadRules['cadences']>([]);
  const [selectedCadence, setSelectedCadence] = useState<ITruckLoadCadences>();

  const [haveMultipleRules, setHaveMultipleRules] = useState(false);

  const getVehicles = (orgId: string) => {
    fetchVehicles({ organizationId: orgId, takeCommonVehicles: true })
      .then(res => {
        const options = res.map(vehicle => ({
          value: vehicle.id,
          label: vehicle.vehicleModel,
        }));
        setVehicles(options);
      })
      .catch(() => message.error('Erro ao buscar veículos'));
  };
  const getPickUpLocations = (orgId: string) => {
    fetchLocations({ seedTypeId: defaultSeedType, organizationId: orgId })
      .then(res => {
        const options = res.map(location => ({
          value: location.id,
          label: location.title,
        }));
        setLocations(options);
      })
      .catch(() => message.error('Erro ao buscar locais de embarque'));
  };
  const getSeedTypes = () => {
    fetchSeedType({})
      .then(res => {
        const options = res.map(seedType => ({
          value: seedType.nameWithoutAccent,
          label: seedType.name,
        }));
        setSeedTypes(options);
      })
      .catch(() => message.error('Erro ao buscar culturas'));
  };
  const onFinish = (value: IBasicDataFormValues) => {
    const preScheduleAuthor = customerData ? customerData.id : userData?.id;
    const orgId = userData?.organization.id || customerData?.organizationId;

    createPreSchedule({
      input: {
        cadence_id: selectedCadence?.id || '',
        created_by: preScheduleAuthor || '',
        pick_up_location_id: value.pickUpLocation.value,
        vehicle_id: value.vehicle,
      },
    })
      .then(res => {
        const aux = {
          cadence: selectedCadence,
          loadName: value.loadName,
          customerCapacity: 0, // tem que fazer uma request somente para esse campo, por isso será temporariamente removido!
          organizationId: orgId,
          pickupLocation: {
            title: value.pickUpLocation.label,
            id: value.pickUpLocation.value,
          },
          preScheduleId: res.id,
          seedType: value.seedType,
          selectedDate: moment(selectedCadence?.startTime),
          unity: value.unity,
          cancel: res,
          vehicle: {
            id: res.vehicle.id,
            bagsCapacity: res.vehicle.bagsCapacity,
            vehicleModel: res.vehicle.vehicleModel,
          },
        } as IStepOneData;
        setStepOneData(aux);
        nextStep();
      })
      .catch(() => null);
  };

  const isLoading =
    isFetchingVehicles || isFetchingSeedType || isFetchingLocations;

  const firstAllert = haveMultipleRules && (
    <Alert
      message="No momento existem mais de uma regra com dados parecidos, utilize os
    filtros acima para encontrar a regra desejada!"
    />
  );

  useEffect(() => {
    const orgId =
      userData?.organization.id || customerData?.organizationId || '';
    listTruckLoadsRule({ organizationId: orgId })
      .then(res => {
        if (res.length == 1) {
          const rule = res[0];
          form.setFieldsValue({
            seedType: rule.seedType.name,
            pickUpLocation: {
              label: rule.pickUpLocation.title,
              value: rule.pickUpLocation.id,
            },
            unity: rule.unity,
          });
          setCadences(rule.cadences);
        } else {
          getSeedTypes();
          setHaveMultipleRules(true);
        }
      })
      .catch();
    getVehicles(orgId);
  }, [form]);

  return (
    <>
      <Row gutter={[24, 24]} style={{ marginBottom: 88 }}>
        <Col span={24}>
          <Card title="Informe os dados básicos da carga" loading={isLoading}>
            <BasicData
              form={form}
              vehicles={vehicles}
              seedTypes={seedTypes}
              pickUpLocations={locations}
              getPickUpLocation={getPickUpLocations}
              setCadences={setCadences}
              onFinish={onFinish}
            />
          </Card>
        </Col>

        {haveMultipleRules && <Col span={24}>{firstAllert}</Col>}

        <Col span={24}>
          <Card
            title="Selecione o dia para agendamento"
            loading={isListingTruckLoadsRule}
          >
            <TruckLoadsCalendar
              cadences={cadences}
              setSelectedCadence={setSelectedCadence}
            />
          </Card>
        </Col>
      </Row>

      <TruckLoadFooter>
        <Space style={{ margin: '24px 0' }}>
          <Button
            danger
            onClick={() => history.push('/cargas')}
            icon={<CloseOutlined />}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            icon={<ArrowRightOutlined />}
            loading={isCreatingPreSchedule}
            disabled={!selectedCadence}
          >
            Próximo
          </Button>
        </Space>
      </TruckLoadFooter>
    </>
  );
};

export default StepOne;
