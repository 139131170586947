import client from '../../api/urql/customClient';
import {
  ICreateVehicle,
  IUpdateVehicle,
  IVehicle,
} from '../interfaces/Vehicle';
import VehicleQueries from '../queries/Vehicle';

class VehiclesController {
  static createVehicle = ({
    input,
  }: {
    input: ICreateVehicle;
  }): Promise<{ id: string }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(VehicleQueries.createVehicle(), {
          input,
        })
        .then(({ createVehicle }) => {
          resolve(createVehicle);
        })
        .catch(reject)
    );
  };

  static getVehicles = ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<IVehicle[]> =>
    new Promise((resolve, reject) =>
      client
        .query(VehicleQueries.getVehicles(), {
          where: {
            organization_id: organizationId,
          },
        })
        .then(({ vehicles }) => {
          const sortedVehicles = vehicles.sort((a, b) =>
            b.updatedAt.localeCompare(a.updatedAt)
          );
          resolve(sortedVehicles);
        })
        .catch(err => {
          reject(err);
        })
    );

  static updateVehicle = ({
    vehicleId,
    input,
  }: {
    vehicleId: string;
    input: IUpdateVehicle;
  }): Promise<{ id: string }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(VehicleQueries.updateVehicle(), {
          where: {
            id: vehicleId,
          },
          input,
        })
        .then(({ updateVehicle }) => {
          resolve(updateVehicle);
        })
        .catch(reject)
    );
  };

  static deleteVehicle = ({
    vehicleId,
  }: {
    vehicleId: string;
  }): Promise<{ success: boolean }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(VehicleQueries.deleteVehicle(), {
          where: {
            id: vehicleId,
          },
        })
        .then(({ deleteVehicle }) => {
          resolve(deleteVehicle);
        })
        .catch(reject)
    );
  };
}

export default VehiclesController;
