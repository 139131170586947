import { ReactElement } from 'react';

import { Row } from 'antd';

import I18n from '../../components/i18n';

import './index.scss';

interface AuthenticationProps {
  children: ReactElement;
}

const Authentication = ({
  children,
}: AuthenticationProps): ReactElement<unknown> => {
  return (
    <Row justify="center" align="middle" className="hero-container">
      <I18n />
      {children}
    </Row>
  );
};

export default Authentication;
