import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { rejects } from 'assert';
import { t } from 'i18next';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import UserContext from '../../../../contexts/user';
import { useRequest } from '../../../../hooks/useRequest';
import ClientsController from '../../../../structures/controllers/Clients';
import ScheduleRulesController from '../../../../structures/controllers/ScheduleRules';
import { Farm } from '../../../../structures/interfaces/Clients';
import {
  CustomerRuleInputFormValues,
  ICreateCustomerRule,
  IEditCustomerRule,
  IFindRules,
} from '../../../../structures/interfaces/ScheduleRules';
import { RuleObject } from 'antd/lib/form';
import { scrollOptions } from '../../../../utils/formOptions';

export interface IConfirmEditionProps {
  customerRuleModalVisible: boolean;
  onOkCustomerRule: () => void;
  onCancelCustomerRule: () => void;
  selectedCustomerRule: IEditCustomerRule | undefined;
  referenceRule?: IFindRules | undefined;
  customerRules: ICreateCustomerRule[];
  setCustomerRules: Dispatch<SetStateAction<ICreateCustomerRule[]>>;
}
const { Option } = Select;
const { RangePicker } = DatePicker;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CustomerRuleEdition = ({
  customerRuleModalVisible,
  onOkCustomerRule,
  onCancelCustomerRule,
  selectedCustomerRule,
  referenceRule,
  customerRules,
  setCustomerRules,
}: IConfirmEditionProps) => {
  const editFooter = [
    <Button key="back" danger onClick={onCancelCustomerRule}>
      {t('pages.settings.deleteBranchCompany.cancelButton')}
    </Button>,
    <Button
      key="confirm"
      type="primary"
      htmlType="submit"
      onClick={() => {
        onOkCustomerRule();
        form.submit();
      }}
    >
      {t('pages.settings.deleteBranchCompany.confirmButton')}
    </Button>,
  ];

  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 8 };
  const requiredRule = {
    required: true,
    message: t('pages.settings.pickUpLocations.requiredRuleMessage'),
  };
  const validValueRule = {
    validator(rule: RuleObject, value: number) {
      if (value === null && !rule.required) {
        return Promise.resolve();
      }
      if (value <= 0) {
        return Promise.reject(t('pages.scheduleRules.insertAValidValue'));
      }

      return Promise.resolve();
    },
  };

  const [editCustomerRuleRequest] = useRequest(
    ScheduleRulesController.editCustomerRule
  );

  const updateCustomerRule = (values: CustomerRuleInputFormValues) => {
    if (!selectedCustomerRule) return;

    const [start, end] = values.rulePeriod;
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isNewRule = !referenceRule?.customerRules.some(
      cr => cr.id === selectedCustomerRule.id
    );

    const editingCustomerRule = customerRules.find(
      customerRule => customerRule.id === selectedCustomerRule.id
    );

    if (!editingCustomerRule) return;

    if (isNewRule) {
      Object.assign(editingCustomerRule, {
        startDate: start,
        endDate: end,
        timezone: tz,
        capacity: values.capacity,
      });

      setCustomerRules([...customerRules]);
    } else {
      editCustomerRuleRequest({
        id: selectedCustomerRule.id,
        input: {
          capacity: values.capacity,
          endDate: end,
          startDate: start,
        },
      })
        .then(value => {
          Object.assign(editingCustomerRule, {
            ...value,
          });

          setCustomerRules([...customerRules]);
        })
        .catch(rejects);
    }
  };

  const { userData } = useContext(UserContext);
  const [form] = useForm();
  const [getClientsRequest] = useRequest(ClientsController.getFarms);
  const [clients, setClients] = useState<Farm[]>([]);

  const getClients = () => {
    if (userData) {
      getClientsRequest(userData.organization.id)
        .then(cust => {
          setClients(cust);
        })
        .catch(() => {
          <Alert
            message={t('errors.900.message')}
            description={t('errors.900.description')}
          />;
        });
    }
  };

  useEffect(() => {
    getClients();
  }, [userData]);

  useEffect(() => {
    if (selectedCustomerRule) {
      form.setFieldsValue({
        customerId: selectedCustomerRule.customerId,
        rulePeriod: [
          moment(selectedCustomerRule.startDate),
          moment(selectedCustomerRule.endDate),
        ],
        capacity: selectedCustomerRule.capacity,
      });
    }
  }, [selectedCustomerRule]);

  const onFinish = (values: CustomerRuleInputFormValues) => {
    updateCustomerRule(values);
  };

  return (
    <>
      <Modal
        forceRender
        title={t('pages.scheduleRules.editRules.confirmRuleCard.modalTitle')}
        width={1000}
        onCancel={onCancelCustomerRule}
        open={customerRuleModalVisible}
        onOk={onOkCustomerRule}
        footer={editFooter}
      >
        <Form
          scrollToFirstError={scrollOptions}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col {...colSize}>
              <Form.Item
                rules={[{ ...requiredRule }]}
                name="customerId"
                label={t('pages.scheduleRules.newRules.clientLabel')}
              >
                <Select
                  key={selectedCustomerRule?.id}
                  disabled
                  placeholder={t(
                    'pages.scheduleRules.newRules.defaultValueSelect'
                  )}
                >
                  {clients &&
                    clients.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name || item.tradeName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col {...colSize}>
              <Form.Item
                name="rulePeriod"
                label={t('pages.scheduleRules.newRules.rulePeriodLabel')}
                validateFirst
                rules={[{ ...requiredRule }]}
              >
                <RangePicker format={'DD/MM/YYYY'} />
              </Form.Item>
            </Col>

            <Col {...colSize}>
              <Form.Item
                rules={[{ ...requiredRule }, () => validValueRule]}
                name="capacity"
                label={t(
                  'pages.scheduleRules.newRules.maximumCapacityPerCompanyLabel'
                )}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerRuleEdition;
