import { Card, Col, Form, Row, Select } from 'antd';
import { TFunction } from 'i18next';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { withTranslation } from 'react-i18next';

interface IUnityInformationProps {
  t: TFunction;
  setUnityType: Dispatch<SetStateAction<string>>;
}

const { Option } = Select;

const UnityInformationCard = ({
  t,
  setUnityType,
}: IUnityInformationProps): ReactElement<unknown> => {
  return (
    <Card
      style={{ height: '100%' }}
      title={t('pages.scheduleRules.newRules.unityInformation.unityTitle')}
    >
      <Row gutter={24}>
        <Col>
          <Form.Item
            name="unity"
            label={t(
              'pages.scheduleRules.newRules.unityInformation.measurementUnityLabel'
            )}
            initialValue="BAG"
          >
            <Select onChange={(value: string) => setUnityType(value)}>
              <Option value="BAG">
                {t('pages.scheduleRules.newRules.unityInformation.bagsOption')}
              </Option>
              <Option value="SMALL_BAG">
                {t(
                  'pages.scheduleRules.newRules.unityInformation.smallBagOption'
                )}
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(UnityInformationCard);
