import { Col, Form, Input, InputNumber, message, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { ReactElement, useContext, useEffect } from 'react';
import { IVehicle } from '../../../structures/interfaces/Vehicle';
import VehiclesController from '../../../structures/controllers/Vehicle';
import { useRequest } from '../../../hooks/useRequest';
import UserContext from '../../../contexts/user';

export interface IUpdateVehicleFormValues {
  id: string;
  vehicleModel: string;
  bodywork: string;
  weightCapacity: number;
  bagsCapacity: number;
  axlesAmount: number;
}
interface IUpdateVehicleProps {
  form: FormInstance<IUpdateVehicleFormValues>;
  updateData?: IVehicle;
  doListVehicles?: () => void;
}

const UpdateVehicle = ({
  form,
  updateData,
  doListVehicles,
}: IUpdateVehicleProps): ReactElement => {
  const { userData } = useContext(UserContext);
  const [createVehicle] = useRequest(VehiclesController.createVehicle);
  const [updateVehicle] = useRequest(VehiclesController.updateVehicle);

  const onFinish = (values: IUpdateVehicleFormValues) => {
    if (userData) {
      if (!updateData) {
        createVehicle({
          input: {
            axles_amount: values.axlesAmount,
            bags_capacity: values.bagsCapacity,
            bodywork: values.bodywork,
            organization_id: userData.organization.id,
            vehicle_model: values.vehicleModel,
            weight_capacity: values.weightCapacity,
          },
        })
          .then(() => {
            message.success('Veiculo criado com sucesso!');
            form.resetFields();
            doListVehicles && doListVehicles();
          })
          .catch(() => {
            message.error('Erro ao criar o veículo!');
            form.resetFields();
          });
      } else {
        updateVehicle({
          input: {
            axles_amount: values.axlesAmount,
            bags_capacity: values.bagsCapacity,
            bodywork: values.bodywork,
            vehicle_model: values.vehicleModel,
            weight_capacity: values.weightCapacity,
          },
          vehicleId: values.id,
        })
          .then(() => {
            message.success('Veiculo atualizado com sucesso!');
            form.resetFields();
            doListVehicles && doListVehicles();
          })
          .catch(() => {
            message.error('Erro ao atualizar o veículo!');
            form.resetFields();
          });
      }
    }
  };

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        bagsCapacity: updateData.bagsCapacity,
        bodywork: updateData.bodywork,
        id: updateData.id,
        vehicleModel: updateData.vehicleModel,
        weightCapacity: updateData.weightCapacity,
        axlesAmount: updateData.axlesAmount,
      });
    }
  }, [updateData]);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item name={'id'} hidden />

      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item name={'vehicleModel'} label="Modelo">
            <Input placeholder="Insira o modelo do veículo" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={'bodywork'} label="Carroceria">
            <Input placeholder="Insira a carroceria do veículo" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={'weightCapacity'} label="Capacidade em Kg">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Insira a capacidade em Kg"
              addonAfter="Kg"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={'bagsCapacity'} label="Capacidade em Bags">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Insira a capacidade em Bags"
              addonAfter="Bags"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={'axlesAmount'} label="Quantidade de eixos">
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Insira a quantidade de eixos"
              addonAfter="Eixos"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UpdateVehicle;
