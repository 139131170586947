import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import { TFunction } from 'i18next';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import AddressController from '../../../structures/controllers/Address';
import CompanyController from '../../../structures/controllers/Company';
import { ICityName } from '../../../structures/interfaces/Address';
import { validateCnpj } from '../../../utils/inputRules';
import { IErrorMessage } from '../../../utils/interfaces';
import { UF, UFNameOptions } from '../../../utils/UF';
import { localCountryName } from '../localCountryName';
import { scrollOptions } from '../../../utils/formOptions';

const newBranchCompany = ({
  t,
  visible,
  onClose,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
}): ReactElement<unknown> => {
  const [ufOptions, setUfOptions] = useState<string>();
  const [stateName, setStateName] = useState<string>('');
  const [cityOptions, setCityOptions] = useState<ICityName[]>([]);
  const [findCitiesForStateRequest] = useRequest(
    AddressController.findCitiesByState
  );
  const [newBranchCompany, isNewBranchCompanyRequesting] = useRequest(
    CompanyController.newBranchCompany
  );

  const { Option } = Select;
  const [form] = Form.useForm();
  const { userData } = useContext(UserContext);
  const info = () => {
    message.success(t('pages.settings.newBranchCompany.success.message'));
  };
  const infoError = (err: IErrorMessage) => {
    message.error({
      content: t(`${err.message}`),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  const onFinish = (values: any) => {
    values.zipCode = values.zipCode.replace(/[^0-9]/g, '');
    values.documentNumberCnpj = values.documentNumberCnpj.replace(
      /[^0-9]/g,
      ''
    );
    const req = {
      ...values,
      countryName: `${localCountryName}`,
      parentId: `${userData?.organization.id}`,
      stateName: stateName,
    };
    newBranchCompany({ registerBranchOrganization: req })
      .then(() => {
        info();
        onClose();
        form.resetFields();
      })
      .catch(err => infoError(err));
  };

  const handleOnChange = (selectedValue: string) => {
    const shortName = UFNameOptions.find(({ value }) => {
      return value === selectedValue;
    });
    setUfOptions(selectedValue);
    setStateName(shortName?.label as string);
  };

  useEffect(() => {
    if (ufOptions) {
      findCitiesForStateRequest(ufOptions)
        .then(city => {
          setCityOptions(city);
          if (
            cityOptions.find(
              city => form.getFieldValue('cityName') !== city.cityName
            )
          ) {
            form.setFieldsValue({
              cityName: null,
            });
          }
        })
        .catch(err => infoError(err));
    }
  }, [ufOptions]);

  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 6 };
  const { Title } = Typography;

  const formNewBranchCompany = (
    <Form
      scrollToFirstError={scrollOptions}
      name="basic"
      onFinish={onFinish}
      layout="vertical"
      form={form}
    >
      <Title level={5}>
        {t('pages.settings.newBranchCompany.titleCompanyInfo')}
      </Title>
      <Row gutter={[24, 0]}>
        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.newBranchCompany.tradeName')}
            name="tradeName"
            rules={[
              {
                required: true,
                message: t('pages.settings.newBranchCompany.rmTradeName'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.newBranchCompany.phTradeName')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.newBranchCompany.companyName')}
            name="companyName"
            rules={[
              {
                required: true,
                message: t('pages.settings.newBranchCompany.rmCompanyName'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.newBranchCompany.phCompanyName')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.newBranchCompany.documentNumberCnpj')}
            name="documentNumberCnpj"
            normalize={Formatter.formatCNPJ}
            validateFirst
            rules={[
              {
                required: true,
                message: t(
                  'pages.settings.newBranchCompany.rmDocumentNumberCnpj'
                ),
              },
              {
                min: 14,
                message: 'O CNPJ precisa ter no mínimo 14 dígitos',
              },
              { ...validateCnpj, message: t(validateCnpj.message) },
            ]}
          >
            <Input
              placeholder={t(
                'pages.settings.newBranchCompany.phDocumentNumberCnpj'
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      <Title level={5}>
        {t('pages.settings.newBranchCompany.titleInfoAddress')}
      </Title>
      <Row gutter={[24, 0]}>
        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.newBranchCompany.localName')}
            name="title"
            rules={[
              {
                required: true,
                message: t('pages.settings.newBranchCompany.rmLocalName'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.newBranchCompany.phLocalName')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            normalize={Formatter.formatZipCode}
            label={t('pages.settings.newBranchCompany.zipCode')}
            name="zipCode"
            rules={[
              {
                required: true,
                message: t('pages.settings.newBranchCompany.rmZipCode'),
              },
              {
                min: 9,
                max: 9,
                message: t('pages.settings.newBranchCompany.rmZipCodeMinSize'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.newBranchCompany.phZipCode')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.newBranchCompany.stateShortName')}
            name="stateShortName"
            rules={[
              {
                required: true,
                message: t('pages.settings.newBranchCompany.rmStateShortName'),
              },
            ]}
          >
            <Select
              placeholder={t(
                'pages.settings.newBranchCompany.phStateShortName'
              )}
              onSelect={(value: UF) => handleOnChange(value)}
            >
              {UFNameOptions.map(uf => {
                return (
                  <Option key={uf.value} value={uf.value}>
                    {uf.value} - {uf.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.newBranchCompany.cityName')}
            name="cityName"
            rules={[
              {
                required: true,
                message: t('pages.settings.newBranchCompany.rmCityName'),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t('pages.settings.newBranchCompany.phCityName')}
              optionFilterProp="children"
            >
              {cityOptions?.map(city => {
                return (
                  <Option
                    key={`${city.cityName}_${city.id}`}
                    value={city.cityName}
                  >
                    {city.cityName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.newBranchCompany.district')}
            name="district"
            rules={[
              {
                required: true,
                message: t('pages.settings.newBranchCompany.rmDistrict'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.newBranchCompany.phDistrict')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.newBranchCompany.street')}
            name="street"
            rules={[
              {
                required: true,
                message: t('pages.settings.newBranchCompany.rmStreet'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.newBranchCompany.phStreet')}
            />
          </Form.Item>
        </Col>

        <Col {...colSize}>
          <Form.Item
            label={t('pages.settings.newBranchCompany.streetNumber')}
            name="streetNumber"
            rules={[
              {
                required: true,
                message: t('pages.settings.newBranchCompany.rmStreetNumber'),
              },
            ]}
          >
            <Input
              placeholder={t('pages.settings.newBranchCompany.phStreetNumber')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const footerModal = [
    <Button key="back" danger onClick={onClose}>
      {t('pages.settings.newBranchCompany.cancelButton')}
    </Button>,
    <Button
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
      }}
      loading={isNewBranchCompanyRequesting}
    >
      {t('pages.settings.newBranchCompany.confirmButton')}
    </Button>,
  ];

  return (
    <>
      <Modal
        title={t('pages.settings.newBranchCompany.title')}
        width={'70%'}
        open={visible}
        onCancel={onClose}
        footer={footerModal}
      >
        {formNewBranchCompany}
      </Modal>
    </>
  );
};

export default withTranslation()(newBranchCompany);
