import { CopyOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Layout,
  message,
  Row,
  Typography,
} from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import Formatter from '../../../classes/Formatter';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import { IGetClient } from '../../../structures/interfaces/Clients';
import { FormInstance } from 'antd/es/form/Form';

const copyToClipboard = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
    message.success('Copiado para área de transferência!');
  } catch (err) {
    message.error('Falha ao copiar a senha!');
  }
};

interface IResetPassProps {
  form: FormInstance;
  data?: IGetClient;
}

const ResetContactPassword = ({
  form,
  data,
}: IResetPassProps): ReactElement => {
  const [password, setPassword] = useState('');
  const [resetPass] = useRequest(ClientsController.resetCustomerPass);

  useEffect(() => {
    form.resetFields();
    setPassword('');
  }, [open]);

  const handleOnOk = () => {
    if (!data) return null;
    resetPass({ ids: [data.id] })
      .then(res => {
        form.setFieldsValue({ password: res[0].password });
        setPassword(res[0].password);
      })
      .catch(() => {
        message.error('Não foi possível resetar a senha deste usuário!');
      });
  };

  return (
    <Layout>
      <Descriptions column={1} style={{ marginBottom: 32 }}>
        <Descriptions.Item label="Nome">
          {data?.tradeName || data?.name}
        </Descriptions.Item>
      </Descriptions>

      {password ? (
        <Form form={form} onFinish={handleOnOk}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="password">
                <Input.Password />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Button
                disabled={!password}
                type="primary"
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(form.getFieldValue('password'))}
              />
            </Col>
          </Row>
        </Form>
      ) : (
        <Typography.Text strong>
          Clique para resetar a senha de seu cliente!
        </Typography.Text>
      )}
    </Layout>
  );
};

export default ResetContactPassword;
