import jwtDecode, { JwtPayload } from 'jwt-decode';

import { getDataFromStorage, setDataToStorage } from '../utils';

import { IAuthToken } from '../utils/interfaces';

/**
 * This class manage user storage data
 */

class User {
  static getToken(): string | null {
    return getDataFromStorage('token', !this.getKeepConnected());
  }

  static getCrop(): string | null {
    return getDataFromStorage('crop');
  }

  static getOrganization(): string | null {
    return getDataFromStorage('organization');
  }

  static setTokenToStorage(
    token: string,
    keepConnected = false
  ): Promise<IAuthToken & JwtPayload> {
    return new Promise((resolve, reject) => {
      try {
        const decodedToken = jwtDecode<IAuthToken & JwtPayload>(`${token}`);

        setDataToStorage('token', token, !keepConnected);
        setDataToStorage('keepConnected', keepConnected);

        resolve(decodedToken);
      } catch (e) {
        reject({
          message: 'errors.invalidToken.message',
          description: 'errors.invalidToken.description',
        });
      }
    });
  }

  private static getKeepConnected(): string | null {
    return getDataFromStorage('keepConnected');
  }
}

export default User;
