import { Button, Col, Form, Input, Row, Space } from 'antd';
import { ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollOptions } from '../../../utils/formOptions';
import { IFarmResponse } from '../../../structures/interfaces/Clients';
import { ClearOutlined, FilterOutlined } from '@ant-design/icons';

interface IFarmsFilter {
  setFilteredFarms: (value: SetStateAction<IFarmResponse[]>) => void;
  filteredFarms: IFarmResponse[];
  farms: IFarmResponse[];
}
const FarmsFilter = ({
  setFilteredFarms,
  filteredFarms,
  farms,
}: IFarmsFilter): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = ({ search }: { search?: string }) => {
    if (!search) {
      setFilteredFarms(farms);
    } else {
      const treatedSearch = search.toLowerCase().trim();
      const filteredFarmsItems: IFarmResponse[] = [];
      filteredFarms.map(client => {
        if (client.slug?.includes(treatedSearch))
          filteredFarmsItems.push(client);
      });
      setFilteredFarms(filteredFarmsItems);
    }
  };

  return (
    <Form
      scrollToFirstError={scrollOptions}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      size="small"
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Pesquisa" name="search">
            <Input.Search
              placeholder="Procure itens na tabela"
              enterButton
              onSearch={() => form.submit()}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Space size={12}>
            <Button
              icon={<ClearOutlined />}
              children={t('pages.clients.clearFilter')}
              onClick={() => form.resetFields()}
            />
            <Button
              type="primary"
              children={t('pages.clients.filter')}
              icon={<FilterOutlined />}
              onClick={() => form.submit()}
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default FarmsFilter;
