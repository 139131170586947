/**
 * Panel theme layout configuration
 */

const layoutStyles = {
  textColor: '#363A45',
  backgroundColor:
    'transparent linear-gradient(180deg, #001529 0%, #001529 100%) 0% 0% no-repeat padding-box',
  menuSelectedOption: '#1890ff',
};

const colorPallette = {
  primaryBg: '#006AEC',
  primaryColor: '#FFF',
};

module.exports = {
  layoutStyles,
  colorPallette,
};
