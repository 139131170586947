import moment from 'moment';
import defaultClient from '../../api/urql/client';
import client from '../../api/urql/customClient';
import Formatter from '../../classes/Formatter';
import { ILogin, IUpdateUserData } from '../../utils/interfaces';
import {
  IAllowUserAccess,
  ICreateUser,
  ICreateUserResponse,
  ICrop,
  ICustomerOrganization,
  IGetLoginDataUser,
  IGetPermission,
  IGetUser,
  IGetUserResponse,
  IRegisterUserInput,
  IRegisterUserResponse,
  IUpdateUser,
  IUpdateUserAccount,
  IUserOrganization,
  IUserPermissions,
} from '../interfaces/User';
import { UserOrganizationModal } from '../models/User';
import queries from '../queries/User';

const buildSlug = (users: IGetUser[]) =>
  users?.map(user => ({
    ...user,
    slug: Formatter.removeAccents(
      `${user.id} ${user.userType} ${user.name} ${user.email} ${user.createdAt} ${user.documentNumberCpf} ${user.organization.tradeName} ${user.phoneNumber}`.toLowerCase()
    ),
  }));

const filterUserByType = (users: IGetUser[], userType?: string) =>
  userType != undefined
    ? users.filter(user => {
        if (user.userType && user.userType.id === userType) {
          return user;
        }
      })
    : users;

const filterUserByDate = (
  users: IGetUser[],
  date?: [moment.Moment, moment.Moment]
) =>
  date != undefined
    ? users.filter(user => {
        return moment(user.createdAt).isBetween(date[0], date[1], 'day', '[]');
      })
    : users;

const filterUsersByStatus = (users: IGetUser[], userStatus?: string) =>
  userStatus != undefined
    ? users.filter(user => user.status === userStatus)
    : users;

class UserController {
  static login = ({
    email,
    password,
  }: ILogin): Promise<{
    token: string;
  }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.login(), { email, password })
        .then(data => {
          resolve({
            token: data.Login.token,
          });
          resolve({ token: data.Login.token });
        })
        .catch(reject)
    );

  static getOrganizationId = (userId: string): Promise<IUserOrganization[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getUserOrganizations(), { userId })
        .then(({ data: { OrganizationManagers } }) =>
          resolve(
            OrganizationManagers.map(
              ({ organization }) => new UserOrganizationModal(organization)
            )
          )
        )
        .catch(reject)
    );

  static createUser = (createUser: ICreateUser): Promise<ICreateUserResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.createUser(), {
          createUserInput: createUser,
        })
        .then(({ createdUser }) => resolve(createdUser))
        .catch(reject)
    );

  static validateEmail = (token: string): Promise<string> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.validateEmail(), { token })
        .then(({ validateEmail }) => {
          resolve(validateEmail.token);
        })
        .catch(reject)
    );

  static sendRecoveryCode = (email: string): Promise<string> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.sendRecoveryCode(), { input: { email } })
        .then(sendRecoveryCode => {
          resolve(sendRecoveryCode.sendCode.email);
        })
        .catch(reject)
    );

  static validateRecoveryCode = ({
    email,
    recoveryCode,
  }: {
    email: string;
    recoveryCode: string;
  }): Promise<{ token: string }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.validadeRecoveryCode(), {
          input: { email, recovery_code: recoveryCode },
        })
        .then(validadeRecoveryCode => {
          resolve({
            token: validadeRecoveryCode.validateToken.token,
          });
        })
        .catch(reject)
    );

  static resetPassword = ({
    password,
    id,
    token,
  }: {
    password: string;
    id: string;
    token: string;
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) =>
      defaultClient
        .mutation(
          queries.resetPassword(),
          {
            input: { password },
            where: { id },
          },
          { fetchOptions: { headers: { Authorization: `Bearer ${token}` } } }
        )
        .toPromise()
        .then(({ data, error }) => {
          if (data) {
            resolve({
              success: data?.resetpassword.success,
            });
          } else {
            reject(error);
          }
        })
        .catch(reject)
    );

  static getFirstUserData = (id: string): Promise<IGetUserResponse> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getFirstUserData(), { where: { id } })
        .then(({ firstUserData }) => {
          resolve(firstUserData);
        })
        .catch(err => reject(err))
    );

  static getUser = (id: string): Promise<IGetUserResponse> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getUser(), { where: { id } })
        .then(({ getUserData }) => {
          resolve(getUserData);
        })
        .catch(err => reject(err))
    );

  static getUserDataOnLogin = (id: string): Promise<IGetLoginDataUser> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getUserDataLogin(), { where: { id } })
        .then(({ getUserData }) => {
          resolve(getUserData);
        })
        .catch(err => reject(err))
    );

  static updatePassword = ({
    password,
    id,
  }: {
    password: string;
    id: string;
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.updatePassword(), {
          input: { password },
          where: { id },
        })
        .then(({ status }) => {
          resolve({ success: status });
        })
        .catch(err => reject(err))
    );

  static getUserPermissionOptions = (): Promise<IUserPermissions[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.userPermissionsTable())
        .then(({ getPermissions }) => {
          const data: IUserPermissions[] = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let tmpObj: any = {};
          getPermissions.map(({ userPermissions, userType }) => {
            userPermissions.map(item => {
              if (!(item.module in tmpObj)) {
                tmpObj[item.module] = {};
                tmpObj[item.module]['module'] = item.module;
                tmpObj['type'] = userType.id;
              }
              tmpObj[item.module][item.operation] = item.id;
            });

            data.push(tmpObj);
            tmpObj = {};
          });

          resolve(data);
        })
        .catch(err => reject(err))
    );

  static getUserByOrganizationId = (
    organizationId: string
  ): Promise<IGetUser[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getUserByOrganizationId(), {
          where: { organization_id: organizationId },
        })
        .then(({ getUser }) => {
          const users: IGetUser[] = getUser
            .map(({ ...users }) => {
              return {
                ...users,
                createdAtString: moment(users.createdAt).format('DD/MM/YYYY'),
                documentNumberCpfMask: Formatter.formatCPF(
                  users.documentNumberCpf
                ),
                phoneNumberMask: Formatter.formatCellphone(users.phoneNumber),
              };
            })
            .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));
          resolve(users);
        })
        .catch(err => reject(err))
    );

  static getUserPermissions = (): Promise<IGetPermission[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getUserPermissions())
        .then(({ getPermissions }) => {
          resolve(getPermissions);
        })
        .catch(err => reject(err))
    );

  static filterUsers = (
    getUsers: IGetUser[],
    {
      search = '',
      userType,
      userDate,
      userStatus,
    }: {
      search?: string;
      userType?: string;
      userDate?: [moment.Moment, moment.Moment];
      userStatus?: string;
    }
  ): IGetUser[] => {
    return filterUserByDate(
      filterUsersByStatus(
        filterUserByType(
          buildSlug(getUsers).filter(user =>
            user.slug.includes(
              Formatter.removeAccents(search.toLowerCase().trim())
            )
          ),
          userType
        ),
        userStatus
      ),
      userDate
    );
  };

  static updateUserAccount = ({
    user,
    id,
  }: {
    user: IUpdateUserAccount;
    id: string;
  }): Promise<IUpdateUserAccount> =>
    new Promise((resolve, reject) => {
      client
        .mutation(queries.updateUserAccount(), {
          where: { id },
          input: {
            name: user.name,
            phone_number: user.phoneNumber,
            email: user.email,
          },
        })
        .then(updateUser => {
          resolve(updateUser.user);
        })
        .catch(err => {
          reject(err);
        });
    });

  static updateUser = ({
    user,
    id,
  }: {
    user: IUpdateUser;
    id: string;
  }): Promise<IUpdateUser> =>
    new Promise((resolve, reject) => {
      client
        .mutation(queries.updateUser(), {
          where: { id },
          input: {
            type_id: user.typeId,
            name: user.name,
            document_number_cpf: user.documentNumberCpf,
            organization_id: user.organizationId,
            phone_number: user.phoneNumber,
            receive_notifications: user.receiveNotifications,
            ignore_driver_expiration: user.ignoreDriverExpiration,
            email: user.email,
            permissions: {
              delete: user.permissions.delete,
              add: user.permissions.add,
            },
          },
        })
        .then(({ user }) => {
          resolve(user);
        })
        .catch(err => {
          reject(err);
        });
    });

  static deleteUser = ({ id }: { id: string }): Promise<boolean> =>
    new Promise((resolve, reject) => {
      client
        .mutation(queries.deleteUser(), {
          where: { id },
        })
        .then(({ status }) => {
          resolve(status);
        })
        .catch(err => {
          reject(err);
        });
    });
  static registerUser = ({
    user,
  }: {
    user: IRegisterUserInput;
  }): Promise<IRegisterUserResponse> =>
    new Promise((resolve, reject) => {
      client
        .mutation(queries.registerUser(), {
          input: {
            user_type: user.userType,
            name: user.name,
            document_number_cpf: user.documentNumberCpf,
            organization_id: user.organizationId,
            phone_number: user.phoneNumber,
            email: user.email,
            permissions: user.permissions,
            receive_notifications: user.receive_notifications,
            ignore_driver_expiration: user.ignoreDriverExpiration,
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });

  static firstLogin = ({
    email,
    password,
  }: ILogin): Promise<{
    token: string;
  }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.firstLogin(), { input: { email, password } })
        .then(data => {
          resolve({
            token: data.FirstLogin.token,
          });
          resolve({ token: data.FirstLogin.token });
        })
        .catch(reject)
    );

  static allowUserAccess = ({
    userId,
    email,
    organizationId,
    userType,
    permissions,
  }: IAllowUserAccess): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) => {
      client
        .mutation(queries.allowUserAccess(), {
          input: {
            user_id: userId,
            organization_id: organizationId,
            user_type: userType,
            email,
            permissions,
          },
        })
        .then(({ allowUserAccess }) => {
          resolve(allowUserAccess);
        })
        .catch(err => {
          reject(err);
        });
    });

  static updateUserData = ({
    userData,
    id,
  }: {
    userData: IUpdateUserData;
    id: string;
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.updateUserFirstLogin(), {
          where: {
            id,
          },
          input: {
            document_number_cpf: userData.documentNumberCpf,
            email: userData.email,
            name: userData.name,
            password: userData.password,
            phone_number: userData.phoneNumber,
          },
        })
        .then(({ updateUserData }) => {
          resolve(updateUserData);
        })
        .catch(err => {
          reject(err);
        })
    );

  static getCrops = (organization_id: string): Promise<ICrop[]> => {
    return new Promise((resolve, reject) =>
      client
        .query(queries.getCrops(), {
          where: { organization_id },
        })
        .then(({ getCropsData }) => {
          resolve(getCropsData);
        })
        .catch(err => reject(err))
    );
  };

  static getCustomerOrganization = (
    id: string
  ): Promise<ICustomerOrganization[]> => {
    return new Promise((resolve, reject) =>
      client
        .query(queries.getCustomerOrganization(), {
          where: {
            id,
          },
        })
        .then(({ customerOrganization }) => {
          resolve(customerOrganization);
        })
        .catch(err => reject(err))
    );
  };
}

export default UserController;
